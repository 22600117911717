import { Overlay, OverlayRef, PositionStrategy } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, Injector, TemplateRef } from '@angular/core';
import { CustomTooltipComponent } from './custom-tooltip.component';

/**
 * TooltipService is responsible for displaying tooltips in the application.
 * It manages the creation, positioning, and disposal of tooltip components.
 */
@Injectable({
  providedIn: 'root',
})
export class TooltipService {
  /** Reference to the currently displayed overlay tooltip. */
  private overlayRef?: OverlayRef;
  /** The current trigger element for the tooltip. */
  private currentTriggerElement?: HTMLElement;

  /**
   * Constructor to inject necessary services for creating tooltips.
   * @param overlay Instance of Overlay to manage the tooltip's overlay.
   * @param injector Instance of Injector for creating new injectors for tooltip content.
   */
  constructor(
    private overlay: Overlay,
    private injector: Injector,
  ) {}

  /**
   * Displays a tooltip connected to the specified trigger element.
   * @param triggerElement The element to which the tooltip is anchored.
   * @param content The content to display in the tooltip, either as a string or a TemplateRef.
   */
  showTooltip(triggerElement: HTMLElement, content: string | TemplateRef<unknown>) {
    this.hideTooltip(); // Close any existing tooltip

    this.currentTriggerElement = triggerElement; // Store the trigger element

    const positionStrategy = this.getPositionStrategy(triggerElement);
    this.overlayRef = this.overlay.create({ positionStrategy });

    const tooltipPortal = new ComponentPortal(CustomTooltipComponent, null, this.createInjector(content));
    this.overlayRef.attach(tooltipPortal);
  }

  /**
   * Hides the currently displayed tooltip, if any.
   */
  hideTooltip() {
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = undefined;
    }
    this.currentTriggerElement = undefined; // Clear the trigger element
  }

  /**
   * Returns the current trigger element for the tooltip.
   */
  getTriggerElement(): HTMLElement | undefined {
    return this.currentTriggerElement;
  }

  /**
   * Creates an Injector for providing tooltip content to the CustomTooltipComponent.
   * @param content The content for the tooltip, either as a string or a TemplateRef.
   * @returns A new Injector configured with the provided content.
   */
  private createInjector(content: string | TemplateRef<unknown>): Injector {
    return Injector.create({
      providers: [{ provide: 'tooltipContent', useValue: content }],
      parent: this.injector,
    });
  }

  /**
   * Determines the position strategy for the tooltip based on the trigger element's position.
   * @param triggerElement The element to which the tooltip is connected.
   * @returns A PositionStrategy object that defines where the tooltip will be displayed.
   */
  private getPositionStrategy(triggerElement: HTMLElement): PositionStrategy {
    const elementRect = triggerElement.getBoundingClientRect();
    const viewportHeight = window.innerHeight;
    const spaceBelow = viewportHeight - elementRect.bottom;
    const spaceAbove = elementRect.top;

    let positionStrategy = this.overlay.position().flexibleConnectedTo(triggerElement);

    if (spaceBelow >= 200 || spaceBelow >= spaceAbove) {
      // Position below
      positionStrategy = positionStrategy.withPositions([
        { originX: 'center', originY: 'bottom', overlayX: 'center', overlayY: 'top' },
      ]);
    } else {
      // Position above
      positionStrategy = positionStrategy.withPositions([
        { originX: 'center', originY: 'top', overlayX: 'center', overlayY: 'bottom' },
      ]);
    }

    return positionStrategy;
  }
}
