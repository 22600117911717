import { FormGroup } from '@angular/forms';

/**
 * Custom validator to check if two form controls have matching values.
 * @param controlName - The name of the control to compare.
 * @param matchingControlName - The name of the control to compare with.
 * @returns A validation function that checks if the values of the specified controls match.
 */
export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}

/**
 * Custom validator to check if at least one of the 'phone' or 'email' form controls is valid.
 * @param formControlName - The name of the optional control to validate (defaults to 'email').
 * @returns A validation function that checks if either 'phone' or 'email' is valid.
 */
export function oneValidator(formControlName?: string) {
  return (formGroup: FormGroup) => {
    const phone = formGroup.controls['phone'];
    const email = formControlName ? formGroup.controls[formControlName] : formGroup.controls['email'];

    if (email.value) {
      const match = email.value.match('[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,63}');
      if (match === null) {
        phone.setErrors({ optionalMatch: true });
        email.setErrors({ optionalMatch: true });
      } else {
        if (phone.value === null || phone.value === '') {
          phone.setErrors(null);
        }
        email.setErrors(null);
      }
    }
    if (phone.value) {
      const match = String(phone.value).match('^(\\+?d{1,4}[s-])?(?!0+s+,?$)\\d{10}s*,?$');
      if (match === null && phone.value.length !== 10) {
        phone.setErrors({ optionalMatch: true });
      } else {
        phone.setErrors(null);
        if (email.value === null || email.value === '') {
          email.setErrors(null);
        }
      }
    }

    if (phone.value?.length !== 10 && (email.value === null || email.value === '')) {
      phone.setErrors({ optionalMatch: true });
      email.setErrors({ optionalMatch: true });
    }
  };
}
