import { Component, Input } from '@angular/core';
/**
 * Dropdown menu component that displays a menu before or after the trigger element.
 */
@Component({
  selector: 'app-dropdown-menu',
  templateUrl: './dropdown-menu.component.html',
  styleUrls: ['./dropdown-menu.component.scss'],
})
export class DropdownMenuComponent {
  /**
   * Determines the horizontal position of the dropdown relative to the trigger element.
   * Possible values are 'before' (left of the trigger) or 'after' (right of the trigger).
   * Default value is 'after'.
   */
  @Input() public xPosition: 'before' | 'after' = 'after';
}
