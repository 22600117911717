import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TimepickerComponent } from './timepicker.component';
import { FeatherModule } from 'angular-feather';
import { NgbDropdownModule, NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [TimepickerComponent],
  imports: [CommonModule, FeatherModule, NgbDropdownModule, NgbTimepickerModule, FormsModule],
  exports: [TimepickerComponent],
})
export class TimepickerModule {}
