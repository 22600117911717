<div class="hs-tab-card-title flex-between">
  <h2>{{ 'contacts' | translate }}</h2>
  <div class="dialog-header-icons">
    <i-feather name="x" class="pointer" mat-dialog-close></i-feather>
  </div>
</div>
<mat-dialog-content>
  <mat-tab-group [disableRipple]="true" class="severity-lavel-tab-group">
    <mat-tab>
      <ng-template mat-tab-label>{{ 'contactList' | translate }}</ng-template>
      <div class="contacts-list">
        <div class="form-group search-input search-100">
          <input type="text" class="form-control" />
          <i-feather name="search" class="feather-20 clr-disabled"></i-feather>
        </div>
        <div class="contacts-list-group">
          <div class="contacts-type">
            <div class="contacts-type-header flex-between" (click)="patientsExpended = !patientsExpended">
              <p class="mb-0 ff-bold">{{ 'myPatients' | translate }}</p>
              <i-feather name="{{ patientsExpended ? 'chevron-up' : 'chevron-down' }}" class="feather-16"></i-feather>
            </div>
            <div class="contacts-type-content" [class.d-none]="!patientsExpended">
              <div class="contacts-list-item flex-between">
                <div class="contact-with-icon">
                  <div class="user-icon">
                    <img src="../../../../../../assets/images/icons/user.png" alt="user" />
                  </div>
                  <span>{{ 'mauriceCruz' | translate }}</span>
                </div>
                <i-feather name="mail" class="feather-16"></i-feather>
              </div>
              <div class="contacts-list-item flex-between">
                <div class="contact-with-icon">
                  <div class="user-icon">
                    <img src="../../../../../../assets/images/icons/user.png" alt="user" />
                  </div>
                  <span>{{ 'mauriceCruz' | translate }}</span>
                </div>
                <i-feather name="mail" class="feather-16"></i-feather>
              </div>
            </div>
          </div>
          <div class="contacts-type">
            <div class="contacts-type-header flex-between" (click)="facilitysExpended = !facilitysExpended">
              <p class="mb-0 ff-bold">{{ 'facilityStaff' | translate }}</p>
              <i-feather name="{{ facilitysExpended ? 'chevron-up' : 'chevron-down' }}" class="feather-16"></i-feather>
            </div>
          </div>
          <div class="contacts-type">
            <div class="contacts-type-header flex-between" (click)="medicalExpended = !medicalExpended">
              <p class="mb-0 ff-bold">{{ 'myMedicalStaff' | translate }}</p>
              <i-feather name="{{ medicalExpended ? 'chevron-up' : 'chevron-down' }}" class="feather-16"></i-feather>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>{{ 'history' | translate }}</ng-template>
      Content 2
    </mat-tab>
  </mat-tab-group>
</mat-dialog-content>
