import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { ToastrService } from 'ngx-toastr';
import { PermissionsService } from 'src/app/modules/permissions/services/permissions.service';
import { CommunicationService } from 'src/app/modules/settings/communication/communication.service';
import {
  OOOResponse,
  OutOfOffice,
  ToggleView,
} from 'src/app/modules/settings/communication/components/interfaces/out-of-office';
import { TranslatePipe } from 'src/app/modules/shared/pipes/translate.pipe';
import { Utils } from 'src/app/modules/shared/utils/utils';

/**
 * Component to manage Out of Office settings.
 */
@Component({
  selector: 'app-out-of-office',
  templateUrl: './out-of-office.component.html',
  styleUrls: ['./out-of-office.component.scss'],
})
export class OutOfOfficeComponent {
  /** Input to toggle view of Out of Office settings. */
  @Input() toggleView!: ToggleView;
  /** Form group for Out of Office settings. */
  outOfOfficeForm!: FormGroup;
  /** Object to hold Out of Office settings. */
  outOfOffice: OutOfOffice = {
    status: false,
    from: '',
    to: '',
    subject: '',
    body: '',
  };
  /** Today's date. */
  today = new Date();

  /**
   * Constructor for OutOfOfficeComponent.
   * @param fb FormBuilder instance for creating form controls.
   * @param communicationService CommunicationService instance for API interactions.
   * @param toastrService ToastrService instance for displaying notifications.
   * @param translatePipe TranslatePipe instance for translating text.
   */
  constructor(
    private fb: FormBuilder,
    private communicationService: CommunicationService,
    private permissionsService: PermissionsService,
    private toastrService: ToastrService,
    private translatePipe: TranslatePipe,
  ) {
    this.getOutOfOffice();
  }

  /**
   * Toggles the view of Out of Office settings.
   */
  toggleOOOView() {
    this.toggleView.outOfOffice = !this.toggleView.outOfOffice;
    this.createOOOFormControls();
    this.enableDisableOOODateControls();
    this.validateFormChanges();
  }

  /**
   * Handles changes in the 'status' checkbox.
   */
  onChangedStatus() {
    if (
      this.toggleView.outOfOffice &&
      this.outOfOfficeForm?.controls?.status?.value &&
      this.outOfOfficeForm?.controls?.from?.value
    ) {
      if (!this.communicationService.dateIsLessThanToday(this.outOfOfficeForm?.value?.from)) {
        this.setOOODates();
      }
    }
    this.enableDisableOOODateControls();
  }

  /**
   * Enables or disables date controls based on the 'status' checkbox.
   */
  enableDisableOOODateControls() {
    if (!this.outOfOfficeForm?.value?.status) {
      this.outOfOfficeForm.controls.from.disable();
      this.outOfOfficeForm.controls.to.disable();
    } else {
      this.outOfOfficeForm.controls.from.enable();
      this.outOfOfficeForm.controls.to.enable();
    }
    this.outOfOfficeForm.updateValueAndValidity();
    this.validateFormChanges();
  }

  /**
   * Sets default 'from' and 'to' dates.
   */
  setOOODates() {
    const from = Utils.startOfDate();
    const to = Utils.getNextDate(from);
    this.outOfOfficeForm.controls?.from?.patchValue(from);
    this.outOfOfficeForm.controls?.to?.patchValue(to);
    this.outOfOfficeForm.updateValueAndValidity();
  }

  /**
   * Handles changes in the 'from' date.
   * @param from Event containing the selected 'from' date.
   */
  onChangedFromDate(from: MatDatepickerInputEvent<Date>) {
    if (from?.value) {
      this.outOfOfficeForm.controls?.to?.patchValue(from?.value);
      this.outOfOfficeForm.updateValueAndValidity();
    }
  }

  /**
   * Validates if there are changes in the form compared to the original settings.
   */
  validateFormChanges() {
    if (
      this.outOfOfficeForm.value.subject !== this.outOfOffice.subject ||
      this.outOfOfficeForm.value.body !== this.outOfOffice.body ||
      (this.outOfOfficeForm.value.from && this.outOfOfficeForm.value.from !== this.outOfOffice.from) ||
      (this.outOfOfficeForm.value.to && this.outOfOfficeForm.value.to !== this.outOfOffice.to) ||
      this.outOfOfficeForm.value.status !== this.outOfOffice.status
    ) {
      this.communicationService.calendarChanges.outOfOffice.hasChanges = true;
    } else {
      this.communicationService.calendarChanges.outOfOffice.hasChanges = false;
    }
  }

  /**
   * Opens a confirmation dialog to reset settings to default.
   */
  openResetSettingsConfirmDialog() {
    const data = {
      title: `${this.translatePipe.transform('resetToDefaultSettingsTitle')}`,
      message:
        `${this.translatePipe.transform('resetOOOSettingsConfirmLabel')}` ||
        'Your Out of Office settings will be reset to the default settings defined for the account. Are you sure you want to continue?',
    };
    const confirmDialog = this.communicationService.openOOOConfirmDialog(data);
    confirmDialog.afterClosed().subscribe({
      next: (response) => {
        if (response) {
          this.resetToDefaultOOOSettings();
        }
      },
      error: (error) => {
        if (error) {
          //  console.log(error);
        }
      },
    });
  }

  /**
   * Creates form controls for Out of Office settings.
   */
  createOOOFormControls() {
    this.outOfOfficeForm = this.fb.group({
      status: [this.outOfOffice.status || false],
      from: [this.outOfOffice.from || '', Validators.required],
      to: [this.outOfOffice.to || '', Validators.required],
      subject: [this.outOfOffice.subject || '', Validators.required],
      body: [this.outOfOffice.body || '', Validators.required],
    });
  }

  /**
   * Callback function invoked after successful update of Out of Office settings.
   * @param response Updated OutOfOffice object.
   */
  updateSuccessHandlerCallBack(response: OutOfOffice) {
    if (response) {
      this.getOutOfOffice();
      this.toggleOOOView();
      this.toastrService.success(this.translatePipe.transform('outOfOfficeSettingsUpdatedSuccessfully'));
    }
  }

  /**
   * Submits the Out of Office form if valid.
   */
  submit() {
    this.outOfOfficeForm.controls.subject.patchValue(this.outOfOfficeForm.value.subject.trim());
    this.outOfOfficeForm.controls.body.patchValue(this.outOfOfficeForm.value.body.trim());
    if (this.outOfOfficeForm.valid) {
      this.updateOutOfOffice();
    }
  }

  /**
   * Retrieves Out of Office settings from the server.
   */
  getOutOfOffice() {
    if (!this.permissionsService.hasPermission('self.OutOfOffice.Read')) {
      return;
    }
    this.communicationService.getOutOfOffice().subscribe({
      next: (response: OOOResponse) => {
        if (response?.data) {
          this.outOfOffice = response?.data;
        }
      },
      error: (error) => {
        if (error) {
          //  console.log(error);
        }
      },
    });
  }

  /**
   * Updates Out of Office settings on the server.
   */
  updateOutOfOffice() {
    const oooPostO = this.outOfOfficeForm.value;
    oooPostO.from = Utils.convertLocalToUTC(this.outOfOfficeForm.value.from);
    oooPostO.to = Utils.convertLocalToUTC(this.outOfOfficeForm.value.to);
    this.communicationService.updateOutOfOffice(oooPostO).subscribe({
      next: (response) => {
        this.updateSuccessHandlerCallBack(response);
      },
      error: (error) => {
        if (error) {
          //  console.log(error);
        }
      },
    });
  }

  /**
   * Resets Out of Office settings to default on the server.
   */
  resetToDefaultOOOSettings() {
    this.communicationService.resetDefaultOOOSettings().subscribe({
      next: (response) => {
        this.updateSuccessHandlerCallBack(response);
      },
      error: (error) => {
        if (error) {
          //  console.log(error);
        }
      },
    });
  }
}
