<form [formGroup]="userGrpEditForm">
  <div class="user-groups-filter">
    <div class="ug-filter-label">
      <i-feather name="filter" class="feather-20"></i-feather>
      <span>Filter by:</span>
    </div>
    <div class="form-check">
      <input type="checkbox" (change)="filterUserGrp('custom')" class="form-check-input pointer" id="ug" />
      <label class="form-check-label pointer" for="ug">User Groups</label>
    </div>
    <div class="form-check">
      <input type="checkbox" (change)="filterUserGrp('default')" class="form-check-input pointer" id="manager-ug" />
      <label class="form-check-label pointer" for="manager-ug">Manager User Groups</label>
    </div>
  </div>
  @if (userGrpList.length > 0) {
    <div class="search-ug">
      <strong>Available Groups:</strong>
      <div class="form-group search-input">
        <i-feather name="search" class="feather-20"></i-feather>
        <input type="search" [formControl]="search" class="form-control" placeholder="Search" />
      </div>
    </div>
    <div class="ug-checklist-wrapper">
      <div class="ug-checklist-left">
        <div class="form-check ps-0">
          <!-- <input type="checkbox" id="selectAll" (change)="selectAll($event)" [indeterminate]="indeterminateSelectAll" class="pointer" id="sa" /> -->
          <mat-checkbox
            class="example-margin"
            [formControl]="selectAllCheckbox"
            (change)="selectAll($event)"
            [(indeterminate)]="indeterminateSelectAll"
            id="sa"
            labelPosition="after"
          >
            Select All
          </mat-checkbox>
        </div>
        @for (item of userGroups.controls; track item; let i = $index) {
          <div formArrayName="userGroupListForm">
            <div [formGroupName]="i" class="form-check">
              <input type="checkbox" class="form-check-input pointer" formControlName="value" [id]="i" />
              <label class="form-check-label pointer" [for]="i">{{ item.value.name }} </label>
            </div>
          </div>
        }
      </div>
      <div class="ug-checklist-controls">
        <!-- [disabled]="noSelectedUserGrp" -->
        <button class="btn btn-primary" [disabled]="anySelectedInLeftList()" (click)="moveRight()">
          <i-feather name="chevron-right" class="feather-20"></i-feather>
        </button>
        <button class="btn btn-primary" [disabled]="anySelectedInRightList()" (click)="moveLeft()">
          <i-feather name="chevron-left" class="feather-20"></i-feather>
        </button>
      </div>
      <div class="ug-right-wrapper">
        <strong class="selected-ug">Selected Groups:</strong>
        <div class="ug-checklist-right">
          @for (item of selectedUserGrp.controls; track item; let i = $index) {
            <div class="ug-checklist-type" formArrayName="selectedUserGroupListForm">
              <ng-container [formGroupName]="i">
                <div class="form-check">
                  <input type="checkbox" class="form-check-input pointer" formControlName="value" [id]="i" />
                  <label class="form-check-label pointer" [for]="i">{{ item.value.name }} </label>
                </div>
                <div class="custom-select-arrow">
                  <div class="ug-member-select">
                    <strong [ngClass]="{ disabled: item.get('role')?.value === 'owner' }">Type:</strong>
                    <mat-select
                      class="ug-select"
                      formControlName="role"
                      panelClass="ug-select-panel"
                      [ngClass]="{
                        'is-invalid': item.get('role')?.invalid && (submitted || item.get('role')?.touched)
                      }"
                    >
                      @for (type of userGroupTypes; track type) {
                        <mat-option [hidden]="type.value === 'owner'" [value]="type.value" [value]="type.value">
                          {{ type.viewValue }}
                        </mat-option>
                      }
                    </mat-select>
                  </div>
                </div>
              </ng-container>
            </div>
          }
        </div>
      </div>
    </div>
  }
</form>
