import { Injectable } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpService } from './http.service';
/**
 * Service for handling file uploads and related operations.
 */
@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  /**
   * Constructs an instance of FileUploadService.
   *
   * @param httpService - The HTTP service used for making HTTP requests.
   */
  constructor(private httpService: HttpService) {}
  /**
   * Uploads a single file.
   * @param file The file to upload (as FormData).
   * @param fileType Type of the file.
   * @param headers Optional headers for the HTTP request.
   * @param hideLoader Whether to hide the loader during the request (default: false).
   * @param queryParametersString Additional query parameters for the URL (default: '').
   * @returns Observable that emits HttpEvent<FileUploadSuccess>.
   */
  public uploadFile(
    file?: FormData,
    fileType?: string,
    headers?: object,
    hideLoader = false,
    queryParametersString = '',
  ) {
    const url = `${environment.url.apiHost}${environment.url.version}/fileUpload?fileType=${fileType}${queryParametersString}`;
    return this.httpService.post(url, file, headers, hideLoader);
  }
  /**
   * Uploads multiple files.
   * @param files The files to upload (as FormData).
   * @param fileCategory Category/type of the files being uploaded.
   * @param headers Optional headers for the HTTP request.
   * @param hideLoader Whether to hide the loader during the request (default: false).
   * @param queryParametersString Additional query parameters for the URL (default: '').
   * @returns Observable of unknown type.
   */
  public uploadMultipleFiles(
    files: FormData,
    fileCategory: string,
    headers: unknown,
    hideLoader = false,
    queryParametersString = '',
  ): Observable<unknown> {
    const url = `${environment.url.apiHost}${environment.url.version}/fileUpload/multiple?fileType=${fileCategory}${queryParametersString}`;
    return this.httpService.post(url, files, headers, hideLoader);
  }
  /**
   * Retrieves the access signature URL for uploading a file.
   *
   * @param fileName - Optional. The name of the file for which to retrieve the access signature.
   * @returns A promise resolving to an object containing the URL for uploading the file.
   */
  public async uploadFileAccessSignature(fileName?: string): Promise<{ url: string }> {
    const url = `${environment.url.apiHost}${environment.url.version}/fileUpload/${fileName}?container=DOCUMENT`;
    return firstValueFrom(this.httpService.get(url));
  }
  /**
   * Retrieves the file extension from a given file URL.
   *
   * @param fileUrl - The URL of the file from which to extract the extension.
   * @returns The file extension extracted from the file URL.
   */
  public getFileExtension(fileUrl: string) {
    let fileExt = '';
    if (fileUrl) {
      const FILE_EXT_REGEX = /.([0-9a-z]+)(?:[?#]|$)/i;
      const extension = fileUrl.toLowerCase().match(FILE_EXT_REGEX);
      const INDEX_NUMBER = 1;
      fileExt = extension && extension[INDEX_NUMBER] ? extension[INDEX_NUMBER] : '';
    }
    return fileExt;
  }
  /**
   * Determines the type of file based on its URL or extension.
   *
   * @param fileUrl - The URL of the file or its extension.
   * @returns A string representing the type of the file ('image', 'pdf', 'worddoc', 'docx', 'txt', 'audio', 'video', 'youtube', 'url', or 'other').
   */
  public getFileType(fileUrl: string): string {
    let fileType = '';
    if (fileUrl) {
      const fileExt = this.getFileExtension(fileUrl);
      if (fileExt.match(/^(jpg|jpeg|png|gif|bmp|raw|svg|tiff|webp|heif|image\/png)$/)) {
        fileType = 'image';
      } else if (fileExt.match(/^pdf$/)) {
        fileType = 'pdf';
      } else if (fileExt.match(/^(doc|docx|dot|docm|dotx|dotm|docb|xls|xlsm|xltx|xltm|ppt|pot|pps|pptm|potx|potm)$/)) {
        fileType = 'worddoc';
      } else if (fileExt.match(/^(docx|xlsx|pptx|xdoc|ods|excel|msword|rtf)$/)) {
        fileType = 'docx';
      } else if (fileExt.match(/^(txt)$/)) {
        fileType = 'txt';
      } else if (fileExt.match(/^(mp3|ogg|oga|ogv)$/)) {
        fileType = 'audio';
      } else if (fileExt.match(/^(mp4|webm|flv|wmv|avi|mpg|mpeg|mov)$/)) {
        fileType = 'video';
      } else {
        if (
          fileUrl.match(
            /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/,
          )
        ) {
          fileType = 'youtube';
        } else if (
          fileUrl.match(
            /(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)/,
          )
        ) {
          fileType = 'video';
        } else if (
          fileUrl.match(
            /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
          )
        ) {
          fileType = 'url';
        } else {
          fileType = 'other';
        }
      }
    }
    return fileType;
  }
  /**
   * Determines the icon path based on the file type derived from the file URL.
   *
   * @param fileUrl - The URL of the file.
   * @returns A string representing the path to the corresponding icon image.
   */
  public getFileIcon(fileUrl: string): string {
    let icon = '';
    if (fileUrl) {
      const fileType = this.getFileType(fileUrl);
      switch (fileType) {
        case 'image':
          icon = 'assets/images/icons/image.svg';
          break;
        case 'pdf':
          icon = 'assets/images/icons/pdf.svg';
          break;
        case 'worddoc':
        case 'docx':
        case 'txt':
          icon = 'assets/images/icons/doc.svg';
          break;
        case 'audio':
          icon = 'assets/images/icons/video.svg';
          break;
        case 'video':
          icon = 'assets/images/icons/video.svg';
          break;
        case 'youtube':
          icon = 'assets/images/icons/youtube.svg';
          break;
        case 'url':
          icon = 'assets/images/icons/link.svg';
          break;
        case 'other':
          icon = 'assets/images/icons/no-preview.svg';
          break;
        default:
          icon = 'assets/images/icons/no-preview.svg';
          break;
      }
    }
    return icon;
  }
  /**
   * Determines the MIME type of the file based on its URL.
   *
   * @param fileUrl - The URL of the file.
   * @returns A string representing the MIME type of the file.
   */
  public getMimeType(fileUrl: string): string {
    const fileExtension = this.getFileExtension(fileUrl);
    const fileType = this.getFileType(fileUrl);
    let mimeType = '';
    switch (fileType) {
      case 'image':
        switch (fileExtension) {
          case 'heif':
            mimeType = 'image/heif';
            break;
          case 'png':
            mimeType = 'image/png';
            break;
          case 'bmp':
            mimeType = 'image/bmp';
            break;
          case 'jpg':
          case 'jpeg':
            mimeType = 'image/jpeg';
            break;
          case 'gif':
            mimeType = 'image/gif';
            break;
          case 'svg':
            mimeType = 'image/svg+xml';
            break;
          case 'webp':
            mimeType = 'image/webp';
            break;
          default:
            mimeType = '';
            break;
        }
        break;
      case 'video':
        switch (fileExtension) {
          case 'avi':
            mimeType = 'video/x-msvideo';
            break;
          case 'mp4':
            mimeType = 'video/mp4';
            break;
          case 'mpeg':
          case 'mpg':
            mimeType = 'video/mpeg';
            break;
          case 'webm':
            mimeType = 'video/webm';
            break;
          case 'mov':
            mimeType = 'video/quicktime';
            break;
          case 'flv':
            mimeType = 'video/x-flv';
            break;
          case 'oga':
          case 'ogv':
          case 'ogg':
            mimeType = 'video/ogg';
            break;
          default:
            mimeType = '';
            break;
        }
        break;
      case 'audio':
        switch (fileExtension) {
          case 'mp3':
            mimeType = 'audio/mpeg';
            break;
          case 'oga':
          case 'ogv':
          case 'ogg':
            mimeType = 'audio/ogg';
            break;
          default:
            mimeType = '';
            break;
        }
        break;
      case 'pdf':
        mimeType = 'application/pdf';
        break;
      case 'txt':
        mimeType = 'text/plain';
        break;
      default:
        mimeType = '';
        break;
    }
    return mimeType;
  }
  /**
   * Retrieves the file access signature for uploading video lobby recordings.
   *
   * @param fileName - Optional. The name of the file for which the access signature is requested.
   * @returns A Promise resolving to an object containing the URL for file upload.
   */
  public async getVideoLobbyUploadFileAccessSignature(fileName?: string): Promise<{ url: string }> {
    const url = `${environment.url.apiHost}${environment.url.version}/videoLobby/recording/${fileName}`;
    return firstValueFrom(this.httpService.get(url));
  }
}
