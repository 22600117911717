import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { FeatherModule } from 'angular-feather';
import { ConfirmationDialogData } from '../../interfaces/common.entities';
/**
 * Component representing an information dialog.
 */
@Component({
  selector: 'app-information-dialog',
  standalone: true,
  imports: [MatDialogModule, FeatherModule],
  templateUrl: './information-dialog.component.html',
  styleUrl: './information-dialog.component.scss',
})
export class InformationDialogComponent {
  /**
   * Constructor for InformationDialogComponent.
   * @param data Injected data containing dialog information.
   */
  public constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData) {}
}
