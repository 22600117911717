import { Component, Inject } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { FeatherModule } from 'angular-feather';
import {
  FrequencyOptions,
  IValueLabel,
  MonthOption,
  MonthOptions,
  SetPosOptions,
  WeekOption,
  WeekOptions,
} from 'src/app/modules/calendar/entities/calendar-entities';
import { TranslatePipe } from 'src/app/modules/shared/pipes/translate.pipe';
import { CalendarService } from '../../services/calendar.service';

/**
 * Custom Recurrence Options Component
 */
@Component({
  selector: 'app-custom-recurrence-options',
  templateUrl: './custom-recurrence-options.component.html',
  styleUrls: ['./custom-recurrence-options.component.scss'],
  standalone: true,
  imports: [MatDialogModule, FeatherModule, TranslatePipe, ReactiveFormsModule, MatSelectModule],
})
export class CustomRecurrenceOptionsComponent {
  /**
   * Represents the form group for rule settings.
   */
  rRuleSettingFormGroup;
  /**
   * Array of available frequency options for recurrence.
   * These options are used in the recurrence settings UI.
   */
  availableFreqOptions = FrequencyOptions;
  /**
   * Array of available week options.
   * These options are used when setting weekly recurrence rules.
   */
  availableWeekOptions = WeekOptions;
  /**
   * Array of available options for 'by set position' in recurrence rules.
   */
  availableBySetPosOptions = SetPosOptions;
  /**
   * Array of available month options.
   * These options are used when setting monthly recurrence rules.
   */
  availableMonthOptions = MonthOptions;
  /**
   * Array of available month days.
   * Represents the days of the month (1 to 31) that can be selected.
   */
  availableMonthDays = [...Array(31).keys()].map((i) => {
    return { label: `${i + 1}`, value: i + 1 };
  });

  /**
   * Constructor of the component.
   * @param calendarService The service for handling calendar operations
   * @param dialogRef Reference to the dialog opened with this component
   * @param data Data passed to the dialog, containing form group data
   */
  constructor(
    private calendarService: CalendarService,
    private dialogRef: MatDialogRef<CustomRecurrenceOptionsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { rRuleSettingFormGroup: FormGroup },
  ) {
    this.rRuleSettingFormGroup = this.calendarService.getRRuleSettingFormGroup(this.data.rRuleSettingFormGroup.value);
  }

  /**
   * Checks if the given weekday is active in the form group.
   * @param value The value of the weekday (e.g., 'MO', 'TU', etc.)
   * @returns True if active, false otherwise
   */
  isActiveByWeekDay(value: string) {
    return this.rRuleSettingFormGroup?.controls?.byweekday.value.indexOf(value) >= 0 ? true : false;
  }

  /**
   * Checks if the given month day is active in the form group.
   * @param value The value of the month day
   * @returns True if active, false otherwise
   */
  isActiveByMonthDay(value: number) {
    return this.rRuleSettingFormGroup?.controls?.bymonthday.value.indexOf(String(value)) >= 0 ? true : false;
  }

  /**
   * Checks if the given month is active in the form group.
   * @param value The value of the month
   * @returns True if active, false otherwise
   */
  isActiveByMonth(value: number) {
    return this.rRuleSettingFormGroup?.controls?.bymonth.value.indexOf(String(value)) >= 0 ? true : false;
  }

  /**
   * Sets the week day as active or inactive in the form group.
   * @param weekByDay The week day option to set
   */
  setByWeekDay(weekByDay: WeekOption) {
    if (weekByDay) {
      if (this.rRuleSettingFormGroup?.controls?.byweekday?.value.indexOf(weekByDay.pValue) >= 0) {
        this.rRuleSettingFormGroup?.controls?.byweekday.value.splice(
          this.rRuleSettingFormGroup?.controls?.byweekday.value.indexOf(weekByDay.pValue),
          1,
        );
      } else {
        this.rRuleSettingFormGroup?.controls?.byweekday.value.push(weekByDay.pValue);
      }
    }
  }

  /**
   * Sets the month day as active or inactive in the form group.
   * @param byMonthDay The month day option to set
   */
  setByMonthDay(byMonthDay: IValueLabel) {
    if (byMonthDay) {
      if (this.rRuleSettingFormGroup?.controls?.bymonthday?.value.indexOf(String(byMonthDay.value)) >= 0) {
        this.rRuleSettingFormGroup?.controls?.bymonthday.value.splice(
          this.rRuleSettingFormGroup?.controls?.bymonthday.value.indexOf(String(byMonthDay.value)),
          1,
        );
      } else {
        this.rRuleSettingFormGroup?.controls?.bymonthday.value.push(String(byMonthDay.value));
      }
    }
  }

  /**
   * Sets the month as active or inactive in the form group.
   * @param byMonth The month option to set
   */
  setByMonth(byMonth: MonthOption) {
    if (byMonth) {
      if (this.rRuleSettingFormGroup?.controls?.bymonth?.value.indexOf(String(byMonth.value)) >= 0) {
        this.rRuleSettingFormGroup?.controls?.bymonth.value.splice(
          this.rRuleSettingFormGroup?.controls?.bymonth.value.indexOf(String(byMonth.value)),
          1,
        );
      } else {
        this.rRuleSettingFormGroup?.controls?.bymonth.value.push(String(byMonth.value));
      }
    }
  }

  /**
   * Handles the change event for frequency options.
   * Resets relevant form controls based on the selected frequency.
   */
  onChangeFrequencyOptions() {
    this.rRuleSettingFormGroup?.controls?.eachOption?.patchValue('eachOption');
    this.rRuleSettingFormGroup?.controls?.bymonthday?.patchValue([]);
    this.rRuleSettingFormGroup?.controls?.byweekday?.patchValue([]);
    this.rRuleSettingFormGroup?.controls?.bymonth?.patchValue([]);
    this.rRuleSettingFormGroup?.controls?.bysetpos?.patchValue(null);
    this.rRuleSettingFormGroup?.controls?.onTheWeekOption?.patchValue(null);
    this.rRuleSettingFormGroup?.controls?.onTheOption?.patchValue(false);
    this.rRuleSettingFormGroup.updateValueAndValidity();
  }

  /**
   * Handles the change event for each option.
   * Invokes the common callback for radio options change.
   */
  onChangeEachOption() {
    this.onChangeRadioOptionsCallBack();
  }

  /**
   * Handles the change event for on-the option.
   * Invokes the common callback for radio options change.
   */
  onChangeOnTheOption() {
    this.onChangeRadioOptionsCallBack();
  }

  /**
   * Resets form controls based on radio options change.
   */
  onChangeRadioOptionsCallBack() {
    this.rRuleSettingFormGroup?.controls?.onTheWeekOption.patchValue(null);
    this.rRuleSettingFormGroup.controls?.bymonthday?.patchValue([]);
    this.rRuleSettingFormGroup.controls?.byweekday?.patchValue([]);
    this.rRuleSettingFormGroup?.controls?.bysetpos?.patchValue(null);
    this.rRuleSettingFormGroup.updateValueAndValidity();
  }

  /**
   * Handles the change event for on-the-week options.
   * Updates the form group with selected week options.
   */
  onChangeOnTheWeekOptions() {
    const weekOption = this.rRuleSettingFormGroup?.value?.onTheWeekOption || null;
    if (weekOption) {
      if (weekOption === 'allDay' || weekOption === 'weekday' || weekOption === 'weekend') {
        if (weekOption === 'allDay') {
          this.rRuleSettingFormGroup.controls?.byweekday?.patchValue(['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU']);
        }
        if (weekOption === 'weekday') {
          this.rRuleSettingFormGroup.controls?.byweekday?.patchValue(['MO', 'TU', 'WE', 'TH', 'FR']);
        }
        if (weekOption === 'weekend') {
          this.rRuleSettingFormGroup.controls?.byweekday?.patchValue(['SA', 'SU']);
        }
      } else {
        if (weekOption) {
          this.rRuleSettingFormGroup.controls?.byweekday?.patchValue([]);
          this.setByWeekDay(weekOption);
        }
      }
      this.rRuleSettingFormGroup.updateValueAndValidity();
    }
  }

  /**
   * Validates the form for custom recurrence options.
   * @returns True if form is valid, false otherwise
   */
  validate() {
    let valid = false;
    if (this.rRuleSettingFormGroup?.controls?.freq.value?.toLowerCase() === 'daily') {
      valid = true;
    }
    if (this.rRuleSettingFormGroup?.controls?.freq.value?.toLowerCase() === 'weekly') {
      valid = this.rRuleSettingFormGroup?.controls?.byweekday?.value.length > 0 ? true : false;
    }
    if (this.rRuleSettingFormGroup?.controls?.freq.value?.toLowerCase() === 'monthly') {
      if (this.rRuleSettingFormGroup?.controls?.eachOption?.value === 'eachOption') {
        valid = this.rRuleSettingFormGroup?.controls?.bymonthday?.value.length > 0 ? true : false;
      }
      if (this.rRuleSettingFormGroup?.controls?.eachOption?.value === 'onTheOption') {
        valid =
          this.rRuleSettingFormGroup?.controls?.bysetpos?.value &&
          this.rRuleSettingFormGroup?.controls?.byweekday?.value.length > 0
            ? true
            : false;
      }
    }
    if (this.rRuleSettingFormGroup?.controls?.freq.value?.toLowerCase() === 'yearly') {
      if (this.rRuleSettingFormGroup?.controls?.onTheOption?.value) {
        valid =
          this.rRuleSettingFormGroup?.controls?.bysetpos?.value &&
          this.rRuleSettingFormGroup?.controls?.byweekday?.value.length > 0 &&
          this.rRuleSettingFormGroup?.controls?.bymonth?.value.length > 0
            ? true
            : false;
      } else {
        valid = this.rRuleSettingFormGroup?.controls?.bymonth?.value.length > 0 ? true : false;
      }
    }
    return valid && this.rRuleSettingFormGroup.valid ? true : false;
  }

  /**
   * Validates the form and closes the dialog if valid.
   * Only closes the dialog if the form passes validation.
   */
  setCustomRRuleSettings() {
    if (this.validate()) {
      this.dialogRef.close(this.rRuleSettingFormGroup);
    }
  }
}
