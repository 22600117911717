<div class="page-header">
  <div class="page-header-grid">
    <h1 *ngIf="!filterEnable">{{ 'userManageMent' | translate }}</h1>
    <h1 *ngIf="filterEnable">{{ 'systemUsers' | translate }}</h1>

    <div *appPermissions="'self.Management_User.Write'" class="btn-group">
      <button *ngIf="filterEnable" class="btn btn-primary btn-flex" [routerLink]="['/users/add']">
        <i-feather name="plus-circle" class="feather-20 mr-2"></i-feather> {{ 'addUser' | translate }}
      </button>
      <button
        *ngIf="!filterEnable"
        class="btn btn-primary btn-flex"
        [routerLink]="['/userManagment/add']"
        [queryParams]="{ clientId: clientID }"
      >
        <i-feather name="plus-circle" class="feather-20 mr-2"></i-feather> {{ 'addUser' | translate }}
      </button>
    </div>
  </div>
</div>
<div class="page-content-wrapper">
  @if (usersGroup) {
    <div class="card-base">
      <div class="flex-between user-serach-flex pb-3">
        <div class="form-group">
          <label>Search by User ID or External ID</label>
          <div class="user-search">
            <div class="input-group input-search">
              <input type="text" class="form-control" [(ngModel)]="search" />
              <div class="input-group-prepend">
                <div class="input-group-text" [ngClass]="search === '' ? '' : 'search-active'">
                  <i-feather name="search" (click)="searchByID(search)"></i-feather>
                </div>
              </div>
            </div>
            <button class="btn btn-secondary" *ngIf="search !== ''" (click)="cancelSearch()">cancel</button>
          </div>
          <div class="" *ngIf="search !== '' && externalIdSearchUsers">No record found.</div>
        </div>

        <div class="filter-box">
          <div class="filter-label">
            <span class="pe-1">{{ 'filterBy' | translate }}:</span>
            <span class="pe-0 pointer" [matMenuTriggerFor]="userMatMenu">
              <span> {{ selectedFilterByOption.displayText || 'All clients' }}</span>
              <i-feather name="chevron-down" class="feather-16"></i-feather>
            </span>
          </div>
          <mat-menu #userMatMenu="matMenu" class="mat-more-dropdown">
            <a mat-menu-item (click)="onChangeFilterOptions(fOption)" *ngFor="let fOption of filterOptions">{{
              fOption.displayText
            }}</a>
          </mat-menu>
        </div>
      </div>

      <app-table
        class="prov-table-size action-enabled"
        [columns]="columns"
        [displayedColumns]="displayedColumns"
        [paginationOptions]="paginationOptions"
        [data]="usersGroup"
        [tableRender]="usedTable"
        (clickOnActionItems)="toggleUserStatus($event)"
        (delete)="deleteUser()"
        (sort)="sortByColumn($event)"
        (refetch)="refreshUsers($event)"
        (search)="openSearchAndFiltersDialog($event)"
        (filter)="openSearchAndFiltersDialog($event)"
        (clear)="clear($event)"
      ></app-table>
    </div>
  }
</div>
