import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PatientTabsItem } from '../../dashboard/health-summary-tabs/entities/tabs.entities';
import { NotesTypes } from '../../shared/interfaces/common.entities';
import { NotesComponent } from '../component/notes.component';
import { NotesDialogComponent } from '../notes-dialog/notes-dialog.component';
import { NotesCommonRequest } from '../notes.entities';
/**
 * Component that represents a widget for managing and displaying notes related to a patient or trial.
 * It allows adding and viewing notes through a dialog.
 */
@Component({
  selector: 'app-notes-widget',
  templateUrl: './notes-widget.component.html',
  styleUrl: './notes-widget.component.scss',
})
export class NotesWidgetComponent implements OnInit {
  /**
   * Input: Selected item from patient tabs to associate notes with.
   */
  @Input() public selectedItem!: PatientTabsItem;
  /**
   * Input: Patient ID to associate notes with.
   */
  @Input() public patientId!: string;
  /**
   * Data object for managing notes.
   */
  data!: NotesCommonRequest;
  /**
   * Reference to NotesComponent for accessing its methods.
   */
  @ViewChild(NotesComponent) notesComponent: NotesComponent | undefined;

  /**
   * Constructs the NotesWidgetComponent.
   * @param dialog MatDialog service for opening dialogs.
   */
  constructor(private dialog: MatDialog) {}

  /**
   * Initializes the component by fetching notes data and assigning it to `this.data`.
   */
  ngOnInit(): void {
    this.data = this.getNotesObject();
  }

  /**
   * Opens a dialog for adding new notes.
   * Subscribes to dialog closed event to refresh notes list if applicable.
   */
  clickAddNotes() {
    const notesData: NotesCommonRequest = this.getNotesObject();
    notesData.hideNoteInputBox = false;

    const dialog: MatDialogRef<NotesDialogComponent> = this.dialog.open(NotesDialogComponent, {
      data: notesData,
      disableClose: true,
      panelClass: 'full-screen-dialog',
    });
    dialog.afterClosed().subscribe((): void => {
      if (this.notesComponent) this.notesComponent.getAllNotes();
    });
  }

  /**
   * Generates the NotesCommonRequest object based on selected item or patient ID.
   * @returns NotesCommonRequest An object containing endpoint and data for managing notes.
   */
  private getNotesObject() {
    let notesData: NotesCommonRequest;
    //for reach specific patient
    if (this.selectedItem.reachPatientDetails) {
      notesData = {
        endPoint: `/reach/${this.selectedItem.reachPatientDetails.trialId}/patient/${this.selectedItem.patientId}`,
        hideNoteInputBox: true,
        notesRequestData: {
          userId: this.selectedItem.patientId || '',
          relatedTo: {
            type: NotesTypes.ReachTrial,
          },
        },
      };
    } else {
      notesData = {
        endPoint: `/user/${this.patientId}`,
        hideNoteInputBox: true,
        notesRequestData: {
          userId: this.patientId,
          relatedTo: {
            type: NotesTypes.User,
            id: this.patientId,
          },
        },
      };
    }

    return notesData;
  }
}
