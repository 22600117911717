<header class="site-header">
  <div class="header-wrapper">
    <div class="header-left">
      <div class="icon-buttons">
        <button
          class="btn btn-trigger"
          type="button"
          (click)="openDashboardDialog()"
          [ngClass]="{ 'close-menu': menu }"
        >
          <i-feather name="menu"></i-feather>
          <i-feather name="x"></i-feather>
        </button>
      </div>
      <div class="header-logo pointer" routerLink="/dashboard">
        <!-- {{userConfiguration?.clientImageUrl}} -->
        <span *ngIf="userConfiguration && userConfiguration.clientImageUrl; else elseTemplate">
          <img class="web-logo" [src]="userConfiguration?.clientImageUrl" />
        </span>

        <ng-template #elseTemplate>
          <img class="web-logo" src="assets/images/logo/medocity.png" alt="Medocity" />
          <img class="mobile-logo" src="assets/images/logo/small/medocity.svg" alt="Medocity" />
        </ng-template>
      </div>
    </div>
    <div class="header-right">
      <div class="hr-col icon-buttons">
        <button class="btn position-relative btn-primary" type="button" (click)="openChatAssistant()">
          <i-feather name="message-circle"></i-feather>
        </button>
      </div>

      <div class="hr-col icon-buttons">
        <!-- <i-feather name="search" class=" header-mobile-search" (click)="openSearchDialog()"></i-feather> -->
        <button
          class="btn position-relative btn-primary"
          type="button"
          [ngClass]="{ 'btn-disable': videoLobbyService.pipMode() }"
          (click)="openVideoLobby()"
          *appPermissions="'self.VideoLobby.Read'"
        >
          <i-feather name="video" class="test1"></i-feather>
          <!-- <span class="badge rounded-pill">3</span> -->
        </button>

        <!-- <button class="btn btn-primary position-relative" type="button" (click)="openFilterDialog($event)">
          <i-feather name="filter"></i-feather>
          <span *ngIf="isFilterApplied" class="active-badge header-filter-badge"></span>
        </button> -->
      </div>
      <div class="hr-col active-user">
        <div ngbDropdown>
          <div class="active-user-toggle" id="activeUserDropdown" ngbDropdownToggle>
            <div class="active-user-icon">
              <img
                [src]="userData.imageUrl ? userData.imageUrl : 'assets/images/icons/user.png'"
                class="user-icon"
                width="32"
                height="32"
                alt="user"
              />
              <span [ngClass]="doNotDisturb ? 'active-badge' : 'inactive-badge'"></span>
            </div>
            <div class="dropbtn">
              <span>{{ userData.firstName }} {{ userData.lastName }}</span>
              <i-feather name="chevron-down" class="feather-20"></i-feather>
            </div>
          </div>
          <div ngbDropdownMenu aria-labelledby="activeUserDropdown" class="ngb-more-dropdown">
            <button ngbDropdownItem [routerLink]="['/manage-profile']" routerLinkActive="router-link-active">
              <i-feather name="user" class="feather-20 drop-icon"></i-feather
              ><span class="ngb-dropdown-text">{{ 'manageProfile' | translate }}</span>
            </button>
            <button ngbDropdownItem (click)="logOut()" class="active-user-logout">
              <i-feather name="log-out" class="feather-20 drop-icon"></i-feather
              ><span class="ngb-dropdown-text">{{ 'signOut' | translate }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
