/* eslint-disable @angular-eslint/use-lifecycle-interface */
import { Component, ElementRef, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
/**
 * Component for capturing photos from the camera.
 */
@Component({
  selector: 'app-camera-viewer',
  templateUrl: './camera-viewer.component.html',
  styleUrls: ['./camera-viewer.component.scss'],
})
export class CameraViewerComponent {
  /**
   * Reference to the video element in the template.
   */
  @ViewChild('video')
  public video!: ElementRef;
  /**
   * Reference to the canvas element in the template.
   */
  @ViewChild('canvas')
  public canvas!: ElementRef;
  /**
   * Array to store captured images.
   */
  public captures: string = '';
  /**
   * The media stream from the camera.
   */
  stream!: MediaStream;
  /**
   * The captured file.
   */
  file!: File;
  /**
   * The current file name index for captured files.
   */
  fileName = 0;
  /**
   * Constructor of the component.
   * @param dialogRef - Reference to the dialog used for displaying the component.
   */
  constructor(public dialogRef: MatDialogRef<CameraViewerComponent>) {
    this.captures = '';
  }

  /**
   * Starts video streaming from the user's camera.
   */
  public ngAfterViewInit() {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ video: true }).then((stream) => {
        this.video.nativeElement.srcObject = stream;
        this.video.nativeElement.play();
        this.stream = stream;
      });
    }
  }
  /**
   * Captures a photo from the video stream and prepares it for output.
   * Closes the dialog and returns the captured photo as a File object.
   */
  public capture() {
    this.canvas.nativeElement.getContext('2d').drawImage(this.video.nativeElement, 0, 0, 640, 480);
    this.captures = this.canvas.nativeElement.toDataURL('image/jpeg');
    this.file = this.dataURLtoFile(this.captures, Math.random() + 'a1.jpg');
    this.stream.getTracks().forEach(function (track) {
      track.stop();
    });

    this.dialogRef.close(this.file);
  }
  /**
   * Converts a data URL to a File object.
   * @param dataurl - The data URL representing the image.
   * @param filename - The desired filename for the File object.
   * @returns The converted File object.
   */
  public dataURLtoFile(data: string, filename: string) {
    const arr = data?.split(','),
      mime = arr[0].match(/:(.*?);/)?.[1],
      bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }
  /**
   * Closes the dialog and stops the video stream.
   */
  close() {
    this.stream.getTracks().forEach(function (track) {
      track.stop();
    });
    this.dialogRef.close();
  }
}
