<div class="table-wrapper">
  <div class="table-responsive">
    <table mat-table [dataSource]="dataSource" matSort>
      @for (column of columns; track column; let colIndex = $index) {
        <ng-container [matColumnDef]="column.title">
          <th
            mat-header-cell
            *matHeaderCellDef
            [style.min-width]="column?.columnWidth"
            [ngClass]="{
              'active-cell': column?.active
            }"
          >
            <span>
              <span>{{
                column && (column.title.toLowerCase() === 'manager' || column.title.toLowerCase() === 'managed')
                  ? (column.title | clientLabel)
                  : column.title
              }}</span>
            </span>
            @if (column.search?.icon) {
              <span class="search-filter badge-indicator">
                <i-feather name="search" class="feather-20 pointer" (click)="clickOnSearch($event, column)">
                </i-feather>
                @if (column.search?.active) {
                  <span class="inactive-badge"> </span>
                }
              </span>
            }
            @if (column.filter?.icon) {
              <span class="search-filter">
                <i-feather name="filter" class="feather-20 pointer" (click)="clickOnFilter($event, column)">
                </i-feather>
                @if (column.filter?.active) {
                  <span class="inactive-badge"> </span>
                }
              </span>
            }
            @if (column.sort) {
              <span class="tbl-sort">
                <i-feather
                  name="chevron-up"
                  class="feather-20"
                  [ngClass]="{
                    'active-sort': column.active && column.order && column.order === 'ASC'
                  }"
                  (click)="sortByColumn(column.key, 'ASC')"
                ></i-feather>
                <i-feather
                  name="chevron-down"
                  class="feather-20"
                  [ngClass]="{
                    'active-sort': column.active && column.order && column.order === 'DESC'
                  }"
                  (click)="sortByColumn(column.key, 'DESC')"
                ></i-feather>
              </span>
            }
            <!-- {{column | json}} -->
          </th>
          <td mat-cell *matCellDef="let element">
            <span class="d-flex">
              @if (element && column.key === 'name' && showCheckbox) {
                <span class="me-1">
                  <!-- {{element.checked}} -->
                  <div class="form-check-flex d-inline">
                    <input
                      type="checkbox"
                      [disabled]="element.isCheckBoxDisabled"
                      [(ngModel)]="element.checked"
                      (change)="changeChkState(element.id.queryParams.uid, element.checked)"
                      class="pointer form-check-input"
                    />
                  </div>
                </span>
              }
              @if (element && column.key) {
                <span
                  [ngClass]="{
                    'text-primary': column && column.hasLink
                  }"
                  class="d-flex align-items-center"
                >
                  @if (column && !column?.icons) {
                    <span>
                      <div class="head-in ws-pre-line">
                        {{
                          (column &&
                          column.key &&
                          (column.key.toLowerCase() === 'usertype' ||
                            column.key.toLowerCase() === 'rolemetatype' ||
                            column.key.toLowerCase() === 'acceptingusertypes')
                            ? (element[column.key].value | clientLabel)
                            : element[column.key].value) || '-'
                        }}
                      </div>
                      @if (element[column.key].queryParams.description) {
                        <div class="description mt-1">
                          {{ element[column.key].queryParams.description || '-' }}
                        </div>
                      }
                    </span>
                  }
                  @if (column && column?.icons && column?.icons?.length) {
                    <span class="action-icons action">
                      @for (icon of column.icons; track icon; let iconIndex = $index) {
                        @if (icon.show && (icon.iName || element.actions.icons[iconIndex].iName)) {
                          <i-feather
                            name="{{
                              icon.iName
                                ? icon.iName
                                : element.actions.icons[iconIndex].iName
                                  ? element.actions.icons[iconIndex].iName
                                  : ''
                            }}"
                            class="pointer feather-20"
                            [class.disabled]="
                              icon.key === 'delete' &&
                              (element?.isUserDefaultGroup?.value ||
                                element?.isClientDefaultUserGroup?.value ||
                                icon.class === 'disabled' ||
                                (element?.providedBy && element.providedBy.value === 'PLATFORM'))
                            "
                            (click)="clickOnActionIcons($event, icon, element)"
                          ></i-feather>
                        }
                        @if (icon.show && icon.iName === 'userStatus') {
                          <i-feather
                            name="{{
                              icon.iName
                                ? icon.iName
                                : element.actions.icons[iconIndex].iName
                                  ? element.actions.icons[iconIndex].iName
                                  : ''
                            }}"
                            class="pointer feather-20"
                            (click)="clickOnActionIcons($event, icon, element)"
                          ></i-feather>
                        }
                      }
                    </span>
                  }
                </span>
              }
            </span>
          </td>
        </ng-container>
      }
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <ng-container>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          [ngClass]="{
            disabled: row.status && row.status.value && row.status.value.toLowerCase() !== 'active'
          }"
        ></tr>
      </ng-container>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="displayedColumns.length">
          <div class="norecord">{{ 'noRecordFound' | translate }}</div>
        </td>
      </tr>
    </table>
  </div>
  @if (showPagination) {
    <div>
      @if (data && data.length > 0) {
        <app-pagination [paginationOptions]="paginationOptions" (pagination)="handlePagination()"></app-pagination>
      }
    </div>
  }
</div>
