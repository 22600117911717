import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslatePipe } from 'src/app/modules/shared/pipes/translate.pipe';
import { HsNoAccessComponent } from './components/hs-no-access/hs-no-access.component';
import { PermissionsDirective } from './directives/permissions.directive';
import { PermissionsService } from './services/permissions.service';

@NgModule({
  declarations: [HsNoAccessComponent, PermissionsDirective],
  providers: [PermissionsService],
  imports: [CommonModule, TranslatePipe],
  exports: [PermissionsDirective, HsNoAccessComponent],
})
export class PermissionsModule {
  public getComponent() {
    return HsNoAccessComponent;
  }
}
