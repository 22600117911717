// import { Directive, ElementRef, HostListener, Input } from '@angular/core';

// @Directive({
//   selector: '[appReadMore]'
// })
// export class ReadMoreDirective {
//   @Input('appReadMore') maxLength: number = 50;
//   private isShowMore = true;

//   constructor(private el: ElementRef) {}

//   @HostListener('click') onClick() {
//     this.isShowMore = !this.isShowMore;
//     this.toggleShowMore();
//   }

//   private toggleShowMore() {
//     const element = this.el.nativeElement;
//     const content = element.innerText;
//     if (this.isShowMore && content.length > this.maxLength) {
//       element.innerHTML = `${content.slice(0, this.maxLength)}... <a href="javascript:void(0);">Show more</a>`;
//     } else {
//       element.innerHTML = `${content} <a href="javascript:void(0);">Show less</a>`;
//     }
//   }
// }

import { Directive, ElementRef, HostListener, Input } from '@angular/core';
/**
 * Directive to truncate text content and toggle between full and truncated views on click.
 *
 * @Directive
 * @selector `[appReadMore]`
 */
@Directive({
  selector: '[appReadMore]',
})
export class ReadMoreDirective {
  /**
   * Maximum length of text content before truncation.
   */
  @Input() readMoreText!: string;
  /**
   * Optional text to display for the "Read more" link.
   */
  @Input() maxLength!: number;
  /**
   * Constructs the directive.
   *
   * @param elementRef Reference to the host element where the directive is applied.
   */
  constructor(private elementRef: ElementRef) {}
  /**
   * Toggles between full and truncated text content on click.
   *
   * @HostListener ('click') Listens for click events on the host element.
   */
  @HostListener('click') onClick() {
    this.toggleReadMore();
  }
  /**
   * Toggles the visibility of the full and truncated text content based on the `maxLength` input.
   */
  private toggleReadMore(): void {
    const element = this.elementRef.nativeElement as HTMLElement;
    const content = element.textContent || '';
    const shouldTruncate = content.length > this.maxLength;

    if (shouldTruncate) {
      const truncatedContent = element.textContent === content ? content.substr(0, this.maxLength) + '...' : content;
      element.textContent = truncatedContent;
    }
  }
}
