import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthServiceService } from 'src/app/modules/authentication/services/auth-service.service';
import { CalendarService } from 'src/app/modules/calendar/services/calendar.service';
import { ChatAiAssistantComponent } from 'src/app/modules/chat-ai-assistant/chat-ai-assistant.component';
import { SearchDialogComponent } from 'src/app/modules/shared/components/search-dialog/search-dialog.component';
import { CommonService } from 'src/app/modules/shared/services/common.service';
import { VideoLobbyComponent } from 'src/app/modules/video-lobby/components/video-lobby/video-lobby.component';
import { VideoLobbyService } from 'src/app/modules/video-lobby/services/video-lobby.service';
import { DemographicData } from '../../interfaces/common.entities';
import { CssService } from '../../services/css.service';
import { InformationDialogComponent } from '../information-dialog/information-dialog.component';
import { DashboardSelectComponent } from './header-dialogs/dashboard/dashboard-select.component';
import { HeaderFilterComponent } from './header-dialogs/header-filter/header-filter.component';
/**
 * Component representing the header of the application.
 * Responsible for managing user interactions such as opening dialogs, fetching user data, and handling session-related functionalities.
 */
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  /** Input property to toggle sidebar visibility */
  @Input() isExpanded = false;
  /** Indicates whether a filter is currently applied */
  public isFilterApplied = false;
  /** Reference to MatDialog for opening dialogs */
  public dialog = inject(MatDialog);
  /** Flag indicating whether the do not disturb mode is active */
  doNotDisturb = false;
  /** Flag indicating whether the header menu is open */
  menu = false;
  /** Holds user demographic data */
  userData!: DemographicData;
  /** Output event emitter for toggling the sidebar */
  @Output() toggleSidebar: EventEmitter<boolean> = new EventEmitter<boolean>();
  /** Holds the screen size classification ('sm', 'md', 'lg', etc.) */
  screenSize: string = '';
  /**
   * Injectable services used by the HeaderComponent.
   */
  public videoLobbyService = inject(VideoLobbyService);
  /**
   * Private instance of the CommonService injected into the component.
   * Provides common utilities and shared data across the application.
   */
  #commonService = inject(CommonService);
  /**
   * Private instance of the CalendarService injected into the component.
   * Provides calendar-related functionalities and data retrieval.
   */
  #calendarService = inject(CalendarService);
  /**
   * Private instance of the CssService injected into the component.
   * Provides CSS-related utilities and manages viewport size observations.
   */
  #cssService = inject(CssService);
  /**
   * Private instance of the AuthServiceService injected into the component.
   * Manages user authentication and authorization operations.
   */
  #authService = inject(AuthServiceService);
  /** Holds user configuration data */
  userConfiguration: any;

  /**
   * - Fetches user demographic data.
   * - Subscribes to profile update events to fetch demographic data again if updated.
   * - Checks and initializes component-specific states.
   * - Fetches and subscribes to 'Do Not Disturb' settings.
   * - Fetches user configuration settings.
   */
  ngOnInit(): void {
    this.getUserDemographic();
    this.#commonService.profileUpdate.subscribe({
      next: (response) => {
        if (response) {
          this.getUserDemographic();
        }
      },
    });
    this.checkFilterAppliedState();
    this.fetchDoNotDisturbSetting();
    this.subscribeDoNotDisturbSetting();
    this.getUserConfiguration();
  }
  /**
   * Opens the search dialog component.
   * Uses MatDialog service to open a SearchDialogComponent in a dialog.
   * Applies 'search-dialog' panel class to the dialog for styling.
   */
  public openSearchDialog(): void {
    this.dialog.open(SearchDialogComponent, {
      panelClass: 'search-dialog',
    });
  }
  /** Fetches the do not disturb setting from the calendar service */
  fetchDoNotDisturbSetting() {
    this.#calendarService.getDoNotDisturbSetting().subscribe({
      next: (response) => {
        if (response?.data?.settings) {
          this.#calendarService.doNotDisturb$.next(response.data.settings.doNotDisturb);
        }
      },
    });
  }
  /** Subscribes to changes in the do not disturb setting */
  subscribeDoNotDisturbSetting() {
    this.#calendarService.doNotDisturb$.subscribe({
      next: (response) => {
        this.doNotDisturb = !response;
      },
    });
  }

  /** Retrieves user demographic data from the authentication service */
  getUserDemographic() {
    this.userData = this.#authService.getUserDemographic();
  }
  /** Retrieves user configuration data from the authentication service */
  getUserConfiguration() {
    this.userConfiguration = this.#authService.getUserConfiguration();
  }
  /** Opens the dashboard selection dialog */
  openDashboardDialog() {
    const dialogRef = this.dialog.open(DashboardSelectComponent, {
      panelClass: 'header-menu-dialog',
      position: { top: '60px', left: '24px' },
      backdropClass: 'header-menu-backdrop',
    });
    this.menu = true;
    dialogRef.afterClosed().subscribe({
      next: () => {
        this.menu = false;
      },
    });
  }
  /**
   * Opens the header filter dialog component.
   * Uses MatDialog service to open a HeaderFilterComponent in a dialog.
   * Determines the position based on the mouse event and screen size.
   * Applies 'header-filter-dialog' panel class to the dialog for styling.
   *
   * @param event The mouse event that triggered the opening of the dialog.
   */
  openFilterDialog(event: MouseEvent) {
    this.#cssService.windowSize$.subscribe((res) => {
      this.screenSize = res;
    });

    const modalWidth = 260;
    const position = { top: '60px', left: event.pageX - modalWidth + 'px' };
    this.dialog.open(HeaderFilterComponent, {
      panelClass: 'header-filter-dialog',
      position: this.screenSize === 'sm' ? {} : this.screenSize === 'md' ? {} : position,
    });
  }
  /**
   * Opens the video lobby dialog if camera and microphone permissions are granted,
   * otherwise opens an information dialog indicating no permission.
   * Uses MatDialog for opening dialogs and VideoLobbyService for permissions.
   * @returns Promise<void>
   */
  public async openVideoLobby(): Promise<void> {
    if (this.videoLobbyService.pipMode()) return;
    if (await this.videoLobbyService.checkCameraMicrophonePermission()) {
      this.dialog.open(VideoLobbyComponent, {
        panelClass: ['video-lobby-dialog', 'full-screen-dialog'],
        hasBackdrop: false,
        disableClose: true,
        scrollStrategy: new NoopScrollStrategy(),
      });
    } else {
      this.dialog.open(InformationDialogComponent, {
        data: {
          title: this.videoLobbyService.noVideoPermissionTitle,
          message: this.videoLobbyService.noVideoPermissionMessage,
        },
        panelClass: 'confirmation-dialog',
      });
    }
  }
  /** Retrieves the user name from the authentication service */
  getUserName() {
    return this.#authService.getUserName();
  }
  /** Logs out the user using the authentication service */
  logOut() {
    this.#authService.signOut();
  }

  /**
   * Opens the calendar dialog if the user has permission.
   */
  openChatAssistant() {
    // if (this.permissionsService.hasPermission('self.Calendar.Read')) {
    this.dialog.open(ChatAiAssistantComponent, {
      panelClass: 'ai-assistant-dialog',
      position: { right: '0px' },
      hasBackdrop: true,
      disableClose: true,
    });
  }

  /** Checks if a filter is currently applied based on local storage */
  private checkFilterAppliedState() {
    if (localStorage.getItem('master-filter')) {
      this.isFilterApplied = true;
    } else {
      this.isFilterApplied = false;
    }
  }
}
