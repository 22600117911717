import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ChatParticipant } from '@azure/communication-chat';
import { CommunicationUserIdentifier } from '@azure/communication-common';
import { BehaviorSubject } from 'rxjs';
import { ContactDialogData } from '../../entities/message.entities';
import { MessageService } from '../../services/message.service';
/**
 * Component for managing participants in a message dialog.
 */
@Component({
  selector: 'app-message-dialog',
  templateUrl: './message-dialog.component.html',
})
export class MessageDialogComponent implements OnInit {
  /** BehaviorSubject for participant list. */
  public participantList$: BehaviorSubject<ChatParticipant[]>;
  /** Flag indicating whether to render the contact component. */
  public renderContactComponent = false;
  /** Array of participants in the message thread. */
  public participantList: ChatParticipant[] = [];
  /** Selected managed user to be added as a participant. */
  private selectedManagedUser: ChatParticipant | null = null;
  /** Original participant list before any modifications. */
  private originalParticipantList: ChatParticipant[] = [];
  /**
   * Constructor of MessageDialogComponent.
   *
   * @param messageService - The service for managing messages.
   * @param dialog - Reference to the material dialog.
   * @param contactData - Data passed into the dialog.
   */
  public constructor(
    private messageService: MessageService,
    private dialog: MatDialogRef<MessageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public contactData: ContactDialogData,
  ) {
    this.participantList$ = new BehaviorSubject(this.participantList);
  }

  /**
   * This method initializes the participant list asynchronously by fetching it from
   * the message service (`messageService.getParticipantList`). It subscribes to the
   * `participantList$` observable to update `participantList`, `originalParticipantList`,
   * and set `renderContactComponent` to true once the participant list is retrieved.
   */
  public async ngOnInit() {
    this.participantList$.next(await this.messageService.getParticipantList(this.contactData.threadId));
    this.participantList$.subscribe((list: ChatParticipant[]): void => {
      this.participantList = list;
      this.originalParticipantList = JSON.parse(JSON.stringify(list));
      this.renderContactComponent = true;
    });
  }

  /**
   * Updates the selected managed user.
   *
   * @param managedUser - The selected managed user.
   */
  public updateManagedUser(managedUser: ChatParticipant | null): void {
    this.selectedManagedUser = managedUser;
  }
  /**
   * Saves the updated participant list and managed user.
   * Adds new participants, removes deleted participants, and closes the dialog.
   */
  public async saveParticipants(): Promise<void> {
    await this.messageService.addParticipants(this.contactData.threadId, this.participantList);
    if (this.selectedManagedUser) {
      await this.messageService.addParticipants(this.contactData.threadId, [this.selectedManagedUser]);
    }

    this.participantList.forEach((participant: ChatParticipant): void => {
      const index: number = this.originalParticipantList.findIndex(
        (el: ChatParticipant): boolean =>
          (el.id as CommunicationUserIdentifier).communicationUserId ===
          (participant.id as CommunicationUserIdentifier).communicationUserId,
      );
      if (index === -1) {
        return;
      }
      this.originalParticipantList.splice(index, 1);
    });

    await this.messageService.removeParticipants(this.contactData.threadId, this.originalParticipantList);
    this.dialog.close(true);
  }
}
