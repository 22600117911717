import { NgModule } from '@angular/core';
import { LoadChildrenCallback, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './modules/shared/guards/auth.guard';
import { LoggedInGuard } from './modules/shared/guards/logged-in.guard';
const routes: Routes = [
  {
    path: '', //this wil load auth module if no route found
    redirectTo: '/auth/login',
    pathMatch: 'full',
  },
  { path: 'login', redirectTo: 'auth/login', pathMatch: 'full' },
  {
    path: 'activateaccount',
    redirectTo: 'auth/activateaccount',
    pathMatch: 'full',
  },
  {
    path: 'signup',
    redirectTo: 'auth/create-account',
    pathMatch: 'full',
  },
  {
    path: 'resetpassword',
    redirectTo: 'auth/resetPassword',
    pathMatch: 'full',
  },
  {
    path: 'verification',
    redirectTo: 'auth/verification',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    loadChildren: () => import('src/app/modules/authentication/auth.module').then((m) => m.AuthModule),
    canActivate: [LoggedInGuard],
  },
  {
    path: 'settings',
    loadChildren: () => import('src/app/modules/settings/settings.module').then((m) => m.SettingsModule),
  },
  {
    path: '',
    canLoad: [AuthGuard],
    loadChildren: loadFunction(),
  },
  {
    //this is wild card route
    path: '**',
    loadComponent: () =>
      import('src/app/modules/shared/components/not-found/not-found.component').then((m) => m.NotFoundComponent),
  },
];

/** Function to lazy load MedocityModule */
export function loadFunction(): LoadChildrenCallback {
  return () => import('src/app/modules/medocity/medocity.module').then((m) => m.MedocityModule);
}
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
