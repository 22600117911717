import { Component, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { map } from 'rxjs/operators';
import {
  AddEditUserGroupComponent,
  UserGrpList,
} from 'src/app/modules/shared/components/add-edit-user-group/add-edit-user-group.component';
import { ManagedDialogComponent } from 'src/app/modules/user-management/components/managed-group-dialog/managed-group.component';
import { ManagedUserComponent } from 'src/app/modules/user-management/components/managed-user-dialog/managed-user.component';
import { UsersService } from '../../users.service';

/**
 * Interface representing a user group resource.
 */
export interface userGrpRes {
  /**
   * The identifier of the client associated with the user group.
   */
  client: string;

  /**
   * The name of the user group.
   */
  group: string;

  /**
   * Indicates whether this group is the default group for the user.
   */
  groupName: string;

  /**
   * Indicates whether this group is the default group for the user.
   */
  isUserDefaultGroup: boolean;

  /**
   * The role assigned to the user within the group.
   */
  role: string;
}

/**
 * ProfileEditComponent is responsible for editing user profiles.
 */
@Component({
  selector: 'app-profile-edit',
  templateUrl: './profile-edit.component.html',
  styleUrls: ['./profile-edit.component.scss'],
})
export class ProfileEditComponent {
  /** Stores the post data. */
  post: any;
  /** Stores the user data. */
  users: any;
  /** Tracks whether the form has been submitted. */
  submitted = false;
  /** Stores the route ID. */
  routeId: any = '';
  /** Stores the route URL. */
  routeURL: any;
  /** Stores the client ID. */
  clientID: string = '';
  /** Indicates whether the filter is enabled. */
  filterEnable = false;
  /** Stores the user ID. */
  uid: string = '';
  /** Indicates whether the role dashboard edit is enabled. */
  roleDashboardEdit = false;
  /** Stores the request parameters. */
  params: any = { limit: 100, page: 1, search: '', filter: '', sort: 'name|DESC' };
  /** Stores the list of roles. */
  roleList: any = [];
  /** Stores the checked roles. */
  checked: any = [];
  /** Form group for updating user information. */
  updateUser: FormGroup;
  /** Indicates whether edit is enabled or disabled. */
  enableDisableEdit = false;
  /** Indicates whether dashboard edit is enabled. */
  editDashboard = false;
  /** Indicates whether content group edit is enabled. */
  editContentGroup = false;
  /** Stores roles information. */
  rolesInfo = {
    privateMobile: 1800124152,
    role: 1800124124,
  };
  /** Stores the selected roles. */
  selectedRoles: any = [];
  /** Stores the list of dashboards. */
  dashboardList: any = [];
  /** Stores the selected dashboards. */
  selectedDashboard: any = [];
  /** Stores the list of managers. */
  managersList: any;
  /** Stores the user data. */
  userData: any;
  /** Stores the result roles. */
  resultRoles: any;
  /** Stores the unchecked values. */
  unCheckedVal: any = [];
  /** Stores the current user type. */
  currentUserType: any;
  /** Stores the selected user type. */
  userTypeSelected: any;
  /** Stores the default dashboard value. */
  getDefaultDashboardValue: any = '';
  /** Stores the external IDs. */
  exIds: any = [];
  /** Stores the selected external ID. */
  selectedExId: any;
  /** Stores the role error message. */
  roleError = '';
  /** Indicates whether the panel is open or not. */
  panelOpenState = false;
  /** Stores the items. */
  items = Array.from({ length: 1 }).map((_, i) => `Item #${i}`);
  /** Stores the step value. */
  step = 0;
  /** Indicates whether edit user group is enabled. */
  editUsersGroup = false;
  /** Indicates whether edit user managing is enabled. */
  editUserManaging = false;
  /** Stores the list of groups. */
  groupList: any = [];
  /** Stores the managed user data. */
  manageUserData: any = [];
  /** Stores the support user data. */
  supportUser: any = [];
  /** Stores the support group data. */
  supportGroup: Array<UserGrpList> = [];
  /** Stores the unselected group data. */
  unSelectedGroup: any = [];
  /** Stores the members data. */
  membersData: any = [];
  /** Stores the co-owner users data. */
  coOwnerUsers: any = [];
  /** Stores the co-owner list data. */
  coOwnerList: any = [];
  /** Stores the group list data. */
  groupListData: any = [];
  /** Indicates whether the support panel is expanded. */
  expandSupport = false;
  /** Indicates whether the user group control is disabled. */
  disableUserGroupControl: boolean = false;

  /** Reference to the AddEditUserGroupComponent. */
  @ViewChild(AddEditUserGroupComponent) AddEditUserGroupComponent!: AddEditUserGroupComponent;

  /** Flag to indicate if all groups are accessible. */
  accessAllGroups = false;

  /** Flag to indicate if the user group form has been submitted. */
  submittedUserGrp = false;

  /** Array to filter user groups based on selected criteria. */
  userGrpFilter: Array<string> = [];

  /**
   * Constructor initializing dependencies and setting up initial state.
   * @param router - Router for navigation.
   * @param route - ActivatedRoute for accessing route parameters.
   * @param formBuilder - FormBuilder for creating forms.
   * @param _UsersService - Service for user operations.
   * @param toast - ToastrService for displaying notifications.
   * @param dialog - MatDialog for opening dialogs.
   */
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private _UsersService: UsersService,
    private toast: ToastrService,
    // private _RolesService: RolesService,
    private dialog: MatDialog,
  ) {
    this.route.queryParams.subscribe((res) => {
      this.clientID = res.clientId;
      this.uid = res.uid;
    });

    if (this.clientID) {
      this.filterEnable = false;
      if (this.uid) {
        this.editUser(this.uid);
      }
      this.routeId = this.uid;
    } else {
      this.filterEnable = true;
      if (this.uid) {
        this.editUser(this.uid);
      }
    }

    this.updateUser = this.formBuilder.group({
      roles: this.formBuilder.array([]),
      dashboards: this.formBuilder.array([]),
      userGroup: this.formBuilder.array([]),
      defaultDashboard: '',
    });
  }

  /**
   * Enables or disables edit mode.
   */
  enableEdit() {
    this.enableDisableEdit = !this.enableDisableEdit;
    this.getRoles();
  }

  /**
   * Enables or disables dashboard edit mode.
   */
  dashboardEdit() {
    this.editDashboard = !this.editDashboard;
    this.getRoles();
  }

  /**
   * Enables or disables user group edit mode.
   */
  editUserGroup() {
    this.unSelectedGroup = [];
    this.editUsersGroup = !this.editUsersGroup;
    this.getUserGroups();
  }

  /**
   * Saves the user group by comparing selected user groups with the existing group list data.
   */
  saveUserGroup() {
    this.submittedUserGrp = true;

    const userGroups = this.AddEditUserGroupComponent.returnSelectedUserGrps();
    if (userGroups.length > 0) {
      for (const item of this.groupListData) {
        if (!userGroups.find((elem: UserGrpList) => elem.id === item.id)) {
          this.deleteUserGroup(item.id, item.role);
        }
      }

      for (const item of userGroups) {
        if (!this.groupListData.find((elem: UserGrpList) => elem.id === item.id && elem.role === item.role)) {
          this.AddUserGrp(item);
        }
      }
    }
  }

  /**
   * Adds a user group to the service.
   *
   * @param data - The user group data to be added.
   */
  AddUserGrp(data: UserGrpList) {
    const body = {
      users: [this.userData.demographics.user],
    };
    this._UsersService.addUserGroup(body, data.id, data?.role).subscribe((res) => {
      this.editUserGroup();
      // this.getUserGroups();
    });
  }

  /**
   * Deletes a user group based on the given group ID and owner type.
   *
   * @param gid - The ID of the group to delete.
   * @param ownerType - The type of owner associated with the group.
   */
  deleteUserGroup(gid: any, ownerType: string) {
    //console.log(this.userData.demographics.user)
    const postData = 'member';

    this._UsersService
      .deleteUserGroup(ownerType, gid, this.userData.demographics.user)
      .pipe(
        map((response: any) => {
          return response;
        }),
      )
      .subscribe(
        (groups) => {
          this.toast.success(groups.message);
          this.editUserGroup();
        },
        (error) => {
          if (error) {
            //  console.log(error);
            this.toast.error(error.error.message);
          }
        },
      );
  }

  /**
   * Enables or disables user managing edit mode.
   */
  editManaging() {
    this.editUserManaging = !this.editUserManaging;
  }

  /**
   * Enables or disables content group edit mode.
   */
  contentGroupEdit() {
    this.editContentGroup = !this.editContentGroup;
    this.getRoles();
  }

  /**
   * Navigates to the edit user details page.
   */
  editUserDetails() {
    //this.router.navigate
    const url = '/userManagment/edit';
    this.router.navigate([url], {
      queryParams: { clientId: this.clientID, uid: this.uid },
    });
  }

  /**
   * Fetches user groups.
   */
  getUserGroups() {
    this._UsersService.getUserGroupData(this.uid).subscribe((result) => {
      result.owner.forEach((ele: userGrpRes) => {
        ele.role = 'owner';
      });
      result.coOwner.forEach((ele: userGrpRes) => {
        ele.role = 'CoOwner';
      });
      result.member.forEach((ele: userGrpRes) => {
        ele.role = 'Member';
      });
      result.observer.forEach((ele: userGrpRes) => {
        ele.role = 'Observer';
      });

      this.groupList = [];
      for (const item in result) {
        const temp = result[item];
        if (temp) {
          this.groupList.push(...temp);
        }
      }
      this.groupListData = [];
      this.groupList.forEach((ele: userGrpRes) => {
        const obj = { id: ele.group, name: ele.groupName, role: ele.role, defualtUserGrp: ele.isUserDefaultGroup };
        this.groupListData.push(obj);
      });
    });
  }

  /**
   * Fetches managers based on the usedIn parameter.
   * @param usedIn - Specifies the context for fetching managers.
   * @param gid - Group ID.
   * @param data - Additional data.
   * @param group - Group object.
   * @param index - Index value.
   */
  getManagers(usedIn?: any, gid?: any, data?: any, group?: any, index?: any) {
    // console.log(data)
    this._UsersService.getManagers(usedIn).subscribe((result) => {
      // console.log(result, '999', usedIn)
      if (usedIn == 'support' || usedIn == 'Manager') {
        // this._UsersService.getManagers('coOwner').subscribe((data)=>{
        //   console.log(data.data);
        // })
        this.openDialogAddCoOwner(gid, result.data, group, index);
      }
      if (usedIn == 'Managed') {
        this.openUserDialogData(gid, result.data, data, group, index, true);
      }

      if (usedIn == 'co-owner') {
        this.openUserDialogData(gid, result.data, data, group, index, false);
      }
      this.managersList = result.data.find((o: any) => o.id === this.userData?.primaryManager);
    });
  }

  /**
   * Fetches external IDs.
   */
  getExternalIds() {
    this._UsersService.getExternalIds(this.userTypeSelected).subscribe(
      (result) => {
        this.exIds = [];
        for (let i = 0; i < this.userData.externalIds.length; i++) {
          const resData = result.find((o: any) => o._id === this.userData.externalIds[i].k);
          // console.log(resData)
          if (resData) {
            this.exIds.push(resData);
          }
        }
        this.selectedExId = this.exIds;
      },
      (error) => {
        if (error) {
          //console.log(error);
        }
      },
    );
  }

  /**
   * Edits user data based on the provided user ID.
   * @param userId - User ID.
   */
  editUser(userId: any) {
    this._UsersService.editUsers(userId).subscribe((result) => {
      this.userData = result;
      this.currentUserType = result.userType;
      this.userTypeSelected = result.userType;
      this.getExternalIds();
      this.getDefaultDashboardValue = result.defaultDashboard;
      this.updateUser.patchValue({
        defaultDashboard: result.settings.defaultDashboard,
      });
      this.getRoles();

      this.getUserGroups();
      this.getGroups();
    });
  }

  /**
   * Gets the groups list based on user type and group ID.
   * @param userType - User type.
   * @param gid - Group ID.
   * @param index - Index value (optional).
   */
  getGroupsList(userType: any, gid: any, index?: any) {
    this.coOwnerList = [];
    this._UsersService
      .getGroupsList(userType, gid, this.params.limit, this.params.page, this.params.sort)
      .subscribe((result) => {
        this.coOwnerList = result.data;
        if (this.groupList.owner[index]) {
          // item exists, so only modify the property
          this.groupList.owner[index].coOwnerList = this.coOwnerList;
        } else {
          // item doesn't exists, so add a new object entirely
          this.groupList.owner[index] = {
            coOwnerList: this.coOwnerList,
          };
        }
      });
  }

  /**
   * Gets the default dashboard value.
   * @param val - Dashboard value.
   */
  getDefaultDashboard(val: any) {
    this.getDefaultDashboardValue = val;
  }

  /**
   * Gets the roles information.
   */
  getRoles() {
    this._UsersService.getAllRoles(this.params).subscribe((result) => {
      this.resultRoles = result.data;
      if (this.resultRoles.length > 0) {
        this.roleList = [];
        this.dashboardList = [];
        this.unCheckedVal = [];
        this.resultRoles.forEach((item: any) => {
          // if(this.userData.roles.some( (id2:string)  => id2 === item.id)){
          //   this.rolesFormArray.push(new FormControl(false));
          // }else{
          //   this.rolesFormArray.push(new FormControl(false));
          // }
          this.rolesFormArray.push(new FormControl(false));

          item.checked = false;
          this.unCheckedVal.push(false);

          if (item.roleMetaType == this.userTypeSelected) {
            this.roleList.push(item);
          }
        });

        if (this.uid && this.userData?.roles.length > 0) {
          this.selectedRoles = [];
          this.dashboardList = [];
          const data = this.roleList.filter((id1: any) => this.userData.roles.some((id2: any) => id2.id === id1.id));

          for (let i = 0; i < this.roleList.length; i++) {
            for (let j = 0; j < data.length; j++) {
              if (this.roleList[i]._id === data[j]._id) {
                if (this.roleList[i].dashboards.length > 0) {
                  this.dashboardList = [...this.roleList[i].dashboards];
                }

                this.selectedRoles.push(data[j]._id);
                const langArr = <FormArray>this.updateUser.controls['roles'];
                langArr.controls[i].patchValue(true);
              }
            }
          }
        }
      }
    });
  }

  /**
   * Handles the selection/deselection of roles.
   * @param val - The role object being selected/deselected.
   * @param event - The event object triggered by the checkbox.
   */
  getSelected(val: any, event: any) {
    this.selectedRoles = [];
    this.getDefaultDashboardValue = '';
    if (event.target.checked) {
      this.selectedRoles.push(val._id);
      if (val?.dashboards?.length > 0) {
        val.dashboards.forEach((item: any) => {
          this.dashboardsFormArray.push(new FormControl(false));
          item.checked = false;
          this.dashboardList.push(item);
        });
      }
      this.updateAddRole(val);
    } else {
      this.selectedRoles = this.selectedRoles.filter((item: any) => item !== val._id);
      if (val?.dashboards?.length > 0) {
        val.dashboards.forEach((item: any, index: any) => {
          this.dashboardList = this.dashboardList.filter((item: any) => item.id !== val.dashboards[index].id);
        });
      }

      this.updateRole(val);
    }
  }

  /**
   * Handles the selection/deselection of user groups.
   * @param val - The group object being selected/deselected.
   * @param list - List of groups.
   * @param event - The event object triggered by the checkbox.
   */
  getSelectedGroup(val: any, list: Array<any>, event: any) {
    if (event.target.checked) {
      this.unSelectedGroup.push(val.group);
    } else {
      if (list.length > 1) {
        this.updateUserGroup(val.group);
      } else {
        this.toast.error(`Cannot remove all user's group`);
      }
    }
  }

  /**
   * Filters user groups based on the specified filter value.
   *
   * @param filterUsrGrpValue - The value to filter the user groups by.
   */
  filterUserGrp(filterUsrGrpValue: any) {
    if (this.userGrpFilter.find((ele) => ele === filterUsrGrpValue)) {
      const index = this.userGrpFilter.findIndex((el) => el === filterUsrGrpValue);
      if (index >= 0) this.userGrpFilter.splice(index, 1);
    } else {
      this.userGrpFilter.push(filterUsrGrpValue);
    }
    this.getGroups('', this.userGrpFilter);
  }

  /**
   * Searches user groups based on the specified search key.
   *
   * @param searchKey - The key to search user groups by.
   */
  searchUserGrp(searchKey?: string) {
    this.getGroups(searchKey, this.userGrpFilter);
  }

  /**
   * Gets the groups information.
   */
  getGroups(searchKey?: string, FilterKey?: Array<any>) {
    this._UsersService.getGroups(searchKey, FilterKey).subscribe((result) => {
      this.supportGroup = result;
    });
  }

  /**
   * Adds a user to a co-owner user group.
   * @param gid - Group ID.
   * @param uid - User ID.
   * @param type - Type of user (optional).
   * @param index - Index value (optional).
   */
  addCoOwnerUserGroup(gid: any, uid: any, type?: any, index?: any) {
    const postData = {
      users: [uid],
    };
    this._UsersService
      .addUserGroup(postData, gid, 'coOwner')
      .pipe(
        map((response: any) => {
          return response;
        }),
      )
      .subscribe(
        (groups) => {
          if (groups.status != 400) {
            this.toast.success('User Group added Successfully');
            this.getUserGroups();
            this.getGroupsList('coOwner', gid, index);
            this.editUsersGroup = false;
            if (type === 'support') {
              this.expandSupport = true;
            } else {
              this.expandSupport = false;
            }
          } else {
            this.toast.error(groups.message);
          }
        },
        (error) => {
          if (error) {
            //  console.log(error);
            this.toast.error(error.error.message);
          }
        },
      );
  }

  /**
   * Updates a user group.
   * @param gid - Group ID.
   */
  updateUserGroup(gid: any) {
    //console.log(this.userData.demographics.user)
    const postData = 'member';

    this._UsersService
      .deleteUserGroup(postData, gid, this.userData.demographics.user)
      .pipe(
        map((response: any) => {
          return response;
        }),
      )
      .subscribe(
        (groups) => {
          this.toast.success('User removed from group successfully');
          this.getUserGroups();
          // this.editUsersGroup = false;
        },
        (error) => {
          if (error) {
            //  console.log(error);
            this.toast.error(error.error.message);
          }
        },
      );
  }

  /**
   * Updates the roles for the user.
   * @param val - Role value (optional).
   */
  updateAddRole(val?: any) {
    //console.log(this.selectedRoles)
    if (this.selectedRoles.length === 0) {
      this.roleError = 'Role is required';
      return;
    }

    const postData = {
      roles: this.selectedRoles,
      client: this.clientID,
      userType: val.roleMetaType,
    };

    this._UsersService
      .updateAddUserRole(postData, this.uid)
      .pipe(
        map((response) => {
          this.users = response;
          return response;
        }),
      )
      .subscribe(
        (users) => {
          this.toast.success('Role updated successfully.');
          //  this.editDashboard = false;
          // this.enableDisableEdit = false;
          this.editUser(this.uid);
        },
        (error) => {
          if (error) {
            this.selectedRoles = [];
            // this.enableDisableEdit = false;
            // console.log(this.selectedRoles)
            this.editUser(this.uid);
            const control = <FormArray>this.updateUser.controls['roles'];
            for (let i = control.length - 1; i >= 0; i--) {
              control.removeAt(i);
            }

            // console.log(error);
          }
        },
      );
  }

  /**
   * Updates the roles for the user.
   * @param val - Role value (optional).
   */
  updateRole(val?: any) {
    this._UsersService
      .deleteUserRole(this.uid, val.id)
      .pipe(
        map((response) => {
          this.users = response;
          return response;
        }),
      )
      .subscribe(
        (users) => {
          this.toast.success('Role updated successfully.');
          //  this.editDashboard = false;
          // this.enableDisableEdit = false;
          this.editUser(this.uid);
        },
        (error) => {
          if (error) {
            // this.enableDisableEdit = false;
            // console.log(error);
          }
        },
      );
  }

  /**
   * Opens a dialog to manage user groups.
   * @param data - Data object.
   */
  openDialog(data?: any) {
    const result = this.supportGroup.filter((o1: any) => !data?.some((o2: any) => o1._id === o2.group));
    //console.log(result)
    const dialogRef = this.dialog.open(ManagedDialogComponent, {
      panelClass: 'custom-modalbox',
      data: {
        managedUser: result,
        title: 'Join Support Group',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      // console.log(result,'00000')
      if (result.length > 0) {
        result.forEach((item: any, index: any) => {
          this.addCoOwnerUserGroup(item, this.userData.demographics.user);
        });
      }
    });
  }

  /**
   * Opens a dialog to manage co-owner user groups.
   * @param gid - Group ID.
   * @param group - Group object.
   * @param index - Index value (optional).
   */
  dialogForCoOwner(gid: any, group: any, index?: any) {
    this.getManagers('Manager', gid, index, group);
  }

  /**
   * Opens a dialog to add co-owners to user groups.
   * @param gid - Group ID.
   * @param data - Data object.
   * @param group - Group object.
   * @param indexVal - Index value.
   */
  openDialogAddCoOwner(gid: any, data = [], group: any, indexVal: any) {
    let result;
    if (this.coOwnerList !== undefined) {
      result = data.filter((o1: any) => !this.coOwnerList.some((o2: any) => o1.id === o2.id));
    } else {
      result = data;
    }
    // console.log(group)
    const dialogRef = this.dialog.open(ManagedDialogComponent, {
      panelClass: 'custom-modalbox',
      data: {
        managedUser: result,
        title: 'Add Supporting Members',
        subtitle: group.groupName,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      //console.log(result, '999990')
      if (result.length > 0) {
        result.forEach((item: any, index: any) => {
          this.addCoOwnerUserGroup(gid, item, 'support', indexVal);
        });
      }
    });
  }

  /**
   * Deletes a co-owner from a user group.
   * @param gid - Group ID.
   * @param uid - User ID.
   * @param type - Type of user (optional).
   * @param index - Index value (optional).
   */
  deleteCoOwnerInUserGroup(gid: any, uid?: any, type?: any, index?: any) {
    const postData = 'coOwner';
    this._UsersService
      .deleteUserGroup(postData, gid, uid)
      .pipe(
        map((response: any) => {
          return response;
        }),
      )
      .subscribe(
        (groups) => {
          this.toast.success('Co-Owner removed from group successfully');
          if (type === 'support') {
            this.expandSupport = true;
          } else {
            this.expandSupport = false;
          }
          this.getUserGroups();
          this.getGroupsList('coOwner', gid, index);
        },
        (error) => {
          if (error) {
            //  console.log(error);
            this.toast.error(error.error.message);
          }
        },
      );
  }

  /**
   * Opens a dialog for managing user groups.
   * @param gid - Group ID.
   * @param data - Data object.
   * @param type - Type of user.
   * @param group - Group object.
   */
  openDialogUser(gid: any, data: any, type?: any, group?: any) {
    this.getManagers(type, gid, data, group);
  }

  /**
   * Opens a dialog for managing user data.
   * @param gid - Group ID.
   * @param data - Data object.
   * @param member - Member object.
   * @param group - Group object.
   * @param index - Index value (optional).
   * @param isOwner - Boolean to check if owner.
   */
  openUserDialogData(gid: any, data: any, member: any, group?: any, index?: any, isOwner?: boolean) {
    const dialogRef = this.dialog.open(ManagedUserComponent, {
      panelClass: 'custom-modalbox',
      data: {
        gid: gid,
        uid: this.uid,
        data: data,
        member: member,
        group: group,
        isOwner: isOwner,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  /**
   * Updates the default dashboard settings.
   */
  updateDashboard() {
    const postData = {
      settings: {
        defaultDashboard: this.getDefaultDashboardValue,
      },
    };
    this._UsersService
      .updateDashboard(postData, this.uid)
      .pipe(
        map((response) => {
          this.users = response;
          return response;
        }),
      )
      .subscribe((users) => {
        this.toast.success('Default Dashboard updated successfully.');
        this.editDashboard = false;
        this.editUser(this.uid);
      });
  }

  /**
   * Expands or collapses the panel.
   * @param matExpansionPanel - Material expansion panel object.
   * @param event - Event object.
   */
  expandPanel(matExpansionPanel: MatExpansionPanel, event: any) {
    event.stopPropagation();
    if (!this._isExpansionIndicator(event.target)) {
      matExpansionPanel.toggle();
    }
  }

  /**
   * Checks if the target is an expansion indicator.
   * @param target - Event target.
   * @returns Boolean indicating if the target is an expansion indicator.
   */
  private _isExpansionIndicator(target: EventTarget | any): boolean {
    //  console.log('999');
    const expansionIndicatorClass = 'mat-expansion-indicator';
    return target.classList && target.classList.contains(expansionIndicatorClass);
  }

  /**
   * Returns the roles FormArray from the updateUser FormGroup.
   */
  get rolesFormArray() {
    return this.updateUser.controls.roles as FormArray;
  }

  /**
   * Returns the userGroup FormArray from the updateUser FormGroup.
   */
  get groupsFormArray() {
    return this.updateUser.controls.userGroup as FormArray;
  }

  /**
   * Returns the dashboards FormArray from the updateUser FormGroup.
   */
  get dashboardsFormArray() {
    return this.updateUser.controls.dashboards as FormArray;
  }
}
