<div class="page-header">
  <div class="page-header-grid">
    @if (!filterEnable) {
      <h1>{{ 'userManageMent' | translate }}</h1>
    }
    @if (filterEnable) {
      <h1>{{ 'systemUsers' | translate }}</h1>
    }

    <div *appPermissions="'self.Management_User.Write'" class="btn-group">
      @if (filterEnable) {
        <button class="btn btn-primary btn-flex" [routerLink]="['/users/add']">
          <i-feather name="plus-circle" class="feather-20 mr-2"></i-feather>
          <span class="btn-label">{{ 'addUser' | translate }}</span>
        </button>
      }
      @if (!filterEnable) {
        <button
          class="btn btn-primary btn-flex"
          [routerLink]="['/userManagment/add']"
          [queryParams]="{ clientId: clientID }"
        >
          <i-feather name="plus-circle" class="feather-20 mr-2"></i-feather>
          <span class="btn-label">{{ 'addUser' | translate }}</span>
        </button>
      }
    </div>
  </div>
</div>
<div class="page-content-wrapper">
  @if (usersGroup) {
    <div class="card-base">
      <div class="flex-between user-serach-flex pb-3">
        <div class="form-group">
          <label>Search by User ID or External ID</label>
          <div class="user-search">
            <div class="input-group input-search">
              <input type="text" class="form-control" [(ngModel)]="search" />
              <div class="input-group-prepend">
                <div class="input-group-text" [ngClass]="search === '' ? '' : 'search-active'">
                  <i-feather name="search" (click)="searchByID(search)"></i-feather>
                </div>
              </div>
            </div>
            @if (search !== '') {
              <button class="btn btn-secondary" (click)="cancelSearch()">cancel</button>
            }
          </div>
          @if (search !== '' && externalIdSearchUsers) {
            <div class="">No record found.</div>
          }
        </div>

        <div class="filter-box">
          <div class="filter-label">
            <span class="pe-1">{{ 'filterBy' | translate }}:</span>
            <span class="pe-0 pointer" [matMenuTriggerFor]="userMatMenu">
              <span> {{ selectedFilterByOption.displayText || 'All clients' }}</span>
              <i-feather name="chevron-down" class="feather-16"></i-feather>
            </span>
          </div>
          <mat-menu #userMatMenu="matMenu" class="mat-more-dropdown">
            @for (fOption of filterOptions; track fOption) {
              <a mat-menu-item (click)="onChangeFilterOptions(fOption)">{{ fOption.displayText }}</a>
            }
          </mat-menu>
        </div>
      </div>

      <app-table
        class="prov-table-size action-enabled"
        [columns]="columns"
        [displayedColumns]="displayedColumns"
        [paginationOptions]="paginationOptions"
        [data]="usersGroup"
        [tableRender]="usedTable"
        (clickOnActionItems)="toggleUserStatus($event)"
        (delete)="deleteUser()"
        (sort)="sortByColumn($event)"
        (refetch)="refreshUsers($event)"
        (search)="openSearchAndFiltersDialog($event)"
        (filter)="openSearchAndFiltersDialog($event)"
        (clear)="clear($event)"
      ></app-table>
    </div>
  }
</div>
