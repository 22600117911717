import { Injectable, Signal, WritableSignal, computed, inject, signal } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Contact } from '../interfaces/contact.entities';
import { HttpService } from './http.service';
/**
 * Injectable service provided in the root injector.
 * This service is a singleton instance available throughout the application.
 */
@Injectable({
  providedIn: 'root',
})
export class ContactsService {
  /**
   * Signal holding the list of contacts.
   */
  public contactList: WritableSignal<Contact[]> = signal([]);
  /**
   * Computed signal for managed users from the contact list.
   */
  public managedUsers: Signal<Contact[]> = computed(() => {
    return this.contactList().filter((contact: Contact): boolean => contact.userType === 'Managed');
  });
  /**
   * Computed signal for manager users from the contact list.
   */
  public managerUsers: Signal<Contact[]> = computed(() => {
    return this.contactList().filter((contact: Contact): boolean => contact.userType === 'Manager');
  });
  /**
   * Injected instance of HttpService to perform HTTP requests.
   */
  #http = inject(HttpService);
  /**
   * Base URL for the API endpoints.
   */
  #baseUrl: string = environment.url.apiHost;
  /**
   * API version used for API requests.
   */
  #apiVersion: string = environment.url.version;
  /**
   * Constructor of ContactsService.
   * Initializes the service and updates contacts if not already initialized.
   */
  public constructor() {
    if (!this.contactList()) {
      this.updateContacts();
    }
  }
  /**
   * Updates the contact list from the backend.
   * Fetches contacts from the API and sets them in the contactList signal.
   */
  public async updateContacts(): Promise<void> {
    const url = `${this.#baseUrl}${this.#apiVersion}/contact`;
    try {
      this.contactList.set(await firstValueFrom(this.#http.get(url, {}, true)));
    } catch {}
  }
  /**
   * Retrieves a contact by ACS ID.
   * @param acsId ACS ID of the contact to retrieve.
   * @returns The contact object if found, otherwise null.
   */
  public getUserFromACSId(acsId: string): Contact | null {
    return this.contactList().find((contact: Contact): boolean => contact.userAcsId === acsId) || null;
  }
  /**
   * Retrieves a contact by user ID.
   * @param userId User ID of the contact to retrieve.
   * @returns The contact object if found, otherwise null.
   */
  public getContactFromUserId(userId: string): Contact | null {
    return this.contactList().find((contact: Contact): boolean => contact.user === userId) || null;
  }
}
