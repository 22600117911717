<div class="full-body">
  @if (loading) {
    <app-loader></app-loader>
  }
  <router-outlet></router-outlet>
  @if (token === null || token === '' || isUnderMaintenancePage) {
    <div class="footer-wrapper">
      <app-footer></app-footer>
    </div>
  }
  <div [ngClass]="{ cutomBackdrop: backdropAndLoaderService.showBackdrop() }"></div>
</div>
