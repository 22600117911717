<div class="demo-tab-content page-content-wrapper">
  <div class="row">
    <div class="col-md-6">
      @if (renderOOOBusinessHours()) {
        <app-out-of-business-hours [toggleView]="toggleView"></app-out-of-business-hours>
      } @else {
        <div class="card-base">
          <div class="common-card-header flex-between">
            <h2>{{ 'outsideOfBusinessHours' | translate }}</h2>
          </div>
          <div>
            <i>{{ 'dontHaveViewPermission' | translate }}</i>
          </div>
        </div>
      }
    </div>
    <div class="col-md-6">
      @if (renderOutOfOffice()) {
        <app-out-of-office [toggleView]="toggleView"></app-out-of-office>
      } @else {
        <div class="card-base">
          <div class="common-card-header flex-between">
            <h2>{{ 'outOfOffice' | translate }}</h2>
          </div>
          <div>
            <i>{{ 'dontHaveViewPermission' | translate }}</i>
          </div>
        </div>
      }
    </div>
  </div>
</div>
