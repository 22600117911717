<div class="confirmation-dialog dialog-sm">
  <div class="hs-tab-card-title flex-between">
    <h2>{{ 'inactiveSessionWarning' | translate }}</h2>

    <div class="dialog-header-icons">
      <i-feather name="x" class="pointer" [mat-dialog-close]="false"></i-feather>
    </div>
  </div>
  <mat-dialog-content class="mat-dialog-content mb-1">
    <div>
      {{ 'inactiveSessionWarningMessageLine1' | translate }}
      {{ 'inactiveSessionWarningMessageLine2' | translate }}
      <strong>{{ commonService.idleCountdown() }}</strong> {{ 'seconds' | translate }}
    </div>
  </mat-dialog-content>
</div>
