<label for="" class="form-label">{{ 'dateOfBirth' | translate }}</label>
<form [formGroup]="form">
  <div class="date-form-group">
    <div class="form-group">
      <label for="" class="form-label">{{ 'month' | translate }} </label>
      <input
        placeholder="MM"
        formControlName="dobMonth"
        type="text"
        digitOnly
        class="form-control"
        (change)="changeMonth(form.controls['dobMonth'].value)"
        (blur)="
          delegateBox(form.controls['dobYear'].value, form.controls['dobMonth'].value, form.controls['dobDay'].value)
        "
        onKeyPress="if(this.value.length==2) return false;"
      />
    </div>
    <div class="form-group">
      <label for="" class="form-label">{{ 'dayTab' | translate }}</label>
      <input
        placeholder="DD"
        formControlName="dobDay"
        class="form-control"
        type="text"
        digitOnly
        (change)="changeDate(form.controls['dobDay'].value)"
        (blur)="
          delegateBox(form.controls['dobYear'].value, form.controls['dobMonth'].value, form.controls['dobDay'].value)
        "
        onKeyPress="if(this.value.length==2) return false;"
      />
    </div>
    <div class="form-group yy">
      <label for="" class="form-label">{{ 'year' | translate }}</label>
      <input
        placeholder="YYYY"
        formControlName="dobYear"
        class="form-control"
        type="text"
        maxlength="4"
        digitOnly
        (change)="changeYear(form.controls['dobYear'].value)"
        (blur)="
          delegateBox(form.controls['dobYear'].value, form.controls['dobMonth'].value, form.controls['dobDay'].value)
        "
      />
    </div>
  </div>

  <div class="danger" *ngIf="finalError">{{ 'validDateOfBirthIsRequired' | translate }}.</div>
</form>
