import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChatMessage } from '@azure/communication-chat';
import { Contact } from 'src/app/modules/shared/interfaces/contact.entities';
import { ContactsService } from 'src/app/modules/shared/services/contacts.service';
import { ReadByContactWithDate, ReadByUser } from '../../entities/message.entities';

/**
 * Component to display participants who have read a message.
 */
@Component({
  selector: 'app-message-read-by',
  templateUrl: './message-read-by.component.html',
  styleUrls: ['./message-read-by.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageReadByComponent implements OnInit {
  /**
   * List of participants who have read the message.
   */
  public readReceiptParticipants: ReadByContactWithDate[] = [];
  /**
   * Injected ContactsService instance.
   */
  #contactsService = inject(ContactsService);

  /**
   * Constructor to initialize the component.
   * @param change Reference to the ChangeDetectorRef for change detection.
   * @param messageService Instance of MessageService for message-related operations.
   * @param chatMessage Injected ChatMessage data containing message metadata.
   */
  public constructor(
    private change: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) private chatMessage: ChatMessage,
  ) {}

  /**
   * Retrieves users who have read the message and their contact information.
   */
  public ngOnInit(): void {
    const readByUsers: ReadByUser[] = JSON.parse(this.chatMessage.metadata?.readBy || '[]');
    readByUsers.forEach((user: ReadByUser): void => {
      const userContact: Contact | null = this.#contactsService.getUserFromACSId(user.communicationUserId);
      if (userContact) {
        this.readReceiptParticipants.push({
          ...userContact,
          date: user.date,
        });
      }
    });
    this.change.markForCheck();
  }
}
