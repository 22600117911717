import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { FilterComponent } from 'src/app/modules/shared/components/filter/filter.component';
import { FilterOptions } from 'src/app/modules/shared/interfaces/common.entities';
import { ParamsOptions } from 'src/app/modules/shared/interfaces/pagination-options';
import { HttpService } from 'src/app/modules/shared/services/http.service';
import { environment } from 'src/environments/environment';
import { GenericTableColumn } from '../interfaces/generic-table';
import { Utils } from '../utils/utils';
import { CssService } from './css.service';
/**
 * @description
 * Service for managing filters and interacting with backend APIs.
 */
@Injectable({
  providedIn: 'root',
})
export class FilterService {
  /**
   * @description
   * BehaviorSubject holding filter options.
   */
  filterOptions: BehaviorSubject<Array<FilterOptions>> = new BehaviorSubject<Array<FilterOptions>>([]);
  /**
   * @description
   * Object storing currently applied filters.
   */
  appliedFilters: any = {};
  /**
   * @description
   * Flag indicating whether an API call is in progress.
   */
  apiIsProcessing = false;
  /**
   * @description
   * Base URL for API requests.
   */
  apiUrl: string = environment.url.apiHost;
  /**
   * @description
   * API version to use.
   */
  apiVersion: string = environment.url.version;
  /**
   * @description
   * Screen size based on CSS service.
   */
  screenSize: string = '';
  /**
   * @description
   * Constructor injecting necessary services.
   * @param httpService - HttpService for HTTP requests.
   * @param router - Angular Router for navigation.
   * @param route - Angular Router for route information.
   * @param dialog - MatDialog for opening dialogs.
   * @param cssService - CssService for CSS-related operations.
   */
  constructor(
    private httpService: HttpService,
    private dialog: MatDialog,
    private cssService: CssService,
  ) {}
  /**
   * @description
   * Fetches options for a specific column from the backend.
   * @param tableName - Name of the table.
   * @param columnName - Name of the column.
   * @param flag - Flag for HTTP request.
   * @param params - Parameters for the request.
   * @param separator - Separator for filter parameters.
   * @returns Observable of the HTTP response.
   */
  fetchOptionsByColumn(
    tableName: string,
    columnName: string,
    flag = false,
    params?: ParamsOptions,
    separator?: string,
  ) {
    let url = `${this.apiUrl}${this.apiVersion}/${tableName}/column/${columnName}?`;
    if (!params?.filter) {
      delete params?.filter;
    } else {
      params = this.removeParamsByKey(columnName, params, separator);
      url = url + `&filter=${params?.filter}`;
    }
    if (!params?.search) {
      delete params?.search;
    } else {
      url = url + `&search=${params?.search}`;
    }
    return this.httpService.get(url, params, flag);
  }
  /**
   * @description
   * Removes a specific parameter from the provided ParamsOptions object.
   * @param key - Key to remove from the filter.
   * @param params - ParamsOptions object containing parameters.
   * @param separator - Separator for filter parameters.
   * @returns Modified ParamsOptions object.
   */
  removeParamsByKey(key: string, params: ParamsOptions, separator?: string) {
    const zero = 0;
    const filter = params?.filter?.split(separator || ',');
    const item = filter?.find((ele) => ele.includes(key));
    if (item) {
      const index = filter?.findIndex((ele) => ele == item);
      if (index != undefined) {
        filter?.splice(index, 1);
        if (filter?.length !== undefined) {
          filter?.length > zero ? (params.filter = filter?.join(separator || ',')) : delete params.filter;
        }
      }
    }
    return params;
  }
  /**
   * @description
   * Retrieves options for a specific column and updates the filterOptions BehaviorSubject.
   * @param tableName - Name of the table.
   * @param columnName - Name of the column.
   * @param params - Parameters for the request.
   * @param separator - Separator for filter parameters.
   * @param dialog - Optional MatDialogRef for dialog operations.
   * @param flag - Flag for HTTP request.
   */
  getOptionsByColumn(
    tableName: string,
    columnName: string,
    params: ParamsOptions,
    separator?: string,
    dialog?: MatDialogRef<FilterComponent>,
    flag?: boolean,
  ) {
    this.apiIsProcessing = true;
    this.fetchOptionsByColumn(tableName, columnName, flag, params, separator).subscribe({
      next: (response) => {
        if (response) {
          this.apiIsProcessing = false;
          if (dialog) {
            dialog.componentInstance.searchList = response && response.data ? response.data : response;
          } else {
            this.filterOptions.next(response && response.data ? response.data : response);
          }
        }
      },
      error: (error) => {
        if (error) {
          this.apiIsProcessing = false;
          //  console.log(error);
        }
      },
    });
  }
  /**
   * @description
   * Opens a search filter dialog based on screen size and event position.
   * @param $event - Event triggering the dialog.
   * @param typeofColumn - Type of column for conditional logic.
   * @returns Reference to the opened MatDialog.
   */
  openSearchFilterDialog($event: any, typeofColumn?: string): MatDialogRef<FilterComponent> {
    this.cssService.windowSize$.subscribe((res) => {
      this.screenSize = res;
    });
    // console.log(this.screenSize,'screen')
    if (typeofColumn !== 'videoLobby') {
      this.dialog.closeAll();
    }

    const modalWidth = 500;
    const position = {
      left:
        document.body.clientWidth - $event.pageX > modalWidth
          ? $event.pageX + 'px'
          : document.body.clientWidth - modalWidth - 100 + 'px',
      top: $event.pageY + 7 + 'px',
    };
    const dialog = this.dialog.open(FilterComponent, {
      data: $event,
      hasBackdrop: true,
      disableClose: false,
      panelClass: $event.type === 'filter' ? 'mobile-full-screen-dialog' : '',
      position: this.screenSize === 'sm' ? {} : this.screenSize === 'md' ? {} : position,
    });
    return dialog;
  }
  /**
   * @description
   * Toggles active class based on applied filters for a column.
   * @param columns - Array of GenericTableColumn objects.
   * @param key - Key identifying the column.
   */
  toggleColumnActiveClass(columns: Array<GenericTableColumn>, key: string) {
    const index = columns.findIndex((ele: GenericTableColumn) => ele.key === key);
    const column = columns[index];
    const filterO = this.getAppliedFiltersByKey(column.filterKey || column.key);
    if (index >= 0 && column.hasOwnProperty('search') && column.search) {
      column.search.active = filterO && (filterO.id || Utils.isString(filterO)) ? true : false;
    }
    if (index >= 0 && column.hasOwnProperty('filter') && column.filter) {
      column.filter.active = filterO && filterO.length > 0 ? true : false;
    }
  }
  /**
   * @description
   * Resets all applied filters.
   */
  resetFilters() {
    this.appliedFilters = {};
  }
  /**
   * @description
   * Sets a filter option as applied for a specific key.
   * @param key - Key for the filter option.
   * @param value - FilterOptions object to set.
   */
  setAppliedFilters(key: string | undefined, value: FilterOptions) {
    if (key) {
      this.appliedFilters[key] = value;
    }
  }
  /**
   * @description
   * Retrieves all currently applied filters.
   * @returns Object containing applied filters.
   */
  getAppliedFilters() {
    return this.appliedFilters;
  }
  /**
   * @description
   * Retrieves applied filters for a specific key.
   * @param key - Key for the applied filter.
   * @returns Applied filter value.
   */
  getAppliedFiltersByKey(key: string | undefined) {
    if (key) {
      return this.appliedFilters[key];
    }
    return '';
  }
  /**
   * @description
   * Generates a query string representation of all applied filters.
   * @param separator - Separator for multiple values in a filter.
   * @returns String representation of applied filters.
   */
  getAppliedFiltersParamString(separator?: string) {
    let paramString = '';
    Object.keys(this.getAppliedFilters()).forEach((oKey: string) => {
      const values = this.getAppliedFiltersByKey(oKey);
      if (paramString) {
        paramString = `${paramString},`;
      }
      if (Array.isArray(values)) {
        paramString = paramString + (values?.length ? `${oKey}|${values.map((o) => o.id).join(separator || ':')}` : '');
      } else {
        paramString = paramString + (values?.id ? `${oKey}|${values?.id}` : `${oKey}|${values}`);
      }
    });
    return paramString;
  }
  /**
   * @description
   * Removes a specific applied filter by its key.
   * @param key - Key of the filter to remove.
   */
  removeFiltersByKey(key: string | undefined) {
    if (key) {
      delete this.appliedFilters[key];
    }
  }
}
