import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ChatParticipant } from '@azure/communication-chat';
import { ToastrService } from 'ngx-toastr';
import { DeleteThreadDialogData, RecallDialogData } from '../../entities/message.entities';
import { MessageService } from '../../services/message.service';

/**
 * Component for displaying confirmation dialogs for message actions.
 */
@Component({
  selector: 'app-message-confirmation',
  templateUrl: './message-confirmation.component.html',
  styleUrls: ['./message-confirmation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageConfirmationComponent {
  /**
   * Constructor of MessageConfirmationComponent.
   * @param dialog - MatDialogRef for the current dialog instance.
   * @param messageService - MessageService for recalling messages or deleting threads.
   * @param toast - ToastrService for displaying toast notifications.
   * @param messageData - Data passed into the component from the parent dialog.
   */
  constructor(
    private dialog: MatDialogRef<MessageConfirmationComponent>,
    private messageService: MessageService,
    private toast: ToastrService,
    @Inject(MAT_DIALOG_DATA) public messageData: RecallDialogData | DeleteThreadDialogData,
  ) {}

  /**
   * Handler for the 'Ok' button click event.
   * Depending on the function specified in messageData, recalls a message or removes a participant.
   */
  public async clickedOk(): Promise<void> {
    if (this.messageData.function === 'remove') {
      await this.removeParticipant();
      return;
    }
    await this.recallMessage();
  }

  /**
   * Recalls a message using the MessageService.
   * Closes the dialog if recall is successful; otherwise, displays an error toast.
   */
  private async recallMessage(): Promise<void> {
    try {
      await this.messageService.recallMessage(
        this.messageData.threadId,
        (this.messageData as RecallDialogData).messageId,
        (this.messageData as RecallDialogData).metadata,
      );
      this.dialog.close();
    } catch {
      this.toast.error('There was an error in Recalling Message. Please try again.', 'Error');
    }
  }

  /**
   * Removes a participant from a thread using the MessageService.
   * Closes the dialog with a success flag if participant removal is successful;
   * otherwise, displays an error toast.
   */
  private async removeParticipant(): Promise<void> {
    const participant: ChatParticipant = {
      id: {
        communicationUserId: this.messageService.communicationUserId,
      },
    };
    try {
      await this.messageService.removeParticipants(this.messageData.threadId, [participant]);
      this.dialog.close(true);
    } catch {
      this.toast.error('There was an error in Deleting the Thread. Please try again.', 'Error');
    }
  }
}
