<div class="dialog-lg">
  <div class="hs-tab-card-title flex-between">
    <h2>
      {{ data && data.eventDetails && data.eventDetails.title ? data.eventDetails.title : ('addEvent' | translate) }}
    </h2>

    <div class="dialog-header-icons">
      <!-- <button (click)="recurrenceCustomOption()" class="mr-3">Custom option</button> -->
      <i-feather name="check" class="pointer" (click)="submit()"></i-feather>
      @if (data && data.eventDetails && data.eventDetails.id) {
        <span>
          <span *appPermissions="'self.Calendar.Delete'">
            @if (data && data.eventDetails && data.eventDetails.id) {
              <i-feather name="trash-2" class="pointer" (click)="delete()"></i-feather>
            }
          </span>
        </span>
      }
      <i-feather name="x" class="pointer" mat-dialog-close></i-feather>
    </div>
  </div>
  <mat-dialog-content class="mat-dialog-content">
    <form class="add-event-form" [formGroup]="addEditEventForm">
      <div class="add-event-col">
        <div class="form-check-flex video-visit align-items-center" *appPermissions="'self.VideoLobby.Write'">
          <input
            type="checkbox"
            class="form-check-input"
            id="video-call"
            formControlName="isVirtualVisit"
            (change)="onChangeVideoVisit()"
          />
          <label class="check-flex-label clr-primary mb-0" for="video-call">{{ 'videoVisit' | translate }}</label>
          <!-- <i-feather name="video" class="event-video-icon feather-20"></i-feather> -->
        </div>
        <!-- <div class="video-visit-link">
        <a href="https://medocity.siteportal.com/pvk-dvoy-fkd">https://medocity.siteportal.com/pvk-dvoy-fkd</a>
      </div> -->
        <div class="form-group">
          <label required>{{ 'Title' | translate }}</label>
          <input type="text" placeholder="{{ 'title' | translate }}" class="form-control" formControlName="title" />
          @if (addEditEventForm.invalid && submitted && f.title.errors) {
            <div class="invalid-feedback">
              @if (f.title.errors.required) {
                <div>{{ 'thisIsRequiredField' | translate }}</div>
              }
            </div>
          }
        </div>
        @if (!addEditEventForm.controls.isVirtualVisit.value) {
          <div class="form-group form-check-flex all-day-check">
            <input
              type="checkbox"
              class="form-check-input"
              id="all-day"
              formControlName="allDay"
              (change)="onChangeAllDay()"
            />
            <label class="check-flex-label clr-primary mb-0" for="all-day">{{ 'allDay' | translate }}</label>
          </div>
        }
        <div class="form-group date-block mb-1">
          <div class="date-col">
            <div class="date-picker">
              <input
                class="form-control"
                matInput
                placeholder="{{ 'startDate' | translate }}"
                [matDatepicker]="eventStartPicker"
                formControlName="eventStart"
                [min]="now"
                (dateChange)="onChangeEventStart($event)"
                readonly
              />
              <mat-datepicker-toggle matIconSuffix [for]="eventStartPicker"></mat-datepicker-toggle>
              <mat-datepicker #eventStartPicker></mat-datepicker>
            </div>
            <div class="form-control time-picker-input">
              <div class="time-value">
                {{ f.eventStart.value | date: 'hh:mm a' }}
              </div>
              @if (f.eventStart.value) {
                <app-timepicker
                  [timeControl]="f.eventStart"
                  [minuteStep]="minuteStep"
                  (changeTime)="onChangeTime($event, 'start')"
                ></app-timepicker>
              }
            </div>
            <span>{{ 'to' | translate }}</span>
          </div>
          <div class="date-col">
            <div class="date-picker">
              <input
                class="form-control"
                matInput
                [matDatepicker]="eventEndPicker"
                formControlName="eventEnd"
                placeholder="{{ 'endDate' | translate }}"
                [min]="f.eventStart.value"
                (dateChange)="onChangeEventEnd($event)"
                readonly
              />
              <mat-datepicker-toggle matIconSuffix [for]="eventEndPicker"></mat-datepicker-toggle>
              <mat-datepicker #eventEndPicker></mat-datepicker>
            </div>
            <div class="form-control time-picker-input">
              <div class="time-value">
                {{ f.eventEnd.value | date: 'hh:mm a' }}
              </div>
              @if (f.eventEnd.value) {
                <app-timepicker
                  [timeControl]="f.eventEnd"
                  [minuteStep]="minuteStep"
                  (changeTime)="onChangeTime($event, 'end')"
                ></app-timepicker>
              }
            </div>
          </div>
        </div>
        @if (addEditEventForm.invalid && submitted && f.eventStart.errors) {
          <div class="invalid-feedback mb-2">
            @if (f.eventStart.errors.startGreaterThanEnd) {
              <div>{{ 'startNotGreaterThanEnd' | translate }}</div>
            }
            @if (f.eventStart.errors.startGreaterThanCurrentTime) {
              <div>
                {{ 'startGreaterThanCurrentTime' | translate }}
              </div>
            }
          </div>
        }
        @if (!addEditEventForm?.controls?.isVirtualVisit?.value) {
          <div class="form-group">
            <label for="recurrence">{{ 'recurrence' | translate }}</label>
            <div class="custom-select-arrow">
              <mat-select
                name="recurrenceOption"
                class="form-control"
                formControlName="rOption"
                (selectionChange)="onChangeRecurrenceOptions()"
              >
                @for (item of recurrenceOptions; track item) {
                  <mat-option class="option-style" [value]="item.value">
                    {{ item.key | translate }}
                  </mat-option>
                }
              </mat-select>
            </div>
            <!-- <span class="ff-medium bottom-label">Every 1 week(s) on Sunday, Tuesday and Thursday</span> -->
          </div>
        }
        @if (addEditEventForm.controls.custom.value) {
          <div class="form-group ff-bold rrule-text">
            {{ rRuleString }}
          </div>
        }
        @if (addEditEventForm.value.rOption !== 'doesNotRepeat' && !addEditEventForm?.controls?.isVirtualVisit?.value) {
          <div class="form-group">
            <label for="end">End</label>
            <div class="date-block">
              <div class="custom-select-arrow sm-50">
                <mat-select
                  name="recurrenceOption"
                  class="form-control"
                  formControlName="untilOption"
                  (selectionChange)="onChangeUntilOptions()"
                >
                  @for (option of untilOptions; track option) {
                    <mat-option class="option-style" [value]="option.value">
                      {{ option.key | translate }}
                    </mat-option>
                  }
                </mat-select>
              </div>
              <div>
                <div class="d-flex align-items-center">
                  @if (addEditEventForm.value.untilOption === 'afterNumberOfTimes') {
                    <input
                      type="text"
                      class="form-control num-field me-1"
                      [min]="1"
                      [value]="1"
                      (input)="onChangeNumberOfTimes($event)"
                    />
                  }
                  @if (addEditEventForm.value.untilOption === 'afterNumberOfTimes') {
                    <span>{{ 'times' | translate }}</span>
                  }
                </div>
                @if (addEditEventForm.invalid && submitted && rRuleSettingFormGroup.get('count')?.errors) {
                  <div class="invalid-feedback">
                    @if (rRuleSettingFormGroup.get('count')?.errors?.required) {
                      {{ 'thisIsRequiredField' | translate }}
                    }
                  </div>
                }
              </div>
              <div>
                @if (addEditEventForm.value.untilOption === 'onDate') {
                  <div class="date-picker">
                    <input
                      matInput
                      class="form-control"
                      [matDatepicker]="untilOnDatePicker"
                      (dateChange)="onChangeUntilDate($event)"
                      [min]="f.eventStart.value"
                      formControlName="untilDate"
                      placeholder="{{ 'until' | translate }}"
                      readonly
                    />
                    <mat-datepicker-toggle matIconSuffix [for]="untilOnDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #untilOnDatePicker></mat-datepicker>
                  </div>
                }
                @if (addEditEventForm.invalid && submitted && rRuleSettingFormGroup.get('until')?.errors) {
                  <div class="invalid-feedback">
                    @if (rRuleSettingFormGroup.get('until')?.errors?.required) {
                      <div>
                        {{ 'thisIsRequiredField' | translate }}
                      </div>
                    }
                  </div>
                }
              </div>
            </div>
          </div>
        }
        @if (!addEditEventForm.controls.isVirtualVisit.value) {
          <div class="form-group">
            <label for="Location">{{ 'location' | translate }}</label>
            <input
              type="text"
              class="form-control"
              placeholder="{{ 'location' | translate }}"
              formControlName="location"
            />
          </div>
        }
        <div class="form-group">
          <span>
            <label for="area1" class="form-label">{{ 'notes' | translate }}</label> ({{
              'thisWillBeVisibleToAllAttendees' | translate
            }})</span
          >
          <textarea
            class="form-control event-notes"
            id="area1"
            placeholder="{{ 'notes' | translate }}"
            rows="5"
            formControlName="notes"
          ></textarea>
        </div>
      </div>
      <!-- <div class="add-event-col-sep"></div> -->
      <div class="add-event-col">
        @if (!toggleOrganizerView) {
          <div class="form-group">
            <label class="clr-disabled">{{ 'organizer' | translate }}</label>
            <div class="block-chips bg-lightGreen">
              <span class="item"
                >{{ addEditEventForm.controls.organizer.value?.fullName }}
                <i-feather
                  name="plus"
                  class="feather-20 remove-chips-item"
                  (click)="toggleOrganizerView = !toggleOrganizerView"
                ></i-feather
              ></span>
            </div>
          </div>
        }
        <div class="form-group">
          <label>{{ 'attendees' | translate }}*</label>
          <input
            type="text"
            matInput
            required
            [matAutocomplete]="autoGroup"
            formControlName="keywords"
            placeholder="{{ 'typeHereToSearch' | translate }}"
            class="form-control"
          />
          @if (
            addEditEventForm.invalid &&
            submitted &&
            f.keywords.errors &&
            addEditEventForm.controls.attendees.value.length <= 0
          ) {
            <div class="invalid-feedback">
              @if (f.keywords.errors.required) {
                <div>{{ 'attendeesIsRequired' | translate }}</div>
              }
            </div>
          }
          <mat-autocomplete #autoGroup="matAutocomplete" class="calendar-autoComplete">
            @for (group of data.attendees; track group) {
              <mat-optgroup label="{{ group.label | clientLabel }}">
                @for (
                  user of group.users | filter: addEditEventForm.controls.keywords.value : 'userFullName';
                  track user
                ) {
                  <mat-option class="option-style">
                    <div (click)="onClickAttendant(user)">
                      <img [src]="user.userImageUrl" />
                      <span>{{ user.userFullName }}</span>
                    </div>
                  </mat-option>
                }
              </mat-optgroup>
            }
          </mat-autocomplete>
        </div>
        <!-- <div class="form-group">
          <label>{{ 'attendees' | translate }}*</label>
          <input type="text" class="form-control" />
          <div *ngIf="submitted && f.attendees.errors" class="invalid-feedback">
            <div *ngIf="f.attendees.errors.required">{{ 'attendeesIsRequired' | translate }}</div>
          </div>
        </div> -->
        @if (toggleOrganizerView) {
          <div class="form-group">
            <label class="clr-disabled">{{ 'organizer' | translate }}</label>
            <div class="block-chips bg-lightGreen">
              <span class="item"
                >{{ addEditEventForm.controls.organizer.value?.fullName }}
                <i-feather
                  name="x"
                  class="feather-20 remove-chips-item"
                  (click)="toggleOrganizerView = !toggleOrganizerView"
                ></i-feather
              ></span>
            </div>
          </div>
        }

        @if (selectedAttendees.member.length) {
          <div class="form-group">
            <label class="clr-disabled">{{ 'managed' | clientLabel }}</label>
            <div class="block-chips bg-lightOrange">
              @for (member of selectedAttendees.member; track member) {
                <div>
                  <div class="item">
                    <span>{{ member.fullName }}</span>
                    <i-feather
                      name="x"
                      class="feather-20 remove-chips-item"
                      (click)="removeAttendant(member.user, 'member')"
                    ></i-feather>
                  </div>
                </div>
              }
            </div>
          </div>
        }
        @if (selectedAttendees.coOwner.length) {
          <div class="form-group">
            <label class="clr-disabled">{{ 'manager' | clientLabel }}</label>
            <div class="block-chips bg-lightBlue">
              @for (coOwner of selectedAttendees.coOwner; track coOwner) {
                <div>
                  <div class="item">
                    <span>{{ coOwner.fullName }}</span>
                    <i-feather
                      name="x"
                      class="feather-20 remove-chips-item"
                      (click)="removeAttendant(coOwner.user, 'coOwner')"
                    ></i-feather>
                  </div>
                </div>
              }
            </div>
          </div>
        }
      </div>
    </form>
  </mat-dialog-content>
</div>
