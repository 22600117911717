import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';
/**
 * Directive to trim whitespace from input values for text-based form controls.
 *
 * @Directive
 * @selector `input:not([type=checkbox])[formControlName], input:not([type=checkbox])[formControl], input:not([type=number])[formControl], input:not([type=checkbox])[ngModel]`
 */
@Directive({
  selector:
    'input:not([type=checkbox])[formControlName], input:not([type=checkbox])[formControl],input:not([type=number])[formControl], input:not([type=checkbox])[ngModel]',
})
export class TrimDirective {
  /**
   * Constructs the directive with a reference to NgControl for interacting with form control values.
   *
   * @param control The NgControl instance associated with the directive's host element.
   */
  constructor(private control: NgControl) {}
  /**
   * Listens for the 'keyup' event to trim whitespace and multiple spaces from the input value.
   */
  @HostListener('keyup', ['$event'])
  onKeyUp() {
    if (isNaN(parseInt(this.control?.control?.value))) {
      this.control?.control?.setValue(
        this.control?.control?.value?.trim() === '' ? '' : this.control?.control?.value?.replace(/\s\s+/g, ''),
      );
    } else {
      if (typeof this.control?.control?.value !== 'number') {
        this.control?.control?.setValue(this.control?.control?.value?.trim());
      }
    }
  }
  /**
   * Listens for the 'blur' event to trim whitespace from the input value.
   */
  @HostListener('blur', ['$event'])
  onBlur() {
    // console.log('called from blur')
    if (isNaN(this.control?.control?.value)) {
      this.control?.control?.setValue(this.control?.control?.value?.trim());
    }
  }
}
