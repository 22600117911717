<div class="dialog-sm">
  <div class="hs-tab-card-title flex-between">
    <h2>{{ 'resourceRecommendation' | translate }}</h2>
    <span class="dialog-header-icons">
      <i-feather mat-dialog-close name="x" class="pointer" aria-label="Close"></i-feather>
    </span>
  </div>
  <mat-dialog-content>
    <div class="message-recommended-resources">
      <div class="message-recommended-resources-resources">
        {{ data.summary }}
      </div>

      @for (resource$ of resourceData$; track resource$) {
        @if (resource$ | async; as resource) {
          <div class="message-recommended-resources-item card-base card-varient">
            <div class="message-recommended-resources-options">
              <div class="recommendation-resource-thumbnail">
                @if (resource.resource.imageUrl) {
                  <img [src]="resource.resource.imageUrl" alt="recommendation-resource-thumbnail" />
                }
                @if (!resource.resource.imageUrl) {
                  <div class="recommendation-resource-thumbnail-inner">
                    <i-feather [name]="resource.resource.mimetype" [ngClass]="resource.resource.mimetype"></i-feather>
                  </div>
                }
              </div>
              <!-- <div ngbDropdown>
              <i-feather name="more-vertical" class="pointer" ngbDropdownToggle></i-feather>
              <div ngbDropdownMenu aria-labelledby="ngb-more-dropdown" class="ngb-more-dropdown">
                <div class="recommendation-resource-rection-icons">
                  <i-feather name="star" class="feather-16"></i-feather>
                  <i-feather name="thumbs-up" class="feather-16"></i-feather>
                  <i-feather name="thumbs-down" class="feather-16"></i-feather>
                </div>
              </div>
            </div> -->
            </div>
            <h2>
              {{ resource.resource.caption }}
            </h2>
            <div class="message-recommended-resources-details">
              <p>{{ resource.resource.description }}</p>
            </div>
            <span
              class="btn btn-primary"
              (click)="
                clickedViewNow(resource.resource.contentType, resource.resource.contentUrl || resource.resource.topicId)
              "
              >{{ 'viewNow' | translate }}</span
            >
          </div>
        }
      }
    </div>
  </mat-dialog-content>
</div>
