import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { DirectivesModule } from '../shared/directives/directive.module';
import { GroupAddEditComponent } from './components/group-add-edit/group-add-edit.component';
import { GroupUseraddComponent } from './components/group-useradd/group-useradd.component';
import { GroupViewComponent } from './components/group-view/group-view.component';
import { GroupComponent } from './components/group/group.component';
import { GroupsRoutingModule } from './groups-routing.module';

@NgModule({
  declarations: [GroupComponent, GroupAddEditComponent, GroupViewComponent, GroupUseraddComponent],
  imports: [CommonModule, GroupsRoutingModule, SharedModule, DirectivesModule, MatMenuModule],
})
export class GroupsModule {}
