<div id="app">
  <div class="form-group button-file">
    <div class="hs-tab-card-title">
      <div class="dialog-header-icons">
        <i-feather name="x" (click)="close()"></i-feather>
      </div>
    </div>
    <div><video #video id="video" autoplay></video></div>
    <div>
      <button id="snap" class="btn btn-primary" (click)="capture()">{{ 'snapPhoto' | translate }}</button>
    </div>
    <canvas #canvas id="canvas" width="640" height="480"></canvas>
  </div>
</div>
