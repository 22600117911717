<div class="meeting-options">
  <div class="d-flex justify-content-between mb-4">
    <h3 class="mb-0">{{ 'meetingOptions' | translate }}</h3>
    <span class="group-icons">
      <i-feather name="check" class="feather-20 text-secondary pointer"></i-feather>
      <i-feather name="x" class="feather-20 text-secondary pointer" (click)="closeSidePanel.emit(true)"></i-feather>
    </span>
  </div>

  <p class="label-heading mb-4">{{ 'audiosettings' | translate }}</p>

  <form>
    <div class="form-group mb-4">
      <div class="custom-select-arrow">
        <label
          >{{ 'whoCanBypassTheLobby' | translate }}
          <i-feather name="info" class="feather-20 pointer ms-1" aria-label="info"></i-feather
        ></label>
        <select class="form-control">
          <option value="">People in my org and guests</option>
          <option value="">People in my org and guests</option>
        </select>
      </div>
    </div>

    <div class="form-check-flex align-items-center justify-content-between mb-4">
      <label>{{ 'peopleDialingInCanBypassTheLobby' | translate }}</label>
      <mat-slide-toggle [checked]="false"></mat-slide-toggle>
    </div>
    <div class="form-check-flex align-items-center justify-content-between mb-4">
      <label
        >{{ 'announceWhenPeopleDialingInJoinOrLeave' | translate }}
        <i-feather name="info" class="feather-20 pointer ms-1" aria-label="info"></i-feather
      ></label>
      <mat-slide-toggle [checked]="true"></mat-slide-toggle>
    </div>

    <div class="form-check-flex align-items-center justify-content-between mb-4">
      <label class="m-0">{{ 'chooseCoorganizers' | translate }}:</label>
    </div>

    <div class="form-check-flex align-items-center justify-content-between me-2">
      <label
        >{{ 'toAssignARoleToAparticipantInviteThemToTheMeetingIndividually' | translate }}.
        <a href="#">{{ 'learnMore' | translate }}</a></label
      >
    </div>

    <div class="form-group mb-4">
      <div class="custom-select-arrow">
        <label>{{ 'whoCanPresent' | translate }}</label>
        <select class="form-control">
          <option value="">Everyone</option>
          <option value="">Everyone</option>
        </select>
      </div>
    </div>

    <div class="form-check-flex align-items-center justify-content-between mb-4">
      <label
        >{{ 'allowMicForAttendees' | translate }}
        <i-feather name="info" class="feather-20 pointer ms-1" aria-label="info"></i-feather
      ></label>
      <mat-slide-toggle [checked]="true"></mat-slide-toggle>
    </div>

    <div class="form-check-flex align-items-center justify-content-between mb-4">
      <label
        >{{ 'allowCameraForAttendees' | translate }}
        <i-feather name="info" class="feather-20 pointer ms-1" aria-label="info"></i-feather
      ></label>
      <mat-slide-toggle [checked]="true"></mat-slide-toggle>
    </div>
    <div class="form-group mb-4">
      <div class="custom-select-arrow">
        <label>{{ 'meetingChat' | translate }}</label>
        <select class="form-control">
          <option value="">On</option>
        </select>
      </div>
    </div>

    <div class="form-check-flex align-items-center justify-content-between mb-4">
      <label
        >{{ 'allowReactions' | translate }}
        <i-feather name="info" class="feather-20 pointer ms-1" aria-label="info"></i-feather
      ></label>
      <mat-slide-toggle [checked]="true"></mat-slide-toggle>
    </div>
    <div class="form-check-flex align-items-center justify-content-between mb-4">
      <label
        >{{ 'provideCARTCaptions' | translate }}
        <i-feather name="info" class="feather-20 pointer ms-1" aria-label="info"></i-feather
      ></label>
      <mat-slide-toggle [checked]="false"></mat-slide-toggle>
    </div>
    <div class="form-check-flex align-items-center justify-content-between mb-4">
      <label
        >{{ 'allowAttendanceReport' | translate }}
        <i-feather name="info" class="feather-20 pointer ms-1" aria-label="info"></i-feather
      ></label>
      <mat-slide-toggle [checked]="true"></mat-slide-toggle>
    </div>
  </form>
</div>
