import { Component } from '@angular/core';
/**
 * Component for managing contacts.
 */
@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss'],
})
export class ContactsComponent {
  /**
   * Flag indicating whether the patients section is expanded.
   */
  patientsExpended = false;
  /**
   * Flag indicating whether the facilities section is expanded.
   */
  facilitysExpended = false;
  /**
   * Flag indicating whether the medical section is expanded.
   */
  medicalExpended = false;
}
