import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { DatePipe, NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  Input,
  OnDestroy,
  OnInit,
  signal,
  WritableSignal,
} from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { FeatherModule } from 'angular-feather';
import { firstValueFrom, Subscription } from 'rxjs';
import { EventDetailsComponent } from 'src/app/modules/calendar/components/event-details/event-details.component';
import { Attendant } from 'src/app/modules/calendar/entities/calendar-entities';
import { WebSocketService } from 'src/app/modules/medocity/services/web-socket.service';
import { InformationDialogComponent } from 'src/app/modules/shared/components/information-dialog/information-dialog.component';
import { VideoLobbyWebSocketEvent } from 'src/app/modules/shared/interfaces/common.entities';
import { Contact } from 'src/app/modules/shared/interfaces/contact.entities';
import { TranslatePipe } from 'src/app/modules/shared/pipes/translate.pipe';
import { ContactsService } from 'src/app/modules/shared/services/contacts.service';
import { Utils } from 'src/app/modules/shared/utils/utils';
import { Attendee, EventInputCalendar, VideoLobby } from '../../entities/video-lobby.entities';
import { VideoLobbyService } from '../../services/video-lobby.service';
import { VideoLobbyComponent } from '../video-lobby/video-lobby.component';
/**
 * Component representing a list of video lobbies.
 */
@Component({
  selector: 'app-video-list',
  templateUrl: './video-list.component.html',
  styleUrls: ['./video-list.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatDialogModule, DatePipe, FeatherModule, NgClass, TranslatePipe],
})
export class VideoListComponent implements OnInit, OnDestroy {
  /** Input property to determine widget mode. */
  @Input() widgetMode: boolean = false;
  /** Signal to manage the list of video lobbies. */
  public videoLobbyList: WritableSignal<VideoLobby[]> = signal([]);
  /** Signal to track the current time. */
  public currentTime: WritableSignal<number> = signal(new Date().getTime());
  /** Injected instance of VideoLobbyService. */
  public videoLobbyService = inject(VideoLobbyService);

  /** Interval ID for updating current time. */
  #dateInterval!: ReturnType<typeof setInterval>;
  /** Injected MatDialog instance for opening dialogs. */
  #dialog = inject(MatDialog);
  /** Injected ContactsService instance for managing contacts. */
  #contactService = inject(ContactsService);
  /** Injected WebSocketService instance for WebSocket communication. */
  #webSocket = inject(WebSocketService);
  /** Subscription for WebSocket events. */
  #webSocketSub$!: Subscription;
  /**
   * Initializes the component:
   * - Starts interval to update current time.
   * - Retrieves the list of video lobbies.
   * - Initializes WebSocket subscriptions.
   */
  public ngOnInit(): void {
    this.#dateInterval = setInterval(() => {
      this.currentTime.set(new Date().getTime());
    }, 1000 * 60);
    this.#getLobbyList();
    this.#initSocketSubscriptions();
  }
  /**
   * Cleans up resources:
   * - Clears the interval for updating current time.
   * - Unsubscribes from WebSocket subscriptions.
   * - Clears timers for each video lobby.
   */
  public ngOnDestroy(): void {
    clearInterval(this.#dateInterval);
    this.#webSocketSub$.unsubscribe();
    this.videoLobbyList().forEach((lobby: VideoLobby): void => {
      if (lobby.checkedInInterval) clearTimeout(lobby.checkedInInterval);
    });
  }
  /**
   * Initiates a call in the specified video lobby.
   * @param lobby The video lobby to start the call in.
   */
  public async startCall(lobby: VideoLobby): Promise<void> {
    if (this.widgetMode) {
      await this.#openVideoLobby();
      await firstValueFrom(this.videoLobbyService.dialogLoadingComplete);
    }
    this.videoLobbyService.activeCall.set(lobby);
    this.videoLobbyService.startCall(lobby.roomId);
    lobby.callStatusSignal.set('Started');
    lobby.checkedInTimer.set('');
    if (lobby.checkedInInterval) clearTimeout(lobby.checkedInInterval);
  }
  /**
   * Opens event details dialog for the specified video lobby.
   * @param lobbyDetails Details of the video lobby.
   */
  public openEventDetails(lobbyDetails: VideoLobby): void {
    const eventDetails: EventInputCalendar = {
      extendedProps: {
        virtualVisit: true,
        roomId: lobbyDetails.roomId,
        id: lobbyDetails.roomId,
        allDay: false,
        attendees: lobbyDetails.attendees,
        organizer: {
          id: lobbyDetails.host.userId,
          fullName: `${lobbyDetails.host.firstName} ${lobbyDetails.host.lastName}`,
        },
        duration:
          (new Date(lobbyDetails.validUntil).getTime() - new Date(lobbyDetails.validFrom).getTime()) / 1000 / 60,
        eventStart: lobbyDetails.validFrom,
        eventEnd: lobbyDetails.validUntil,
        notes: lobbyDetails.notes,
        eventTitle: lobbyDetails.eventTitle,
      },
    };

    const attendees: Array<{ label: string; users: Attendant[] }> = [
      {
        label: 'Manager',
        users:
          this.#contactService.managerUsers().map((contact: Contact): Attendant => {
            return {
              userImageUrl: contact.userImageUrl,
              userFullName: contact.userFullName,
              id: contact._id,
              user: contact.user,
              userAcsId: contact.userAcsId || '',
              groupOwnerType: contact.groupOwnerType,
              userGroupRelation: contact.userGroupRelation,
            };
          }) || [],
      },
      {
        label: 'Managed',
        users:
          this.#contactService.managedUsers().map((contact: Contact): Attendant => {
            return {
              userImageUrl: contact.userImageUrl,
              userFullName: contact.userFullName,
              id: contact._id,
              user: contact.user,
              userAcsId: contact.userAcsId || '',
              groupOwnerType: contact.groupOwnerType,
              userGroupRelation: contact.userGroupRelation,
            };
          }) || [],
      },
    ];

    this.#dialog.open(EventDetailsComponent, {
      data: {
        eventDetails,
        attendees,
      },
      panelClass: ['mob-dialog-page', 'full-screen-dialog'],
    });
  }
  /**
   * Requests check-in for the specified video lobby.
   * @param lobby The video lobby to request check-in for.
   */
  public async requestCheckIn(lobby: VideoLobby): Promise<void> {
    if (lobby.checkInRequestStatus()) return;
    try {
      await this.videoLobbyService.requestCheckIn(lobby.id);
      lobby.checkInRequestStatus.set(true);
    } catch {
      lobby.checkInRequestStatus.set(false);
    }
  }
  /**
   * Retrieves the list of video lobbies based on current date.
   * Sets up timers and status signals for each lobby.
   */
  #getLobbyList(): void {
    const startTime = Utils.startOfDate();
    const endTime = Utils.endOfDate(new Date());
    const dateO = {
      startTime: Utils.convertLocalToUTC(startTime),
      endTime: Utils.convertLocalToUTC(endTime),
    };
    this.videoLobbyService.getLobbyList(dateO).subscribe({
      next: (response: VideoLobby[]) => {
        if (response && response.length) {
          response.sort(
            (a: VideoLobby, b: VideoLobby): number => new Date(a.validFrom).getTime() - new Date(b.validFrom).getTime(),
          );

          const pastEvents: VideoLobby[] = [];
          const upcomingEvents: VideoLobby[] = [];

          response.forEach((lobby: VideoLobby): void => {
            this.#setUpLobbyEvents(lobby);

            if (new Date(lobby.validUntil).getTime() > this.currentTime()) {
              upcomingEvents.push(lobby);
            } else {
              pastEvents.push(lobby);
            }
          });
          this.videoLobbyList.set([...upcomingEvents, ...pastEvents]);
        }
      },
      error: (error) => {
        console.log(error);
      },
    });
  }
  /**
   * Sets up event handlers and signals for a video lobby.
   * @param lobby The video lobby to set up events for.
   */
  #setUpLobbyEvents(lobby: VideoLobby): void {
    lobby.callStatusSignal = signal(lobby.callStatus);
    this.#setRequestCheckIn(lobby);
    this.#setCallTimer(lobby);
    lobby.checkedInTimer = signal('');
    this.#startCheckInTimer(lobby);
    this.#findParticipantToDisplay(lobby);
  }
  /**
   * Sets up check-in request status for a video lobby.
   * @param lobby The video lobby to set up check-in request status for.
   */
  #setRequestCheckIn(lobby: VideoLobby): void {
    const managedUser: Attendee | undefined = lobby.attendees.find(
      (attendee: Attendee): boolean => attendee.userType === 'Managed',
    );
    if (!managedUser || lobby.checkedIn) {
      lobby.checkInRequestStatus = signal(null);
      return;
    }
    lobby.checkInRequestStatus = signal(lobby.checkInRequested);
  }
  /**
   * Sets up call timer based on start and end time of the video lobby.
   * @param lobby The video lobby to set up call timer for.
   */
  #setCallTimer(lobby: VideoLobby): void {
    const startTimeMilli = new Date(lobby.validFrom).getTime() - 60 * 1000;
    const endTimeMilli = new Date(lobby.validUntil).getTime();
    lobby.enableCall = computed(() => {
      if (this.currentTime() > endTimeMilli) return 'Past';
      else if (this.currentTime() < startTimeMilli) return 'Upcoming';
      return 'Current';
    });
  }
  /**
   * Opens the video lobby dialog for widget mode.
   * Waits for dialog loading to complete.
   */
  async #openVideoLobby(): Promise<void> {
    if (this.videoLobbyService.pipMode()) return;
    if (await this.videoLobbyService.checkCameraMicrophonePermission()) {
      this.#dialog.open(VideoLobbyComponent, {
        panelClass: ['video-lobby-dialog', 'full-screen-dialog'],
        hasBackdrop: false,
        disableClose: true,
        scrollStrategy: new NoopScrollStrategy(),
      });
    } else {
      this.#dialog.open(InformationDialogComponent, {
        data: {
          title: this.videoLobbyService.noVideoPermissionTitle,
          message: this.videoLobbyService.noVideoPermissionMessage,
        },
        panelClass: 'confirmation-dialog',
      });
    }
  }
  /**
   * Initializes WebSocket subscriptions for handling video lobby events.
   */
  #initSocketSubscriptions(): void {
    this.#webSocketSub$ = this.#webSocket.videoLobbyEvent.subscribe((res: VideoLobbyWebSocketEvent): void => {
      switch (res.type) {
        case 'VideoLobbyCreated':
          this.#setUpLobbyEvents(res.videoLobbyDetails);
          this.videoLobbyList.update((list) => [res.videoLobbyDetails, ...list]);
          break;
        case 'VideoLobbyUpdated':
          this.videoLobbyList.update((list) =>
            list.map((lobby) => {
              if (lobby.id === res.id) {
                this.#updateLobby(lobby, res.videoLobbyDetails);
              }
              return lobby;
            }),
          );
          break;
        case 'VideoLobbyDeleted':
          if (this.videoLobbyService.activeCall()?.id === res.id) return;
          this.videoLobbyList.update((list) => list.filter((lobby) => lobby.id !== res.id));
          break;
      }

      const lobby: VideoLobby | undefined = this.videoLobbyList().find(
        (lobby: VideoLobby): boolean => lobby.id === res.id,
      );
      if (!lobby) return;
      switch (res.type) {
        case 'CheckInRequested':
          lobby.checkInRequestStatus.set(true);
          break;
        case 'CheckedIn':
          lobby.checkedIn = res.date;
          this.#startCheckInTimer(lobby);
          break;
        case 'CallStarted':
          lobby.callStatusSignal.set('Started');
          lobby.checkedInTimer.set('');
          if (lobby.checkedInInterval) clearTimeout(lobby.checkedInInterval);
          break;
        case 'CallEnded':
          lobby.callStatusSignal.set('Completed');
          break;
        case 'CallRecordingStarted':
          if (!this.videoLobbyService.recordingId()) this.videoLobbyService.recordingId.set('No ID');
          break;
        case 'CallRecordingStopped':
          this.videoLobbyService.recordingId.set('');
          break;
      }
    });
  }
  /**
   * Starts the check-in timer for a video lobby.
   * @param lobby The video lobby to start the check-in timer for.
   */
  #startCheckInTimer(lobby: VideoLobby): void {
    if (!lobby.checkedIn || lobby.callStatus !== 'Active') return;
    const endTimeMilli = new Date(lobby.validUntil).getTime();
    if (this.currentTime() > endTimeMilli) return;
    if (lobby.checkedInInterval) clearTimeout(lobby.checkedInInterval);

    lobby.checkedInInterval = setInterval(() => {
      if (this.currentTime() > endTimeMilli) {
        lobby.checkedInTimer.set('');
        clearInterval(lobby.checkedInInterval);
        return;
      }

      const diff = new Date().getTime() - new Date(lobby.checkedIn || '').getTime();
      const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((diff % (1000 * 60)) / 1000);
      lobby.checkedInTimer.set(
        `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`,
      );
    }, 1000);
  }
  /**
   * Finds a participant to display in the list for a video lobby.
   * @param lobby The video lobby to find the participant for.
   */
  #findParticipantToDisplay(lobby: VideoLobby): void {
    lobby.attendees.forEach((attendee: Attendee): void => {
      attendee.userType === 'Managed';
    });

    let attendee: Attendee | undefined = lobby.attendees.find((attendee) => attendee.userType === 'Managed');
    if (attendee) {
      lobby.displayedParticipantInList = attendee;
      return;
    }

    attendee = lobby.attendees.find((attendee) => attendee.acsId !== this.videoLobbyService.acsId);
    lobby.displayedParticipantInList = attendee || ({} as Attendee);
  }
  /**
   * Updates an existing video lobby with new details.
   * @param oldLobby The old video lobby to update.
   * @param newLobby The new video lobby details.
   */
  #updateLobby(oldLobby: VideoLobby, newLobby: VideoLobby): void {
    // Needs further testing for edge cases
    // If no change in time, don't reset
    // On going call discussion
    oldLobby.eventTitle = newLobby.eventTitle;

    oldLobby.validFrom = newLobby.validFrom;
    oldLobby.validUntil = newLobby.validUntil;

    oldLobby.attendees = newLobby.attendees;
    this.#findParticipantToDisplay(oldLobby);

    oldLobby.callStatus = newLobby.callStatus;
    oldLobby.callStatusSignal.set(oldLobby.callStatus);

    oldLobby.checkedIn = newLobby.checkedIn;
    this.#setCallTimer(oldLobby);
    oldLobby.checkedInTimer = signal('');
    this.#startCheckInTimer(oldLobby);

    oldLobby.checkInRequested = newLobby.checkInRequested;
    this.#setRequestCheckIn(oldLobby);

    oldLobby.notes = newLobby.notes;
  }
}
