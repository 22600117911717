import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ChatMessageEditedEvent,
  ChatMessageReceivedEvent,
  ChatParticipant,
  ChatThreadCreatedEvent,
  ParticipantsRemovedEvent,
} from '@azure/communication-chat';
import { CommunicationUserIdentifier } from '@azure/communication-common';
import { Subscription } from 'rxjs';
import { ACSService } from 'src/app/modules/shared/services/acs.service';
import { ChatFilter, ChatThreadMetaData } from '../../entities/message.entities';
import { MessageService } from '../../services/message.service';
import { MessageComponent } from '../message/message.component';

/**
 * Component to display and manage a list of message threads for a specific patient.
 */
@Component({
  selector: 'app-message-thread-list',
  templateUrl: './message-thread-list.component.html',
  styleUrls: ['./message-thread-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageThreadListComponent implements OnInit, OnDestroy {
  /**
   * Input property representing the patient ID associated with the message threads.
   */
  @Input() public patientId = '';
  /**
   * Array holding the list of chat thread metadata.
   */
  public threadList: ChatThreadMetaData[] = [];
  /**
   * Subscription for new message received or edited events.
   * @private
   */
  private newMessageSub$!: Subscription;

  /**
   * Subscription for thread removal events.
   * @private
   */
  private removedThreadSub$!: Subscription;
  /**
   * Subscription for new thread creation events.
   * @private
   */
  private newThreadSub$!: Subscription;
  /**
   * Subscription for closed message dialog events.
   * @private
   */
  private closedMessageDialogSub$!: Subscription;
  /**
   * Filter object used to filter chat threads.
   * @private
   */
  private chatThreadFilter: ChatFilter = {
    subject: '',
    message: '',
    unread: false,
    startDate: null,
    endDate: null,
    user: '',
    to: null,
    from: null,
    page: 1,
    unhandled: false,
  };

  /**
   * Constructor to initialize the component.
   * @param acsService Instance of ACSService for Azure Communication Services integration.
   * @param change Reference to the ChangeDetectorRef for change detection.
   * @param dialog Reference to the MatDialog for opening dialogs.
   * @param messageService Instance of MessageService for message-related operations.
   */
  constructor(
    private acsService: ACSService,
    private change: ChangeDetectorRef,
    private dialog: MatDialog,
    private messageService: MessageService,
  ) {}

  /**
   * Initializes chat thread filter, initializes chat service, refreshes thread metadata, and subscribes to events.
   */
  public async ngOnInit(): Promise<void> {
    this.chatThreadFilter.user = this.patientId;
    await this.acsService.initChat();
    await this.refreshThreadsMetadata();
    this.eventSubscriptions();
  }
  /**
   * Lifecycle hook called before component destruction.
   * Unsubscribes from all subscriptions to prevent memory leaks.
   */
  public ngOnDestroy(): void {
    this.newMessageSub$?.unsubscribe();
    this.removedThreadSub$?.unsubscribe();
    this.newThreadSub$?.unsubscribe();
    this.closedMessageDialogSub$?.unsubscribe();
  }
  /**
   * Opens a message thread in a dialog.
   * @param thread The chat thread metadata representing the thread to open.
   */
  public openThread(thread: ChatThreadMetaData): void {
    this.dialog.open(MessageComponent, {
      panelClass: ['full-screen-dialog', 'message-dialog'],
      autoFocus: false,
      disableClose: true,
      data: {
        patientId: this.patientId,
        thread,
      },
    });
  }
  /**
   * Fetches and refreshes chat thread metadata.
   * @private
   */
  private async refreshThreadsMetadata(): Promise<void> {
    this.threadList = (await this.messageService.getMyMessageThreads(this.chatThreadFilter)).data;
    this.change.markForCheck();
  }
  /**
   * Subscribes to various events related to chat threads.
   * @private
   */
  private eventSubscriptions(): void {
    this.newMessageSub$ = this.acsService.refreshData$.subscribe(
      async (res: ChatMessageReceivedEvent | ChatMessageEditedEvent): Promise<void> => {
        if ('editedOn' in res) return;
        this.refreshMetadataAfterDelay(10000);
      },
    );

    this.removedThreadSub$ = this.acsService.removedThread$.subscribe((res: ParticipantsRemovedEvent): void => {
      res.participantsRemoved.forEach((participant: ChatParticipant): void => {
        if (
          (participant.id as CommunicationUserIdentifier).communicationUserId ===
          this.messageService.communicationUserId
        ) {
          const index: number = this.threadList.findIndex(
            (thread: ChatThreadMetaData): boolean => thread.threadId === res.threadId,
          );
          if (index > -1) {
            this.threadList.splice(index, 1);
            this.change.markForCheck();
          }
        }
      });
    });

    this.newThreadSub$ = this.acsService.newThread$.subscribe((res: ChatThreadCreatedEvent): void => {
      if (res) {
        this.refreshMetadataAfterDelay(10000);
      }
    });

    this.closedMessageDialogSub$ = this.messageService.closedMessageDialog$.subscribe((res: boolean): void => {
      if (res) {
        this.refreshMetadataAfterDelay(10000);
      }
    });
  }

  /**
   * Refreshes chat thread metadata after a specified delay.
   * @param delay Delay in milliseconds before refreshing metadata.
   * @private
   */
  private refreshMetadataAfterDelay(delay: number): void {
    clearTimeout(this.acsService.threadListRefreshTimeout);
    this.acsService.threadListRefreshTimeout = setTimeout(() => {
      this.refreshThreadsMetadata();
    }, delay);
  }
}
