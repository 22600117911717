<div class="dialog-sm">
  <div class="hs-tab-card-title flex-between">
    <h2>{{ 'notes' | translate }}</h2>
    <span class="dialog-header-icons">
      <i-feather name="check" class="pointer" (click)="saveNotes()" *appPermissions="'self.Notes.Write'"></i-feather>
      <i-feather name="x" mat-dialog-close></i-feather>
    </span>
  </div>
  <app-notes [notesCommonRequest]="data"></app-notes>
</div>
