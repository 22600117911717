import { NgClass, NgTemplateOutlet } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { FeatherModule } from 'angular-feather';
import { TranslatePipe } from 'src/app/modules/shared/pipes/translate.pipe';
import { Attachment, AttachmentPreviewData, UsersFeedback } from '../../entities/message.entities';
import { MessageService } from '../../services/message.service';
import { MessageAttachmentFavouriteComponent } from '../message-attachment-favourite/message-attachment-favourite.component';

/**
 * Component for previewing message attachments.
 */
@Component({
  selector: 'app-message-attachment-preview',
  templateUrl: './message-attachment-preview.component.html',
  styleUrls: ['./message-attachment-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FeatherModule, TranslatePipe, MatDialogModule, NgClass, NgTemplateOutlet],
})
export class MessageAttachmentPreviewComponent implements AfterViewInit {
  /** List of attachments to preview. */
  public attachments: Attachment[] = [];
  /** The currently selected attachment for preview. */
  public selectedAttachment!: Attachment;
  /** URL of the selected attachment. */
  public selectedAttachmentUrl!: SafeResourceUrl;
  /** Index of the selected attachment in the attachments array. */
  public selectedAttachmentIndex!: number;
  /** Flag to control whether to show the selected attachment preview. */
  public showSelectedAttachment!: boolean;
  /** Flag indicating if the current attachment is marked as favorite. */
  public isFavourite = false;

  /**
   * Constructor of MessageAttachmentPreviewComponent.
   * @param change - ChangeDetectorRef for triggering change detection.
   * @param dialog - MatDialog for opening dialogs.
   * @param messageService - MessageService for retrieving attachment data.
   * @param sanitize - DomSanitizer for sanitizing attachment URLs.
   * @param attachmentData - Data passed into the component from the parent dialog.
   */
  public constructor(
    private change: ChangeDetectorRef,
    private dialog: MatDialog,
    private messageService: MessageService,
    private sanitize: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) private attachmentData: AttachmentPreviewData,
  ) {
    this.attachments = this.attachmentData.attachmentList;
  }
  /**
   * This method previews the selected attachment by finding its index in `attachments` array
   * based on the URL of `attachmentData.selectedAttachment`. It then asynchronously previews
   * the attachment using `previewAttachment()`.
   */
  public async ngAfterViewInit(): Promise<void> {
    const index: number = this.attachments.findIndex(
      (attachment: Attachment): boolean => attachment.url === this.attachmentData.selectedAttachment.url,
    );
    await this.previewAttachment(this.attachmentData.selectedAttachment, index);
  }

  /**
   * Preview the selected attachment.
   * @param attachment - The attachment to preview.
   * @param index - Index of the attachment in the attachments array.
   */
  public async previewAttachment(attachment: Attachment, index: number): Promise<void> {
    this.showSelectedAttachment = false;

    this.selectedAttachment = attachment;
    this.selectedAttachmentIndex = index;
    this.selectedAttachmentUrl = this.sanitize.bypassSecurityTrustResourceUrl(
      (await this.messageService.getTemporaryFileUrl(this.selectedAttachment.url, this.attachmentData.threadId)).url,
    );
    this.showSelectedAttachment = true;
    this.isFavourite = this.checkForFavourite(this.selectedAttachment.usersFeedback || []);
    this.change.markForCheck();
  }
  /**
   * Navigate to the previous attachment in the attachments array.
   */
  public previousAttachment(): void {
    if (this.attachments.length === 1) return;
    const index: number =
      this.selectedAttachmentIndex === 0 ? this.attachments.length - 1 : this.selectedAttachmentIndex - 1;
    this.previewAttachment(this.attachments[index], index);
  }
  /**
   * Navigate to the next attachment in the attachments array.
   */
  public nextAttachment(): void {
    if (this.attachments.length === 1) return;
    const index: number =
      this.selectedAttachmentIndex === this.attachments.length - 1 ? 0 : this.selectedAttachmentIndex + 1;
    this.previewAttachment(this.attachments[index], index);
  }
  /**
   * Check if preview is available based on the MIME type of the attachment.
   *
   * @param mimeType - The MIME type of the attachment.
   * @returns True if preview is available, otherwise false.
   */
  public isPreviewAvailable(mimeType: string): boolean {
    if (mimeType === 'image/heif' || mimeType === 'video/quicktime') return false;
    if (mimeType === 'text/plain' || mimeType === 'application/pdf') return true;
    const type: string = mimeType.split('/')[0];
    if (type === 'image' || type === 'audio' || type === 'video') return true;
    return false;
  }
  /**
   * Add or remove the current attachment from favorites.
   *
   * @param attachment - The attachment to add or remove from favorites.
   */
  public async addToFavourites(attachment: Attachment): Promise<void> {
    if (this.isFavourite) {
      try {
        await this.messageService.markAsFavourite(
          this.attachmentData.threadId,
          this.attachmentData.messageId,
          false,
          '',
          attachment.url,
        );
        this.isFavourite = false;
      } catch {}
      this.change.markForCheck();
      return;
    }

    const dialogRef: MatDialogRef<MessageAttachmentFavouriteComponent> = this.dialog.open(
      MessageAttachmentFavouriteComponent,
      {
        data: {
          threadId: this.attachmentData.threadId,
          messageId: this.attachmentData.messageId,
          url: attachment.url,
        },
      },
    );
    dialogRef.afterClosed().subscribe((res: boolean): void => {
      if (res) {
        this.isFavourite = true;
        this.change.markForCheck();
      }
    });
  }
  /**
   * Check if the current user has marked the attachment as favorite.
   *
   * @param usersFeedback - Array of user feedback for the attachment.
   * @returns True if marked as favorite, otherwise false.
   */
  private checkForFavourite(usersFeedback: UsersFeedback[]): boolean {
    const index: number = usersFeedback.findIndex(
      (feedback: UsersFeedback): boolean => feedback.communicationUserId === this.messageService.communicationUserId,
    );
    if (index < 0) return false;
    return usersFeedback[index].favorite;
  }
}
