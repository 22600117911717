import { Component, OnDestroy } from '@angular/core';
import { PermissionsService } from 'src/app/modules/permissions/services/permissions.service';
import { CommunicationService } from '../../communication.service';

/**
 * Component for managing calendar settings.
 */
@Component({
  selector: 'app-calendar-settings',
  templateUrl: './calendar-settings.component.html',
  styleUrls: ['./calendar-settings.component.scss'],
})
export class CalendarSettingsComponent implements OnDestroy {
  /**
   * Toggle states for different calendar settings.
   */
  toggleView = {
    /**
     * Toggle state for Out of Office settings.
     */
    outOfOffice: false,
    /**
     * Toggle state for Out of Office Business Hours settings.
     */
    OOOBusinessHours: false,
  };

  /**
   * Constructor to inject dependencies.
   * @param communicationService Service for managing communication-related functionality.
   */
  constructor(
    private communicationService: CommunicationService,
    private permissionsService: PermissionsService,
  ) {}

  /**
   * Lifecycle hook that is called when the component is being destroyed.
   * Resets calendar changes in the communication service.
   */
  ngOnDestroy(): void {
    this.communicationService.resetCalendarChanges();
  }

  /**
   * Checks if the user has permission to read out of business hours information.
   * @returns {boolean} Returns true if the user has permission, otherwise false.
   */
  renderOOOBusinessHours() {
    if (this.permissionsService.hasPermission('self.OutOfBusiness.Read')) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Checks if the user has permission to read out of office information.
   * @returns {boolean} Returns true if the user has permission, otherwise false.
   */
  renderOutOfOffice() {
    if (this.permissionsService.hasPermission('self.OutOfOffice.Read')) {
      return true;
    } else {
      return false;
    }
  }
}
