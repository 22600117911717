<form [formGroup]="groupForm">
  <div class="page-header group-view-header">
    <div class="editable-inline">
      <div class="editable-heading">
        <h1 *ngIf="!isShow">{{ groupForm.value.gName }}</h1>
        <div [ngClass]="isActive ? 'width-none' : members && !members.isClientDefaultUserGroup ? 'width' : ''">
          <input *ngIf="isShow" type="text" class="editable" formControlName="gName" placeholder="Group Name " />
        </div>
      </div>
      <div *appPermissions="'self.Management_UserGroup.Update'" (click)="isActive = !isActive">
        <div *ngIf="members && members.groupType !== 'default'">
          <i-feather
            name="edit-2"
            class="feather-20 pointer"
            (click)="toggleDisplay()"
            [ngClass]="{ disabled: members && members.isClientDefaultUserGroup }"
            *ngIf="!isShow"
          >
          </i-feather>
        </div>
        <div class="editable-icons" *ngIf="isShow" (click)="toggleDisplay()">
          <i-feather name="check" (click)="updateGroup()" class="feather-20 pointer"> </i-feather>
          <i-feather name="x" class="feather-20 pointer"> </i-feather>
        </div>
      </div>
    </div>
    <nav>
      <ol class="breadcrumb pt-0">
        <li class="breadcrumb-item">
          <a [routerLink]="['/groupManagment/list']" [queryParams]="{ clientId: clientID }">User Group Management </a>
        </li>
        <li class="breadcrumb-item active">{{ groupForm.value.gName }}</li>
      </ol>
    </nav>
    <div class="editable-inline mt-2 mb-1" [ngClass]="{ 'owner-space': isOwner }">
      <ng-container *ngIf="!isOwner">
        <h2 class="ff-regular">{{ 'owner' | translate }}: {{ Owner || '' }}</h2>
        <div *ngIf="members && members.groupType !== 'default'">
          <i-feather
            *appPermissions="'self.Management_UserGroup.Update'"
            name="edit-2"
            class="feather-20 pointer"
            [ngClass]="{ disabled: members && members.isClientDefaultUserGroup }"
            (click)="toggleOwner()"
          >
          </i-feather>
        </div>
      </ng-container>
      <ng-container *ngIf="isOwner">
        <h2 class="ff-regular">{{ 'owner' | translate }}:</h2>
        <div class="form-group">
          <div class="custom-select-arrow">
            <select class="form-control" formControlName="owner">
              <option *ngFor="let manager of managersList" [value]="manager.id">
                {{ manager.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="editable-icons pointer" (click)="toggleOwner()">
          <i-feather name="check" (click)="updateGroup()" class="feather-20"> </i-feather>
          <i-feather name="x" class="feather-20"> </i-feather>
        </div>
      </ng-container>
    </div>
    <div class="editable-inline">
      <div class="editable-heading">
        <p *ngIf="!isVisible">
          {{ groupForm.value.description }}
        </p>
        <div
          class="edit-input"
          [ngClass]="activeClass ? 'width-none' : members && !members.isClientDefaultUserGroup ? 'width' : ''"
        >
          <input
            *ngIf="isVisible"
            type="text"
            class="editable editable-description"
            formControlName="description"
            placeholder="Description"
          />
        </div>
      </div>
      <div *appPermissions="'self.Management_UserGroup.Update'" (click)="activeClass = !activeClass">
        <div *ngIf="members && members.groupType !== 'default'">
          <i-feather
            name="edit-2"
            class="feather-20 pointer"
            [ngClass]="{ disabled: members && members.isClientDefaultUserGroup }"
            (click)="toggle()"
            *ngIf="!isVisible"
          >
          </i-feather>
        </div>
        <div class="editable-icons" *ngIf="isVisible" (click)="toggle()">
          <i-feather name="check" (click)="updateGroup()" class="feather-20 pointer"> </i-feather>
          <i-feather name="x" class="feather-20 pointer"> </i-feather>
        </div>
      </div>
    </div>
  </div>

  <div class="page-content-wrapper">
    <div class="card-base user-group-view-tab">
      <!-- {{selectedIndex}} -->
      <mat-tab-group
        disableRipple="true"
        [(selectedIndex)]="selectedIndex"
        class="site-tab-group"
        animationDuration="0ms"
      >
        <mat-tab label="Co-owners">
          <div class="site-tab-content">
            <div class="group-add-user">
              <button
                class="btn btn-primary btn-flex"
                [routerLink]="['/groupManagment/useradd']"
                [queryParams]="{
                  clientId: clientID,
                  gid: gid,
                  selectedGroup: groupForm.value.gName,
                  userLevel: 'Manager'
                }"
              >
                <i-feather name="plus-circle" class="feather-20 mr-2"></i-feather> {{ 'addUser' | translate }}
              </button>
            </div>
            <app-table
              [columns]="columnsSupport"
              [displayedColumns]="displayColumns(columnsSupport)"
              [paginationOptions]="paginationCoOwnerOptions"
              [data]="supportGroup"
              (delete)="deleteSupport($event)"
              (refetch)="refreshUsers($event, 'co-owner')"
              (sort)="sortCoOwnerGrp($event)"
            ></app-table>
          </div>
        </mat-tab>
        <mat-tab label="Observers (read-only)">
          <div class="site-tab-content">
            <div class="group-add-user" *ngIf="!members?.isClientDefaultUserGroup">
              <button
                class="btn btn-primary btn-flex"
                [routerLink]="['/groupManagment/useradd']"
                [queryParams]="{
                  clientId: clientID,
                  gid: gid,
                  selectedGroup: groupForm.value.gName,
                  userLevel: 'observer'
                }"
              >
                <i-feather name="plus-circle" class="feather-20 mr-2"></i-feather> {{ 'addUser' | translate }}
              </button>
            </div>
            <app-table
              [columns]="memberGroupColumns"
              [displayedColumns]="displayColumns(observersGroupColumns)"
              [paginationOptions]="paginationObserverOptions"
              [data]="observerGroup"
              (delete)="deleteObserver($event)"
              (refetch)="refreshUsers($event, 'observers')"
              (sort)="sortObserverGrp($event)"
            ></app-table>
          </div>
        </mat-tab>
        <mat-tab label="Members">
          <div class="site-tab-content">
            <div class="group-add-user">
              <button
                class="btn btn-primary btn-flex"
                [routerLink]="['/groupManagment/useradd']"
                [queryParams]="{
                  clientId: clientID,
                  gid: gid,
                  selectedGroup: groupForm.value.gName,
                  userLevel: 'Managed'
                }"
              >
                <i-feather name="plus-circle" class="feather-20 mr-2"></i-feather> {{ 'addUser' | translate }}
              </button>
            </div>
            <app-table
              [columns]="memberGroupColumns"
              [displayedColumns]="displayColumns(memberGroupColumns)"
              [paginationOptions]="paginationMembersOptions"
              [data]="memberGroup"
              (delete)="deleteMember($event)"
              (refetch)="refreshUsers($event, 'member')"
              (sort)="sortMemberGrp($event)"
            ></app-table>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</form>
