import { ChangeDetectionStrategy, Component, Inject, OnInit, inject } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Observable, map, startWith } from 'rxjs';
import { Contact } from 'src/app/modules/shared/interfaces/contact.entities';
import { ContactsService } from 'src/app/modules/shared/services/contacts.service';
import { ThreadFilterDialogData } from '../../entities/message.entities';
import { MessageService } from '../../services/message.service';

/**
 * Component for filtering message threads based on various criteria.
 */
@Component({
  selector: 'app-message-filter-dialog',
  templateUrl: './message-filter-dialog.component.html',
  styleUrls: ['./message-filter-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageFilterDialogComponent implements OnInit {
  /** FormGroup for the filter form containing all filter criteria. */
  public filterForm: FormGroup;
  /** Array of contacts available for filtering by sender and receiver. */
  public contacts!: Contact[];
  /** Observable for the filtered list of sender contacts based on user input. */
  public filteredFromContacts!: Observable<Contact[]>;
  /** Observable for the filtered list of receiver contacts based on user input. */
  public filteredToContacts!: Observable<Contact[]>;
  /** Current date used as the default value for date filters. */
  public todayDate: Date = new Date();
  /** Service for managing contacts. */
  #contactsService = inject(ContactsService);
  /** Service for managing Messages */
  #messageService = inject(MessageService);

  /**
   * Constructor of MessageFilterDialogComponent.
   * @param dialog - Reference to the material dialog for this component.
   * @param toast - Service for displaying toast notifications.
   * @param filterData - Data containing initial filter values.
   */
  public constructor(
    private dialog: MatDialogRef<MessageFilterDialogComponent>,
    private toast: ToastrService,
    @Inject(MAT_DIALOG_DATA) public filterData: ThreadFilterDialogData,
  ) {
    this.filterForm = new FormGroup({
      from: new FormControl<null | Contact>(this.filterData.chatThreadFilter.from),
      to: new FormControl<null | Contact>(this.filterData.chatThreadFilter.to),
      subject: new FormControl(this.filterData.chatThreadFilter.subject, Validators.maxLength(500)),
      message: new FormControl(this.filterData.chatThreadFilter.message, Validators.maxLength(500)),
      startDate: new FormControl(this.filterData.chatThreadFilter.startDate),
      endDate: new FormControl(this.filterData.chatThreadFilter.endDate),
      unread: new FormControl(this.filterData.chatThreadFilter.unread),
      unhandled: new FormControl(this.filterData.chatThreadFilter.unhandled),
    });
  }

  /**
   * Get the 'from' AbstractControl from the filterForm.
   * @returns {AbstractControl | null} The 'from' AbstractControl or null if not found.
   */
  private get from(): AbstractControl | null {
    return this.filterForm.get('from');
  }

  /**
   * Get the 'to' AbstractControl from the filterForm.
   * @returns {AbstractControl | null} The 'to' AbstractControl or null if not found.
   */
  private get to(): AbstractControl | null {
    return this.filterForm.get('to');
  }

  /**
   * Get the 'subject' AbstractControl from the filterForm.
   * @returns {AbstractControl | null} The 'subject' AbstractControl or null if not found.
   */
  public get subject(): AbstractControl | null {
    return this.filterForm.get('subject');
  }

  /**
   * Get the 'message' AbstractControl from the filterForm.
   * @returns {AbstractControl | null} The 'message' AbstractControl or null if not found.
   */
  public get message(): AbstractControl | null {
    return this.filterForm.get('message');
  }

  /**
   * Initializes the component.
   * - Loads the list of contacts for sender and receiver filtering.
   * - Sets up observables to filter contacts based on user input.
   */
  public ngOnInit() {
    this.contacts = this.#contactsService.contactList().filter((contact: Contact) => contact.userAcsId);
    this.contacts.push({
      groupOwnerType: '',
      groupType: '',
      userAcsId: this.#messageService.communicationUserId,
      _id: '',
      user: this.#messageService.userDemographicData.userID,
      userGroupRelation: '',
      group: '',
      groupName: '',
      userFullName: this.#messageService.displayName,
      userImageUrl: this.#messageService.userDemographicData.imageUrl,
      myRelationToGroup: '',
      userType: 'Manager',
    });

    if (this.from) {
      this.filteredFromContacts = this.from.valueChanges.pipe(
        startWith(''),
        map((value) => {
          const name = typeof value === 'string' ? value : value?.userFullName;
          return name ? this.filterContacts(name as string) : this.contacts.slice();
        }),
      );
    }
    if (this.to) {
      this.filteredToContacts = this.to.valueChanges.pipe(
        startWith(''),
        map((value) => {
          const name = typeof value === 'string' ? value : value?.userFullName;
          return name ? this.filterContacts(name as string) : this.contacts.slice();
        }),
      );
    }
  }

  /**
   * Handles the click event for the search button.
   * Validates the filter form and closes the dialog if valid, showing an error toast otherwise.
   */
  public clickedSearch(): void {
    if (this.filterForm.invalid) return;
    const startDate: Date | undefined = this.filterForm.get('startDate')?.value;
    const endDate: Date | undefined = this.filterForm.get('endDate')?.value;

    if (endDate) endDate.setHours(23, 59, 59);

    if (startDate && endDate && startDate > endDate) {
      this.toast.error('Start Date should be less than End Date', 'Error');
      return;
    }
    this.dialog.close(this.filterForm.value);
  }

  /**
   * Handles the click event for the reset button.
   * Resets all form fields to their initial values and clears any applied filters.
   */
  public clickedReset(): void {
    this.filterForm.reset({
      subject: '',
      message: '',
      unread: false,
      startDate: null,
      endDate: null,
      to: null,
      from: null,
      unhandled: false,
    });
  }

  /**
   * Display function for autocomplete input fields.
   * @param contact - Contact object to display in the autocomplete field.
   * @returns The display value for the autocomplete input.
   */
  public autocompleteDisplayFn(contact: Contact): string {
    return contact && contact.userFullName ? contact.userFullName : '';
  }

  /**
   * Filters the list of contacts based on the provided name value.
   * @param name - Name value to filter contacts by.
   * @returns Filtered array of contacts whose names match the provided name value.
   */
  private filterContacts(name: string): Contact[] {
    const filterValue = this.normalizeValue(name);
    return this.contacts.filter((contact: Contact) => this.normalizeValue(contact.userFullName).includes(filterValue));
  }

  /**
   * Normalizes the provided value by converting it to lowercase and removing whitespace.
   * @param value - Value to normalize.
   * @returns Normalized value with lowercase letters and no whitespace.
   */
  private normalizeValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  }
}
