import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { PaginationOptions } from '../../interfaces/pagination-options';
/**
 * Component that manages pagination controls and emits events when pagination options change.
 */
@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnChanges {
  /** Input pagination options for configuring the pagination behavior. */
  @Input() paginationOptions!: PaginationOptions;
  /** Output event emitter that emits updated pagination options when pagination changes. */
  @Output() pagination: EventEmitter<PaginationOptions> = new EventEmitter<PaginationOptions>();
  /** ViewChild reference to MatPaginator component for interacting with pagination controls. */
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  /** Current page index of the paginator. */
  pageIndex = 0;
  /** Available page size options for the paginator. */
  pageSizeOptions: number[] = [5, 10, 15, 20];
  /** Event emitted when the page changes. */
  pageEvent!: PageEvent;

  /**
   * Represents a simple constructor.
   */
  constructor() {}

  /**
   * Lifecycle hook that is called when any input property of the component changes.
   * It updates the `pageIndex` based on the `paginationOptions.currentPage`.
   */
  ngOnChanges(): void {
    this.pageIndex = this.paginationOptions?.currentPage - 1 || 0;
  }
  /**
   * Handles the page change event from the paginator.
   * Updates `paginationOptions` based on the event and emits the updated options.
   * @param e The PageEvent containing information about the page change.
   */
  handlePage(e: PageEvent): void {
    if (e.previousPageIndex && e.previousPageIndex > e.pageIndex) {
      this.paginationOptions.currentPage = e.pageIndex <= 0 ? 1 : e.pageIndex + 1;
    } else {
      this.paginationOptions.currentPage = e.pageIndex + 1;
    }
    this.pageIndex = this.paginationOptions.currentPage;
    this.paginationOptions.limit = e.pageSize;
    this.pagination.emit(this.paginationOptions);
  }
}
