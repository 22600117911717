import { Component } from '@angular/core';
/**
 * Component representing more events.
 */
@Component({
  selector: 'app-more-event',
  templateUrl: './more-event.component.html',
  styleUrls: ['./more-event.component.scss'],
})
export class MoreEventComponent {
  /**
   * Constructor of the MoreEventComponent.
   */
  constructor() {}
}
