import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthServiceService } from 'src/app/modules/authentication/services/auth-service.service';
/**
 * Injectable decorator marks the class as available to be injected
 * as a dependency, typically into Angular services or other components.
 * This decorator should be applied to services that are provided in
 * the root injector.
 *
 * @decorator
 */
@Injectable({
  providedIn: 'root',
})
export class LoggedInGuard {
  /**
   * Constructs the LoggedInGuard service with required dependencies.
   *
   * @param _authService The authentication service to check user authorization status.
   * @param router Router service for navigation.
   * @param location Location service for interacting with the browser's URL.
   */
  constructor(
    private _authService: AuthServiceService,
    private router: Router,
    private location: Location,
  ) {}
  /**
   * Checks if the user is logged in and has access to the dashboard.
   * If logged in and has dashboard access, navigates to '/dashboard' and returns false to prevent activation.
   * Otherwise, allows activation.
   *
   * @returns Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
   * Returns false to prevent activation and navigates to '/dashboard' if user is logged in and has dashboard access.
   * Returns true to allow activation if user is not logged in or does not have dashboard access.
   */
  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this._authService.isAuthorized() && this._authService.getAllowDashboardAccess()) {
      this.router.navigate(['/dashboard']);
      return false;
    }
    return true;
  }
}
