<div class="meeting">
  <div class="d-flex justify-content-between mb-2">
    <h3>{{ 'meetingInfo' | translate }}</h3>
    <i-feather name="x" class="feather-20 text-secondary pointer" (click)="closeSidePanel.emit(true)"></i-feather>
  </div>

  <h3 class="mb-3">Meeting with Maurice Cruz</h3>

  <div class="d-flex justify-content-between align-items-center mb-2">
    <h3 class="mb-0">{{ 'shareInvite' | translate }}</h3>
    <button class="btn btn-primary">{{ 'copyMeetingLink' | translate }}</button>

    <!-- <span class="text-secondary">
                              <i-feather name="check" class="feather-20"></i-feather>
                              Copied to clipboard
                          </span> -->
  </div>
  <p class="text-secondary">
    {{ 'joinOnYourComputerMobileAppOrRoomDevice' | translate }}<br /><a href="#">
      {{ 'clickHereToJoinTheMeeting' | translate }}</a
    >
  </p>
  <p class="text-secondary mb-1">{{ 'meetingID' | translate }}: <span>597 199 149</span></p>
  <p class="text-secondary">{{ 'passcode' | translate }}: <span>deGEvW</span></p>
</div>
