import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { WidgetData } from 'src/app/modules/shared/interfaces/common.entities';
import { HttpService } from 'src/app/modules/shared/services/http.service';
import { CreateMessageThreadComponent } from '../create-message-thread/create-message-thread.component';
import { MessageComponent } from '../message/message.component';
/**
 * Message Widget Component
 * Displays a widget for showing messages.
 */
@Component({
  selector: 'app-message-widget',
  templateUrl: './message-widget.component.html',
  styleUrls: ['./message-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageWidgetComponent {
  /**
   * Dashboard widget data
   */
  @Input() public widget!: WidgetData;
  /**
   * Patient Id
   */
  @Input() public patientId = '';

  /**
   * Constructor to inject dependencies.
   * @param dialog Angular Material MatDialog service for opening dialogs.
   * @param http HttpService for HTTP requests.
   */
  public constructor(
    private dialog: MatDialog,
    private http: HttpService,
  ) {}

  /**
   * Opens a full-screen dialog to display a message for the patient.
   */
  public openMessage(): void {
    this.dialog.open(MessageComponent, {
      panelClass: ['full-screen-dialog', 'message-dialog'],
      autoFocus: false,
      disableClose: true,
      data: {
        patientId: this.patientId,
      },
    });
  }

  /**
   * Opens a full-screen dialog to create a new message thread.
   * Subscribes to the dialog close event to handle actions after dialog closure.
   */
  public createNewThread(): void {
    const dialogRef: MatDialogRef<CreateMessageThreadComponent> = this.dialog.open(CreateMessageThreadComponent, {
      autoFocus: false,
      disableClose: true,
      data: {
        headerTitle: 'newMessage',
        initACS: true,
      },
      panelClass: ['full-screen-dialog', 'create-message-dialog'],
    });
    dialogRef.afterClosed().subscribe((): void => {
      this.http.loader.next(false);
    });
  }
}
