import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { timeout } from 'rxjs/operators';
import { AuthServiceService } from 'src/app/modules/authentication/services/auth-service.service';
import { environment } from 'src/environments/environment';
/**
 * Interceptor to modify outgoing HTTP requests by adding headers such as Content-Type, Authorization,
 * application name, language, timezone information, and OS details.
 */
@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  /**
   * Constructor to inject dependencies needed by the interceptor.
   * @param authService The authentication service used to retrieve tokens and authorization status.
   */
  constructor(private authService: AuthServiceService) {}
  /**
   * Intercept method to handle HTTP requests.
   * Modifies the request by adding necessary headers before passing it to the next interceptor or backend.
   * @param request The HTTP request object to be modified.
   * @param next The HTTP handler to pass the modified request to the next interceptor or backend.
   * @returns An observable of HTTP events.
   */
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!request.headers.has('Content-Type')) {
      if (!(request.body instanceof FormData && request.body.get('file'))) {
        request = request.clone({
          setHeaders: { 'Content-Type': 'application/json' },
        });
      }
    }
    if (this.authService.isAuthorized()) {
      request = request.clone({
        headers: request.headers.set('Authorization', 'Bearer ' + this.authService.getToken()),
      });
    }

    if (this.authService.forVerification()) {
      request = request.clone({
        headers: request.headers.set('Authorization', 'Bearer ' + this.authService.getVerificationToken()),
      });
    }
    request = request.clone({
      headers: request.headers.set('app_name', environment.appConfig.name),
    });
    request = request.clone({
      headers: request.headers.set('accept-language', localStorage.getItem('NG_TRANSLATE_LANG_KEY') || 'en'),
    });
    request = request.clone({
      headers: request.headers.set('timezoneoffset', new Date().getTimezoneOffset().toString()),
    });
    request = request.clone({
      headers: request.headers.set('timezonename', Intl.DateTimeFormat().resolvedOptions().timeZone || ''),
    });
    request = request.clone({
      headers: request.headers.set('os', 'web'),
    });

    return next.handle(request).pipe(timeout(300000));
  }
}
