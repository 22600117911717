import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FeatherModule } from 'angular-feather';
import { DotLoaderComponent } from '../shared/components/dot-loader/dot-loader.component';
import { OpenAIService } from './openai.service';

/**
 * Interface representing a message in the chat.
 */
interface Message {
  /** The text of the message. */
  text: string;
  /** Indicates if the message is from the user. */
  isUser: boolean;
}

/**
 * Component for the Chat AI Assistant.
 * This component handles user interactions with the AI, sending messages and displaying responses.
 */
@Component({
  selector: 'app-chat',
  standalone: true,
  imports: [CommonModule, FormsModule, FeatherModule, DotLoaderComponent],
  templateUrl: './chat-ai-assistant.component.html',
  styleUrls: ['./chat-ai-assistant.component.scss'],
})
export class ChatAiAssistantComponent {
  /** Array to hold chat messages. */
  messages: Message[] = [];
  /** The new message input from the user. */
  newMessage: string = '';
  /** Flag indicating if the assistant is typing a response. */
  isAssistantTyping: boolean = false;

  /**
   * Constructor for the ChatAiAssistantComponent.
   * @param openAIService - Service for interacting with OpenAI API.
   * @param dialog - MatDialog for opening dialogs.
   * @param dialogRef - Reference to the dialog instance.
   */
  constructor(
    private openAIService: OpenAIService,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<ChatAiAssistantComponent>,
  ) {}

  /**
   * Sends a message to the AI assistant.
   * If the message is not empty, it updates the messages array and sends the prompt to OpenAI.
   */
  sendMessage() {
    if (this.newMessage.trim() !== '') {
      // Push the user's message
      this.messages.push({ text: this.newMessage, isUser: true });
      const userPrompt = this.newMessage;
      this.newMessage = '';

      // Show typing indicator
      this.isAssistantTyping = true;

      // Send the prompt to OpenAI API
      this.openAIService.sendPrompt(userPrompt).subscribe({
        next: (data) => {
          this.handleStreamingResponse(data); // Handle streaming response
        },
        error: (err) => {
          console.error('Error:', err);
          this.isAssistantTyping = false; // Stop typing indicator on error
        },
      });
    }
  }

  /**
   * Closes the chat dialog.
   */
  closeDialog() {
    this.dialogRef.close();
  }

  /**
   * Handles the streaming response from the OpenAI API.
   * Simulates receiving the response chunk-by-chunk and updates the messages.
   * @param data - The response data from the OpenAI API.
   */
  // Handle streaming response (simulate chunk-by-chunk reception)
  handleStreamingResponse(data: any) {
    let fullResponse = '';
    const streamInterval = 20; // Simulate streaming chunk every 50ms
    let currentIndex = 0;

    const streamIntervalId = setInterval(() => {
      if (currentIndex < data.choices[0].message.content.length) {
        fullResponse += data.choices[0].message.content[currentIndex];
        currentIndex++;

        // Update the last assistant message incrementally
        if (this.messages[this.messages.length - 1]?.isUser === false) {
          this.messages[this.messages.length - 1].text = fullResponse;
        } else {
          this.messages.push({ text: fullResponse, isUser: false });
        }
      } else {
        // Stop streaming when the full message is received
        clearInterval(streamIntervalId);
        this.isAssistantTyping = false;
      }
    }, streamInterval);
  }
}
