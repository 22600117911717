<div class="dialog">
  <div class="hs-tab-card-title flex-between">
    <h2>{{ 'custom' | translate }}</h2>

    <div class="dialog-header-icons">
      <i-feather
        name="check"
        class="pointer"
        [class]="{ disabled: !validate() }"
        (click)="setCustomRRuleSettings()"
      ></i-feather>
      <i-feather name="x" class="pointer" mat-dialog-close></i-feather>
    </div>
  </div>

  <mat-dialog-content class="mat-dialog-content">
    <form [formGroup]="rRuleSettingFormGroup">
      <!--  daily block start-->
      <div class="form-group">
        <div class="custom-select-arrow">
          <mat-select
            name="freq"
            class="form-control"
            (selectionChange)="onChangeFrequencyOptions()"
            [formControl]="rRuleSettingFormGroup.controls.freq"
          >
            <mat-option class="option-style" *ngFor="let fOption of availableFreqOptions" [value]="fOption.value">
              {{ fOption.key | translate }}
            </mat-option>
          </mat-select>
        </div>
      </div>
      <div class="form-group date-block">
        <span>{{ 'every' | translate }}</span>
        <input
          type="number"
          class="form-control num-field"
          [formControl]="rRuleSettingFormGroup.controls.interval"
          [min]="1"
          value="1"
        />
        <span *ngIf="rRuleSettingFormGroup.value?.freq?.toLowerCase() === 'daily'">{{
          'recurrenceDays' | translate
        }}</span>
        <span *ngIf="rRuleSettingFormGroup.value?.freq?.toLowerCase() === 'weekly'">{{
          'recurrenceWeeks' | translate
        }}</span>
        <span *ngIf="rRuleSettingFormGroup.value?.freq?.toLowerCase() === 'monthly'">{{
          'recurrenceMonths' | translate
        }}</span>
        <span *ngIf="rRuleSettingFormGroup.value?.freq?.toLowerCase() === 'yearly'">{{
          'recurrenceYears' | translate
        }}</span>
      </div>
      <!--  daily block  end-->
      <!--  weekly block start-->
      <div class="form-group" *ngIf="rRuleSettingFormGroup.value?.freq?.toLowerCase() === 'weekly'">
        <div class="week-days">
          <h3
            class="day pointer"
            [class]="{ active: isActiveByWeekDay(byWeekOption.pValue) }"
            *ngFor="let byWeekOption of availableWeekOptions"
            (click)="setByWeekDay(byWeekOption)"
          >
            {{ byWeekOption.xsLabelKey | translate }}
          </h3>
        </div>
      </div>
      <!--  weekly block  end-->
      <div class="form-group">
        <div class="radio-inline" *ngIf="rRuleSettingFormGroup.value?.freq?.toLowerCase() === 'monthly'">
          <input
            type="radio"
            name="monthlyYearlyRadioOption"
            id="onEachRadio"
            value="eachOption"
            class="form-check-input me-0"
            [formControl]="rRuleSettingFormGroup.controls.eachOption"
            (change)="onChangeEachOption()"
          />
          <label class="radio-label" for="onEachRadio">{{ 'onEach' | translate }}</label>
        </div>
        <!--  monthly month view block start-->
        <div class="calendar-view" *ngIf="rRuleSettingFormGroup.value?.freq?.toLowerCase() === 'monthly'">
          <div class="week-view">
            <span
              class="date pointer"
              [class]="{ active: isActiveByMonthDay(byMonthDayOption.value) }"
              *ngFor="let byMonthDayOption of availableMonthDays"
              (click)="setByMonthDay(byMonthDayOption)"
              >{{ byMonthDayOption.label }}</span
            >
          </div>
        </div>
        <!--  monthly month view block start-->
        <!--  yearly month view block start-->
        <div class="month-view" *ngIf="rRuleSettingFormGroup.value?.freq?.toLowerCase() === 'yearly'">
          <div class="month-row">
            <h3
              class="month-tab pointer"
              [class]="{ active: isActiveByMonth(byMonthOption.value) }"
              *ngFor="let byMonthOption of availableMonthOptions"
              (click)="setByMonth(byMonthOption)"
            >
              {{ byMonthOption.shortLabelKey | translate }}
            </h3>
          </div>
        </div>
        <!--  yearly month view block end-->
        <div
          class="form-group"
          *ngIf="
            rRuleSettingFormGroup.value?.freq?.toLowerCase() === 'monthly' ||
            rRuleSettingFormGroup.value?.freq?.toLowerCase() === 'yearly'
          "
        >
          <div class="radio-inline" *ngIf="rRuleSettingFormGroup.value?.freq?.toLowerCase() === 'monthly'">
            <input
              type="radio"
              name="monthlyYearlyRadioOption"
              id="onTheOption"
              value="onTheOption"
              class="form-check-input me-0"
              [formControl]="rRuleSettingFormGroup.controls.eachOption"
              (change)="onChangeOnTheOption()"
            />
            <label class="radio-label" for="onTheOption">{{ 'onThe' | translate }}</label>
          </div>
          <div class="radio-inline" *ngIf="rRuleSettingFormGroup.value?.freq?.toLowerCase() === 'yearly'">
            <input
              type="checkbox"
              name="onTheYearOption"
              id="onTheYearOption"
              class="form-check-input me-0"
              [formControl]="rRuleSettingFormGroup.controls.onTheOption"
              (change)="onChangeOnTheOption()"
            />
            <label class="radio-label" for="onTheYearOption">{{ 'onThe' | translate }}</label>
          </div>
          <div class="form-group">
            <div class="date-block">
              <div class="custom-select-arrow">
                <mat-select
                  name="freq"
                  class="form-control"
                  placeholder="{{ 'select' | translate }}"
                  [formControl]="rRuleSettingFormGroup.controls.bysetpos"
                >
                  <mat-option
                    class="option-style"
                    *ngFor="let setPosOption of availableBySetPosOptions"
                    [value]="setPosOption.value"
                    [class]="{ separator: setPosOption.key === 'last' }"
                  >
                    {{ setPosOption.key | translate }}
                  </mat-option>
                </mat-select>
              </div>
            </div>
            <div class="date-block">
              <div class="custom-select-arrow">
                <mat-select
                  name="freq"
                  class="form-control"
                  placeholder="{{ 'select' | translate }}"
                  (selectionChange)="onChangeOnTheWeekOptions()"
                  [formControl]="rRuleSettingFormGroup.controls.onTheWeekOption"
                >
                  <mat-option class="option-style" *ngFor="let weekOption of availableWeekOptions" [value]="weekOption">
                    {{ weekOption.key | translate }}
                  </mat-option>
                  <mat-option class="separator option-style" [value]="'allDay'">{{ 'Day' | translate }}</mat-option>
                  <mat-option class="option-style" [value]="'weekday'">{{ 'weekDay' | translate }}</mat-option>
                  <mat-option class="option-style" [value]="'weekend'">{{ 'weekEndDay' | translate }}</mat-option>
                </mat-select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </mat-dialog-content>
</div>
