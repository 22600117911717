<div class="form-group mb-0 custom-select truncation">
  <div class="custom-select-arrow">
    <select class="form-control" (change)="onSelected($event, 'selection')">
      <!-- <option value="#" selected disabled>–Select–</option> -->
      <option *ngFor="let item of res" [value]="item.subscribedTo.name">{{ item.subscribedTo.name }}</option>
    </select>
  </div>
</div>

<h2 class="my-4">{{ 'inactivityAlerts' | translate }}</h2>

<div class="table-wrapper nudges-table">
  <div class="table-responsive mb-0">
    <table class="mat-mdc-table">
      <tr class="mat-mdc-header-row">
        <th class="mat-mdc-header-cell">{{ 'notifications' | translate }}</th>
        <th class="mat-mdc-header-cell toggle-cell-width" *ngIf="checkedChannels('sms')">{{ 'sms' | translate }}</th>
        <th class="mat-mdc-header-cell toggle-cell-width" *ngIf="checkedChannels('email')">
          {{ 'email' | translate }}
        </th>
        <th class="mat-mdc-header-cell toggle-cell-width" *ngIf="checkedChannels('inApp')">
          {{ 'inAppNotification' | translate }}
        </th>
        <th class="mat-mdc-header-cell toggle-cell-width" *ngIf="checkedChannels('push')">
          {{ 'pushNotification' | translate }}
        </th>
      </tr>

      <ng-container *ngFor="let item of inactivityAlertData; index as i">
        <tr class="mat-mdc-row" [ngClass]="!item.enabled ? 'row-disabled' : ''">
          <td class="mat-mdc-cell">
            <div class="cell-has-child">
              <div class="setting-notification-1">
                <span class="update-toggle" *ngIf="item?.communicationChannels?.smsChannel">
                  <mat-slide-toggle
                    [(ngModel)]="item.enabled"
                    (change)="getUpdatedRow(item, item.key, 'inactivity')"
                  ></mat-slide-toggle>
                </span>

                <span>{{ item.title }} </span>
              </div>
              <!-- <i-feather name="{{appEngagement ? 'chevron-up' : 'chevron-down'}}"
                            class="feather-20 pointer" (click)="applicationEngagement()"></i-feather> -->

              <div class="setting-notification-2">
                <span class="form-group mb-0">
                  <div class="custom-select-arrow">
                    <select
                      class="form-control"
                      [(ngModel)]="item.frequency"
                      (change)="getUpdatedRow(item, item.key, 'inactivity')"
                    >
                      <option *ngFor="let x of dropdownValue" [value]="x.id">
                        {{ x.value }}
                      </option>
                    </select>
                  </div>
                </span>
                &nbsp; Thru &nbsp;
                <span class="form-group time-input time-dropdown d-flex align-items-center my-0">
                  <div class="form-control time-picker-input">
                    <div>
                      {{ toDate(item.time) | date: 'h:mm a' }}
                    </div>
                    <app-timepicker
                      *ngIf="item.time"
                      [timeControl]="dateFormate"
                      (changeTime)="onWeightTimeChanged($event, item)"
                      [defaultTime]="defaultTime"
                      (focusout)="getUpdatedRow(item, item.key, 'inactivity')"
                    ></app-timepicker>
                  </div>
                  <div class="alert-message ms-2" [ngbTooltip]="weightTooltip" placement="top" container="body">
                    <i-feather name="info" class="feather-20 site-edit pointer"></i-feather>
                  </div>
                  <ng-template #weightTooltip>
                    <!-- <div *ngIf="item.frequency ===  'Everyday' ">
                                                Individual will be reminded at

                                                every day to complete their {{item.title}}.
                                            </div> -->
                    <!-- <div *ngIf="item.frequency !==  'Everyday'"> -->
                    <div>
                      <!-- Individual will be reminded if at
                                                after not completing their {{item.title}} for the past
                                                {{item.frequency}} days. -->

                      {{ 'if' | translate }} {{ item.title }} {{ 'byIndividual' | translate }} {{ item.frequency }}
                      {{ 'priorToTodayThroughTodayAt' | translate }} {{ toDate(item.time) | date: 'h:mm a' }},
                      {{ 'youWillBeNotifiedAt' | translate }}
                      {{ toDate(item.time) | date: 'h:mm a' }} {{ 'todayInBetweenParagraph' | translate }}
                    </div>
                  </ng-template>
                </span>
              </div>

              <span>
                <div
                  class="flex-between reminder-gap"
                  *ngIf="item.resendAfter !== undefined && item?.resendAfter !== undefined"
                >
                  <div class="flex-between">
                    <span class="pe-2">{{ 'resendAfter' | translate }}</span>
                    <div class="form-group mb-0">
                      <input
                        type="text"
                        maxlength="4"
                        appOnlyNumber
                        [(ngModel)]="item.resendAfter"
                        class="reminder-input form-control"
                        (change)="getUpdatedRow(item, item.key, 'inactivity')"
                      />
                    </div>
                    <span class="pl-1"> {{ 'day(s)' | translate }} </span>
                  </div>
                  <div class="flex-between">
                    <span class="pe-2">#{{ 'attempts' | translate }}</span>
                    <div class="form-group mb-0">
                      <input
                        type="text"
                        maxlength="4"
                        appOnlyNumber
                        class="reminder-input form-control"
                        (change)="getUpdatedRow(item, item.key, 'inactivity')"
                        [(ngModel)]="item.attempts"
                      />
                    </div>
                  </div>
                </div>
              </span>
            </div>
          </td>
          <ng-container *ngIf="item?.communicationChannels">
            <td class="mat-mdc-cell toggle-cell" *ngIf="checkedChannels('sms')">
              <span *ngIf="item?.communicationChannels?.smsChannel">
                <mat-slide-toggle
                  [(ngModel)]="item.communicationChannels.sms"
                  (change)="getUpdatedRow(item, item.key, 'inactivity')"
                ></mat-slide-toggle>
              </span>
            </td>
            <td class="mat-mdc-cell toggle-cell" *ngIf="checkedChannels('email')">
              <span *ngIf="item.communicationChannels?.emailChannel">
                <mat-slide-toggle
                  [(ngModel)]="item.communicationChannels.email"
                  (change)="getUpdatedRow(item, item.key, 'inactivity')"
                ></mat-slide-toggle>
              </span>
            </td>
            <td class="mat-mdc-cell toggle-cell" *ngIf="checkedChannels('inApp')">
              <span *ngIf="item.communicationChannels?.inAppChannel">
                <mat-slide-toggle
                  [(ngModel)]="item.communicationChannels.inApp"
                  (change)="getUpdatedRow(item, item.key, 'inactivity')"
                ></mat-slide-toggle>
              </span>
            </td>
            <td class="mat-mdc-cell toggle-cell" *ngIf="checkedChannels('push')">
              <span *ngIf="item.communicationChannels?.pushChannel">
                <mat-slide-toggle
                  [(ngModel)]="item.communicationChannels.push"
                  (change)="getUpdatedRow(item, item.key, 'inactivity')"
                ></mat-slide-toggle>
              </span>
            </td>
          </ng-container>
        </tr>
      </ng-container>
    </table>
  </div>
</div>
<h2 class="my-4 pt-4">{{ 'monitoringAlerts' | translate }}</h2>
<div class="table-wrapper nudges-table">
  <div class="table-responsive mb-0">
    <table class="mat-mdc-table">
      <tr class="mat-mdc-header-row">
        <th class="mat-mdc-header-cell">{{ 'notifications' | translate }}</th>
        <th class="mat-mdc-header-cell toggle-cell-width" *ngIf="checkedChannels('sms')">{{ 'sms' | translate }}</th>
        <th class="mat-mdc-header-cell toggle-cell-width" *ngIf="checkedChannels('email')">
          {{ 'email' | translate }}
        </th>
        <th class="mat-mdc-header-cell toggle-cell-width" *ngIf="checkedChannels('inApp')">
          {{ 'inAppNotification' | translate }}
        </th>
        <th class="mat-mdc-header-cell toggle-cell-width" *ngIf="checkedChannels('push')">
          {{ 'pushNotification' | translate }}
        </th>
      </tr>

      <ng-container *ngFor="let item of monitoringAlertData; index as i">
        <tr class="mat-mdc-row">
          <td class="mat-mdc-cell">
            <div class="cell-has-child">
              <strong>{{ item.title }} </strong>
              <!-- <i-feather name="{{appEngagement ? 'chevron-up' : 'chevron-down'}}"
                            class="feather-20 pointer" (click)="applicationEngagement()"></i-feather> -->
            </div>
          </td>
          <ng-container *ngIf="item?.communicationChannels">
            <td class="mat-mdc-cell toggle-cell" *ngIf="checkedChannels('sms')">
              <span *ngIf="item?.communicationChannels?.smsChannel">
                <mat-slide-toggle
                  [(ngModel)]="item.communicationChannels.sms"
                  (change)="getUpdatedRow(item, item.key, 'monitoring')"
                ></mat-slide-toggle>
              </span>
            </td>
            <td class="mat-mdc-cell toggle-cell" *ngIf="checkedChannels('email')">
              <span *ngIf="item.communicationChannels?.emailChannel">
                <mat-slide-toggle
                  [(ngModel)]="item.communicationChannels.email"
                  (change)="getUpdatedRow(item, item.key, 'monitoring')"
                ></mat-slide-toggle>
              </span>
            </td>
            <td class="mat-mdc-cell toggle-cell" *ngIf="checkedChannels('inApp')">
              <span *ngIf="item.communicationChannels?.inAppChannel">
                <mat-slide-toggle
                  [(ngModel)]="item.communicationChannels.inApp"
                  (change)="getUpdatedRow(item, item.key, 'monitoring')"
                ></mat-slide-toggle>
              </span>
            </td>
            <td class="mat-mdc-cell toggle-cell" *ngIf="checkedChannels('push')">
              <span *ngIf="item.communicationChannels?.pushChannel">
                <mat-slide-toggle
                  [(ngModel)]="item.communicationChannels.push"
                  (change)="getUpdatedRow(item, item.key, 'monitoring')"
                ></mat-slide-toggle>
              </span>
            </td>
          </ng-container>
        </tr>
      </ng-container>
    </table>
  </div>
</div>
