<nav>
  <ol class="breadcrumb">
    <li class="breadcrumb-item">
      <a routerLink="/clients/list">{{ 'dashboard' | translate }}</a>
    </li>
    <li class="breadcrumb-item">
      <a routerLink="/userManagment/list">{{ 'user' | translate }}</a>
    </li>
    <li class="breadcrumb-item active">{{ 'access' | translate }}</li>
  </ol>
</nav>

<h1 class="mt-2">{{ 'access' | translate }}</h1>

<div class="row">
  <div class="col-md-6 col-12">
    <div class="col-providers">
      <div class="col-pvdr-row">
        <h2>{{ 'Patients' | translate }}</h2>
        <button
          class="btn btn-primary"
          [routerLink]="['/userManagment/add-access']"
          [queryParams]="{ clientId: clientID, uid: uid }"
        >
          <i-feather name="plus-circle" class="feather-20 mr-2"></i-feather>{{ 'addUser' | translate }}
        </button>
      </div>
      <div class="col-pvdr-row">
        <div class="pvdr-left">
          <div class="p-icon-img">
            <i-feather name="user"></i-feather>
          </div>
          <span>Bob Smith</span>
        </div>
        <i-feather name="trash-2" class="feather-20"></i-feather>
      </div>
      <div class="col-pvdr-row">
        <div class="pvdr-left">
          <div class="p-icon-img">
            <i-feather name="users"></i-feather>
          </div>
          <span>HP Health Group</span>
        </div>
        <i-feather name="trash-2" class="feather-20"></i-feather>
      </div>
    </div>
  </div>

  <div class="col-md-6 col-12">
    <div class="col-providers">
      <div class="col-pvdr-row">
        <h2>{{ 'support' | translate }}</h2>
        <button
          class="btn btn-primary"
          [routerLink]="['/selected-client/details/user/add-access']"
          [queryParams]="{ clientId: clientID, uid: uid }"
        >
          <i-feather name="plus-circle" class="feather-20 mr-2"></i-feather> {{ 'addUser' | translate }}
        </button>
      </div>
      <div class="col-pvdr-row">
        <div class="pvdr-left">
          <div class="p-icon-img">
            <i-feather name="user"></i-feather>
          </div>
          <span>Bob Smith</span>
        </div>
        <i-feather name="trash-2" class="feather-20"></i-feather>
      </div>
      <div class="col-pvdr-row">
        <div class="pvdr-left">
          <div class="p-icon-img">
            <i-feather name="users"></i-feather>
          </div>
          <span>HP Health Group</span>
        </div>
        <i-feather name="trash-2" class="feather-20"></i-feather>
      </div>
    </div>
    <!-- Providers end -->
  </div>
</div>
