import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import {
  ChatWebSocketEvent,
  VideoLobbyWebSocketEvent,
  WebSocketEvent,
} from 'src/app/modules/shared/interfaces/common.entities';
/**
 * Service for WebSocket communication.
 */
@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  /**
   * Subject for alert WebSocket events.
   * @type {Subject<WebSocketEvent>}
   */
  public alertEvent: Subject<WebSocketEvent> = new Subject();
  /**
   * Subject for chat WebSocket events.
   * @type {Subject<ChatWebSocketEvent>}
   */
  public chatEvent: Subject<ChatWebSocketEvent> = new Subject();
  /**
   * Subject for video lobby WebSocket events.
   * @type {Subject<VideoLobbyWebSocketEvent>}
   */
  public videoLobbyEvent: Subject<VideoLobbyWebSocketEvent> = new Subject();
}
