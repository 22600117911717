<div class="vl-dialog-container" [ngClass]="videoLobbyService.pipMode() ? 'pip-card' : 'dialog-xl'">
  <div
    class="hs-tab-card-title flex-between"
    cdkDragBoundary=".cdk-global-overlay-wrapper"
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle
    [cdkDragFreeDragPosition]="videoLobbyService.dialogPosition()"
    [cdkDragDisabled]="!videoLobbyService.pipMode()"
  >
    <h2>
      @if (!videoLobbyService.pipMode()) {
        <span>{{ 'videoLobby' | translate }}</span>
      }
      @if (videoLobbyService.callInProgress$ | async) {
        <span class="record-video">
          {{ videoLobbyService.callDuration() }}
          @if (videoLobbyService.recordingId()) {
            <i-feather name="record" class="active feather-16 ms-2"></i-feather>
          }
          @if (videoLobbyService.recordingId() && !videoLobbyService.pipMode()) {
            <span> {{ 'recording' | translate }}... </span>
          }
        </span>
      }
    </h2>
    <span class="dialog-header-icons">
      @if (videoLobbyService.pipMode()) {
        <i-feather
          [name]="videoLobbyService.microphoneStatus() === 'On' ? 'mic' : 'mic-off'"
          class="pointer"
          [ngClass]="videoLobbyService.microphoneStatus() === 'On' ? 'text-success' : 'clr-secondary'"
          (click)="
            videoLobbyService.microphoneStatus() === 'On'
              ? videoLobbyService.muteAudio()
              : videoLobbyService.unmuteAudio()
          "
        ></i-feather>
      }
      @if (videoLobbyService.activeCall() && !videoLobbyService.pipMode()) {
        <i-feather name="minimize-2" class="pointer" (click)="videoLobbyService.enterPip()"></i-feather>
      }
      @if (videoLobbyService.activeCall() && videoLobbyService.pipMode()) {
        <i-feather name="maximize-2" class="pointer" (click)="videoLobbyService.exitPip()"></i-feather>
      }
      @if (videoLobbyService.pipMode() && videoLobbyService.isScreenSharingInProgress()) {
        <i-feather
          name="unshare-screen-white"
          class="pointer"
          aria-label="Stop Screen Share"
          ngbTooltip="{{ 'stopScreenSharing' | translate }}"
          (click)="videoLobbyService.stopScreenShare()"
        ></i-feather>
      }
      @if (videoLobbyService.pipMode() && !videoLobbyService.isScreenSharingInProgress()) {
        <i-feather
          name="share-screen-white"
          class="pointer"
          aria-label="Start Screen Share"
          ngbTooltip="{{ 'startScreenSharing' | translate }}"
          (click)="videoLobbyService.startScreenShare()"
        ></i-feather>
      }
      @if (videoLobbyService.pipMode()) {
        <i-feather name="phone" class="pointer vdo-call" (click)="stopLocalVideoStream()" mat-dialog-close></i-feather>
      }
      @if (!videoLobbyService.pipMode()) {
        <i-feather
          mat-dialog-close
          name="x"
          class="pointer"
          aria-label="Close"
          (click)="stopLocalVideoStream()"
        ></i-feather>
      }
    </span>
  </div>

  <div class="video-lobby-wrap">
    @if (!videoLobbyService.pipMode()) {
      <app-video-list [ngClass]="[!videoLobbyService.activeCall() ? 'mob-show' : 'mob-hide']"></app-video-list>
    }
    @if (showVideoCallComponent()) {
      <app-video-call
        [ngClass]="{
          'w-100': videoLobbyService.pipMode(),
          'mob-show': videoLobbyService.activeCall(),
          'mob-hide': !videoLobbyService.activeCall()
        }"
      ></app-video-call>
    }
  </div>
</div>
