import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ManagedDialogComponent } from '../managed-group-dialog/managed-group.component';

/**
 * Component for managing the addition of users in a dialog.
 */
@Component({
  selector: 'app-dialog',
  templateUrl: './managed-add.component.html',
  styleUrls: ['./managed-add.component.scss'],
})
export class ManagedAddComponent {
  /** message displayed in the dialog */
  message: string = 'Are you sure?';
  /** Text for the confirm button */
  confirmButtonText = 'Yes';
  /** Text for the cancel button */
  cancelButtonText = 'Cancel';
  /** Search query string */
  search: string = '';
  /** Array of user data */
  userData: any = [];
  /** Array of selected user IDs to add */
  addUserData: Array<string> = [];
  /** Array of group data */
  groupData: any = [];

  /**
   * Represents a component/service constructor.
   * @param dialog - MatDialog service for opening dialogs.
   * @param data - Data passed to the dialog when opened.
   * @param dialogRef - Reference to the dialog instance.
   */
  constructor(
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<ManagedAddComponent>,
  ) {
    if (data) {
      this.userData = data.manageUser;
      this.groupData = data.groupData;
      this.message = data.message || this.message;
      if (data.buttonText) {
        this.confirmButtonText = data.buttonText.ok || this.confirmButtonText;
        this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
      }
    }
  }

  /**
   * Closes the dialog with a confirmation response.
   */
  onConfirmClick(): void {
    this.dialogRef.close(true);
  }

  /**
   * Closes the dialog with the list of added user IDs.
   */
  onDoneClick(): void {
    this.dialogRef.close(this.addUserData);
  }

  /**
   * Updates the list of selected user IDs based on checkbox state.
   * @param data - User data containing user ID.
   * @param event - Checkbox change event.
   */
  getUserData(data: any, event: any) {
    if (event.target.checked) {
      this.addUserData.push(data.id);
    } else {
      if (this.addUserData.length > 1) {
        this.addUserData = this.addUserData.filter((v) => v != data.id);
      } else {
        this.addUserData = [];
      }
    }
  }

  /**
   * Opens a dialog for adding a user.
   */
  openAddUser() {
    const dialogRef = this.dialog.open(ManagedDialogComponent, {
      panelClass: 'custom-modalbox',
      data: {
        manageUser: this.userData,
      },
    });

    dialogRef.afterClosed().subscribe(() => {
      //console.log(`Dialog result: ${result}`);
    });
  }

  /**
   * Clears the search input.
   */
  clearSearch() {
    this.search = '';
  }
}
