import { Directive, ElementRef, HostListener, Input, TemplateRef } from '@angular/core';
import { TooltipService } from './tooltip.service';

/**
 * Directive that displays a tooltip on hover.
 */
@Directive({
  selector: '[appTooltip]',
  standalone: true,
})
export class TooltipDirective {
  /**
   * The content to be displayed in the tooltip.
   * This can be a string or a template reference.
   */
  @Input('appTooltip')
  content!: string | TemplateRef<unknown>;

  /**
   * Creates an instance of TooltipDirective.
   * @param el - The element reference to which the directive is applied.
   * @param tooltipService - The service used to manage tooltip visibility.
   */
  constructor(
    private el: ElementRef,
    private tooltipService: TooltipService,
  ) {}

  /**
   * Event listener for mouse enter event.
   * Displays the tooltip when the mouse enters the element.
   */
  @HostListener('mouseenter') onMouseEnter() {
    this.tooltipService.showTooltip(this.el.nativeElement, this.content);
    document.addEventListener('click', this.onDocumentClick.bind(this));
  }

  /**
   * Event listener for mouse leave event.
   * Hides the tooltip when the mouse leaves the element if it is not scrollable.
   */
  @HostListener('mouseleave') onMouseLeave() {
    if (!this.isScrollable()) {
      this.tooltipService.hideTooltip();
      document.removeEventListener('click', this.onDocumentClick.bind(this));
    }
  }

  /**
   * Determines if the tooltip content is scrollable.
   * @returns True if the content is a long string or a template reference; otherwise false.
   */
  private isScrollable(): boolean {
    return (typeof this.content === 'string' && this.content.length > 100) || this.content instanceof TemplateRef; // Adjust this condition as needed
  }

  /**
   * Hides the tooltip when a click occurs outside of the element.
   * @param event - The click event.
   */
  private onDocumentClick(event: Event) {
    if (!this.el.nativeElement.contains(event.target)) {
      this.tooltipService.hideTooltip();
      document.removeEventListener('click', this.onDocumentClick.bind(this));
    }
  }
}
