<div class="roles-modal dialog-xs">
  <div class="hs-tab-card-title flex-between" [ngClass]="{ 'align-items-start': subtitle }">
    <div class="managed_dialog-title">
      <h2>{{ title }}</h2>
      <p class="managed_dialog-subtitle" *ngIf="subtitle">{{ subtitle }}</p>
    </div>
    <div class="dialog-header-icons">
      <i-feather name="check" (click)="onDoneClick()"></i-feather>
      <i-feather name="x" mat-dialog-close></i-feather>
    </div>
  </div>
  <div class="form-group search-input mb-4">
    <input type="text" class="form-control" [(ngModel)]="search" placeholder="Search" />
    <i-feather name="search" class="feather-20 clr-disabled"></i-feather>
  </div>
  <div class="scrolling_sec">
    <!-- <pre>{{supportUser | json}}</pre> -->
    <div class="form-group form-check" *ngFor="let group of supportUser | filter: search">
      <input
        #checkboxes
        type="checkbox"
        class="form-check-input"
        id="{{ group.id }}"
        (change)="getGroupData(group, $event)"
      />
      <label for="ds" class="form-check-label">{{ group.name }} </label>
    </div>
    <div class="norecord" *ngIf="supportUser.length === 0">{{ 'noRecordFound' | translate }}</div>
  </div>
</div>
