import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ClickOutSideDirective } from './click-out-side.directive';
import { CmsLinksHandlerDirective } from './cms-links-handler.directive';
import { DigitOnlyDirective } from './digit-only.directive';
import { SpecialCharacterDirective } from './no-special-character.directive';
import { OnlyNumberDirective } from './only-number.directive';
import { ReadMoreDirective } from './read-more.directive';
import { SpaceHandleDirective } from './space-handle.directive';
import { TrimDirective } from './trim.directive';
@NgModule({
  imports: [CommonModule],
  declarations: [
    CmsLinksHandlerDirective,
    ClickOutSideDirective,
    OnlyNumberDirective,
    TrimDirective,
    SpaceHandleDirective,
    DigitOnlyDirective,
    ReadMoreDirective,
    SpecialCharacterDirective,
  ],
  exports: [
    CmsLinksHandlerDirective,
    ClickOutSideDirective,
    OnlyNumberDirective,
    TrimDirective,
    SpaceHandleDirective,
    DigitOnlyDirective,
    ReadMoreDirective,
    SpecialCharacterDirective,
  ],
})
export class DirectivesModule {}
