/**
 * Default starting hour for business hours.
 */
export const DEFAULT_FROM_HOUR = 9;
/**
 * Default ending hour for business hours.
 */
export const DEFAULT_TO_HOUR = 18;
/**
 * Default minutes for business hours.
 */
export const DEFAULT_MINUTES = 30;
/**
 * Number zero constant.
 */
export const NUMBER_ZERO = 0;
/**
 * Number one constant.
 */
export const NUMBER_ONE = 1;
/**
 * Represents options for each day of the week.
 */
export const WeekOptions: WeekOption[] = [
  {
    /**
     * Key representing Monday.
     */
    key: 'monday',
    /**
     * Short label key for Monday.
     */
    shortLabelKey: 'mon',
    /**
     * Extra small label key for Monday.
     */
    xsLabelKey: 'm',
    /**
     * Full name value of Monday.
     */
    value: 'Mon',
    /**
     * Abbreviated value of Monday.
     */
    pValue: 'MO',
  },
  {
    /**
     * Key representing Tuesday.
     */
    key: 'tuesday',
    /**
     * Short label key for Tuesday.
     */
    shortLabelKey: 'tue',
    /**
     * Extra small label key for Tuesday.
     */
    xsLabelKey: 't',
    /**
     * Full name value of Tuesday.
     */
    value: 'Tue',
    /**
     * Abbreviated value of Tuesday.
     */
    pValue: 'TU',
  },
  {
    /**
     * Key representing Wednesday.
     */
    key: 'wednesday',
    /**
     * Short label key for Wednesday.
     */
    shortLabelKey: 'wed',

    /**
     * Extra small label key for Wednesday.
     */
    xsLabelKey: 'w',

    /**
     * Full name value of Wednesday.
     */
    value: 'Wed',

    /**
     * Abbreviated value of Wednesday.
     */
    pValue: 'WE',
  },
  {
    /**
     * Key representing Thursday.
     */
    key: 'thursday',

    /**
     * Short label key for Thursday.
     */
    shortLabelKey: 'thu',

    /**
     * Extra small label key for Thursday.
     */
    xsLabelKey: 't',

    /**
     * Full name value of Thursday.
     */
    value: 'Thu',

    /**
     * Abbreviated value of Thursday.
     */
    pValue: 'TH',
  },
  {
    /**
     * Key representing Friday.
     */
    key: 'friday',

    /**
     * Short label key for Friday.
     */
    shortLabelKey: 'fri',

    /**
     * Extra small label key for Friday.
     */
    xsLabelKey: 'f',

    /**
     * Full name value of Friday.
     */
    value: 'Fri',

    /**
     * Abbreviated value of Friday.
     */
    pValue: 'FR',
  },
  {
    /**
     * Key representing Saturday.
     */
    key: 'saturday',

    /**
     * Short label key for Saturday.
     */
    shortLabelKey: 'sat',

    /**
     * Extra small label key for Saturday.
     */
    xsLabelKey: 's',

    /**
     * Full name value of Saturday.
     */
    value: 'Sat',

    /**
     * Abbreviated value of Saturday.
     */
    pValue: 'SA',
  },
  {
    /**
     * Key representing Sunday.
     */
    key: 'sunday',

    /**
     * Short label key for Sunday.
     */
    shortLabelKey: 'sun',

    /**
     * Extra small label key for Sunday.
     */
    xsLabelKey: 's',

    /**
     * Full name value of Sunday.
     */
    value: 'Sun',

    /**
     * Abbreviated value of Sunday.
     */
    pValue: 'SU',
  },
];

/**
 * Represents out-of-office settings.
 */
export interface OutOfOffice {
  /**
   * Indicates the status of out-of-office.
   */
  status: boolean;

  /**
   * Start date of out-of-office.
   */
  from: string;

  /**
   * End date of out-of-office.
   */
  to: string;

  /**
   * Subject of out-of-office message.
   */
  subject: string;

  /**
   * Body of out-of-office message.
   */
  body: string;
}

/**
 * Represents out-of-office with business hours settings.
 */
export interface OOOBusinessHours {
  /**
   * Indicates the status of for business hours.
   */
  status: boolean;
  /**
   * Start date of for business hours.
   */
  from: string;
  /**
   * End date of for business hours.
   */
  to: string;
  /**
   * Subject of for business hours message.
   */
  subject: string;
  /**
   * Body of for business hours message.
   */
  body: string;
  /**
   * Days of the week for business hours.
   */
  dayOfWeek: Array<string>;
}

/**
 * Represents business hours settings.
 */
export interface BusinessHours {
  /**
   * Indicates the status of business hours.
   */
  status: boolean;

  /**
   * Start time of business hours as a string.
   */
  from: string;

  /**
   * Optional start time of business hours as a Date object.
   */
  fromTime?: Date;

  /**
   * End time of business hours as a string.
   */
  to: string;

  /**
   * Optional end time of business hours as a Date object.
   */
  toTime?: Date;

  /**
   * Days of the week for business hours.
   */
  dayOfWeek: Array<string>;

  /**
   * Default time zone for business hours.
   */
  defaultTimeZone?: string;

  /**
   * Subject of business hours message.
   */
  subject: string;

  /**
   * Body of business hours message.
   */
  body: string;
}

/**
 * Represents the response format for out-of-office settings.
 */
export interface OOOResponse {
  /**
   * Indicates the success status of the response.
   */
  success: number;

  /**
   * Data containing out-of-office settings.
   */
  data: OutOfOffice;
}

/**
 * Represents the response format for out-of-office with business hours settings.
 */
export interface OOOBusinessHoursResponse {
  /**
   * Indicates the success status of the response.
   */
  success: number;

  /**
   * Data containing business hours settings.
   */
  data: BusinessHours;
}

/**
 * Represents the options for each day of the week.
 */
export interface WeekOption {
  /**
   * Key representing the day of the week.
   */
  key: string;

  /**
   * Short label key for the day of the week.
   */
  shortLabelKey: string;

  /**
   * Extra small label key for the day of the week.
   */
  xsLabelKey: string;

  /**
   * Full name value of the day of the week.
   */
  value: string;

  /**
   * Abbreviated value of the day of the week.
   */
  pValue: string;
}

/**
 * Represents the toggle view for out-of-office and business hours.
 */
export interface ToggleView {
  /**
   * Indicates whether out-of-office view is toggled.
   */
  outOfOffice: boolean;

  /**
   * Indicates whether out-of-office with business hours view is toggled.
   */
  OOOBusinessHours: boolean;
}
