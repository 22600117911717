import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Attachment, AttachmentBasicData, RecalledMessageData } from '../../entities/message.entities';
import { MessageService } from '../../services/message.service';
/**
 * Component for viewing details of a recalled message.
 */
@Component({
  selector: 'app-view-recalled-message',
  templateUrl: './view-recalled-message.component.html',
  styleUrls: ['./view-recalled-message.component.scss'],
})
export class ViewRecalledMessageComponent {
  /** Array to store attachments related to the recalled message. */
  public attachments: Attachment[] = [];
  /**
   * Constructs the ViewRecalledMessageComponent.
   * @param messageService Service for message-related operations.
   * @param recalledMessageData Data object containing details of the recalled message.
   */
  public constructor(
    private messageService: MessageService,
    @Inject(MAT_DIALOG_DATA) public recalledMessageData: RecalledMessageData,
  ) {
    recalledMessageData.attachments.forEach((el: AttachmentBasicData): void => {
      if (!el.mimeType) return;
      this.attachments.push({
        ...el,
        documentId: '',
        fileIcon:
          el.mimeType.split('/')[0] === 'image'
            ? '/assets/images/icons/image.svg'
            : this.messageService.getFileIcon(el.mimeType, el.url) || '',
      });
    });
  }
}
