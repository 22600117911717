import { Injectable, WritableSignal, signal } from '@angular/core';
/**
 * Service to manage backdrop and loader visibility.
 */
@Injectable({
  providedIn: 'root',
})
export class BackdropAndLoaderService {
  /**
   * Signal to control the visibility of the backdrop.
   * @type {WritableSignal<boolean>}
   */
  public showBackdrop: WritableSignal<boolean> = signal(false);
}
