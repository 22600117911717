import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
/**
 * Component for filtering events using a dialog.
 */
@Component({
  selector: 'app-event-filter',
  templateUrl: './event-filter.component.html',
  styleUrls: ['./event-filter.component.scss'],
})
export class EventFilterComponent {
  /**
   * Options for event filtering.
   */
  options!: EventFilterType;

  /**
   * Constructor for EventFilterComponent.
   * @param dialog Reference to the MatDialogRef of the current dialog instance.
   * @param data Data object containing initial filter options.
   */
  constructor(
    public dialog: MatDialogRef<EventFilterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FilterDialogData,
  ) {
    this.options = { ...this.data.options };
  }

  /**
   * Resets the filter options to initial state and closes the dialog.
   */
  reset() {
    this.options = { appointment: false, virtualVisit: false };
    this.dialog.close(this.options);
  }

  /**
   * Closes the dialog and passes the current filter options.
   */
  clickOnCheckMark() {
    this.dialog.close(this.options);
  }
}
/**
 * Represents the types of events that can be filtered.
 */
export interface EventFilterType {
  /**
   * Indicates if appointments should be included in the filter.
   */
  appointment: boolean;

  /**
   * Indicates if virtual visits should be included in the filter.
   */
  virtualVisit: boolean;
}

/**
 * Data structure for the filter dialog, containing options for event filtering.
 */
export interface FilterDialogData {
  /**
   * Options for filtering events.
   */
  options: EventFilterType;
}
