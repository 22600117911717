import { ChangeDetectorRef, Component, Inject, OnDestroy } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { MatCalendar } from '@angular/material/datepicker';
import { Subject, takeUntil } from 'rxjs';

/**
 * Custom header component for the datepicker.
 * This component provides navigation and displays the current month and year.
 */
@Component({
  selector: 'app-custom-header-for-datepicker',
  templateUrl: './custom-header-for-datepicker.component.html',
  styleUrls: ['./custom-header-for-datepicker.component.scss'],
})
export class CustomHeaderForDatepickerComponent<D> implements OnDestroy {
  /** Private subject to handle component destruction */
  private _destroyed = new Subject<void>();

  /**
   * Constructor of the component.
   * @param _calendar The MatCalendar instance
   * @param _dateAdapter The DateAdapter instance for date formatting
   * @param _dateFormats The date formats used for display
   * @param cdr ChangeDetectorRef for detecting changes
   */
  constructor(
    private _calendar: MatCalendar<D>,
    private _dateAdapter: DateAdapter<D>,
    @Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats,
    cdr: ChangeDetectorRef,
  ) {
    _calendar.stateChanges.pipe(takeUntil(this._destroyed)).subscribe(() => cdr.markForCheck());
  }

  /**
   * Retrieves the label for the current period (month and year).
   * @returns The formatted label in uppercase
   */
  get periodLabel() {
    return this._dateAdapter
      .format(this._calendar.activeDate, this._dateFormats.display.monthYearLabel)
      .toLocaleUpperCase();
  }

  /**
   * Handler for when the previous button is clicked.
   * Adjusts the active date to the previous month.
   */
  previousClicked() {
    this._calendar.activeDate = this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1);
  }

  /**
   * Handler for when the next button is clicked.
   * Adjusts the active date to the next month.
   */
  nextClicked() {
    this._calendar.activeDate = this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1);
  }

  /**
   * This method completes the `_destroyed` subject to signal the destruction of the component
   * and ensure that any ongoing subscriptions or observers are properly cleaned up.
   */
  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }
}
