<div class="wrapper">
  <div class="main-content">
    <app-header></app-header>
    <div class="router-content">
      <router-outlet></router-outlet>
    </div>
    <!-- show to dashboard footer -->
    @if (authService.getToken() !== null) {
      <div class="footer-wrapper">
        <app-footer></app-footer>
      </div>
    }
  </div>
</div>
