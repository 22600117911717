import { AfterViewInit, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { HttpService } from 'src/app/modules/shared/services/http.service';
import { environment } from 'src/environments/environment';
/**
 * Component for displaying Healthwise content preview.
 * Fetches data based on provided topicId and displays HTML content, media, and transcript.
 */
@Component({
  selector: 'app-healthwise-preview',
  templateUrl: './healthwise-preview.component.html',
  styleUrls: ['./healthwise-preview.component.scss'],
})
export class HealthwisePreviewComponent implements AfterViewInit {
  /**
   * Observable stream of HealthwiseData fetched from the API.
   */
  public healthwiseData$!: Observable<HealthwiseData>;
  /**
   * Base URL of the API endpoint.
   */
  private baseUrl: string = environment.url.apiHost;
  /**
   * API version used for the endpoint.
   */
  private apiVersion: string = environment.url.version;
  /**
   * Constructor to initialize HttpService and inject MAT_DIALOG_DATA for topicId.
   * @param http - HttpService instance for API calls.
   * @param topicId - Topic ID to fetch HealthwiseData.
   */
  public constructor(
    private http: HttpService,
    @Inject(MAT_DIALOG_DATA) private topicId: string,
  ) {}
  /**
   * Lifecycle hook that fetches HealthwiseData after the view is initialized.
   */
  public ngAfterViewInit(): void {
    this.healthwiseData$ = this.getHealthwiseData();
  }
  /**
   * Fetches HealthwiseData from the API based on the topicId.
   * Constructs the URL using baseUrl, apiVersion, and topicId.
   * @returns Observable<HealthwiseData> - Observable of HealthwiseData fetched from the API.
   */
  private getHealthwiseData(): Observable<HealthwiseData> {
    const url = `${this.baseUrl}${this.apiVersion}/Integration/healthwise/${this.topicId}`;
    return this.http.get(url, {});
  }
}
/**
 * Interface representing the structure of HealthwiseData fetched from the API.
 */
interface HealthwiseData {
  /**
   * HTML content of the Healthwise data.
   */
  html: string;
  /**
   * Unique identifier for the Healthwise data.
   */
  hwid: string;
  /**
   * Transcript text associated with the Healthwise data.
   */
  transcript: string;
  /**
   * Aspect ratio of the media content, if applicable.
   */
  aspect: string;
  /**
   * Title of the Healthwise content.
   */
  title: string;
  /**
   * Type of Healthwise content, can be 'hwTask', 'hwMedia', or 'hwVideo'.
   */
  type: 'hwTask' | 'hwMedia' | 'hwVideo';
  /**
   * Optional media details associated with the Healthwise content.
   */
  media?: {
    /**
     * URL of the thumbnail image for the media.
     */
    thumbnail: string;
    /**
     * Duration of the media content.
     */
    duration: string;
    /**
     * Sources of the media content in different formats.
     */
    sources: {
      mp4_1080p_url: string;
      mp4_720p_url: string;
      mp4_288p_url: string;
      hls_600k_url: string;
      hls_400k_url: string;
    };
  };
  /**
   * Optional abstract or summary of the Healthwise content.
   */
  abstract?: string;
}
