<div class="dialog">
    <div class="hs-tab-card-title flex-between">
        <h2>Oct 10</h2>

        <div class="dialog-header-icons">
            <i-feather name="x" class="pointer" mat-dialog-close></i-feather>
        </div>
    </div>

    <mat-dialog-content class="mat-dialog-content">
        <form>
            <div class="more-event">
                <span class="event-item">9 AM Edgar Graham</span>
                <span class="event-item">1 PM Connor Dunn</span>
                <span class="event-item">2 PM Lorem Ipsum is simply dummy text...</span>
                <span class="event-item">5:00 PM UI/UX Team Meeting</span>
            </div>

        </form>
    </mat-dialog-content>
</div>