import { Component, OnChanges, OnInit, ViewChild } from '@angular/core';
import { MatTab, MatTabGroup, MatTabHeader } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { CommunicationService } from 'src/app/modules/settings/communication/communication.service';
import { TranslatePipe } from 'src/app/modules/shared/pipes/translate.pipe';

/**
 * Component for managing communication settings and tabs.
 */
@Component({
  selector: 'app-communication',
  templateUrl: './communication.component.html',
  styleUrls: ['./communication.component.scss'],
})
export class CommunicationTabComponent implements OnInit, OnChanges {
  /**
   * Enum representing tabs in communication settings.
   */
  tabIndex: settingCommunicationTabs = settingCommunicationTabs.Calendar;
  /**
   * Default tab index for calendar settings.
   */
  calendarTabIndex: settingCommunicationTabs = settingCommunicationTabs.Calendar;
  /**
   * Navigation state to store tab ID from route extras.
   */
  navigation: number;

  /**
   * Reference to MatTabGroup element.
   */
  @ViewChild('communicationTabGroup', { static: true }) private tabGroup: MatTabGroup = Object.create({});

  /**
   * Constructor for the CommunicationTabComponent.
   * Initializes the component with required services and sets initial navigation state.
   * @param {Router} route - Angular Router service for navigation.
   * @param {ActivatedRoute} activatedRoute - Angular ActivatedRoute service for accessing route parameters.
   * @param {CommunicationService} communicationService - Service for managing communication-related functionality.
   * @param {TranslatePipe} translatePipe - Custom pipe for translating text in the UI.
   */
  constructor(
    private route: Router,
    private ActivatedRoute: ActivatedRoute,
    private communicationService: CommunicationService,
    private translatePipe: TranslatePipe,
  ) {
    this.navigation = this.route.getCurrentNavigation()?.extras?.state?.tabId;
  }

  /**
   * Subscribe to fragment changes in route and set tab index accordingly.
   * Bind custom click handler to MatTabGroup.
   */
  ngOnInit(): void {
    this.ActivatedRoute.fragment.subscribe((res) => {
      this.tabIndex = Number(res);
      this.tabGroup.selectedIndex = this.tabIndex;
    });
    this.bindCustomClickHandlerOnMatTab();
  }

  /**
   * Lifecycle hook called when component input properties change.
   * Updates navigation state from current route navigation extras.
   */
  ngOnChanges(): void {
    this.navigation = this.route.getCurrentNavigation()?.extras?.state?.tabId;
  }

  /**
   * Binds a custom click handler to MatTabGroup to handle tab clicks.
   * Overrides default behavior to prompt for unsaved changes.
   */
  bindCustomClickHandlerOnMatTab() {
    (this.tabGroup as MatTabGroup)._handleClick = (tab: MatTab, tabHeader: MatTabHeader, index: number) => {
      if (this.communicationService.validateCalendarChanges()) {
        if (this.tabGroup.selectedIndex != index) {
          this.openDiscardChangesConfirmDialog(tab, tabHeader, index);
        }
      } else {
        this.customTabSuccessClickHandler(tab, tabHeader, index);
      }
    };
  }

  /**
   * Handles successful tab click by setting the selected index and focusing the tab header.
   * @param tab MatTab instance that was clicked.
   * @param tabHeader MatTabHeader instance associated with the tab.
   * @param index Index of the tab that was clicked.
   */
  customTabSuccessClickHandler(tab: MatTab, tabHeader: MatTabHeader, index: number) {
    tabHeader.focusIndex = index;
    this.tabIndex = index;
    if (!tab.disabled) {
      this.tabGroup.selectedIndex = index;
    }
  }

  /**
   * Opens a confirmation dialog to discard changes before changing tabs.
   * @param tab MatTab instance that was clicked.
   * @param tabHeader MatTabHeader instance associated with the tab.
   * @param index Index of the tab that was clicked.
   */
  openDiscardChangesConfirmDialog(tab: MatTab, tabHeader: MatTabHeader, index: number) {
    const data = {
      title: `${this.translatePipe.transform('discardYourChanges')}`,
      message:
        `${this.translatePipe.transform('discardChangesConfirm')}?` || 'Are you sure you want to discard changes?',
    };
    const confirmDialog = this.communicationService.openOOOConfirmDialog(data);
    confirmDialog.afterClosed().subscribe({
      next: (response) => {
        if (response) {
          this.customTabSuccessClickHandler(tab, tabHeader, index);
        }
      },
      error: (error) => {
        if (error) {
          //  console.log(error);
        }
      },
    });
  }
}

/**
 * Enum representing tabs in communication settings.
 */
enum settingCommunicationTabs {
  /**
   * Calendar tab.
   */
  Calendar = 0,
  /**
   * Communication Templates tab.
   */
  Communication_Templates = 1,
  /**
   * Disclaimers tab.
   */
  Disclaimers = 2,
  /**
   * Message Templates tab.
   */
  Message_Templates = 3,
  /**
   * Notification Settings tab.
   */
  Notification_Settings = 4,
}
