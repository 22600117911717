import { NgClass } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, WritableSignal, inject, signal } from '@angular/core';
import { RemoteParticipant } from '@azure/communication-calling';
import { CommunicationUserIdentifier } from '@azure/communication-common';
import { FeatherModule } from 'angular-feather';
import { TranslatePipe } from 'src/app/modules/shared/pipes/translate.pipe';
import { ACSService } from 'src/app/modules/shared/services/acs.service';
import { VideoMeetingParticipant } from '../../entities/video-lobby.entities';
import { VideoLobbyService } from '../../services/video-lobby.service';
/**
 * Component representing the list of participants in a video meeting.
 *
 * This component manages the display and interactions of meeting participants,
 * including their audio and video status.
 */
@Component({
  selector: 'app-meeting-participants',
  templateUrl: './meeting-participants.component.html',
  styleUrls: ['./meeting-participants.component.scss'],
  standalone: true,
  imports: [TranslatePipe, FeatherModule, NgClass],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeetingParticipantsComponent implements AfterViewInit {
  /** Flag to toggle displaying participants who have joined the meeting. */
  public showInThisMeeting = true;
  /** Flag to toggle displaying participants who have not joined the meeting. */
  public showNotJoined = true;
  /** Instance of the VideoLobbyService to manage video lobby functionalities. */
  public videoLobbyService = inject(VideoLobbyService);
  /** Signal holding the list of meeting attendees. */
  public attendees: WritableSignal<VideoMeetingParticipant[]> = signal([]);
  /** ACS ID of the host of the active call. */
  public hostAcsId: string = this.videoLobbyService.activeCall()!.host.acsId;
  /** Signal tracking the total number of joined participants in the meeting. */
  public totalJoinedParticipants: WritableSignal<number> = signal(0);
  /** ACS ID of the current user (self). */
  public selfAcsId = '';
  /** Instance of the ACSService for ACS-related functionalities. */
  #acsService = inject(ACSService);
  /**
   * Lifecycle hook that runs after Angular initializes the component's views.
   * It initializes attendee information and sets up event listeners.
   */
  public async ngAfterViewInit(): Promise<void> {
    this.attendees.set(
      this.videoLobbyService.activeCall()!.attendees.map((x) => ({
        ...x,
        remoteParticipantData: undefined,
        cameraStatus: signal(false),
        microphoneStatus: signal(false),
      })),
    );

    this.selfAcsId = await this.#acsService.getACSToken();
    this.#updateAttendees();
    this.videoLobbyService.call!.on('remoteParticipantsUpdated', () => {
      this.#updateAttendees();
    });
  }
  /**
   * Mutes the microphone of a participant if the current user is the host.
   *
   * @param participant The participant whose microphone is to be muted.
   */
  public muteParticipant(participant: VideoMeetingParticipant): void {
    if (this.hostAcsId === this.selfAcsId) {
      participant.remoteParticipantData!.mute();
    }
  }
  /**
   * Updates the list of attendees and sets up event listeners for microphone and camera status.
   * Updates the total number of joined participants.
   */
  #updateAttendees(): void {
    this.totalJoinedParticipants.set(0);
    this.attendees.update((x: VideoMeetingParticipant[]) => {
      x.map((attendee: VideoMeetingParticipant): void => {
        if (attendee.acsId === this.selfAcsId) {
          attendee.remoteParticipantData = true as unknown as RemoteParticipant;
        } else {
          attendee.remoteParticipantData = this.videoLobbyService.call!.remoteParticipants.find(
            (participant: RemoteParticipant): boolean =>
              (participant.identifier as CommunicationUserIdentifier).communicationUserId === attendee.acsId,
          );
          if (attendee.remoteParticipantData) this.#setUpMicAndCameraListners(attendee);
        }
        if (attendee.remoteParticipantData) this.totalJoinedParticipants.update((y) => y + 1);
      });
      return x;
    });
  }
  /**
   * Sets up event listeners for microphone and camera status changes of a participant.
   *
   * @param attendee The participant whose microphone and camera status listeners are to be set up.
   */
  #setUpMicAndCameraListners(attendee: VideoMeetingParticipant): void {
    // test this
    attendee.microphoneStatus.set(!attendee.remoteParticipantData!.isMuted);
    attendee.cameraStatus.set(attendee.remoteParticipantData!.videoStreams.length > 0);
    attendee.remoteParticipantData!.on('isMutedChanged', () => {
      attendee.microphoneStatus.set(!attendee.remoteParticipantData!.isMuted);
    });
    attendee.remoteParticipantData!.on('videoStreamsUpdated', () => {
      attendee.cameraStatus.set(attendee.remoteParticipantData!.videoStreams.length > 0);
    });
  }
}
