import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
/**
 * @description
 * Injectable service for handling translation data loading and management.
 * Uses HttpClient to fetch translation JSON files.
 */
@Injectable({
  providedIn: 'root',
})
export class TranslateService {
  /**
   * @description
   * Holds the translation data loaded from JSON files.
   */
  data: Record<string, string> = {};
  /**
   * @description
   * Constructor injecting the HttpClient service.
   * @param http - Angular HttpClient for HTTP requests.
   */
  constructor(private http: HttpClient) {}
  /**
   * @description
   * Loads and sets the translation data for the specified language.
   * @param lang - Language code for the translations (default: 'en').
   * @returns Promise resolving to the loaded translation data.
   */
  use(lang: string): Promise<Record<string, string>> {
    return new Promise<Record<string, string>>((resolve) => {
      const langPath = `assets/i18n/${lang || 'en'}.json`;
      this.http.get<Record<string, string>>(langPath).subscribe({
        next: (translation) => {
          this.data = Object.assign({}, translation || {});
          resolve(this.data);
        },
        error: () => {
          this.data = {};
          resolve(this.data);
        },
      });
    });
  }
}
