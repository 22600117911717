import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe to transform user group types into a user-friendly format.
 */
@Pipe({
  name: 'userGrpType',
  standalone: true,
})
export class UserGrpTypePipe implements PipeTransform {
  /**
   * Array of user group types with their corresponding display values.
   */
  userGroupTypes: Array<{ value: string; viewValue: string }> = [
    { value: 'Member', viewValue: 'Member' },
    { value: 'CoOwner', viewValue: 'Co-owner' },
    { value: 'Observer', viewValue: 'Observer' },
    { value: 'owner', viewValue: 'Owner' },
  ];

  /**
   * Transforms a user group value to its display value.
   */
  transform(value: unknown, ...args: unknown[]): unknown {
    const index = this.userGroupTypes.findIndex((ele) => ele.value === value);

    return this.userGroupTypes[index].viewValue;
  }
}
