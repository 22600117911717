@if (paginationOptions) {
  <mat-paginator
    [length]="paginationOptions.totalCount"
    [pageSize]="paginationOptions.limit"
    [pageIndex]="pageIndex"
    [pageSizeOptions]="pageSizeOptions"
    (page)="handlePage($any($event))"
    showFirstLastButtons
  >
  </mat-paginator>
}
