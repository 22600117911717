import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { AuthServiceService } from 'src/app/modules/authentication/services/auth-service.service';
/**
 * AuthGuard service to protect routes based on user authentication and authorization.
 *
 * @@Injectable
 * @providedIn 'root'
 */
@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  /**
   * Constructs the AuthGuard service with required dependencies.
   *
   * @param authService The authentication service to check user authorization status.
   * @param toaster ToastrService for displaying notifications.
   * @param router Router service for navigation.
   */
  constructor(
    private authService: AuthServiceService,
    private toaster: ToastrService,
    private router: Router,
  ) {}
  /**
   * Guards route loading based on user authentication and authorization.
   *
   * @returns True if the user is authorized and allowed access; otherwise, redirects to login or denies access.
   */
  canLoad(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (this.authService.isAuthorized()) {
      if (!this.authService.getAllowDashboardAccess()) {
        this.authService.signOut();
        return false;
      }
      return true;
    } else {
      this.toaster.error('Please login again.', 'Your session has expired.');
      this.authService.signOut();
      return false;
    }
  }

  /**
   * Guards route activation based on user authentication and authorization.
   *
   * @returns True if the user is authorized and allowed access; otherwise, redirects to login or denies access.
   */
  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.authService.isAuthorized()) {
      if (!this.authService.getAllowDashboardAccess()) {
        this.authService.signOut();
        return false;
      }
      return true;
    } else {
      //this.toaster.error('Please login again.', 'Your session has expired.');
      this.authService.signOut();
      return false;
    }
  }
}
