import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from 'src/app/modules/shared/services/http.service';
import { environment } from 'src/environments/environment';
/**
 * Service for managing notes through HTTP requests.
 * This service interacts with the backend API to perform CRUD operations on notes.
 */
@Injectable({
  providedIn: 'root',
})
export class NoteService {
  /**
   * Base URL of the backend API.
   */
  private baseUrl: string = environment.url.apiHost;
  /**
   * API version used for the backend API.
   */
  private apiVersion: string = environment.url.version;

  /**
   * Constructs the NoteService.
   * @param httpService Service for making HTTP requests.
   */
  constructor(private httpService: HttpService) {}
  /**
   * Saves a new note or updates an existing note.
   * @param body The data to be sent in the request body.
   * @param endPoint Optional endpoint to be appended to the base URL.
   * @returns Observable<any> An observable that emits the HTTP response when the note is saved.
   */
  saveNote(body?: any, endPoint?: string): Observable<any> {
    const url = `${this.baseUrl}${this.apiVersion}/notes${endPoint}`;
    return this.httpService.post(url, body);
  }

  /**
   * Retrieves a note from the server.
   * @param endPoint Optional endpoint to be appended to the base URL.
   * @returns Observable<any> An observable that emits the HTTP response containing the note.
   */
  getNote(endPoint?: string): Observable<any> {
    const url = `${this.baseUrl}${this.apiVersion}/notes${endPoint}`;
    return this.httpService.get(url);
  }

  /**
   * Retrieves an alert note based on user ID and alert ID.
   * @param userId The ID of the user associated with the alert note.
   * @param alertId The ID of the alert for which the note is retrieved.
   * @returns Observable<any> An observable that emits the HTTP response containing the alert note.
   */
  getAlertNote(userId?: string, alertId?: string): Observable<any> {
    const url = this.baseUrl + this.apiVersion + '/alert/' + alertId + '/user/' + userId;
    return this.httpService.get(url);
  }
}
