import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FullCalendarModule } from '@fullcalendar/angular';
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { FeatherModule } from 'angular-feather';
import { DirectivesModule } from 'src/app/modules/shared/directives/directive.module';
import { ClientLabelPipe } from 'src/app/modules/shared/pipes/client-label.pipe';
import { FilterPipe } from 'src/app/modules/shared/pipes/filter.pipe';
import { TranslatePipe } from 'src/app/modules/shared/pipes/translate.pipe';
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { CalendarRoutingModule } from './calendar-routing.module';
import { CalendarComponent } from './components/calendar/calendar.component';
import { CustomHeaderForDatepickerComponent } from './components/custom-header-for-datepicker/custom-header-for-datepicker.component';
import { EventFilterComponent } from './components/event-filter/event-filter.component';
import { MoreEventComponent } from './components/more-event/more-event.component';

@NgModule({
  declarations: [CalendarComponent, EventFilterComponent, MoreEventComponent, CustomHeaderForDatepickerComponent],
  providers: [DatePipe],
  imports: [
    CommonModule,
    CalendarRoutingModule,
    NgbTooltipModule,
    FeatherModule,
    TranslatePipe,
    MatSlideToggleModule,
    MatDialogModule,
    FullCalendarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    SharedModule,
    DirectivesModule,
    FormsModule,
    NgbModule,
    ClientLabelPipe,
    FilterPipe,
  ],
})
export class CalendarModule {}
