import { Component, Input } from '@angular/core';
/**
 * Component to display a card with a title when the user has no access.
 * This component is used to inform users about restricted access or permissions.
 */
@Component({
  selector: 'app-hs-no-access',
  templateUrl: './hs-no-access.component.html',
  styleUrls: ['./hs-no-access.component.scss'],
})
export class HsNoAccessComponent {
  /**
   * Input property to set the title of the card.
   * This title is displayed prominently in the card.
   */
  @Input() public cardTitle!: string;

  /**
   * Constructs the HsNoAccessComponent.
   * Initializes the component.
   */
  constructor() {}
}
