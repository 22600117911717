import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Component, inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CalendarComponent } from 'src/app/modules/calendar/components/calendar/calendar.component';
import { MessageComponent } from 'src/app/modules/message/components/message/message.component';
import { PermissionsService } from 'src/app/modules/permissions/services/permissions.service';
import { CommunicationTabComponent } from 'src/app/modules/settings/communication/components/communication-tab/communication.component';
import { InformationDialogComponent } from 'src/app/modules/shared/components/information-dialog/information-dialog.component';
import { VideoLobbyComponent } from 'src/app/modules/video-lobby/components/video-lobby/video-lobby.component';
import { VideoLobbyService } from 'src/app/modules/video-lobby/services/video-lobby.service';
import { ContactsComponent } from '../contacts/contacts.component';
/**
 * Component for dashboard selection actions and dialogs.
 */
@Component({
  selector: 'app-dashboard-select',
  templateUrl: './dashboard-select.component.html',
  styleUrls: ['./dashboard-select.component.scss'],
})
export class DashboardSelectComponent {
  /**
   * Flag indicating whether the settings dropdown is open.
   */
  settingDropdown = false;

  /**
   * Flag indicating whether the communication dropdown is open.
   */
  communicationDropdown = false;

  /**
   * Flag indicating whether the managed user care dropdown is open.
   */
  managedUserCareDropdown = false;

  /**
   * Flag indicating whether the system dropdown is open.
   */
  systemDropdown = false;
  /**
   * Injects VideoLobbyService dependency.
   */
  public videoLobbyService = inject(VideoLobbyService);
  /**
   * Constructor for DashboardSelectComponent.
   * @param dialog - MatDialog service instance for opening dialogs.
   * @param router - Router service instance for navigation.
   * @param dialogRef - MatDialogRef for referencing the dialog component instance.
   * @param permissionsService - PermissionsService for handling user permissions.
   */
  constructor(
    /**
     * Injects MatDialog for opening dialogs.
     */
    public dialog: MatDialog,
    /**
     * Injects Router for navigating between routes.
     */
    private router: Router,
    /**
     * Injects MatDialogRef for referencing the dialog component itself.
     */
    private dialogRef: MatDialogRef<DashboardSelectComponent>,
    /**
     * Injects PermissionsService for handling user permissions.
     */
    private permissionsService: PermissionsService,
  ) {}

  /**
   * Toggles the setting dropdown and closes other dropdowns.
   */
  openSettingDropdown() {
    this.settingDropdown = !this.settingDropdown;
    if (this.settingDropdown) {
      this.communicationDropdown = false;
      this.managedUserCareDropdown = false;
      this.systemDropdown = false;
    }
  }
  /**
   * Toggles the communication dropdown.
   */
  openCommunicationDropdown() {
    this.communicationDropdown = !this.communicationDropdown;
  }
  /**
   * Toggles the managed user care dropdown.
   */
  openManagedUserCareDropdown() {
    this.managedUserCareDropdown = !this.managedUserCareDropdown;
  }
  /**
   * Toggles the system dropdown.
   */
  openSystemDropdown() {
    this.systemDropdown = !this.systemDropdown;
  }
  /**
   * Opens the calendar dialog if the user has permission.
   */
  openCalendar() {
    if (this.permissionsService.hasPermission('self.Calendar.Read')) {
      this.dialog.open(CalendarComponent, {
        panelClass: ['full-calendar-dialog', 'full-screen-dialog'],
        hasBackdrop: true,
        disableClose: true,
      });
    }
  }
  /**
   * Opens the video lobby dialog if permission is granted.
   * Otherwise, opens an information dialog with details on no permission.
   */
  public async openVideoLobby(): Promise<void> {
    if (this.videoLobbyService.pipMode()) return;
    if (await this.videoLobbyService.checkCameraMicrophonePermission()) {
      this.dialog.open(VideoLobbyComponent, {
        panelClass: ['video-lobby-dialog', 'full-screen-dialog'],
        disableClose: true,
        hasBackdrop: false,
        scrollStrategy: new NoopScrollStrategy(),
      });
    } else {
      this.dialog.open(InformationDialogComponent, {
        data: {
          title: this.videoLobbyService.noVideoPermissionTitle,
          message: this.videoLobbyService.noVideoPermissionMessage,
        },
        panelClass: 'confirmation-dialog',
      });
    }
  }
  /**
   * Opens the contacts dialog.
   */
  openContacts() {
    this.dialog.open(ContactsComponent, {
      panelClass: 'header-contacts-dialog',
    });
  }
  /**
   * Opens the messages dialog.
   */
  public openMessages(): void {
    this.dialog.open(MessageComponent, {
      panelClass: ['full-screen-dialog', 'message-dialog'],
      autoFocus: false,
      disableClose: true,
      data: {},
    });
  }
  /**
   * Opens the communication tab dialog.
   */
  openCommunication() {
    this.dialog.open(CommunicationTabComponent, {
      panelClass: 'header-contacts-dialog',
    });
  }
  /**
   * Navigates to the specified route based on the text parameter.
   * Closes the dialog after navigation.
   * @param text - The text identifier for the route to navigate to.
   */
  navigateRoute(text: string) {
    if (text == 'calendar') {
      this.router.navigate(['/common/settings/communication'], { fragment: '0', queryParamsHandling: 'preserve' });
      this.dialogRef.close();
    } else if (text == 'communicationTemplates') {
      this.router.navigate(['/common/settings/communication'], { fragment: '1', queryParamsHandling: 'preserve' });
      this.dialogRef.close();
    } else if (text == 'disclaimers') {
      this.router.navigate(['/common/settings/communication'], { fragment: '2', queryParamsHandling: 'preserve' });
      this.dialogRef.close();
    } else if (text == 'messageTemplates') {
      this.router.navigate(['/common/settings/communication'], { fragment: '3', queryParamsHandling: 'preserve' });
      this.dialogRef.close();
    } else if (text == 'notificationSettings') {
      this.router.navigate(['/common/settings/communication'], { fragment: '4', queryParamsHandling: 'preserve' });
      this.dialogRef.close();
    } else if (text == 'ai/ml') {
      this.router.navigate(['/common/setting/system'], { fragment: '0', queryParamsHandling: 'preserve' });
      this.dialogRef.close();
    } else if (text == 'businessAnalytics') {
      this.router.navigate(['/common/setting/system'], { fragment: '1', queryParamsHandling: 'preserve' });
      this.dialogRef.close();
    } else if (text == 'general') {
      this.router.navigate(['/common/setting/system'], { fragment: '2', queryParamsHandling: 'preserve' });
      this.dialogRef.close();
    } else if (text == 'security') {
      this.router.navigate(['/common/setting/system'], { fragment: '3', queryParamsHandling: 'preserve' });
      this.dialogRef.close();
    } else if (text == 'auditTrial') {
      this.router.navigate(['/common/audit-trial']);
      this.dialogRef.close();
    } else if (text == 'alerts') {
      this.router.navigate(['/common/patient/patient-care'], { fragment: '0', queryParamsHandling: 'preserve' });
      this.dialogRef.close();
    } else if (text == 'careCoordination') {
      this.router.navigate(['/common/patient/patient-care'], { fragment: '1', queryParamsHandling: 'preserve' });
      this.dialogRef.close();
    } else if (text == 'intents') {
      this.router.navigate(['/common/patient/patient-care'], { fragment: '2', queryParamsHandling: 'preserve' });
      this.dialogRef.close();
    } else if (text == 'pro') {
      this.router.navigate(['/common/patient/patient-care'], { fragment: '3', queryParamsHandling: 'preserve' });
      this.dialogRef.close();
    } else if (text == 'resources') {
      this.router.navigate(['/common/patient/patient-care'], { fragment: '4', queryParamsHandling: 'preserve' });
      this.dialogRef.close();
    } else if (text == 'notification') {
      this.router.navigate(['/common/patient/patient-care'], { fragment: '5', queryParamsHandling: 'preserve' });
      this.dialogRef.close();
    }
  }
  // /**
  //  * Opens the calendar dialog if the user has permission.
  //  */
  // openChatAssistant() {
  //   // if (this.permissionsService.hasPermission('self.Calendar.Read')) {
  //   this.dialog.open(ChatAiAssistantComponent, {
  //     panelClass: 'full-calendar-dialog',
  //     position: { right: '20px' },
  //     hasBackdrop: true,
  //     disableClose: true,
  //   });
  //   //}
}
