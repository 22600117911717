import { ChangeDetectionStrategy, Component } from '@angular/core';
/**
 * Component representing a dot loader animation.
 */
@Component({
  selector: 'app-dot-loader',
  standalone: true,
  imports: [],
  templateUrl: './dot-loader.component.html',
  styleUrl: './dot-loader.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DotLoaderComponent {}
