<div class="accessibility">
  <div class="d-flex justify-content-between mb-4">
    <h3 class="mb-0">{{ 'accessibility' | translate }}</h3>
    <i-feather name="x" class="feather-20 text-secondary pointer" (click)="closeSidePanel.emit(true)"></i-feather>
  </div>

  <p class="label-heading mb-4">{{ 'captions' | translate }}</p>

  <form>
    <div class="form-check-flex align-items-center justify-content-between me-2">
      <label class="form-label m-0">{{ 'alwaysShowCaptionsInMyMeetings' | translate }}</label>
      <mat-slide-toggle [checked]="true"></mat-slide-toggle>
    </div>
  </form>
</div>
