import { Component, Input, OnInit } from '@angular/core';
import { CommunicationChannels, updateNotificationData } from '../../../communication.model';
import { CommunicationService } from '../../../communication.service';
/**
 * Component responsible for managing notification settings for a specific patient.
 */
@Component({
  selector: 'app-notification-settings',
  templateUrl: './notification-settings.component.html',
  styleUrls: ['./notification-settings.component.scss'],
})
export class NotificationSettingsComponent implements OnInit {
  /**
   * Array representing dropdown options for notification settings.
   */
  dropdownValue = [
    {
      /**
       * Unique identifier for the dropdown option.
       */
      id: 1,
      /**
       * Display text for the dropdown option.
       */
      value: 'Everyday',
    },
    {
      /**
       * Unique identifier for the dropdown option.
       */
      id: 2,
      /**
       * Display text for the dropdown option.
       */
      value: 'After 1 days incomplete',
    },
    {
      /**
       * Unique identifier for the dropdown option.
       */
      id: 3,
      /**
       * Display text for the dropdown option.
       */
      value: 'After 2 days incomplete',
    },
    {
      /**
       * Unique identifier for the dropdown option.
       */
      id: 4,
      /**
       * Display text for the dropdown option.
       */
      value: 'After 3 days incomplete',
    },
    {
      /**
       * Unique identifier for the dropdown option.
       */
      id: 5,
      /**
       * Display text for the dropdown option.
       */
      value: 'After 4 days incomplete',
    },
    {
      /**
       * Unique identifier for the dropdown option.
       */
      id: 6,
      /**
       * Display text for the dropdown option.
       */
      value: 'After 5 days incomplete',
    },
    {
      /**
       * Unique identifier for the dropdown option.
       */
      id: 7,
      /**
       * Display text for the dropdown option.
       */
      value: 'After 6 days incomplete',
    },
    {
      /**
       * Unique identifier for the dropdown option.
       */
      id: 8,
      /**
       * Display text for the dropdown option.
       */
      value: 'After 7 days incomplete',
    },
  ];
  /** Type of the user (e.g., 'Manager') */
  @Input() type!: 'Manager';
  /** ID of the patient */
  @Input() patientId!: string;
  /** Notification preferences for channels (SMS, email, push, inApp) */
  @Input() channels!: NotificationPreferences;
  /** Data retrieved for notification settings */
  notificationSettingsData!: any;
  /** Flags to manage different types of reminders */
  invitationReminder = false;
  /**
   * Indicates whether reactivation reminders are enabled.
   */
  reactivationReminder = false;
  /**
   * Indicates whether unread message reminders are enabled.
   */
  unreadMessageReminder = false;
  /**
   * Indicates whether invitation reminder attempts are enabled.
   */
  invitationReminderAttempts = false;
  /**
   * Indicates whether reactivation reminder attempts are enabled.
   */
  reactivationReminderAttempts = false;
  /**
   * Indicates whether unread message reminder attempts are enabled.
   */
  unreadMessageReminderAttempts = false;
  /** Constant string '0' */
  zero = '0';
  /** Displayed columns for the manager view */
  displayedManagerColumns: string[] = ['notification', 'sms', 'email', 'inApp', 'pushNotification'];
  /** Object to track expanded state of notification settings */
  expanded: { [key: string]: boolean } = {};

  /**
   * Constructor to inject CommunicationService.
   * @param _CommunicationService - Instance of CommunicationService for communication-related operations.
   */
  constructor(private _CommunicationService: CommunicationService) {}

  /**
   * Retrieves notification settings for the specified patient.
   */
  ngOnInit(): void {
    this.getNotificationSettings();
  }

  /**
   * Retrieves notification settings for the specified patient from the CommunicationService.
   * Filters out specific items from the retrieved data before assigning it to notificationSettingsData.
   */
  public getNotificationSettings(): void {
    this._CommunicationService.getNotificationSettings(this.patientId).subscribe((res) => {
      //  this.notificationSettingsData = res;
      this.notificationSettingsData = res.filter(
        (item: { communicationChannels: null; key: string }) =>
          item.key !== 'completeHealthTracker' &&
          item.communicationChannels !== null &&
          item.communicationChannels !== undefined,
      );
    });
  }

  /**
   * Updates a specific row of notification settings for the patient.
   *
   * @param body - Data object containing updated notification settings.
   */
  public getUpdatedRow(body: updateNotificationData): void {
    const keyToAdd = body.key;
    const data = {
      [keyToAdd]: JSON.parse(JSON.stringify(body)),
    };
    delete data[keyToAdd]['key'];
    this._CommunicationService.updateNotificationSettings(this.patientId, data).subscribe();
  }

  /**
   * Updates nested rows of notification settings for the patient.
   *
   * @param body - Data object containing updated notification settings.
   * @param key - Key identifier for the nested data structure.
   */
  public getUpdatedRowNested(body: any, key: string): void {
    const keyName: string = key;
    const payload: any = { [keyName]: {} };
    // Iterate object notification
    body[keyName].forEach(
      (item: { key: string; communicationChannels: CommunicationChannels; groupTitle: string; title: string }) => {
        const key = item.key;
        const communicationChannels = item.communicationChannels;
        const groupTitle = item.groupTitle;
        const title = item.title;
        // Create the substructure for each item
        payload[keyName][key] = {
          communicationChannels: communicationChannels,
          groupTitle: groupTitle,
          title: title,
        };
      },
    );
    this._CommunicationService.updateNotificationSettings(this.patientId, payload).subscribe();
  }

  /**
   * Retrieves the checked value for a specific notification channel.
   *
   * @param value - Notification channel value to check.
   * @returns Boolean indicating whether the notification channel is checked.
   */
  checkedChannels(value: string) {
    return this._CommunicationService.notificationCheckedChannelValue(value);
  }
}

/**
 * Interface representing notification preferences for channels (SMS, email, push, inApp).
 */
interface NotificationPreferences {
  /**
   * Indicates whether SMS notifications are enabled.
   */
  sms?: boolean;
  /**
   * Indicates whether email notifications are enabled.
   */
  email?: boolean;
  /**
   * Indicates whether push notifications are enabled.
   */
  push?: boolean;
  /**
   * Indicates whether in-app notifications are enabled.
   */
  inApp?: boolean;
}
