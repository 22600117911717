import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  Renderer2,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FeatherModule } from 'angular-feather';
import { TooltipService } from './tooltip.service';

/**
 * Component for displaying a custom tooltip with dynamic content.
 */
@Component({
  selector: 'app-custom-tooltip',
  standalone: true,
  imports: [CommonModule, FeatherModule],
  templateUrl: './custom-tooltip.component.html',
  styleUrls: ['./custom-tooltip.component.scss'],
})
export class CustomTooltipComponent implements AfterViewInit, OnDestroy {
  /**
   * Reference to the tooltip content element.
   */
  @ViewChild('tooltipContent', { static: true }) public tooltipContentRef!: ElementRef;

  /**
   * Indicates if the tooltip content is scrollable.
   */
  public isContentScrollable = false;

  /**
   * Optional mouse leave event listener.
   */
  private mouseLeaveListener?: () => void;

  /**
   * Creates an instance of CustomTooltipComponent.
   * @param content - The content of the tooltip, which can be a string or a template reference.
   * @param tooltipService - The service used to manage tooltip visibility.
   * @param renderer - The renderer used to attach and remove event listeners.
   */
  constructor(
    @Inject('tooltipContent') public content: string | TemplateRef<unknown>,
    private tooltipService: TooltipService,
    private renderer: Renderer2,
  ) {}

  /**
   * Lifecycle hook that is called after the component's view has been fully initialized.
   * Checks if the tooltip content is scrollable.
   */
  public ngAfterViewInit(): void {
    this.checkIfScrollable();
  }

  /**
   * Lifecycle hook that is called just before the component is destroyed.
   * Removes any mouse leave event listener.
   */
  public ngOnDestroy(): void {
    this.removeMouseLeaveListener();
  }

  /**
   * Closes the tooltip.
   */
  public closeTooltip(): void {
    this.tooltipService.hideTooltip();
  }

  /**
   * Checks if the provided content is a template reference.
   * @param content - The content to check.
   * @returns True if the content is a TemplateRef; otherwise, false.
   */
  public isTemplate(content: unknown): content is TemplateRef<unknown> {
    return content instanceof TemplateRef;
  }

  /**
   * Checks if the tooltip content exceeds the maximum height or length.
   * If so, marks it as scrollable; otherwise, attaches a mouse leave listener.
   */
  private checkIfScrollable(): void {
    setTimeout(() => {
      const tooltipElement = this.tooltipContentRef.nativeElement as HTMLElement;
      const contentText = tooltipElement.textContent || '';
      const contentHeight = tooltipElement.scrollHeight;

      if (contentHeight > 200 || contentText.length > 600) {
        this.isContentScrollable = true;
      } else {
        this.attachMouseLeaveListener();
      }
    }, 0); // Ensure DOM has updated
  }

  /**
   * Attaches a mouse leave listener to the tooltip trigger element.
   * Hides the tooltip when the mouse leaves the trigger element.
   */
  private attachMouseLeaveListener(): void {
    const triggerElement = this.tooltipService.getTriggerElement();
    if (triggerElement) {
      // Attach the mouseleave listener using Renderer2
      this.mouseLeaveListener = this.renderer.listen(triggerElement, 'mouseleave', () => {
        this.tooltipService.hideTooltip();
      });
    }
  }

  /**
   * Removes the mouse leave event listener, if it exists.
   */
  private removeMouseLeaveListener(): void {
    if (this.mouseLeaveListener) {
      this.mouseLeaveListener(); // Remove the listener
      this.mouseLeaveListener = undefined;
    }
  }
}
