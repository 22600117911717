<div class="page-header pb-0">
  <h1>{{ 'settings' | translate }}</h1>
</div>
<mat-tab-group disableRipple="true" class="site-tab-group" #communicationTabGroup mat-align-tabs="start">
  <mat-tab>
    <ng-template mat-tab-label>{{ 'calendar' | translate }}</ng-template>
    <div *ngIf="tabIndex === calendarTabIndex">
      <app-calendar-settings></app-calendar-settings>
    </div>
  </mat-tab>
  <!-- <mat-tab>
    <ng-template mat-tab-label>{{ 'Communication Templates' | translate }}</ng-template>
    <app-communication-templates></app-communication-templates>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>{{ 'disclaimers' | translate }}</ng-template>
    No content
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>{{ 'messageTemplates' | translate }}</ng-template>
    <app-communication-message></app-communication-message>
  </mat-tab> -->
  <mat-tab label="Notification Settings">
    <div class="page-content-wrapper">
      <div class="card-base">
        <!-- <app-notification-settings-manager-group></app-notification-settings-manager-group>  -->
        <mat-tab-group>
          <mat-tab>
            <ng-template mat-tab-label>[{{ 'managerUserGroup' | translate }}]</ng-template>
            <div class="tab-content mt-0">
              <app-notification-settings-manager-group></app-notification-settings-manager-group>
            </div>
          </mat-tab>
          <mat-tab>
            <ng-template mat-tab-label>{{ 'manager' | clientLabel }}</ng-template>
            <div class="tab-content mt-0">
              <div class="table-wrapper">
                <app-notification-settings></app-notification-settings>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>
