import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MessageTemplate } from '../../entities/message.entities';
import { MessageAPIsService } from '../../services/message-apis.service';

/**
 * Component to display and manage message templates.
 */
@Component({
  selector: 'app-message-template',
  templateUrl: './message-template.component.html',
  styleUrls: ['./message-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageTemplateComponent implements OnInit {
  /**
   * Flag indicating whether the component is used for creating a new message.
   */
  @Input() public fromCreateMessage = false;
  /**
   * Event emitter to notify when a template is selected.
   */
  @Output() public templateSelected: EventEmitter<MessageTemplate> = new EventEmitter();
  /**
   * Array of message templates to display.
   */
  public displayedTemplates: MessageTemplate[] = [];
  /**
   * FormControl for the search query to filter templates.
   */
  public searchQuery: FormControl<string> = new FormControl('', {
    nonNullable: true,
  });

  /**
   * Array containing all available message templates fetched from the server.
   * @private
   */
  private templates: MessageTemplate[] = [];

  /**
   * The currently selected message template.
   * @private
   */
  private selectedTemplate: MessageTemplate | undefined = undefined;

  /**
   * Constructor to initialize the component.
   * @param change Reference to the ChangeDetectorRef for change detection.
   * @param dialog Reference to the MatDialogRef for dialog operations.
   * @param messageAPIsService Instance of MessageAPIsService for message-related APIs.
   */
  public constructor(
    private change: ChangeDetectorRef,
    private dialog: MatDialogRef<MessageTemplateComponent>,
    private messageAPIsService: MessageAPIsService,
  ) {}

  /**
   * Fetches message templates and sets up search functionality.
   */
  public async ngOnInit(): Promise<void> {
    this.templates = await this.messageAPIsService.getMessageTemplate();
    this.displayedTemplates = this.templates;
    this.change.markForCheck();
    this.searchQuery.valueChanges.subscribe((res: string): void => {
      this.displayedTemplates = this.templates.filter((template: MessageTemplate): boolean =>
        template.message.toLocaleLowerCase().includes(res.toLocaleLowerCase()),
      );
    });
  }

  /**
   * Submits the selected template.
   * Closes the dialog if not used for creating a new message.
   */
  public submitTemplate(): void {
    if (this.fromCreateMessage) return;
    this.dialog.close(this.selectedTemplate);
  }

  /**
   * Selects a template and emits an event if used for creating a new message.
   * @param template The selected message template.
   */
  public selectTemplate(template: MessageTemplate): void {
    this.selectedTemplate = template;
    if (this.fromCreateMessage) {
      this.templateSelected.emit(template);
    }
  }
}
