<div class="dialog-sm">
  <div class="hs-tab-card-title flex-between">
    <h2>{{ messageData.header | translate }}</h2>
    <div class="dialog-header-icons">
      <i-feather name="x" class="pointer" mat-dialog-close></i-feather>
    </div>
  </div>
  <p class="message" [innerHTML]="messageData.message | translate"></p>
  <div class="btn-group btn-center">
    <button class="btn btn-secondary" mat-dialog-close>{{ 'cancel' | translate }}</button>
    <button class="btn btn-primary" (click)="clickedOk()">{{ 'ok' | translate }}</button>
  </div>
</div>
