<div class="dialog-sm">
  <div class="hs-tab-card-title flex-between">
    <h2>Chat Assistant</h2>
    <div class="dialog-header-icons">
      <i-feather name="x" class="pointer" (click)="closeDialog()"></i-feather>
    </div>
  </div>
  <div class="chat-container">
    <div class="chat-box" id="chat-box">
      <!-- Messages will be displayed here -->
      @for (message of messages; track message) {
        <div class="message" [ngClass]="{ 'user-message': message.isUser }">
          @if (message.isUser) {
            <img src="../../../assets/images/user-img/user.png" alt="user" />
          } @else {
            <img src="../../../assets/images/icons/chat-ai.svg" alt="AI" />
          }
          <div class="message-text ws-pre-wrap">{{ message.text }}</div>
        </div>
      }

      <!-- Loading Animation for the Assistant Typing -->
      @if (isAssistantTyping) {
        <div class="message typing-indicator">
          <app-dot-loader></app-dot-loader>
          <!-- <div class="message-text-typing">
          <i>Assistant is typing</i><span class="dot">.</span><span class="dot">.</span><span class="dot">.</span>
        </div> -->
        </div>
      }
    </div>

    <!-- Input area for typing messages -->
    <div class="chat-input-container">
      <input
        [(ngModel)]="newMessage"
        type="text"
        class="chat-input form-control"
        placeholder="Type your message..."
        (keydown.enter)="sendMessage()"
      />
      <button class="send-button btn btn-primary" (click)="sendMessage()">Send</button>
    </div>
  </div>
</div>
