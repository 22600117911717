<div ngbDropdown container="body">
  <div class="open-time-picker" id="openTimePickerIcon" ngbDropdownToggle>
    <i-feather name="clock" class="feather-20"></i-feather>
  </div>
  <!-- <div class="time-picker-container">
    <div class="time-picker-content"></div></div> -->
  <div ngbDropdownMenu aria-labelledby="openTimePickerIcon" style="padding: 0px">
    <div class="time-picker-container">
      <div class="time-picker-content">
        <ngb-timepicker
          [(ngModel)]="time"
          [minuteStep]="minuteStep"
          [meridian]="true"
          (ngModelChange)="timeChangeHandler($event)"
        ></ngb-timepicker>
      </div>
    </div>
  </div>
</div>
