import { Injectable, signal, WritableSignal } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { HttpService } from 'src/app/modules/shared/services/http.service';
import activity from 'src/assets/lookup/activity.json';
import allergyDisease from 'src/assets/lookup/allergyDisease.json';
import Countries from 'src/assets/lookup/countries.json';
import documentCategory from 'src/assets/lookup/documentCategory.json';
import primaryDisease from 'src/assets/lookup/primaryDisease.json';
import primaryDiseaseDiagnosis from 'src/assets/lookup/primaryDiseaseDiagnosis.json';
import CountryStates from 'src/assets/lookup/states.json';
import suffix from 'src/assets/lookup/suffix.json';
import vitals from 'src/assets/lookup/vitals.json';
import { environment } from 'src/environments/environment';
/**
 * Service providing common functionalities and data for the application.
 */
@Injectable({
  providedIn: 'root',
})
export class CommonService {
  /** Base URL for API requests (v2). */
  v2baseUrl: string = environment.url.v2apiHost;
  /** Base URL for API requests. */
  baseUrl: string = environment.url.apiHost;
  /** API version used for API requests. */
  apiVersion: string = environment.url.version;
  /** BehaviorSubject to notify profile updates. */
  profileUpdate: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  /** Subject to emit widget data. */
  widgetData: Subject<any> = new ReplaySubject<any>();
  /** Subject to emit terms data. */
  termsData: Subject<any> = new ReplaySubject<any>();
  /** Flag indicating if unit preference is standard. */
  isUnitPreferenceStandard = false;
  /** WritableSignal for idle countdown (default: 60 seconds). */
  public idleCountdown: WritableSignal<number> = signal(60);
  /**
   * Constructor of CommonService.
   * @param httpService The HTTP service used for making API requests.
   */
  constructor(private httpService: HttpService) {}
  /**
   * Retrieves the list of countries.
   * @returns Observable<any> An observable emitting the list of countries.
   */
  getCountry(): Observable<any> {
    const url = this.baseUrl + this.apiVersion + '/lookup/country';
    return this.httpService.get(url);
  }
  /**
   * Retrieves the list of states.
   * @returns Observable<any> An observable emitting the list of states.
   */
  getState(): Observable<any> {
    const url = this.baseUrl + this.apiVersion + '/lookup/state';
    return this.httpService.get(url);
  }
  /**
   * Generic method to perform HTTP GET request.
   * @param endpoint The API endpoint to call.
   * @param userId Optional user ID for the request.
   * @param hideLoader Optional flag to hide loader during the request.
   * @returns Observable<any> An observable emitting the HTTP response.
   */
  get(endpoint: string, userId = '', hideLoader = false): Observable<any> {
    const url = this.baseUrl + this.apiVersion + endpoint + userId;
    return this.httpService.get(url, {}, hideLoader);
  }
  /**
   * Retrieves the authentication key.
   * @returns Observable<any> An observable emitting the authentication token.
   */
  getKey(): Observable<any> {
    const url = `${this.v2baseUrl}/v2/tableau/getToken`;
    return this.httpService.get(url);
  }
  /**
   * Retrieves the list of states (specific to USA).
   * @returns any[] The list of states in USA.
   */
  getStates() {
    return CountryStates.usa;
  }
  /**
   * Retrieves the list of provinces (specific to Canada).
   * @returns any[] The list of provinces in Canada.
   */
  getProvinces() {
    return CountryStates.canada;
  }
  /**
   * Retrieves the list of countries.
   * @returns { name: string, code: string }[] An array containing country names and codes.
   */
  getCountries() {
    return Object.entries(Countries).map((arr) => ({
      name: arr[1],
      code: arr[0],
    }));
  }
  /**
   * Retrieves the list of primary diseases.
   * @returns any[] The list of primary diseases.
   */
  getprimaryDisease() {
    return primaryDisease;
  }
  /**
   * Retrieves the list of primary disease diagnoses.
   * @returns any[] The list of primary disease diagnoses.
   */
  getPrimaryDiseaseDiagnosis() {
    return primaryDiseaseDiagnosis;
  }
  /**
   * Retrieves the list of allergies and diseases.
   * @returns any[] The list of allergies and diseases.
   */
  getallergy() {
    return allergyDisease;
  }
  /**
   * Retrieves the list of vitals.
   * @returns any[] The list of vitals.
   */
  getVitals() {
    return vitals;
  }
  /**
   * Retrieves the activity lookup data.
   * @returns any The activity lookup data.
   */
  getActivity() {
    return activity;
  }
  /**
   * Retrieves the document category lookup data.
   * @returns any The document category lookup data.
   */
  getDocumentCategory() {
    return documentCategory;
  }
  /**
   * Stores master filter data in local storage.
   * @param masterFilter The master filter data to store.
   */
  storeMasterFilterData(masterFilter: any) {
    localStorage.setItem('master-filter', JSON.stringify(masterFilter));
  }
  /**
   * Retrieves stored master filter data from local storage.
   * @returns any The stored master filter data.
   */
  getStoredMasterFilterData() {
    return JSON.parse(localStorage.getItem('master-filter') || '{}');
  }
  /**
   * Removes master filter data from local storage.
   */
  removeMasterFilterData() {
    localStorage.removeItem('master-filter');
  }
  /**
   * Constructs and retrieves master filter query string.
   * @returns string The master filter query string.
   */
  getMasterFilterQuery() {
    const filterObject = JSON.parse(localStorage.getItem('master-filter') || '{}');
    const filterQuery: string[] = [];
    for (const key in filterObject) {
      const value = filterObject[key];
      if (value && value.length > 0) {
        filterQuery.push(`${key}|${value}`);
      }
    }
    return filterQuery.join(',');
  }
  /**
   * Retrieves the list of suffixes.
   * @returns any[] The list of suffixes.
   */
  getSuffix() {
    return suffix;
  }
  /**
   * Updates data based on unit preference (e.g., temperature, weight).
   * @param data The data to update.
   * @param unit The unit preference ('C', 'F', 'kg', 'lbs', etc.).
   * @returns dataChange The updated data.
   */
  updateUnitPreference(data: dataChange, unit: string) {
    const val = localStorage.getItem('userConfigurations');
    if (val) {
      const parseData = JSON.parse(val);
      if (parseData.UnitPreference === 'standard' && unit.toLowerCase() === 'c') {
        if (data.trends.length > 0) {
          data.trends.forEach((item: trendsInterface) => {
            item.minValue = +this.CToF(item.minValue).toFixed(1);
            item.maxValue = +this.CToF(item.maxValue).toFixed(1);
            item.avgValue = +this.CToF(item.avgValue).toFixed(1);
          });
        }
        if (data.anomaly.length > 0) {
          data.anomaly.forEach((item: anomalyInterface) => {
            item.upper = +this.CToF(item.upper).toFixed(1);
            item.lower = +this.CToF(item.lower).toFixed(1);
            item.value = +this.CToF(item.value).toFixed(1);
          });
        }
        if (data.value) {
          data.value = +this.CToF(data.value).toFixed(1);
        }
        data.unit = 'F';
      } else if (parseData.UnitPreference === 'standard' && unit.toLowerCase() === 'kg') {
        data.trends.forEach((item: trendsInterface) => {
          item.minValue = +this.kgToLbs(item.minValue).toFixed(1);
          item.maxValue = +this.kgToLbs(item.maxValue).toFixed(1);
          item.avgValue = +this.kgToLbs(item.avgValue).toFixed(1);
        });
        data.anomaly.forEach((item: anomalyInterface) => {
          item.upper = +this.kgToLbs(item.upper).toFixed(1);
          item.lower = +this.kgToLbs(item.lower).toFixed(1);
          item.value = +this.kgToLbs(item.value).toFixed(1);
        });
        data.value = +this.kgToLbs(data.value).toFixed(1);
        data.unit = 'lbs';
      } else if (parseData.BloodGlucoseUnit === 'mmol/L' && unit.toLowerCase() === 'mg/dl') {
        data.trends.forEach((item: trendsInterface) => {
          item.minValue = +this.mmDlToOl(item.minValue).toFixed(1);
          item.maxValue = +this.mmDlToOl(item.maxValue).toFixed(1);
          item.avgValue = +this.mmDlToOl(item.avgValue).toFixed(1);
        });
        data.anomaly.forEach((item: anomalyInterface) => {
          item.upper = +this.mmDlToOl(item.upper).toFixed(1);
          item.lower = +this.mmDlToOl(item.lower).toFixed(1);
          item.value = +this.mmDlToOl(item.value).toFixed(1);
        });
        data.value = +this.mmDlToOl(data.value).toFixed(1);
        // data = data;
        data.unit = parseData.BloodGlucoseUnit;
      } else {
        data = data;
      }
    }
    return data;
  }
  /**
   * Updates data post initialization based on unit preference.
   * @param data The data to update.
   * @param unit The unit preference ('F', 'lbs', 'mmol/l', etc.).
   * @returns number The updated data.
   */
  updatePostInitPreference(data: number, unit: string) {
    // console.log(data, unit);
    const val = localStorage.getItem('userConfigurations');
    if (val) {
      const parseData = JSON.parse(val);
      if (parseData.UnitPreference === 'standard' && unit.toLowerCase() === 'f') {
        data = +this.FToC(data).toFixed(4);
      } else if (parseData.UnitPreference === 'standard' && unit.toLowerCase() === 'lbs') {
        data = +this.lbsToKg(data).toFixed(4);
      } else if (parseData.BloodGlucoseUnit === 'mmol/L' && unit.toLowerCase() === 'mmol/l') {
        data = +this.mmDlToOl(data).toFixed(4);
        //data = data;
      } else {
        data = data;
      }
    }
    return data;
  }

  /**
   * Updates data for initial preference based on user configurations.
   * @param data The data to update.
   * @param unit The unit preference ('F', 'lbs', 'kg', 'mmol/l', etc.).
   * @returns number The updated data rounded to one decimal place.
   */
  updateGetInitPreference(data: number, unit: string): number {
    const val = localStorage.getItem('userConfigurations');
    if (val) {
      const parseData = JSON.parse(val);
      if (parseData.UnitPreference === 'standard') {
        if (unit.toLowerCase() === 'f') {
          data = +this.CToF(data).toFixed(1);
        } else if (unit.toLowerCase() === 'lbs' || unit.toLowerCase() === 'kg') {
          data = +this.kgToLbs(data).toFixed(1);
        }
      } else if (parseData.BloodGlucoseUnit === 'mmol/L' && unit.toLowerCase() === 'mmol/l') {
        data = +this.mmOlToDl(data).toFixed(1);
      }
    }
    return parseFloat(data.toFixed(1));
  }
  /**
   * Converts unit based on user preferences stored in local storage.
   * @param value The unit value to convert ('C', 'kg', 'mg/dl', etc.).
   * @returns string The converted unit or original value.
   */
  unitConversion(value: string) {
    const data = localStorage.getItem('userConfigurations');
    let unit = '';
    if (data) {
      const parseData = JSON.parse(data);
      if (parseData.UnitPreference === 'standard' && value.toLowerCase() === 'c') {
        unit = 'F';
      } else if (parseData.UnitPreference === 'standard' && value.toLowerCase() == 'kg') {
        unit = 'lbs';
      } else if (parseData.BloodGlucoseUnit === 'mmol/L' && value.toLowerCase() == 'mg/dl') {
        unit = 'mg/dl';
      } else {
        unit = value;
      }
    }
    return unit;
  }
  /**
   * Converts unit post user preference based on stored configurations.
   * @param value The unit value to convert ('F', 'lbs', 'mmol/l', etc.).
   * @returns string The converted unit or original value.
   */
  unitPostConversion(value: string) {
    const data = localStorage.getItem('userConfigurations');
    let unit = '';
    if (data) {
      const parseData = JSON.parse(data);
      if (parseData.UnitPreference === 'standard' && value.toLowerCase() === 'f') {
        unit = 'C';
      } else if (parseData.UnitPreference === 'standard' && value.toLowerCase() == 'lbs') {
        unit = 'kg';
      } else if (parseData.BloodGlucoseUnit === 'mmol/L' && value.toLowerCase() == 'mmol/l') {
        unit = 'mg/dl';
      } else {
        unit = value;
      }
    }
    return unit;
  }
  /**
   * Retrieves the user's unit preference from local storage.
   * @returns boolean True if the unit preference is 'standard', false otherwise.
   */
  unitPreference() {
    const val = localStorage.getItem('userConfigurations');
    if (val) {
      const parseData = JSON.parse(val);
      const isUnitPreferenceStandard = parseData.UnitPreference === 'standard' ? true : false;
      return isUnitPreferenceStandard;
    }
    return false;
  }
  /**
   * Converts kilograms to pounds.
   * @param val The value in kilograms to convert.
   * @returns number The value converted to pounds.
   */
  kgToLbs(val: number) {
    return val * 2.20462;
  }
  /**
   * Converts pounds to kilograms.
   * @param val The value in pounds to convert.
   * @returns number The value converted to kilograms.
   */
  lbsToKg(val: number) {
    return val / 2.20462;
  }
  /**
   * Converts Celsius to Fahrenheit.
   * @param val The value in Celsius to convert.
   * @returns number The value converted to Fahrenheit.
   */
  CToF(val: number) {
    return (val * 9) / 5 + 32;
  }
  /**
   * Converts Fahrenheit to Celsius.
   * @param val The value in Fahrenheit to convert.
   * @returns number The value converted to Celsius.
   */
  FToC(val: number) {
    return ((val - 32) * 5) / 9;
  }
  /**
   * Converts millimoles per liter (mmol/L) to milligrams per deciliter (mg/dL).
   * @param val The value in mmol/L to convert.
   * @returns number The value converted to mg/dL.
   */
  mmDlToOl(val: number) {
    return val / 18.018;
  }
  /**
   * Converts milligrams per deciliter (mg/dL) to millimoles per liter (mmol/L).
   * @param val The value in mg/dL to convert.
   * @returns number The value converted to mmol/L.
   */
  mmOlToDl(val: number) {
    return val * 18.018;
  }
  /**
   * Converts centimeters (cm) to feet and inches.
   * @param cm The value in centimeters to convert.
   * @returns object An object containing feet, inches, and unit preference status.
   */
  cmToFeetAndInches(cm: number) {
    const val = localStorage.getItem('userConfigurations');
    if (val) {
      const parseData = JSON.parse(val);
      this.isUnitPreferenceStandard = parseData.UnitPreference === 'standard' ? true : false;
      //   return isUnitPreferenceStandard;
    }
    const cmPerInch = 2.54;
    const inchesPerFoot = 12;

    // Convert cm to total inches
    const totalInches = cm / cmPerInch;

    // Get the number of feet
    const feet = Math.floor(totalInches / inchesPerFoot);

    // Get the remaining inches
    const inches = totalInches % inchesPerFoot;

    return {
      feet: feet,
      // inches: Number(inches.toFixed(2)), // rounding to 2 decimal places for more precision
      inches: Math.round(inches), // round off
      isUnitPreferenceStandard: this.isUnitPreferenceStandard,
    };
  }
  /**
   * Converts feet and inches to centimeters (cm).
   * @param feet The value in feet.
   * @param inches The value in inches.
   * @returns number The value converted to centimeters.
   */
  feetAndInchesToCm(feet: number, inches: number): number {
    const cmPerInch = 2.54;
    const inchesPerFoot = 12;
    // Convert feet and inches to total inches
    const totalInches = feet * inchesPerFoot + Number(inches);
    // Convert total inches to cm
    const cm = totalInches * cmPerInch;
    return Math.round(Number(cm.toFixed(1)));
  }
}
/**
 * Interface representing data change information.
 */
export interface dataChange {
  /** Array of trends. */
  trends: [];
  /** Array of anomalies. */
  anomaly: [];
  /** Numeric value associated with the change. */
  value: number;
  /** Unit of measurement for the value. */
  unit: string;
}
/**
 * Interface representing trends information.
 */
export interface trendsInterface {
  /** Minimum value of the trend. */
  minValue: number;
  /** Maximum value of the trend. */
  maxValue: number;
  /** Average value of the trend. */
  avgValue: number;
}
/**
 * Interface representing anomaly information.
 */
export interface anomalyInterface {
  /** Lower bound of the anomaly. */
  lower: number;
  /** Upper bound of the anomaly. */
  upper: number;
  /** Value of the anomaly. */
  value: number;
}
