import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  WritableSignal,
  inject,
  signal,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ChatMessage } from '@azure/communication-chat';
import { CommunicationUserKind } from '@azure/communication-common';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription } from 'rxjs';
import { PermissionsService } from 'src/app/modules/permissions/services/permissions.service';
import { Contact } from 'src/app/modules/shared/interfaces/contact.entities';
import { ContactsService } from 'src/app/modules/shared/services/contacts.service';
import {
  FormattedIntentData,
  FormattedKeywordData,
  IntentKeywordData,
  IntentKeywordDialogData,
  Match,
} from '../../entities/intent-keyword.entities';
import {
  Attachment,
  ChatThreadMetaData,
  RecalledMessageData,
  RecommendedResources,
} from '../../entities/message.entities';
import { MessageAPIsService } from '../../services/message-apis.service';
import { MessageService } from '../../services/message.service';
import { CreateMessageThreadComponent } from '../create-message-thread/create-message-thread.component';
import { IntentKeywordDialogComponent } from '../intent-keyword-dialog/intent-keyword-dialog.component';
import { MessageAttachmentPreviewComponent } from '../message-attachment-preview/message-attachment-preview.component';
import { MessageConfirmationComponent } from '../message-confirmation/message-confirmation.component';
import { MessageReadByComponent } from '../message-read-by/message-read-by.component';
import { MessageRecommendedResourcesComponent } from '../message-recommended-resources/message-recommended-resources.component';
import { ViewRecalledMessageComponent } from '../view-recalled-message/view-recalled-message.component';

/**
 * Component responsible for displaying a single chat message with various functionalities.
 */
@Component({
  selector: 'app-message-ui',
  templateUrl: './message-ui.component.html',
  styleUrls: ['./message-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageUiComponent implements OnInit, OnDestroy, AfterViewInit {
  /**
   * Input property representing the chat message to be displayed.
   */
  @Input() chatMessage!: ChatMessage;
  /**
   * Input property representing the ID of the chat thread associated with the message.
   */
  @Input() threadId!: string;
  /**
   * Input property representing the subject for refreshing the message.
   */
  @Input() refreshMessage$!: Subject<string>;
  /**
   * Input property representing the mode of the component ('MyMessages' or 'MyTeamMessages').
   */
  @Input() public mode: 'MyMessages' | 'MyTeamMessages' = 'MyMessages';
  /**
   * Output event emitted when a new chat thread is created.
   */
  @Output() newThreadCreated: EventEmitter<ChatThreadMetaData> = new EventEmitter<ChatThreadMetaData>();
  /**
   * Communication user ID of the current user.
   */
  public communicationUserId = '';
  /**
   * User ID of the message sender.
   */
  public messageUserId = '';
  /**
   * Array of attachments associated with the chat message.
   */
  public attachments: Attachment[] = [];
  /**
   * Array of recommended resource IDs associated with the chat message.
   */
  public recommendedResources: string[] = [];
  /**
   * Contact information of the message sender.
   */
  public sender: Contact | null = null;
  /**
   * Flag indicating whether the user has write permission for chat messages.
   */
  public hasWritePermission = true;
  /**
   * Highest Severity of Intent. Default = -1 (No Intents)
   */
  #intentHighlightSeverity: WritableSignal<number> = signal(-1);
  /**
   * Array to store formatted Intent Data
   */
  #intents: FormattedIntentData[] = [];
  /**
   * Array to store formatted Keyword Data
   */
  #keywords: FormattedKeywordData[] = [];
  /**
   * Subscription to refresh message updates.
   */
  private refreshMessageSubscription$!: Subscription;
  /**
   * Injected instance of ContactsService for retrieving user contact information.
   */
  #contactsService = inject(ContactsService);

  /**
   * Constructor to initialize the component.
   * @param change Reference to the ChangeDetectorRef for change detection.
   * @param dialog Reference to the MatDialog for opening dialogs.
   * @param messageService Instance of MessageService for message-related operations.
   * @param messageAPIsService Instance of MessageAPIsService for message-related APIs.
   * @param permissions Instance of PermissionsService for permission management.
   * @param toast ToastrService instance for displaying toast notifications.
   * @param document Injected instance of the Document for DOM manipulation.
   */
  public constructor(
    private change: ChangeDetectorRef,
    private dialog: MatDialog,
    private messageService: MessageService,
    private messageAPIsService: MessageAPIsService,
    private permissions: PermissionsService,
    private toast: ToastrService,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  /**
   * Initializes message metadata, permissions, sender information, and subscribes to message refresh events.
   */
  public ngOnInit(): void {
    this.hasWritePermission = this.permissions.hasPermission('self.Chat.Write');
    this.communicationUserId = this.messageService.communicationUserId;
    this.messageUserId = (this.chatMessage.sender as CommunicationUserKind).communicationUserId;
    if (this.communicationUserId === this.messageUserId) {
      this.sender = {
        userImageUrl: this.messageService.userDemographicData.imageUrl,
        userAcsId: this.communicationUserId,
        userFullName: this.messageService.displayName,
        group: '',
        groupName: '',
        groupOwnerType: '',
        groupType: '',
        user: '',
        userGroupRelation: '',
        _id: '',
        myRelationToGroup: '',
        userType: 'Manager',
      };
    } else {
      this.sender = this.#contactsService.getUserFromACSId(this.messageUserId);
    }
    this.attachments = JSON.parse(this.chatMessage.metadata?.attachments || '[]');
    this.recommendedResources = JSON.parse(this.chatMessage.metadata?.recommendedResources || '[]');
    this.attachments.forEach((attachment: Attachment): void => {
      if (!attachment.mimeType) return;
      attachment.fileIcon = this.messageService.getFileIcon(attachment.mimeType, attachment.url) || '';
      if (attachment.mimeType.split('/')[0] === 'image') {
        attachment.fileIcon = '/assets/images/icons/image.svg';
      }
    });

    if (this.chatMessage?.metadata?.intent) {
      this.#processIntentKeywordData();
    }

    this.refreshMessageSubscription$ = this.refreshMessage$.subscribe((messageId: string): void => {
      if (messageId === this.chatMessage.id) this.change.markForCheck();
    });
  }
  /**
   * Lifecycle hook called after the component's view has been fully initialized.
   * Sets up event listeners for displaying tooltips on intent and keyword matches.
   */
  public ngAfterViewInit(): void {
    const links = this.document.querySelectorAll('.zxps-lnk-stp-bbblng');
    links.forEach((el): void => {
      el.addEventListener('click', (event) => {
        event.stopPropagation();
      });
    });
  }
  /**
   * Unsubscribes from all subscriptions to prevent memory leaks.
   */
  public ngOnDestroy(): void {
    this.refreshMessageSubscription$.unsubscribe();
  }
  /**
   * Opens a dialog to display users who have read the message.
   */
  public async openReadBy(): Promise<void> {
    this.dialog.open(MessageReadByComponent, {
      data: this.chatMessage,
      autoFocus: false,
      panelClass: 'full-screen-dialog',
    });
  }
  /**
   * Opens a confirmation dialog to recall the current message.
   */
  public recallMessage(): void {
    this.dialog.open(MessageConfirmationComponent, {
      data: {
        header: 'recallMessage',
        message: 'recallMessageConfirmation',
        function: 'recall',
        threadId: this.threadId,
        messageId: this.chatMessage.id,
        metadata: {
          attachments: this.chatMessage.metadata?.attachments || '[]',
          recallDate: new Date().toISOString(),
          alerts: this.chatMessage.metadata?.alerts || '[]',
          recommendedResources: this.chatMessage.metadata?.recommendedResources || '[]',
          readBy: this.chatMessage.metadata?.readBy || '[]',
          intent: this.chatMessage.metadata?.intent || '[]',
          keyword: this.chatMessage.metadata?.keyword || '[]',
        },
      },
    });
  }
  /**
   * Opens a preview of the message attachment.
   * @param attachment The attachment to preview.
   */
  public openAttachmentPreview(attachment: Attachment): void {
    this.dialog.open(MessageAttachmentPreviewComponent, {
      data: {
        selectedAttachment: attachment,
        attachmentList: this.attachments,
        threadId: this.threadId,
        messageId: this.chatMessage.id,
      },
    });
  }
  /**
   * Opens the recalled message dialog.
   * @returns A promise that resolves when the message is recalled.
   */
  public async openRecalledMessage(): Promise<void> {
    try {
      const recalledMessageData: RecalledMessageData = await this.messageService.getRecalledMessage(
        this.chatMessage.id,
      );
      recalledMessageData.sentDate = this.chatMessage.createdOn;
      this.dialog.open(ViewRecalledMessageComponent, {
        data: recalledMessageData,
      });
    } catch {
      this.toast.error('There was some issue while recalling this message. Please try again.', 'Error');
    }
  }
  /**
   * Opens a dialog to forward the current message to a new thread.
   */
  public forwardMessage(): void {
    const dialogRef: MatDialogRef<CreateMessageThreadComponent> = this.dialog.open(CreateMessageThreadComponent, {
      data: {
        message: this.chatMessage.content?.message,
        headerTitle: 'forwardMessage',
        initACS: false,
      },
      disableClose: true,
      autoFocus: false,
      panelClass: ['full-screen-dialog', 'create-message-dialog'],
    });
    dialogRef.afterClosed().subscribe((res: ChatThreadMetaData): void => {
      if (res) {
        this.newThreadCreated.emit(res);
      }
    });
  }
  /**
   * Opens a dialog to display recommended resources.
   * @returns A promise that resolves when recommended resources are retrieved.
   */
  public async openRecommendedResources(): Promise<void> {
    try {
      const recommendedResources: RecommendedResources = await this.messageAPIsService.retriveResourcesInformation(
        this.recommendedResources[0],
      );
      this.dialog.open(MessageRecommendedResourcesComponent, {
        panelClass: ['full-screen-dialog', 'recommended-resources-dialog'],
        data: recommendedResources,
      });
    } catch {
      this.toast.error('Unable to find Recommended Resources', 'Error');
    }
  }

  /**
   * Process Intent and Keyword Data
   * Called internally
   */
  #processIntentKeywordData(): void {
    const intentsAndKeywords: IntentKeywordData[] = JSON.parse(this.chatMessage.metadata!.intent);
    intentsAndKeywords.forEach((el: IntentKeywordData): void => {
      if (el.type === 'intent') {
        this.#intentHighlightSeverity.set(Math.max(this.#intentHighlightSeverity(), el.severity));
        this.#intents.push({
          severity: el.severity,
          severityClass: `servity-${el.severity}`,
          category: el.intent!,
          rationale: el.matches.map((match: Match) => match.rationale || ''),
        });
      } else if (el.type === 'keyword') {
        el.matches.forEach((match: Match): void => {
          this.#keywords.push({
            severity: el.severity,
            severityClass: `servity-${el.severity}`,
            category: el.keyword!,
            letterCount: match.text.split(' ').length,
            text: match.text,
          });
        });
      }
    });

    if (this.chatMessage.content?.message) {
      if (this.#intentHighlightSeverity() > -1) {
        this.#highlightIntents();
      }

      if (this.#keywords.length) {
        this.#highlightKeywords();
      }
    }
  }

  /**
   * Highlights intents in the chat message content and setup click listner
   * Called internally.
   */
  #highlightIntents(): void {
    this.chatMessage.content!.message = `<span class="zxps-hghlgt-intnt-${this.chatMessage.id} zxps-${this.#intentHighlightSeverity()} pointer">${this.chatMessage.content!.message}</span>`;
    setTimeout(() => {
      this.document.querySelector(`.zxps-hghlgt-intnt-${this.chatMessage.id}`)?.addEventListener('click', () => {
        this.#showIntentKeywordData();
      });
    }, 500);
  }

  /**
   * Highlights keywords in the chat message content and setup click listner
   * Called internally.
   */
  #highlightKeywords(): void {
    this.#keywords.sort((a: FormattedKeywordData, b: FormattedKeywordData): number => b.letterCount - a.letterCount);

    this.#keywords.forEach((el: FormattedKeywordData): void => {
      this.chatMessage.content!.message = this.chatMessage.content!.message!.replaceAll(
        new RegExp(el.text, 'gi'),
        (matchedText: string): string =>
          `<span class="zxps-hghlgt-kywrd-${this.chatMessage.id} zxps-${el.severity} pointer">${matchedText}</span>`, // Using random class names in order to avoid string match
      );
    });

    if (this.#intentHighlightSeverity() === -1) {
      setTimeout(() => {
        this.document.querySelectorAll(`.zxps-hghlgt-kywrd-${this.chatMessage.id}`).forEach((el): void => {
          el.addEventListener('click', (event) => {
            event.stopPropagation();
            this.#showIntentKeywordData();
          });
        });
      }, 500);
    }
  }

  /**
   * Open Intent Keyword Dialog
   * Called Internally
   */
  #showIntentKeywordData(): void {
    const data: IntentKeywordDialogData = {
      intents: this.#intents,
      keywords: this.#keywords,
    };

    const panelClass: string[] = ['intent-keyword-dialog'];
    if (this.#keywords.length && this.#intents.length === 0) {
      panelClass.push('dialog-panel-xs');
    } else {
      panelClass.push('dialog-panel-sm');
    }

    this.dialog.open(IntentKeywordDialogComponent, {
      autoFocus: false,
      disableClose: true,
      data,
      panelClass: ['full-screen-dialog'],
    });
  }
}
