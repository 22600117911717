import { Pipe, PipeTransform } from '@angular/core';
/**
 * A pure pipe that formats a date object or string into a formatted date string.
 * This pipe uses the default JavaScript `Date` object for formatting.
 */
@Pipe({
  name: 'dateTime',
  pure: true,
  standalone: true,
})
export class DateTimePipe implements PipeTransform {
  private currentDate = new Date();

  /**
   * Transform the given date into a formatted string based on the current date.
   * @param date - The date to be formatted. Accepts either a Date object or a string parsable by Date constructor.
   * @param separator - Separator used to separate date and time components. Include spaces as needed.
   * @returns Formatted date string, including "Today" or "Yesterday" if applicable, otherwise full date and time.
   */
  transform(date: string | Date, separator: string): string {
    const dateObj = new Date(date);
    const localeTime: string = dateObj.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

    if (
      dateObj.getMonth() === this.currentDate.getMonth() &&
      dateObj.getFullYear() === this.currentDate.getFullYear()
    ) {
      if (dateObj.getDate() === this.currentDate.getDate()) {
        return `Today${separator}${localeTime}`;
      } else if (dateObj.getDate() === this.currentDate.getDate() - 1) {
        return `Yesterday${separator}${localeTime}`;
      }
    }

    return `${dateObj.toLocaleDateString()}${separator}${localeTime}`;
  }
}
