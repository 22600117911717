import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HealthwisePreviewComponent } from './healthwise-preview.component';
import { FeatherModule } from 'angular-feather';
import { X } from 'angular-feather/icons';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [HealthwisePreviewComponent],
  imports: [CommonModule, FeatherModule.pick({ X }), MatDialogModule],
  exports: [HealthwisePreviewComponent],
})
export class HealthwisePreviewModule {}
