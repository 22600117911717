import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogComponent } from 'src/app/modules/shared/components/confirmation-dialog/confirmation-dialog.component';
import { PaginationOptions, ParamsOptions } from 'src/app/modules/shared/interfaces/pagination-options';
import { Utils } from 'src/app/modules/shared/utils/utils';
import { UsersService } from '../../users.service';
// import { SharedService } from 'src/app/modules/shared/services/';
import { AuthServiceService } from 'src/app/modules/authentication/services/auth-service.service';
import { PermissionsService } from 'src/app/modules/permissions/services/permissions.service';
import { FilterColumnOption } from 'src/app/modules/shared/interfaces/common.entities';
import { GenericTableColumn } from 'src/app/modules/shared/interfaces/generic-table';
import { FilterService } from 'src/app/modules/shared/services/filter.service';
import { filters, userListResponse } from 'src/app/modules/user-management/interfaces/users.interface';

/**
 * Represents a type describing clinical trial content groups.
 */
interface contentGrpClinicalTrialType {
  /**
   * Unique identifier for the clinical trial
   */
  id: string;
  /**
   * Name of the clinical trial
   */
  name: string;
}

/**
 * Represents a group with associated details.
 */
interface Group {
  /**
   * Group identifier or key
   */
  group: string;
  /**
   * Optional name of the group
   */
  groupName?: string;
  /**
   * Relationship or role within the group
   */
  relationToGroup: string;
}

/**
 * Represents an icon configuration for display.
 */
interface icon {
  /**
   * Name or identifier of the icon
   */
  iName: string;
  /**
   * Key associated with the icon
   */
  key: string;
  /**
   * CSS class for styling the icon
   */
  class: string;
  /**
   * Flag indicating if the icon should be displayed
   */
  show: boolean;
  /**
   * Route or link associated with the icon
   */
  route: string;
}

/**
 * Component to manage the user listing view.
 */
@Component({
  selector: 'app-users-listing',
  templateUrl: './users-listing.component.html',
  styleUrls: ['./users-listing.component.scss'],
})
export class UsersListingComponent implements OnInit, OnDestroy {
  /** Parameters for pagination and sorting */
  params: ParamsOptions = { limit: 20, page: 1, sort: 'name|DESC', filter: '' };
  /** Options for pagination */
  paginationOptions!: PaginationOptions;
  /** List of users */
  users = [];
  /** Selected filters */
  selectedFilter: Array<filters> = [];
  /** Columns to be displayed in the table */
  displayedColumns: Array<string> = [];
  /** Flag to show checkbox in the table */
  showCheckbox = false;
  /** Filter options for the users */
  filterOptions: any = [
    // { key: "", displayText: "All Users", displayKey: "allUsers" },
    {
      key: 'Active',
      displayText: 'Active users',
      displayKey: 'activeUsers',
    },
    {
      key: 'InActive',
      displayText: 'Inactive users',
      displayKey: 'inactiveUsers',
    },
  ];

  /** Columns configuration for the table */
  columns: Array<GenericTableColumn> = [
    {
      key: 'name',
      title: 'Name',
      displayKey: 'name',
      filterKey: 'users',
      searchKey: 'name',
      sort: true,
      search: { icon: true, active: false },
      filter: {
        icon: false,
        active: false,
      },
    },
    {
      key: 'userType',
      title: 'User Type',
      displayKey: 'userType',
      filterKey: 'userType',
      searchKey: 'userType',
      sort: true,
      search: { icon: false, active: false },
      filter: { icon: true, active: false },
    },
    {
      key: 'role',
      title: 'Role',
      displayKey: 'role',
      filterKey: 'roles',
      searchKey: 'role',
      sort: false,
      search: { icon: false, active: false },
      filter: { icon: true, active: false },
    },
    {
      key: 'userGroups',
      title: 'User Groups',
      displayKey: 'userGroups',
      filterKey: 'userGroups',
      searchKey: 'userGroups',
      sort: false,
      filter: { icon: true, active: false },
    },
    {
      key: 'contentGroups',
      title: 'Content Groups',
      displayKey: 'contentGroups',
      filterKey: 'contentGroups',
      searchKey: 'contentGroups',
      sort: false,
      search: { icon: false, active: false },
      filter: { icon: true, active: false },
    },
    // { key: 'status', title: 'Status', displayKey: 'status', filterKey: 'status',
    // searchKey:'status',sort: true },
    // {
    //   key: 'clinicalTrial',
    //   title: 'Clinical Trial',
    //   displayKey: 'clinicalTrial',
    //   filterKey: 'clinicalTrials',
    //   searchKey: 'clinicalTrial',
    //   sort: false,
    //   search: { icon: false, active: false },
    //   filter: { icon: true, active: false },
    // },
    {
      key: 'actions',
      title: 'Actions',
      displayKey: 'actions',
      hasLink: false,
      sort: false,
      icons: [
        {
          iName: 'edit',
          key: 'edit',
          class: '',
          show: false,
          route: '/userManagment/profile',
        },
        {
          iName: 'eye',
          key: 'view',
          class: '',
          show: true,
          route: '/userManagment/profile',
        },
        {
          iName: '',
          key: 'userStatus',
          class: '',
          show: this.permissionService.hasPermission('self.Management_User.Update'),
          route: '',
        },
        // {
        //   iName: "user-x", key: "userDeactivate", class:"", show: false, route: ""
        // },
        {
          iName: 'trash-2',
          key: 'delete',
          class: '',
          show: false,
          route: '',
        },
        {
          iName: 'settings',
          key: 'settings',
          class: '',
          show: false,
          route: '',
        },
      ],
    },
  ];

  /** Flag to enable filter */
  filterEnable = false;
  /** Client ID */
  clientID: string | null;
  /** User groups */
  usersGroup: any;
  /** User ID */
  uid: string | null = '';
  /** Used table */
  usedTable = 'User';
  /** Selected filter option */
  selectedFilterByOption = this.filterOptions[0];
  // searchList: any;
  /**
   * Search string for filtering templates.
   */
  search = '';
  /**
   * No ExternalId users
   */
  externalIdSearchUsers = false;

  /**
   * Represents a component/service constructor.
   * @param usersService - Service to handle user data.
   * @param _toastrService - Service to handle toastr notifications.
   * @param router - Router service for navigation.
   * @param route - ActivatedRoute to access route parameters.
   * @param dialog - MatDialog to handle dialog operations.
   * @param authService - Service to handle authentication.
   * @param permissionService - Service to handle permissions.
   * @param filterService - Service to handle filters.
   */
  constructor(
    private usersService: UsersService,
    private _toastrService: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private authService: AuthServiceService,
    private permissionService: PermissionsService,
    private filterService: FilterService,
  ) {
    this.clientID = this.authService.getClientId();
    if (this.clientID) {
      this.filterEnable = false;
    } else {
      this.filterEnable = true;
    }
  }

  /**
   * - Checks user configurations and modifies column display for ClinicalTrial client type.
   * - Initializes displayedColumns based on columns array.
   * - Sets default filter options.
   */
  ngOnInit(): void {
    //  this.params.filter = this.selectedFilterByOption.key ? `status|${this.selectedFilterByOption.key}` : "";
    // this.getUsers();
    const userConfigurations = JSON.parse(localStorage.getItem('userConfigurations') || '');

    if (userConfigurations?.clientType === 'ClinicalTrial') {
      const clinicalTrial = {
        key: 'clinicalTrial',
        title: 'Clinical Trial',
        displayKey: 'clinicalTrial',
        filterKey: 'clinicalTrials',
        searchKey: 'clinicalTrial',
        sort: false,
        filter: { icon: true, active: false },
      };
      this.columns.splice(5, 0, clinicalTrial).join();
    }
    this.displayedColumns = this.columns.map((column: GenericTableColumn) => {
      return column.title;
    });

    this.onChangeFilterOptions(this.filterOptions[0]);
  }
  // refresh() {
  //   this.sharedService.refreshSubject.next();
  // }

  /**
   * Handles filter option change.
   * @param __option - Selected filter option.
   */
  onChangeFilterOptions(__option: object) {
    if (__option) {
      this.resetPaginationOptions();
      this.selectedFilterByOption = __option;
      this.filterService.setAppliedFilters('status', this.selectedFilterByOption.key);
      this.params.filter = this.filterService.getAppliedFiltersParamString();
      this.getUsers();
    }
  }

  /**
   * Fetches users based on current parameters.
   */
  getUsers() {
    // this.params.filter=JSON.stringify(this.selectedFilter)
    for (const item of this.selectedFilter) {
      this.params.filter = this.params.filter + ',' + item.columnName + '|' + item.ids;
    }

    if (this.params.search == '') {
      delete this.params.search;
    }

    if (this.params.filter == '') {
      delete this.params.filter;
    }

    this.usersService.getUsers(this.params).subscribe((result) => {
      this.paginationOptions = result?.pagination;
      this.setPaginationOptions(result?.pagination);
      this.usersGroup = result?.data.map((userData: userListResponse) => {
        userData.actions = JSON.parse(
          JSON.stringify(this.columns[this.columns.map((column) => column.key).indexOf('actions')]),
        );
        userData.actions.icons[
          userData.actions.icons
            .map((icon: { iName: string; key: string; class: string; show: boolean; route: string }) => icon.key)
            .indexOf('userStatus')
        ].iName = userData?.status && userData?.status.toLowerCase() === 'active' ? 'user-x' : 'user-check';
        return this.createTableViewClients(userData);
      });
    });
  }

  // getUsersForSearchList(dialogRef, search:any, eve:any) {
  //   this.params.search = eve + '|' + search;

  //   //  this.params.filter=JSON.stringify(this.params.filter)
  //   this.usersService.getUsersOption(eve, this.params).subscribe((res) => {
  //     const searchList = res;
  //     dialogRef.componentInstance.searchList = searchList;
  //   });
  // }

  /**
   * Returns concatenated string of content group names.
   * @param data - Array of content group objects.
   * @returns Concatenated string of content group names.
   */
  getContentGroup(data: Array<contentGrpClinicalTrialType>) {
    let str = '';
    data.map((data: contentGrpClinicalTrialType) => {
      str += data.name + ', ';
    });
    return str.replace(/(^\s*,)|(,\s*$)/g, '');
  }

  /**
   * Retrieves concatenated user group names from the provided data array.
   * @param data Array of Group objects containing group names
   * @returns Concatenated string of group names
   */
  getUserGroup(data: Group[]) {
    let str = '';
    data.map((data: any) => {
      str += data.groupName + ', ';
    });
    return str.replace(/(^\s*,)|(,\s*$)/g, '');
  }

  /**
   * Retrieves concatenated clinical trial names from the provided data array.
   * @param data Array of contentGrpClinicalTrialType objects containing trial names
   * @returns Concatenated string of trial names
   */
  getClinicalTrials(data: Array<contentGrpClinicalTrialType>) {
    let str = '';
    data.map((data: contentGrpClinicalTrialType) => {
      str += data.name + ', ';
    });
    return str.replace(/(^\s*,)|(,\s*$)/g, '');
  }

  /**
   * Creates table view data for displaying user information.
   * @param user User object containing details to display
   * @returns Object with formatted table data
   */
  createTableViewClients(user: any) {
    //console.log(user)
    const tableData = {
      id: Utils.createGenericTableKeysData(user.id, 'userManagment/profile', {
        clientId: this.clientID,
        uid: user.id,
      }),
      name: Utils.createGenericTableKeysData(user.name ? user.name : '', '', {}),
      userType: Utils.createGenericTableKeysData(user.userType, '', {}),
      role: Utils.createGenericTableKeysData(
        Array.prototype.map
          .call(user.roles, function (item) {
            return item.label;
          })
          .join(', '),
        '',
        {},
      ),
      contentGroups: Utils.createGenericTableKeysData(this.getContentGroup(user.contentGroups), '', {}),
      userGroups: Utils.createGenericTableKeysData(this.getUserGroup(user.userGroups), '', {}),
      status: Utils.createGenericTableKeysData(user && user.status === 'InActive' ? 'Inactive' : 'Active', '', {}),
      clinicalTrial: Utils.createGenericTableKeysData(this.getClinicalTrials(user.clinicalTrials), '', {}),
      actions: user?.actions,
    };
    return tableData;
  }

  /**
   * Opens a confirmation dialog for deleting a user.
   * @returns void
   */
  deleteUser() {
    // id: string
    const confirmDialog = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Confirm remove user',
        message: 'Are you sure, you want to remove an user?',
      },
      panelClass: 'confirmation-dialog',
    });
    confirmDialog.afterClosed().subscribe((result) => {
      if (result === true) {
        this.router.navigateByUrl('/refresh', { skipLocationChange: true }).then(() => {
          this.router.navigate(['/user/list'], {
            queryParams: { clientId: this.clientID },
            queryParamsHandling: 'merge',
          });
        });
      }
    });
  }

  /**
   * Toggles the status of a user (Active/Inactive) based on event data.
   * Updates the user status via the usersService.
   * @param event Event containing user ID and current status
   * @returns void
   */
  toggleUserStatus(event: any) {
    if (event?.id?.value) {
      this.usersService
        .updateUser(
          { status: event?.status?.value && event?.status?.value.toLowerCase() === 'active' ? 'InActive' : 'Active' },
          event?.id?.value,
        )
        .subscribe(
          (response: any) => {
            if (response) {
              event.status.value =
                event?.status?.value && event?.status?.value.toLowerCase() === 'active' ? 'Inactive' : 'Active';
              event.actions.icons[event.actions.icons.map((icon: icon) => icon.key).indexOf('userStatus')].iName =
                event?.status?.value && event?.status?.value.toLowerCase() === 'active' ? 'user-x' : 'user-check';
              this._toastrService.success('User status changed successfully');
              //this.refresh();
            }
          },
          (error) => {
            if (error) {
              //console.log(error);
            }
          },
        );
    }
  }

  /**
   * Placeholder method for adding a user in access (description pending).
   */
  addUserInAccess() {}

  /**
   * Filters the message templates based on the search string.
   * @param event - The search string for filtering.
   */
  searchByID(event: string) {
    if (event == '') {
      // console.log('empty)
    } else {
      this.params.filter = this.filterService.getAppliedFiltersParamString();
      this.usersService.getSearchByUserIdExternalId(event).subscribe((result) => {
        if (result && result.length) {
          this.externalIdSearchUsers = false;
          this.params.filter = this.params.filter + ',users|' + result[0].id;
          this.getUsers();
        } else {
          this.externalIdSearchUsers = true;
          setTimeout(() => {
            this.externalIdSearchUsers = false;
          }, 1000);
        }
      });
    }
  }

  /**
   * Resets the search query and updates the filter parameters.
   */
  cancelSearch() {
    this.search = '';
    this.params.filter = this.filterService.getAppliedFiltersParamString();
    this.getUsers();
  }

  /**
   * Retrieves the default pagination options.
   */
  getDefaultPaginationOptions() {
    const paginationO = {
      limit: 20,
      currentPage: 1,
      totalCount: 0,
    };
    return paginationO;
  }

  /**
   * Sets sorting parameters based on the provided event and retrieves updated user data accordingly.
   * @param {any} event - The event object containing column and order information for sorting.
   */
  sortByColumn(event: any) {
    this.params.sort = `${event.column}|${event.order}`;
    this.getUsers();
  }
  /**
   * Sets pagination options based on provided values.
   * @param paginationO PaginationOptions object containing new values
   * @returns void
   */
  setPaginationOptions(paginationO: PaginationOptions) {
    this.paginationOptions = paginationO;
    this.params.limit = this.paginationOptions?.limit;
    this.params.page = this.paginationOptions?.currentPage;
  }
  /**
   * Resets pagination options to default values.
   * @returns PaginationOptions object with default values
   */
  resetPaginationOptions() {
    return this.getDefaultPaginationOptions();
  }

  // usersNextPage(event: any) {
  //   this.params.limit = event.limit;
  //   this.params.page = event.offset + 1;
  //   this.getUsers();
  // }
  /**
   * Refreshes user list based on pagination options.
   * @param event PaginationOptions object containing new offset and limit
   * @returns void
   */
  refreshUsers(event: PaginationOptions) {
    this.params.limit = event.limit;
    this.params.page = event.offset ? event?.offset + 1 : 1;
    this.setPaginationOptions(event);
    this.getUsers();
  }
  /**
   * Retrieves filter options for a specific column and updates filterService.
   * @param eve Event data containing column details
   * @param params Parameters for filtering options retrieval
   * @returns void
   */
  getFilterOptions(eve: any, params: ParamsOptions) {
    this.usersService.getUsersOption(eve.column.filterKey, params).subscribe((response) => {
      if (response) {
        this.filterService.filterOptions.next(response);
      }
    });
  }
  /**
   * Retrieves options based on keyword search and updates filterService.
   * @param $event Event data containing column and search keyword
   * @param dialog Optional dialog component for displaying search results
   * @param keyword Search keyword
   * @returns void
   */
  getOptionsByKeyword($event: any, dialog: any, keyword: string) {
    this.params.search = `${$event.column.searchKey}|${keyword}`;
    this.getOptionsByColumn($event, dialog);
  }
  /**
   * Retrieves options based on column filter and updates filterService.
   * @param $event Event data containing column details
   * @param dialog Optional dialog component for displaying filter options
   * @returns void
   */
  getOptionsByColumn($event: any, dialog?: any) {
    const tableName = 'user';
    const columnName =
      $event.type && $event.type.toLowerCase() === 'search' ? $event.column.searchKey : $event.column.filterKey;
    this.filterService.getOptionsByColumn(tableName, columnName, this.params, '', dialog, true);
  }
  /**
   * Clears applied filters and updates filterService.
   * @param $event Event data containing filter details
   * @returns void
   */
  clear($event: any) {
    //this is for clearing filter
    this.params.search = '';
    this.filterService.removeFiltersByKey($event.column.filterKey);
    this.filterService.toggleColumnActiveClass(this.columns, $event.column.key);
    this.params.filter = this.filterService.getAppliedFiltersParamString();
    this.getUsers();
  }
  /**
   * Opens search dialog for applying search filters.
   * @param $event Event data containing filter details
   * @returns MatDialogRef for the opened search dialog
   */
  openSearchDialog($event: any) {
    // this is to open search dialog
    $event.search = this.filterService.getAppliedFiltersByKey($event.column.filterKey)?.name || '';
    return this.filterService.openSearchFilterDialog($event);
  }
  /**
   * Opens filters dialog for applying column filters.
   * @param $event Event data containing filter details
   * @returns MatDialogRef for the opened filters dialog
   */
  openFiltersDialog($event: any) {
    // this is to open filter dialog
    this.params.search = '';
    this.getOptionsByColumn($event);
    return this.filterService.openSearchFilterDialog($event);
  }
  /**
   * Handles callback after closing search or filters dialog.
   * Updates applied filters and refreshes user list.
   * @param $event Event data containing filter details
   * @param response Response data from closed dialog
   * @returns void
   */
  dialogAfterClosedCallBack($event: any, response: any) {
    //this method gets called when dialog is closed
    this.filterService.setAppliedFilters($event.column.filterKey, response);
    this.filterService.toggleColumnActiveClass(this.columns, $event.column.key);
    this.params.filter = this.filterService.getAppliedFiltersParamString();
    this.setPaginationOptions({ page: 1, limit: 20, currentPage: 1 });
    this.getUsers();
  }
  /**
   * Opens search or filters dialog based on event type.
   * Handles search and filter processes including keyword search.
   * @param $event FilterColumnOption object containing dialog type and details
   * @returns void
   */
  openSearchAndFiltersDialog($event: FilterColumnOption) {
    // main method in  the filter and search process
    let dialog: any;
    if ($event && $event.type) {
      if ($event.type.toLowerCase() === 'search') {
        dialog = this.openSearchDialog($event);
      }
      if ($event.type.toLowerCase() === 'filter') {
        dialog = this.openFiltersDialog($event);
      }
      dialog.componentInstance.searchKeyUp.subscribe((keyword: string) => {
        this.getOptionsByKeyword($event, dialog, keyword);
      });
      dialog.componentInstance.clear.subscribe({
        next: (flag: boolean) => {
          if (flag) {
            this.clear($event);
          }
        },
      });
      dialog.afterClosed().subscribe({
        next: (response: unknown) => {
          if (response) {
            this.dialogAfterClosedCallBack($event, response);
          }
        },
      });
    }
  }

  /**
   * Resets filters applied via filterService.
   * @returns void
   */
  ngOnDestroy() {
    // this is to reset filters
    this.filterService.resetFilters();
  }
}
