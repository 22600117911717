<div class="hs-tab-card-title flex-between">
  <h2>{{ 'Filter' | translate }}</h2>
  <div class="dialog-header-icons">
    <i-feather name="check" class="pointer" (click)="onApplyFilter()" mat-dialog-close></i-feather>
    <i-feather name="rotate-ccw" class="pointer" (click)="onResetFilter()" mat-dialog-close></i-feather>
    <i-feather name="x" class="pointer" mat-dialog-close></i-feather>
  </div>
</div>

<form>
  @if (clincalTrailFilter.length !== 0) {
    <div>
      <div class="form-group form-group-mat-select">
        <label for="" class="form-label">{{ 'clinicalTrial' | translate }}</label>
        <mat-form-field class="md-mat-select">
          <mat-select
            [formControl]="clinicalTrailForm"
            disableRipple
            panelClass="filter-select-panel"
            (selectionChange)="onClinicalTrialSelect()"
          >
            <mat-option value="">{{ 'all' | translate }}</mat-option>
            @for (clinicalTrial of clincalTrailFilter; track clinicalTrial) {
              <mat-option [value]="clinicalTrial.id">{{ clinicalTrial.name }} </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
      <!-- <div class="form-group form-group-mat-select">
      <label for="" class="form-label">Sites</label>
      <mat-form-field class="md-mat-select">
        <mat-select [formControl]="toppings" disableRipple multiple panelClass="filter-select-panel">
          <mat-option *ngFor="let topping of toppingList" [value]="topping">{{topping}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div> -->
      <div class="form-group form-group-mat-select">
        <label for="" class="form-label">{{ 'participantGroup' | translate }}</label>
        <mat-form-field class="md-mat-select">
          <mat-select [formControl]="participantGroupForm" disableRipple panelClass="filter-select-panel">
            <mat-option value="">{{ 'all' | translate }}</mat-option>
            @for (pGroup of participantGroups; track pGroup) {
              <mat-option [value]="pGroup.id">{{ pGroup.name }} </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  }
  @if (clincalTrailFilter.length === 0) {
    <div>
      <div class="form-group form-group-mat-select">
        <label for="" class="form-label">{{ 'userGroups' | translate }}</label>
        <mat-form-field class="md-mat-select">
          <mat-select
            [formControl]="userGroupsForm"
            panelClass="filter-select-panel"
            (selectionChange)="onUserGroupSelect()"
          >
            <mat-option value="">{{ 'all' | translate }}</mat-option>
            @for (userGroup of userGroups; track userGroup) {
              <mat-option [value]="userGroup.group">{{ userGroup.groupName }} </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
      <div class="form-group form-group-mat-select">
        <label for="" class="form-label">{{ 'managedRoles' | translate }}</label>
        <mat-form-field class="md-mat-select">
          <mat-select [formControl]="managedRolesForm" disableRipple panelClass="filter-select-panel">
            <mat-option value="">{{ 'all' | translate }}</mat-option>
            @for (managedRole of managedRoles; track managedRole) {
              <mat-option [value]="managedRole.id">{{ managedRole.label }} </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  }
</form>
