import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  OOOBusinessHours,
  OutOfOffice,
} from 'src/app/modules/settings/communication/components/interfaces/out-of-office';
import { ConfirmationDialogComponent } from 'src/app/modules/shared/components/confirmation-dialog/confirmation-dialog.component';
import { TranslatePipe } from 'src/app/modules/shared/pipes/translate.pipe';
import { HttpService } from 'src/app/modules/shared/services/http.service';
import { environment } from 'src/environments/environment';
import { PermissionsService } from '../../permissions/services/permissions.service';
/**
 * Service to handle communication-related operations.
 */
@Injectable({
  providedIn: 'root',
})
export class CommunicationService {
  /**
   * The base URL of the API host.
   */
  apiUrl: string = environment.url.apiHost;
  /**
   * The version of the API.
   */
  apiVersion: string = environment.url.version;

  /**
   * Tracks changes in calendar settings.
   */
  public calendarChanges = {
    outOfOffice: {
      hasChanges: false,
    },
    businessHours: {
      hasChanges: false,
    },
  };

  /**
   * Constructs the CommunicationService.
   * @param httpService - The HTTP service for making API requests.
   * @param translatePipe - The translation pipe for handling localization.
   * @param dialog - The MatDialog service for displaying dialogs.
   * @param permissionsService - The service for managing user permissions.
   */
  constructor(
    private httpService: HttpService,
    private translatePipe: TranslatePipe,
    private dialog: MatDialog,
    private permissionsService: PermissionsService,
  ) {}

  /**
   * Retrieves a message template from the server.
   * @param path - API path to retrieve the message template.
   * @returns Observable<any> - The HTTP response.
   */
  public getMessageTemplate(path: string) {
    const url = `${this.apiUrl}${this.apiVersion}${path}`;
    return this.httpService.get(url);
  }

  /**
   * Retrieves replacement data from the server.
   * @param path - The API path to retrieve replacement data.
   * @returns Observable<any> - The HTTP response.
   */
  public getReplacement(path: string) {
    const url = `${this.apiUrl}/${this.apiVersion}${path}`;
    return this.httpService.get(url);
  }

  /**
   * Retrieves message template details from the server.
   * @param path - The API path to retrieve message template details.
   * @param id - The ID of the message template.
   * @returns Observable<any> - The HTTP response.
   */
  public getMessageTemplateDetails(path: string, id: string) {
    const url = `${this.apiUrl}/${this.apiVersion}${path}/${id}`;
    return this.httpService.get(url);
  }

  /**
   * Deletes a specific message template from the server.
   * @param path - The API path to delete the message template.
   * @param id - The ID of the message template to delete.
   * @returns Observable<any> - The HTTP response.
   */
  public deleteMessageTemplateDetails(path: string, id: string) {
    const url = `${this.apiUrl}/${this.apiVersion}${path}/${id}`;
    return this.httpService.delete(url);
  }

  /**
   * Adds a new message template to the server.
   * @param path - The API path to add the message template.
   * @param body - The data of the message template to add.
   * @returns Observable<any> - The HTTP response.
   */
  public addMessageTemplate(path: string, body: object) {
    const url = `${this.apiUrl}/${this.apiVersion}${path}`;
    return this.httpService.post(url, body);
  }

  /**
   * Updates an existing message template on the server.
   * @param path - The API path to update the message template.
   * @param id - The ID of the message template to update.
   * @param body - The updated data of the message template.
   * @returns Observable<any> - The HTTP response.
   */
  public patchMessageTemplate(path: string, id: string, body: object) {
    const url = `${this.apiUrl}/${this.apiVersion}${path}/${id}`;
    return this.httpService.patch(url, body);
  }

  /**
   * Retrieves manager user group data from the server.
   * @returns Observable<any> - The HTTP response.
   */
  public getManagerUserGroup() {
    const url = `${this.apiUrl}/${this.apiVersion}${`/notificationv3/subscription`}`;
    return this.httpService.get(url);
  }

  /**
   * Updates notification settings for a manager.
   * @param body - The updated notification settings data.
   * @returns Observable<any> - The HTTP response.
   */
  public updateNotificationSettingManager(body: any) {
    const url = `${this.apiUrl}/${this.apiVersion}${`/notificationv3/subscription`}`;
    return this.httpService.patch(url, body);
  }

  /**
   * Retrieves notification settings for a specific patient.
   * @param patientId - The ID of the patient to retrieve settings for.
   * @returns Observable<any> - The HTTP response.
   */
  public getNotificationSettings(patientId: string) {
    const url = patientId
      ? `${this.apiUrl}/${this.apiVersion}/notificationv3/user/` + patientId
      : `${this.apiUrl}/${this.apiVersion}/notificationv3`;
    //  const url = `${this.apiUrl}/${this.apiVersion}/notificationv3/user/` + patientId;
    return this.httpService.get(url);
  }

  /**
   * Updates notification settings for a specific patient.
   * @param patientId - The ID of the patient to update settings for.
   * @param body - The updated notification settings data.
   * @returns Observable<any> - The HTTP response.
   */
  public updateNotificationSettings(patientId: string, body: any) {
    const url = patientId
      ? `${this.apiUrl}/${this.apiVersion}/notificationv3/user/` + patientId
      : `${this.apiUrl}/${this.apiVersion}/notificationv3`;
    return this.httpService.patch(url, body);
  }

  /**
   * Retrieves out-of-office settings from the server.
   * @returns Observable<any> - The HTTP response.
   */
  getOutOfOffice() {
    const url = `${this.apiUrl}/${this.apiVersion}/outOfOffice`;
    return this.httpService.get(url);
  }

  /**
   * Updates out-of-office settings on the server.
   * @param body - The updated out-of-office settings.
   * @returns Observable<any> - The HTTP response.
   */
  updateOutOfOffice(body: OutOfOffice) {
    const url = `${this.apiUrl}/${this.apiVersion}/outOfOffice`;
    return this.httpService.patch(url, body);
  }

  /**
   * Resets default out-of-office settings on the server.
   * @returns Observable<any> - The HTTP response.
   */
  resetDefaultOOOSettings() {
    const url = `${this.apiUrl}/${this.apiVersion}/outOfOffice/reset`;
    return this.httpService.patch(url);
  }

  /**
   * Retrieves out-of-office business hours from the server.
   * @returns Observable<any> - The HTTP response.
   */
  getOOOBusinessHours() {
    const url = `${this.apiUrl}/${this.apiVersion}/outOfBusinessHours`;
    return this.httpService.get(url);
  }

  /**
   * Updates out-of-office business hours on the server.
   * @param body - The updated out-of-office business hours.
   * @returns Observable<any> - The HTTP response.
   */
  updateOOOBusinessHours(body: OOOBusinessHours) {
    const url = `${this.apiUrl}/${this.apiVersion}/outOfBusinessHours`;
    return this.httpService.patch(url, body);
  }

  /**
   * Resets default out-of-office business hours settings on the server.
   * @returns Observable<any> - The HTTP response.
   */
  resetDefaultOOOBusinessHoursSettings() {
    const url = `${this.apiUrl}/${this.apiVersion}/outOfBusinessHours/reset`;
    return this.httpService.patch(url);
  }

  /**
   * Opens a confirmation dialog.
   * @param data - Data for the confirmation dialog (title, message).
   * @returns MatDialogRef<ConfirmationDialogComponent> - Reference to the opened dialog.
   */
  openOOOConfirmDialog(data: { title: string; message: string }) {
    const confirmDialog = this.dialog.open(ConfirmationDialogComponent, {
      data: data,
      panelClass: 'confirmation-dialog',
      disableClose: true,
    });
    return confirmDialog;
  }

  /**
   * Checks if a given date is less than or equal to today.
   * @param date - The date to compare.
   * @param min - The minimum date to compare against (default is today).
   * @returns boolean - True if the date is less than or equal to today, false otherwise.
   */
  dateIsLessThanToday(date: string, min = new Date()) {
    if (date) {
      return new Date(new Date(date).setHours(0, 0, 0, 0)).getTime() >= new Date(min.setHours(0, 0, 0, 0)).getTime()
        ? true
        : false;
    }
    return false;
  }

  /**
   * Validates if there are pending calendar changes.
   * @returns boolean - True if there are pending changes, false otherwise.
   */
  validateCalendarChanges() {
    let valid = false;
    if (!this.calendarChanges.outOfOffice.hasChanges && !this.calendarChanges.businessHours.hasChanges) {
      valid = false;
    } else {
      valid = true;
    }
    return valid;
  }

  /**
   * Resets calendar change flags to false.
   */
  resetCalendarChanges() {
    this.calendarChanges = {
      outOfOffice: {
        hasChanges: false,
      },
      businessHours: {
        hasChanges: false,
      },
    };
  }

  /**
   * Checks if a specific notification channel is enabled for the user.
   * @param value - The notification channel to check.
   * @returns boolean - True if the notification channel is enabled, false otherwise.
   */
  notificationCheckedChannelValue(value: string) {
    const userConfigurations = this.permissionsService.getUserConfigurations();
    if (value in userConfigurations.notification && userConfigurations.notification[value] === true) {
      return true;
    } else {
      return false;
    }
    //return true;
  }
}
