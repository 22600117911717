import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { EventInput } from '@fullcalendar/core';
import { Dictionary } from '@fullcalendar/core/internal';
import { RRule, RRuleStrOptions } from 'rrule';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthServiceService } from 'src/app/modules/authentication/services/auth-service.service';
import {
  FrequencyOptions,
  IdFullName,
  MyCalendar,
  RRuleSetting,
  WeekOptions,
} from 'src/app/modules/calendar/entities/calendar-entities';
import { HttpService } from 'src/app/modules/shared/services/http.service';
import { Utils } from 'src/app/modules/shared/utils/utils';
import { environment } from 'src/environments/environment';
import { ContactsService } from '../../shared/services/contacts.service';

/**
 * Injectable service for managing calendar-related operations.
 */
@Injectable({
  providedIn: 'root',
})
export class CalendarService {
  /**
   * Subject for triggering calendar refresh events.
   */
  public calendarRefresh$: BehaviorSubject<{ refresh: boolean; delete?: { id: string | null } }> = new BehaviorSubject<{
    refresh: boolean;
    delete?: { id: string | null };
  }>({ refresh: false, delete: { id: null } });

  /**
   * Subject for managing do not disturb setting.
   */
  public doNotDisturb$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  /**
   * Base URL for API endpoints.
   */
  private baseUrl: string = environment.url.apiHost;
  /**
   * API version used in API endpoints.
   */
  private apiVersion: string = environment.url.version;
  /**
   * Default duration for events in minutes.
   */
  private defaultDuration = 15;
  /**
   * Array of frequency options.
   */
  private frequencyOptions = FrequencyOptions;
  /**
   * Array of week options.
   */
  private weekOptions = WeekOptions;

  /**
   * Constructs an instance of CalendarService.
   * @constructor
   * @param {HttpService} httpService - The HTTP service used for API requests.
   * @param {AuthServiceService} authService - The authentication service for user authentication.
   * @param {ContactsService} contactsService - The service for managing contacts.
   * @param {FormBuilder} fb - The form builder service for creating reactive forms.
   */
  constructor(
    private httpService: HttpService,
    private authService: AuthServiceService,
    private contactsService: ContactsService,
    private fb: FormBuilder,
  ) {}

  /**
   * Fetches events from the API.
   * @returns Observable<any> - Observable of fetched events.
   */
  fetchEvents() {
    const url = `${this.baseUrl}${this.apiVersion}/calendarV3/user`;
    return this.httpService.get(url);
  }

  /**
   * Fetches events for a specific user within a date range.
   * @param userId - The ID of the user.
   * @param from - Start date for fetching events.
   * @param to - End date for fetching events.
   * @returns Observable<any> - Observable of fetched events.
   */
  fetchEventsByUserId(userId: string, from: string, to: string) {
    const url = `${this.baseUrl}${this.apiVersion}/calendarV3/user/${userId}`;
    const params = { params: { fromDate: from, toDate: to } };
    return this.httpService.get(url, params);
  }

  /**
   * Fetches video lobby events for a specific user within a date range.
   * @param userId - The ID of the user.
   * @param from - Start time for fetching events.
   * @param to - End time for fetching events.
   * @returns Observable<any> - Observable of fetched video lobby events.
   */
  fetchVideoLobbyEventsByUserId(userId: string, from: string, to: string) {
    const url = userId
      ? `${this.baseUrl}${this.apiVersion}/videolobby/user/${userId}`
      : `${this.baseUrl}${this.apiVersion}/videolobby`;
    const params = { params: { startTime: from, endTime: to } };
    return this.httpService.get(url, params);
  }

  /**
   * Retrieves the do not disturb setting from the calendar API.
   * @returns Observable<any> - Observable of the do not disturb setting.
   */
  getDoNotDisturbSetting() {
    const url = `${this.baseUrl}${this.apiVersion}/calendarV3/doNotDisturb`;
    return this.httpService.get(url);
  }

  /**
   * Adds a video lobby event via POST request to the API.
   * @param body - The event data to add.
   * @returns Observable<unknown[]> - Observable of the response.
   */
  addVideoLobbyEvent(body: EventInput) {
    const url = `${this.baseUrl}${this.apiVersion}/videolobby`;
    return this.httpService.post(url, body);
  }

  /**
   * Updates a video lobby event via PATCH request to the API.
   * @param id - The ID of the event to update.
   * @param body - The updated event data.
   * @returns Observable<unknown[]> - Observable of the response.
   */
  updateVideoLobbyEvent(id: string, body: EventInput): Observable<unknown[]> {
    const url = `${this.baseUrl}${this.apiVersion}/videolobby/room/${id}`;
    return this.httpService.patch(url, body);
  }

  /**
   * Deletes a video lobby event via DELETE request to the API.
   * @param id - The ID of the event to delete.
   * @returns Observable<unknown[]> - Observable of the response.
   */
  deleteVideoLobbyEvent(id: string): Observable<unknown[]> {
    const url = `${this.baseUrl}${this.apiVersion}/videolobby/room/${id}`;
    return this.httpService.delete(url);
  }

  /**
   * Adds an event via POST request to the calendar API.
   * @param body - The event data to add.
   * @returns Observable<unknown[]> - Observable of the response.
   */
  addEvent(body: EventInput): Observable<unknown[]> {
    const url = `${this.baseUrl}${this.apiVersion}/calendarV3`;
    return this.httpService.post(url, body);
  }

  /**
   * Updates an event via PATCH request to the calendar API.
   * @param id - The ID of the event to update.
   * @param body - The updated event data.
   * @returns Observable<unknown[]> - Observable of the response.
   */
  updateEvent(id: string, body: EventInput): Observable<unknown[]> {
    const url = `${this.baseUrl}${this.apiVersion}/calendarV3/${id}`;
    return this.httpService.patch(url, body);
  }

  /**
   * Deletes an event via DELETE request to the calendar API.
   * @param id - The ID of the event to delete.
   * @returns Observable<unknown[]> - Observable of the response.
   */
  delete(id: string): Observable<unknown[]> {
    const url = `${this.baseUrl}${this.apiVersion}/calendarV3/${id}`;
    return this.httpService.delete(url);
  }

  /**
   * Toggles the do not disturb setting via PUT request to the calendar API.
   * @param body - The new do not disturb setting.
   * @returns Observable<unknown[]> - Observable of the response.
   */
  toggleDoNotDisturb(body: { doNotDisturb: boolean }) {
    const url = `${this.baseUrl}${this.apiVersion}/calendarV3/doNotDisturb`;
    return this.httpService.put(url, body);
  }

  /**
   * Sets the user's calendars in local storage.
   * @param myCalendars - Array of user's calendars.
   */
  setMyCalendars(myCalendars: MyCalendar[]) {
    if (myCalendars.length > 0) {
      localStorage.setItem('myCalendars', JSON.stringify(myCalendars));
    }
  }

  /**
   * Retrieves the user's calendars from local storage.
   * @returns MyCalendar[] | null - Array of user's calendars or null if not found.
   */
  getMyCalendars() {
    const myCalendars = localStorage.getItem('myCalendars');
    return myCalendars ? JSON.parse(myCalendars) : null;
  }

  /**
   * Retrieves the list of contacts from the ContactsService.
   * @returns Observable<any> - Observable of contacts list.
   */
  getContacts() {
    return this.contactsService.contactList();
  }

  /**
   * Retrieves the client ID from the authentication service.
   * @returns string - The client ID of the logged-in user.
   */
  getClientId() {
    return this.authService.getClientId();
  }

  /**
   * Retrieves the logged-in user's ID from the authentication service.
   * @returns string - The user ID of the logged-in user.
   */
  getLoggedInUserId() {
    return this.authService.getUserId();
  }

  /**
   * Retrieves the logged-in user's ACS ID from the authentication service.
   * @returns string - The ACS ID of the logged-in user.
   */
  getLoggedInUserAcsId() {
    return this.authService.getUserAcsId();
  }

  /**
   * Retrieves the logged-in user's username from the authentication service.
   * @returns string - The username of the logged-in user.
   */
  getLoggedInUserName() {
    return this.authService.getUserName();
  }

  /**
   * Retrieves the full name of the logged-in user from the authentication service.
   * @returns string - The full name of the logged-in user.
   */
  getLoggedInUserFullName() {
    return this.authService.getLoggedInUserFullName();
  }

  /**
   * Retrieves the demographic information of the logged-in user from the authentication service.
   * @returns any - The demographic information of the logged-in user.
   */
  getUserDemographic() {
    return this.authService.getUserDemographic();
  }

  /**
   * Retrieves the user configurations from the authentication service.
   * @returns any | null - The user configurations as JSON object, or null if not available.
   */
  getUserConfigurations() {
    const configurations = this.authService.getUserConfiguration();
    return configurations ? JSON.parse(configurations) : null;
  }

  /**
   * Retrieves the appointment time slot duration from user configurations.
   * @returns number | null - The appointment time slot duration in minutes, or null if not available.
   */
  getAppointmentTimeSlotDuration() {
    const configurations = this.getUserConfigurations();
    if (Number(configurations?.appointmentTimeSlot) && !isNaN(configurations?.appointmentTimeSlot)) {
      return Number(configurations?.appointmentTimeSlot);
    }
    return null;
  }

  /**
   * Calculates the duration between two dates in minutes.
   * @param start - The start date/time.
   * @param end - The end date/time.
   * @returns number - The duration in minutes, or 0 if start or end is null.
   */
  getEventDuration(start: Date | string | null, end: Date | string | null) {
    if (start && end) {
      const duration = Math.floor(new Date(end).getTime() - new Date(start).getTime()) / 1000 / 60;
      return duration;
    }
    return 0;
  }

  /**
   * Retrieves the RRule string representation from RRule settings.
   * @param rRuleSetting - Partial RRule options.
   * @returns string - The RRule string representation, or empty string if rRuleSetting is empty.
   */
  getRRuleString(rRuleSetting: Partial<RRuleStrOptions>) {
    return rRuleSetting && Object.keys(rRuleSetting)?.length ? new RRule(rRuleSetting).toText() : '';
  }

  /**
   * Retrieves the index of a frequency option based on its key.
   * @param freq - The frequency key.
   * @returns number - The index of the frequency option, or lowercase freq if not found.
   */
  getFrequencyIndex(freq: string) {
    if (freq) {
      const findIndex = this.frequencyOptions.map((freq) => freq.key.toLowerCase()).indexOf(freq.toLowerCase());
      if (findIndex >= 0) {
        return this.frequencyOptions[findIndex].index;
      }
    }
    return freq ? freq.toLowerCase() : 3;
  }

  /**
   * Retrieves weekday indexes from an array of weekday values.
   * @param byWeekDay - Array of weekday values.
   * @returns number[] - Array of weekday indexes.
   */
  getByWeekDayIndexes(byWeekDay: Array<string>) {
    return byWeekDay?.length
      ? this.weekOptions.filter((o) => byWeekDay.includes(o.pValue)).map((day) => day.value.weekday)
      : [];
  }
  /**
   * Converts a UTC date string to local timezone.
   * @param date - UTC date string to convert.
   * @returns string - Local timezone date string.
   */
  convertUTCToLocal(date: string) {
    return Utils.convertUTCToLocal(date);
  }
  /**
   * Converts a local date to UTC timezone.
   * @param date - Local date to convert.
   * @returns Date - UTC timezone date object.
   */
  convertLocalToUTC(date: Date | string) {
    return Utils.convertLocalToUTC(new Date(date));
  }

  /**
   * Checks if two dates represent the same calendar date.
   * @param start - Start date (optional, defaults to current date).
   * @param end - End date (optional, defaults to current date).
   * @returns boolean - True if the dates represent the same calendar date, otherwise false.
   */
  isSameDate(start: Date = new Date(), end: Date = new Date()) {
    return Utils.isSameDate(start, end);
  }

  /**
   * Checks if a date is in the past.
   * @param date - Date to check (optional, defaults to current date).
   * @returns boolean - True if the date is in the past, otherwise false.
   */
  isPastDate(date: Date = new Date()) {
    return Utils.isPastDate(date);
  }

  /**
   * Returns the start of the calendar date for the given date.
   * @param date - Date (optional, defaults to current date).
   * @returns Date - Start of the calendar date.
   */
  startOfDate(date: Date = new Date()) {
    return Utils.startOfDate(date);
  }

  /**
   * Returns the end of the calendar date for the given date.
   * @param date - Date (optional, defaults to current date).
   * @returns Date - End of the calendar date.
   */
  endOfDate(date: Date = new Date()) {
    return Utils.endOfDate(date);
  }

  /**
   * Returns the next calendar date from the given date.
   * @param date - Date (optional, defaults to current date).
   * @returns Date - Next calendar date.
   */
  getNextDate(date: Date = new Date()) {
    return Utils.getNextDate(date);
  }

  /**
   * Returns the nearest rounded event start date based on the default duration.
   * @param date - Date (optional, defaults to current date).
   * @returns Date - Nearest rounded event start date.
   */
  getEventStartDate(date: Date = new Date()) {
    return Utils.getNearestRoundedDateByDuration(date, this.defaultDuration);
  }

  /**
   * Returns the event end date based on the default duration from the start date.
   * @param date - Start date of the event.
   * @returns Date - Event end date.
   */
  getEventEndDate(date: Date) {
    const start = Utils.isValidDate(date) ? new Date(date) : this.getEventStartDate();
    return Utils.addMinutes(start, this.defaultDuration);
  }

  /**
   * Returns attendees grouped by user type.
   * @param attendees - List of attendees.
   * @returns { coOwner: IdFullName[], member: IdFullName[] } - Grouped attendees.
   */
  getAttendeesGroupByUserType(attendees: IdFullName[]) {
    const attendeesO: { coOwner: IdFullName[]; member: IdFullName[] } = { coOwner: [], member: [] };
    if (attendees?.length) {
      attendees.forEach((attendant: IdFullName) => {
        attendant.user = attendant.id;
        if (this.getLoggedInUserId() === attendant.user) {
          attendant.acsId = this.getLoggedInUserAcsId() || '';
        } else {
          attendant.acsId = this.contactsService.getContactFromUserId(attendant.user)?.userAcsId || '';
        }
        if (attendant?.id) {
          this.contactsService.contactList().find((c) => c.user === attendant.user);
          if (!attendant.hasOwnProperty('fullName') && attendant.firstName) {
            attendant.fullName = `${attendant.firstName} ${attendant.lastName}`;
          }
          if (attendant?.userType?.toLowerCase() === 'managed') {
            attendeesO.member.push(attendant);
          } else {
            attendeesO.coOwner.push(attendant);
          }
        }
      });
    }
    return attendeesO;
  }

  /**
   * Constructs and returns details of an event from a dictionary object.
   * @param event - Event details in dictionary format.
   * @returns Dictionary - Constructed event details.
   */
  getEventDetails(event: Dictionary) {
    if (event) {
      const postEventO = Object.assign({});
      postEventO.id = event && event.id ? event.id : null;
      postEventO.allDay = event && event.allDay ? event.allDay : false;
      postEventO.attendees = event && event.attendees && event.attendees.length > 0 ? event.attendees : [];
      postEventO.organizer =
        event && event.organizer && event.organizer.id
          ? event.organizer
          : { id: this.getLoggedInUserId(), fullName: this.getLoggedInUserFullName() || '' };
      postEventO.calendarType =
        event && event.roomId
          ? 'Virtual Visit'
          : event.calendarType
            ? event.calendarType
            : event && event.type
              ? event.type
              : '';
      postEventO.roomId = event && event.roomId ? event.roomId : undefined;
      postEventO.eventStart = event && event.eventStart ? event.eventStart : '';
      postEventO.eventEnd = event && event.eventEnd ? event.eventEnd : '';
      postEventO.duration = (event && event.duration) || this.getEventDuration(event.eventStart, event.eventEnd);
      postEventO.location = event && event.location ? event.location : '';
      postEventO.notes = event && event.notes ? event.notes : '';
      postEventO.recurring = event && event.recurring ? event.recurring : false;
      postEventO.isVirtualVisit =
        event && (event?.calendarType?.replace(/\s+/g, '')?.toLowerCase() === 'virtualvisit' || event.roomId)
          ? true
          : false;
      postEventO.reminder = event && event.reminder ? event.reminder : event && !event.reminder ? false : true;
      postEventO.rruleSetting = event && event.recurring ? this.getRRuleSettings(event.rruleSetting) : {};
      postEventO.title = event && event.title ? event.title : event.eventTitle ? event.eventTitle : '';
      return postEventO;
    }
  }

  /**
   * Retrieves RRule settings from the provided RRuleSetting object.
   * @param rRuleSetting - RRuleSetting object.
   * @returns RRuleSetting - Processed RRuleSetting object.
   */
  getRRuleSettings(rRuleSetting: RRuleSetting) {
    const rRuleSettingO = {
      freq: rRuleSetting && rRuleSetting.freq ? rRuleSetting.freq : 'DAILY',
      dtstart: rRuleSetting && rRuleSetting.dtstart ? Utils.convertUTCToLocal(rRuleSetting.dtstart) : null,
      until: rRuleSetting && rRuleSetting.until ? Utils.convertUTCToLocal(rRuleSetting.until) : null,
      interval: rRuleSetting && rRuleSetting.interval ? rRuleSetting.interval : 1,
      byweekday: rRuleSetting && rRuleSetting.byweekday ? rRuleSetting.byweekday : [],
      bymonth: rRuleSetting && rRuleSetting.bymonth ? rRuleSetting.bymonth : [],
      bymonthday: rRuleSetting && rRuleSetting.bymonthday ? rRuleSetting.bymonthday : [],
      bysetpos: rRuleSetting && rRuleSetting.bysetpos ? rRuleSetting.bysetpos : null,
      eachOption: rRuleSetting && rRuleSetting.bysetpos ? 'onTheOption' : 'eachOption',
      onTheOption: rRuleSetting && rRuleSetting.bysetpos && rRuleSetting.freq.toLowerCase() === 'yearly' ? true : false,
      onTheWeekOption: rRuleSetting && rRuleSetting.bysetpos && rRuleSetting?.byweekday?.length ? true : false,
    };
    return rRuleSettingO;
  }

  /**
   * Constructs a form group for adding or editing an event.
   * @returns FormGroup - Form group for adding or editing an event.
   */
  getAddEditEventForm() {
    return this.fb.group({
      title: ['', Validators.required],
      eventStart: ['', Validators.required],
      eventEnd: ['', Validators.required],
      calendarType: ['Appointment'],
      isVirtualVisit: [false],
      recurring: [false],
      allDay: [false],
      reminder: [true],
      custom: [false],
      location: [''],
      keywords: ['', [Validators.required]],
      organizer: [{ id: this.getLoggedInUserId(), fullName: this.getLoggedInUserFullName() }],
      attendees: this.fb.array([]),
      notes: [''],
      rOption: ['doesNotRepeat', Validators.required],
      untilOption: ['never'],
      untilDate: [''],
      rruleSetting: this.getRRuleSettingFormGroup(),
    });
  }

  /**
   * Constructs a form group for configuring RRule settings.
   * @param rRuleSetting - Optional RRuleSetting object to initialize form values (default: null).
   * @returns FormGroup - Form group for configuring RRule settings.
   */
  getRRuleSettingFormGroup(rRuleSetting?: RRuleSetting) {
    return this.fb.group({
      /**
       * Frequency of recurrence (default: 'DAILY').
       */
      freq: [rRuleSetting?.freq || 'DAILY'],

      /**
       * Interval between occurrences (default: 1).
       * Validators: required, minimum value of 1.
       */
      interval: [rRuleSetting?.interval || 1, [Validators.required, Validators.min(1)]],

      /**
       * Number of occurrences (default: 1).
       */
      count: [rRuleSetting?.count || 1],

      /**
       * Start date for recurring events (default: '').
       */
      dtstart: [rRuleSetting?.dtstart || ''],

      /**
       * End date for recurring events (default: '').
       */
      until: [rRuleSetting?.until || ''],

      /**
       * Positional specifier for recurring events (default: null).
       */
      bysetpos: [null],

      /**
       * Array of weekdays for recurring events (default: empty array).
       */
      byweekday: this.fb.array(rRuleSetting?.byweekday ? rRuleSetting?.byweekday : []),

      /**
       * Array of months for recurring events (default: empty array).
       */
      bymonth: this.fb.array(rRuleSetting?.bymonth ? rRuleSetting?.bymonth : []),

      /**
       * Array of month days for recurring events (default: empty array).
       */
      bymonthday: this.fb.array(rRuleSetting?.bymonthday ? rRuleSetting?.bymonthday : []),

      /**
       * Option for each occurrence in recurrence.
       */
      eachOption: ['eachOption'],

      /**
       * Option for 'on the' occurrence in yearly frequency.
       */
      onTheOption: [false],

      /**
       * Week options for recurring events.
       */
      onTheWeekOption: [],
    });
  }
}
