import { Injectable } from '@angular/core';
/**
 * Service to manage user permissions based on user configurations stored in localStorage.
 * Provides methods to retrieve user permissions and check access rights.
 */
@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  /**
   * Retrieves user configurations stored in localStorage.
   * @returns User configurations if available, otherwise returns null.
   */
  getUserConfigurations() {
    const userConfigurations = localStorage.getItem('userConfigurations');
    return userConfigurations ? JSON.parse(userConfigurations) : null;
  }

  /**
   * Retrieves all user permissions from user configurations.
   * @returns User permissions if available, otherwise returns null.
   */
  getUserPermissions() {
    return this.getUserConfigurations() && this.getUserConfigurations().permissionSettings;
  }

  /**
   * Retrieves self-specific permissions from user configurations.
   * @returns Array of self-specific permissions, or an empty array if not available.
   */
  getSelfPermissions() {
    return (this.getUserConfigurations() && this.getUserConfigurations().permissionSettings?.self) || [];
  }

  /**
   * Retrieves permissions for others (non-self) from user configurations.
   * @returns Array of others-specific permissions, or an empty array if not available.
   */
  getOthersPermissions() {
    return (this.getUserConfigurations() && this.getUserConfigurations().permissionSettings?.others) || [];
  }

  /**
   * Retrieves scopes from an array of permissions.
   * @param permissions Array of permissions to extract scopes from.
   * @returns Array of scopes extracted from the permissions.
   */
  getPermissionsScopes(permissions: IPermission[]) {
    let scopes: string[] = [];
    if (permissions) {
      scopes = permissions.map((permission) => permission.scope);
    }
    return scopes;
  }

  /**
   * Checks if the current user has a specific permission.
   * @param permission The permission to check in the format 'scope.permission'.
   * @returns True if the user has the permission, false otherwise.
   */
  hasPermission(permission: string) {
    let permissionAccess = false;
    let permissionsList: IPermission[] = [];
    if (permission) {
      const permissionsA = permission.split('.');
      if (permissionsA.length) {
        if (permissionsA[0].toLowerCase() === 'self') {
          permissionsList = this.getSelfPermissions();
        } else if (permissionsA[0].toLowerCase() === 'ai') {
          permissionsList = this.getAIPermissionSettings();
        } else {
          permissionsList = this.getOthersPermissions();
        }
        const scopes = this.getPermissionsScopes(permissionsList);
        if (scopes?.length && scopes.indexOf(permissionsA[1]) >= 0) {
          if (permissionsList[scopes.indexOf(permissionsA[1])]?.permissions.indexOf(permissionsA[2]) >= 0) {
            permissionAccess = true;
          }
        }
      }
    }
    return permissionAccess;
  }

  /**
   * Retrieves AI-specific permission settings from user configurations.
   * @returns Array of AI-specific permissions.
   */
  private getAIPermissionSettings(): IPermission[] {
    const permissionList: IPermission[] = [];
    if (this.getUserConfigurations().aiSettings.genAiPatientSummarization) {
      permissionList.push({
        scope: 'PatientSummarization',
        label: 'Patient Summarization',
        permissions: ['Read'],
      });
    }
    if (this.getUserConfigurations().aiSettings.genAiSuggestedHcpReplies) {
      permissionList.push({
        scope: 'SuggestedHcpReplies',
        label: 'Suggested Hcp Replies',
        permissions: ['Read'],
      });
    }
    return permissionList;
  }
}

/**
 * Interface for defining permissions with labels, permissions array, and scope.
 */
export interface IPermission {
  /**
   * Optional label for the permission.
   */
  label?: string;

  /**
   * Array of permissions associated with the scope.
   */
  permissions: Array<string>;
  /**
   * Scope identifier for the permission.
   */
  scope: string;
}
