import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe to format plain text messages by converting URLs into clickable links.
 */
@Pipe({
  name: 'formatMessage',
  standalone: true,
})
export class FormatMessagePipe implements PipeTransform {
  /**
   * Transforms a plain text message into HTML with clickable links.
   * @param message The message string to transform.
   * @returns The transformed HTML string.
   */
  transform(message: string | undefined): string {
    if (!message) return '';
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return message.replace(
      urlRegex,
      (url) => `<a href="${url}" target="_blank" class="zxps-lnk-stp-bbblng">${url}</a>`,
    );
  }
}
