<div class="dialog">
  <div class="hs-tab-card-title flex-between">
    <h2>{{ 'Filter' | translate }}</h2>

    <div class="dialog-header-icons">
      <i-feather name="rotate-ccw" class="pointer" (click)="reset()"></i-feather>
      <i-feather name="check" class="pointer" (click)="clickOnCheckMark()"></i-feather>
      <i-feather name="x" class="pointer" mat-dialog-close></i-feather>
    </div>
  </div>
  <mat-dialog-content class="mat-dialog-content">
    <form name="eventFilterForm" #eventFilterForm="ngForm">
      <div class="form-check-flex">
        <input
          type="checkbox"
          class="form-check-input"
          id="appointment"
          [(ngModel)]="options.appointment"
          [ngModelOptions]="{ standalone: true }"
        />
        <label class="check-flex-label" for="appointment">{{ 'appointment' | translate }}</label>
      </div>
      <div class="form-check-flex">
        <input
          type="checkbox"
          class="form-check-input"
          id="virtualVisit"
          [(ngModel)]="options.virtualVisit"
          [ngModelOptions]="{ standalone: true }"
        />
        <label class="check-flex-label" for="virtualVisit">{{ 'virtualVisit' | translate }}</label>
      </div>
    </form>
  </mat-dialog-content>
</div>
