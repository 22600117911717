<div class="dialog-lg" *ngIf="healthwiseData$ | async as data">
  <div class="hs-tab-card-title flex-between">
    <h2>{{ data.title }}</h2>
    <span class="dialog-header-icons">
      <i-feather mat-dialog-close name="x" class="pointer" aria-label="Close"></i-feather>
    </span>
  </div>
  <mat-dialog-content>
    <div *ngIf="data.type !== 'hwVideo'" [innerHTML]="data.html"></div>
    <video class="healthwise-video" *ngIf="data.type === 'hwVideo'" autoplay controls>
      <source [src]="data.media?.sources?.mp4_720p_url" />
    </video>
  </mat-dialog-content>
</div>
