<div class="dialog-sm">
  <div class="hs-tab-card-title flex-between">
    <h2 *ngIf="eventDetails && eventDetails.title; else hasNoData">{{ eventDetails.title }}</h2>

    <div class="dialog-header-icons">
      @if (!disableEditing()) {
        <i-feather name="edit-2" class="pointer" *appPermissions="'self.Calendar.Update'" (click)="edit()"></i-feather>
      }
      <i-feather name="x" class="pointer" mat-dialog-close></i-feather>
    </div>
  </div>
  <mat-dialog-content class="mat-dialog-content">
    <div class="hs-cardslist list-col-2 edit-event">
      <div
        class="list-col-2-item"
        *ngIf="eventDetails?.calendarType === 'Virtual Visit' || eventDetails.isVirtualVisit"
      >
        <p class="form-check-flex video-visit">
          <input type="checkbox" class="form-check-input" id="video-call" checked disabled />
          <label for="video-call">{{ 'videoVisit' | translate }}</label>
          <!-- <i-feather name="video" class="feather-20 clr-secondary pointer ml-2"></i-feather> -->
        </p>
        <!-- <p class="detail-value">
          <a href="https://medocity.siteportal.com/pvk-dvoy-fkd">https://medocity.siteportal.com/pvk-dvoy-fkd</a>
        </p> -->
      </div>

      <div class="list-col-2-item">
        <p class="detail-label">{{ 'start' | translate }}:</p>
        <p class="detail-value ff-bold" *ngIf="eventDetails?.eventStart; else hasNoData">
          {{ eventDetails.eventStart | date: dtFormat }}
        </p>
      </div>
      <div class="list-col-2-item">
        <p class="detail-label">{{ 'end' | translate }}:</p>
        <p class="detail-value ff-bold" *ngIf="eventDetails?.eventEnd; else hasNoData">
          {{ eventDetails.eventEnd | date: dtFormat }}
        </p>
      </div>
      <div class="list-col-2-item">
        <p class="detail-label">{{ 'duration' | translate }}:</p>
        <p class="detail-value ff-bold" *ngIf="eventDetails?.duration; else hasNoData">
          {{ eventDetails.duration }}
        </p>
      </div>
      <div class="list-col-2-item">
        <p class="detail-label">{{ 'attendees' | translate }}:</p>
        <div class="detail-value">
          <div *ngIf="eventDetails.attendees?.member?.length <= 0 && eventDetails.attendees?.coOwner?.length <= 0">
            -
          </div>
          <div *ngIf="eventDetails.attendees?.member?.length">
            <span class="clr-disabled">{{ 'managed' | translate }}</span>
            <span class="block-chips bg-lightRed">
              <span *ngFor="let member of eventDetails.attendees.member">
                <span class="item text-capitalize">{{ member.fullName }}</span>
              </span>
            </span>
          </div>
          <div *ngIf="eventDetails.attendees?.coOwner?.length" class="mb-0">
            <span class="clr-disabled">{{ 'manager' | translate }}</span>
            <span class="block-chips bg-lightVoilet">
              <span *ngFor="let coOwner of eventDetails.attendees.coOwner">
                <span class="item text-capitalize">{{ coOwner.fullName }}</span>
              </span>
            </span>
          </div>
        </div>
      </div>

      <div class="list-col-2-item align-items-center">
        <p class="detail-label">{{ 'organizer' | translate }}:</p>
        <p class="detail-value" *ngIf="eventDetails?.organizer?.id; else hasNoData">
          <span class="block-chips bg-lightGreen m-0">
            <span class="item">{{ eventDetails.organizer.fullName }}</span>
          </span>
        </p>
      </div>

      <!-- <div class="list-col-2-item">
        <p class="detail-label">{{ 'Managed' | clientLabel }}:</p>
        <p class="detail-value ff-bold">{{'mauriceCruz' | translate}}</p>
      </div> -->
      <div class="list-col-2-item" *ngIf="!eventDetails?.isVirtualVisit">
        <p class="detail-label">{{ 'location' | translate }}:</p>
        <p class="detail-value" *ngIf="eventDetails?.location; else hasNoData">
          {{ eventDetails.location }}
        </p>
      </div>
      <!-- <div class="list-col-2-item">
        <p class="detail-label">{{'Type' | translate}}:</p>
        <p class="detail-value ff-bold">{{'virtualVisit' | translate}}</p>
      </div> -->
      <div class="list-col-2-item">
        <p class="detail-label">{{ 'notes' | translate }}:</p>
        <p class="detail-value" *ngIf="eventDetails?.notes; else hasNoData">
          {{ eventDetails.notes }}
        </p>
      </div>
    </div>
  </mat-dialog-content>
</div>
<ng-template #hasNoData>-</ng-template>
