import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NotesComponent } from '../component/notes.component';
import { NotesCommonRequest } from '../notes.entities';
/**
 * Component for displaying a dialog to manage notes.
 * Provides functionality to save notes via NotesComponent.
 */
@Component({
  selector: 'app-notes-dialog',
  templateUrl: './notes-dialog.component.html',
  styleUrls: ['./notes-dialog.component.scss'],
})
export class NotesDialogComponent {
  /**
   * Reference to NotesComponent for accessing its methods.
   */
  @ViewChild(NotesComponent) notesComponent: NotesComponent | undefined;
  /**
   * Constructs the NotesDialogComponent.
   * @param dialog MatDialog service for opening dialogs.
   * @param data Data passed into the dialog, containing notes management information.
   * @param dialogRef Reference to the dialog itself for closing it programmatically.
   */
  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public data: NotesCommonRequest,
    public dialogRef: MatDialogRef<NotesDialogComponent>,
  ) {}

  /**
   * Saves the notes using NotesComponent if available.
   * Calls the saveNotes method of NotesComponent.
   */
  saveNotes() {
    if (this.notesComponent) this.notesComponent.saveNotes();
  }
}
