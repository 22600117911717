<div class="roles-modal">
  <div class="managed_user mb-4">
    <div class="preferences-title-bar pb-3 d-flex justify-content-between pr-2">
      <h2>{{ groupData?.name }}: {{ groupData.owner?.name }}</h2>
      <span>
        <i-feather name="x" class="ml-2" mat-dialog-close></i-feather>
      </span>
    </div>

    <h3 class="pb-3">{{ 'managing1' | translate }}</h3>
    <div class="form-group search-input mb-4">
      <input type="text" class="form-control" [(ngModel)]="search" placeholder="Search" />
      <i-feather name="search" class="feather-20 clr-disabled"></i-feather>
    </div>
    <div class="scrolling_sec">
      <div class="form-check" *ngFor="let user of userData | filter: search">
        <input #checkboxes type="checkbox" id="{{ user.id }}" (change)="getUserData(user, $event)" />
        <label>{{ user.name }}</label>
      </div>
      <div class="norecord" *ngIf="userData.length === 0">{{ 'noRecordFound' | translate }}</div>
    </div>
  </div>

  <div class="btn-group">
    <button type="button" class="btn btn-secondary" (click)="onConfirmClick()">{{ 'cancel' | translate }}</button>
    <button type="button" class="btn btn-primary px-3" (click)="onDoneClick()">{{ 'done' | translate }}</button>
  </div>
</div>
