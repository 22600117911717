<footer class="footer">
  <div class="status">
    <span
      ><a target="_blank" href="https://medocity.statuspage.io">{{ 'status' | translate }}</a></span
    ><span class="color-dot" [ngClass]="getStatusMaintenance()"></span>
  </div>
  <div class="copyright-text">
    <span class="footer-logo">
      <img class="footer-web-logo" src="assets/images/logo/footer-logo.png" alt="Medocity" />
      <img class="footer-mobile-logo" src="assets/images/logo/small/medocity.svg" alt="Medocity"
    /></span>
    <!-- | {{ (isDashboardRoute ? 'dashboardFooterText' : 'footerText') | translate }}  -->
    {{ 'copyrightC' | translate }} {{ year }} {{ 'allRightsReserved' | translate
    }}<span class="copyright-border">|</span> {{ 'versionAbbriviation' | translate }}{{ appVersion }}
  </div>
</footer>
