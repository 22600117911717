<div class="confirmation-dialog">
  <div class="hs-tab-card-title flex-between">
    @if (data.title) {
      <h2>{{ data.title }}</h2>
    }
    <div class="dialog-header-icons">
      <i-feather name="x" class="pointer" [mat-dialog-close]="false"></i-feather>
    </div>
  </div>
  <mat-dialog-content>
    <p class="text-center mb-0">{{ data.message }}</p>
    <div class="btn-group btn-center pt-2 pb-0">
      <button class="btn btn-secondary" type="button" [mat-dialog-close]="false">
        {{ data && data.noButtonLabel ? (data.noButtonLabel | translate) : ('cancel' | translate) }}
      </button>
      <button class="btn btn-primary" type="button" [mat-dialog-close]="true">
        {{
          data.acceptButtonText
            ? (data.acceptButtonText | translate)
            : data && data.yesButtonLabel
              ? (data.yesButtonLabel | translate)
              : ('ok' | translate)
        }}
      </button>
    </div>
  </mat-dialog-content>
</div>
