import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { map, Observable } from 'rxjs';
import { ConfirmationDialogComponent } from 'src/app/modules/shared/components/confirmation-dialog/confirmation-dialog.component';
import { HealthwisePreviewComponent } from 'src/app/modules/shared/components/healthwise-preview/healthwise-preview.component';
import { RecommendedResources, ResourceData } from '../../entities/message.entities';
import { MessageAPIsService } from '../../services/message-apis.service';

/**
 * Component to display recommended resources associated with a message.
 */
@Component({
  selector: 'app-message-recommended-resources',
  templateUrl: './message-recommended-resources.component.html',
  styleUrls: ['./message-recommended-resources.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageRecommendedResourcesComponent implements OnInit {
  /**
   * Observable array containing ResourceData objects for recommended resources.
   */
  public resourceData$: Observable<ResourceData>[] = [];

  /**
   * Constructor to initialize the component.
   * @param dialog MatDialog service for opening dialogs.
   * @param messageAPIsService Instance of MessageAPIsService for fetching resource data.
   * @param data Data object containing recommended resources.
   */
  public constructor(
    private dialog: MatDialog,
    private messageAPIsService: MessageAPIsService,
    @Inject(MAT_DIALOG_DATA) public data: RecommendedResources,
  ) {}

  /**
   * Fetches resource data for each resource ID provided in the input data.
   */
  public ngOnInit(): void {
    this.data.resources.forEach((id: string): void => {
      this.resourceData$.push(
        this.messageAPIsService.getResourceData(id).pipe(
          map((res: ResourceData) => {
            if (!res.resource.imageUrl) {
              if (res.resource.contentType === 'Url') res.resource.mimetype = 'globe';
              else if (res.resource.contentType === 'HealthWise') res.resource.mimetype = 'healthwise';
              else if (res.resource.mimetype === 'application/pdf') res.resource.mimetype = 'pdf';
              else if (res.resource.mimetype.split('/')[0] === 'video') res.resource.mimetype = 'video';
              else if (res.resource.mimetype.split('/')[0] === 'audio') res.resource.mimetype = 'volume-2';
              else if (res.resource.mimetype.split('/')[0] === 'image') res.resource.imageUrl = res.resource.contentUrl;
              else res.resource.mimetype = 'file';
            }
            return res;
          }),
        ),
      );
    });
  }

  /**
   * Opens a dialog or navigates to a URL based on the content type of the resource.
   * @param contentType Type of content ('Url', 'FileUpload', 'HealthWise').
   * @param data URL or data related to the content.
   */
  public clickedViewNow(contentType: 'Url' | 'FileUpload' | 'HealthWise', data: string): void {
    if (contentType === 'HealthWise') {
      this.dialog.open(HealthwisePreviewComponent, {
        data,
      });
    } else if (contentType === 'Url') {
      const dialogRef: MatDialogRef<ConfirmationDialogComponent> = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          title: 'Confirm',
          message:
            'This will take you to an external site responsible for its own content. Are you sure you want to continue?',
        },
      });
      dialogRef.afterClosed().subscribe((res: boolean): void => {
        if (res) {
          window.open(data, '_blank');
        }
      });
    } else {
      window.open(data, '_blank');
    }
  }
}
