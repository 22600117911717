<mat-dialog-content>
  <div class="section-multi-links">
    <ul>
      <li class="pointer" routerLink="/dashboard" mat-dialog-close>
        <i-feather name="dashboard" class="feather-20"></i-feather>
        <span>{{ 'dashboard' | translate }}</span>
      </li>
    </ul>
    <ul>
      <li (click)="openCalendar()" class="pointer" *appPermissions="'self.Calendar.Read'" mat-dialog-close>
        <i-feather name="calendar" class="feather-20"></i-feather>
        <span>{{ 'calendar' | translate }}</span>
      </li>
      <li (click)="openMessages()" class="pointer" *appPermissions="'self.Chat.Read'" mat-dialog-close>
        <i-feather name="mail" class="feather-20"></i-feather>
        <span>{{ 'Messages' | translate }}</span>
      </li>
      <li
        (click)="openVideoLobby()"
        class="pointer"
        *appPermissions="'self.VideoLobby.Read'"
        [ngClass]="{ disabled: videoLobbyService.pipMode() }"
        mat-dialog-close
      >
        <i-feather name="video" class="feather-20"></i-feather>
        <span>{{ 'videoLobby' | translate }}</span>
      </li>
    </ul>
    <ul>
      <li class="pointer" *appPermissions="'self.Management_User.Read'" routerLink="/userManagment" mat-dialog-close>
        <i-feather name="user" class="feather-20"></i-feather>
        <span>{{ 'user' | translate }} {{ 'management' | translate }}</span>
      </li>

      <li
        class="pointer"
        *appPermissions="'self.Management_UserGroup.Read'"
        routerLink="/groupManagment"
        mat-dialog-close
      >
        <i-feather name="users" class="feather-20"></i-feather>
        <span>{{ 'userGroup' | translate }} {{ 'management' | translate }}</span>
      </li>

      <!-- <li (click)="openContacts()" class="pointer">
        <i-feather name="users" class="feather-20"></i-feather>
        <span
          >{{ 'contacts' | translate }} <em>{{ 'inProgress' | translate }}</em></span
        >
      </li> -->
    </ul>
    <ul>
      <li class="pointer" routerLink="/support" mat-dialog-close>
        <i-feather name="headphones" class="feather-20"></i-feather>
        <span>{{ 'techSupport' | translate }}</span>
      </li>
      <li class="pointer" *appPermissions="'self.Invitation.Read'" routerLink="/invitation" mat-dialog-close>
        <i-feather name="invitation" class="feather-20"></i-feather>
        <span>{{ 'userInvitation' | translate }}</span>
      </li>
      <li class="pointer" (click)="navigateRoute('auditTrial')" mat-dialog-close>
        <i-feather name="audit-trail" class="feather-20 active"></i-feather>
        <span>{{ 'auditTrail' | translate }}</span>
      </li>
    </ul>
    <ul>
      <li class="pointer" (click)="navigateRoute('calendar')" mat-dialog-close>
        <i-feather name="settings" class="feather-20"></i-feather>
        <span>{{ 'settings' | translate }}</span>
      </li>
      <!-- <li (click)="openChatAssistant()" class="pointer" *appPermissions="'self.Chat.Read'" mat-dialog-close>
        <i-feather name="message-circle" class="feather-20"></i-feather>
        <span>{{ 'Chat Assistant (AI)' | translate }}</span>
      </li> -->
      <!-- <li class="flex-between" (click)="openSettingDropdown()">
        <p>
          <i-feather name="settings" class="feather-20"></i-feather>
          <span>{{ 'settings' | translate }}</span>
        </p>
        <i-feather name="{{ settingDropdown ? 'chevron-up' : 'chevron-down' }}" class="pointer" class="feather-20"> </i-feather>
      </li>
      <ul *ngIf="settingDropdown">
        <li class="flex-column">
          <div class="list-header" (click)="openCommunicationDropdown()">
            <span>{{ 'communication' | translate }}</span>
            <i-feather class="pointer feather-20" name="{{ communicationDropdown ? 'chevron-up' : 'chevron-down' }}"></i-feather>
          </div>
          <ul *ngIf="communicationDropdown">
            <li (click)="navigateRoute('calendar')">{{ 'calendar' | translate }}</li>
            <li (click)="navigateRoute('communicationTemplates')">{{ 'communicationTemplates' | translate }}</li>
            <li (click)="navigateRoute('disclaimers')">{{ 'disclaimers' | translate }}</li>
            <li (click)="navigateRoute('messageTemplates')">{{ 'messageTemplates' | translate }}</li>
            <li class="mb-0" (click)="navigateRoute('notificationSettings')">
              {{ 'notificationSettings' | translate }}
            </li>
          </ul>
        </li>
        <li class="flex-column">
            <div class="list-header" (click)="openManagedUserCareDropdown()">
              <span>{{ 'managedUserCare' | translate }}</span>
              <i-feather class="pointer feather-20" name="{{ managedUserCareDropdown ? 'chevron-up' : 'chevron-down' }}"></i-feather>
            </div>
            <ul *ngIf="managedUserCareDropdown">
              <li (click)="navigateRoute('alerts')">{{ 'alerts' | translate }}</li>
              <li (click)="navigateRoute('careCoordination')">{{ 'careCoordination' | translate }}</li>
              <li (click)="navigateRoute('intents')">{{ 'intents' | translate }}</li>
              <li (click)="navigateRoute('pro')">{{ 'pro' | translate }}</li>
              <li (click)="navigateRoute('resources')">{{ 'resources' | translate }}</li>
              <li (click)="navigateRoute('notification')">{{ 'notificationSettings' | translate }}</li>
            </ul>
          </li>
          <li class="flex-column">
            <div class="list-header" (click)="openSystemDropdown()">
              <span>{{ 'system' | translate }}</span>
              <i-feather class="pointer feather-20" name="{{ systemDropdown ? 'chevron-up' : 'chevron-down' }}"></i-feather>
            </div>
            <ul *ngIf="systemDropdown">
              <li (click)="navigateRoute('ai/ml')">{{ 'aiMl' | translate }}</li>
              <li (click)="navigateRoute('businessAnalytics')">{{ 'businessAnalytics' | translate }}</li>
              <li (click)="navigateRoute('general')">{{ 'general' | translate }}</li>
              <li (click)="navigateRoute('security')">{{ 'security' | translate }}</li>
            </ul>
          </li>  
        <li (click)="navigateRoute('auditTrial')">{{ 'auditTrail' | translate }}</li>
      </ul>

       <li class="flex-between" routerLink="/userManagment">
        <p>
          <i-feather name="mail" class="feather-20"></i-feather>
          <span mat-dialog-close>{{ 'userManagement' | translate }}</span>
        </p>
      </li>
      <li class="flex-between" routerLink="/groupManagment">
        <p>
          <i-feather name="mail" class="feather-20"></i-feather>
          <span mat-dialog-close>{{ 'groupManagement' | translate }}</span>
        </p>

      </li>   -->
    </ul>
  </div>
</mat-dialog-content>
