import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AttachmentFavoriteData } from '../../entities/message.entities';
import { MessageService } from '../../services/message.service';

/**
 * Component for displaying a favorite message attachment.
 */
@Component({
  selector: 'app-message-attachment-favourite',
  templateUrl: './message-attachment-favourite.component.html',
  styleUrls: ['./message-attachment-favourite.component.scss'],
})
export class MessageAttachmentFavouriteComponent {
  /** The file name of the favorite attachment. */
  public fileName = '';
  /**
   * Constructor of the component.
   * @param dialog Reference to the MatDialog for the component.
   * @param messageService Service for handling message-related operations.
   * @param toast ToastrService for displaying toast notifications.
   * @param messageData Data passed into the dialog, containing attachment information.
   */
  public constructor(
    private dialog: MatDialogRef<MessageAttachmentFavouriteComponent>,
    private messageService: MessageService,
    private toast: ToastrService,
    @Inject(MAT_DIALOG_DATA) private messageData: AttachmentFavoriteData,
  ) {}

  /**
   * Handles the click event on the favorite checkbox.
   * Validates the file name and marks the attachment as favorite if valid.
   */
  public async clickedCheck(): Promise<void> {
    if (!this.fileName.trim()) {
      this.toast.error('File Name is required', 'Error');
      return;
    }
    try {
      await this.messageService.markAsFavourite(
        this.messageData.threadId,
        this.messageData.messageId,
        true,
        this.fileName,
        this.messageData.url,
      );
      this.dialog.close(true);
    } catch {}
  }
}
