import { Component, OnInit } from '@angular/core';
import { PermissionsService } from 'src/app/modules/permissions/services/permissions.service';
import { notificationsItem } from '../../../communication.model';
import { CommunicationService } from '../../../communication.service';

/**
 * Component for managing notification settings.
 */
@Component({
  selector: 'app-notification-settings-manager-group',
  templateUrl: './notification-settings-manager-group.component.html',
  styleUrls: ['./notification-settings-manager-group.component.scss'],
})
export class NotificationSettingsManagerGroupComponent implements OnInit {
  /**
   * Dropdown values for selecting notification intervals.
   */
  dropdownValue = [
    {
      id: 1,
      value: 'Everyday',
    },
    {
      id: 2,
      value: 'After 1 days incomplete',
    },
    {
      id: 3,
      value: 'After 2 days incomplete',
    },
    {
      id: 4,
      value: 'After 3 days incomplete',
    },
    {
      id: 5,
      value: 'After 4 days incomplete',
    },
    {
      id: 6,
      value: 'After 5 days incomplete',
    },
    {
      id: 7,
      value: 'After 6 days incomplete',
    },
    {
      id: 8,
      value: 'After 7 days incomplete',
    },
  ];
  /**
   * Constant for zero.
   */
  zero = 0;
  /**
   * Constant for eight.
   */
  eight = 8;
  //monitoringAlertData!: NotificationSettings[];
  //inactivityAlertData!: NotificationSettings[];

  /**
   * Data for monitoring alerts.
   */
  monitoringAlertData: notificationsItem[] = [];
  /**
   * Data for inactivity alerts.
   */
  inactivityAlertData: any[] = [];
  /**
   * Result placeholder.
   */
  res: any;
  /**
   * Default time set to 8:00 AM.
   */
  defaultTime = new Date(new Date().setHours(this.eight, this.zero, this.zero, this.zero));
  /**
   * Current date value.
   */
  dateFormate = {
    value: new Date(),
  };

  /**
   * Name of the subscribed user group.
   */
  subscribedToName!: string;

  /**
   * Constructor for NotificationSettingsManagerGroupComponent.
   * @param _CommunicationService Communication service instance.
   * @param permissionsService Permissions service instance.
   */
  constructor(
    private _CommunicationService: CommunicationService,
    private permissionsService: PermissionsService,
  ) {}

  /**
   * Fetches manager user group data.
   */
  ngOnInit(): void {
    this.getManagerUserGroup();
  }

  /**
   * Fetches manager user group data.
   */
  public getManagerUserGroup(): void {
    this._CommunicationService.getManagerUserGroup().subscribe((res) => {
      this.res = res;
      this.subscribedToName = this.res[0].subscribedTo.name;
      if (this.subscribedToName) {
        this.onSelected(this.subscribedToName, 'default');
      }
    });
  }

  /**
   * Updates notification settings based on the provided data.
   * @param body - Data object to be updated
   * @param key - Key of the data object
   * @param type - Type of notification (e.g., 'inactivity')
   */
  public getUpdatedRow(body: any, key: string, type: string): void {
    const keyToAdd = body.key;
    if (type === 'inactivity') {
      const payload = {
        subscribedTo: body['subscribedTo'].group, // group id   this.patientNudges.weight.time = ;
        [keyToAdd]: {
          communicationChannels: body.communicationChannels,
          title: body.title,
          groupTitle: body.groupTitle,
          frequency: body.frequency,
          time: new Date(body.time).toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' }),
          enabled: body.enabled,
        },
      };
      this._CommunicationService.updateNotificationSettingManager(payload).subscribe();
    } else {
      const payload = {
        subscribedTo: body['subscribedTo'].group, // group id
        [keyToAdd]: {
          communicationChannels: body.communicationChannels,
          title: body.title,
          groupTitle: body.groupTitle,
        },
      };
      this._CommunicationService.updateNotificationSettingManager(payload).subscribe();
    }
  }

  /**
   * Handles the change event for weight time.
   * @param time - Selected time
   * @param item - Item associated with the time change
   */
  public onWeightTimeChanged(time: Date, item: any) {
    if (time) item.time = time;
  }

  /**
   * Converts date string to Date object.
   * @param dStr - Date string to be converted
   * @returns Date object
   */
  public toDate(dStr: any): Date {
    return typeof dStr === 'string' ? this.changeDateFormate(dStr) : dStr;
  }

  /**
   * Changes the format of the date string.
   * @param dStr - Date string to be formatted
   * @returns Formatted Date object
   */
  changeDateFormate(dStr: any) {
    const now = new Date();
    const zeroIndex = 0;
    const thirdIndex = 3;
    now.setHours(dStr.substr(zeroIndex, dStr.indexOf(':')));
    now.setMinutes(dStr.substr(thirdIndex, dStr.indexOf(':')));
    return new Date(now);
  }

  /**
   * Handles the selection of a value.
   * @param selectedValue - Selected value
   * @param selection - Selection type
   */
  onSelected(selectedValue: any, selection: string) {
    const optionSelectedValue = selection === 'selection' ? selectedValue.target.value : selectedValue;
    this.inactivityAlertData = [];
    this.monitoringAlertData = [];
    // You can access the selected value using the selectedValue parameter
    this.res.forEach((item: { subscribedTo: any; data: any[] }) => {
      item.data.forEach((alert) => {
        if (alert.groupTitle === 'Monitoring Alert') {
          this.monitoringAlertData.push({ ...alert, subscribedTo: item.subscribedTo });
        } else if (alert.groupTitle === 'Inactivity Alert') {
          this.inactivityAlertData.push({ ...alert, subscribedTo: item.subscribedTo });
        }
      });
    });
    const commonKeysInactivity = this.inactivityAlertData.filter((item: { subscribedTo: { name: string } }) => {
      return item.subscribedTo.name === optionSelectedValue;
    });

    const commonKeysMonitoring = this.monitoringAlertData.filter((item) => {
      return item.subscribedTo?.name === optionSelectedValue;
    });
    // .map((item: any) => item);
    this.inactivityAlertData = commonKeysInactivity.filter((item) => item['key'] !== 'healthTrackerNotReported');
    this.monitoringAlertData = commonKeysMonitoring.sort((a, b) => a.key.localeCompare(b.key));
  }

  /**
   * Returns checked channels for notification.
   * @param value - Channel value
   * @returns Checked channel value
   */
  checkedChannels(value: string) {
    return this._CommunicationService.notificationCheckedChannelValue(value);
  }
}
