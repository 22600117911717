<form>
  <h3>{{ 'contacts' | translate }}</h3>
  <div class="form-group search-input">
    <input type="text" class="form-control" placeholder="{{ 'search' | translate }}" [formControl]="searchQuery" />
    <i-feather name="search" class="feather-20"></i-feather>
  </div>

  <div class="contacts-list-wrapper">
    @if (displayedManagedUsers().length) {
      <div class="template-group">
        <h6 class="flex-between">
          <span>{{ 'Managed' | clientLabel }}</span>
          @if (!collapseManaged) {
            <i-feather name="chevron-up" class="feather-16" (click)="collapseManaged = true"></i-feather>
          }
          @if (collapseManaged) {
            <i-feather name="chevron-down" class="feather-16" (click)="collapseManaged = false"></i-feather>
          }
        </h6>
        @if (!collapseManaged) {
          @for (user of displayedManagedUsers(); track user.userAcsId) {
            @if (user.userAcsId && (listAllManagedUsers || user.userAcsId === selectedManagedUserACSId)) {
              <div class="contacts-radio">
                <input
                  type="radio"
                  [id]="'managed-user-' + $index"
                  name="managed-users"
                  class="form-check-input"
                  [value]="user.userAcsId"
                  [(ngModel)]="selectedManagedUserACSId"
                  (ngModelChange)="updatedManagedUser(user)"
                />
                <label class="contacts-label" [for]="'managed-user-' + $index">
                  <div class="user-image">
                    <img [src]="user.userImageUrl" [alt]="'managed-user-' + $index" />
                  </div>
                  <div class="contact-name-group">
                    <span class="user-name">{{ user.userFullName }}</span>
                    @if (user.groupName) {
                      <span class="sm-text message-contact-detail-text">
                        <span class="ff-medium">{{ 'Groups' | translate }}:</span> {{ user.groupName }}
                      </span>
                    }
                  </div>
                  <ng-container *appPermissions="'ai.PatientSummarization.Read'">
                    <i-feather
                      name="file"
                      class="feather-16 contact-summary"
                      (click)="showObservationSummary.emit(user.userAcsId)"
                    ></i-feather>
                  </ng-container>
                </label>
              </div>
            }
          }
        }
      </div>
    }

    @if (displayedManagerUsers().length) {
      <div class="template-group">
        <h6 class="flex-between">
          <span>{{ 'Manager' | clientLabel }}</span>
          @if (!collapseManager) {
            <i-feather name="chevron-up" class="feather-16" (click)="collapseManager = true"></i-feather>
          }
          @if (collapseManager) {
            <i-feather name="chevron-down" class="feather-16" (click)="collapseManager = false"></i-feather>
          }
        </h6>

        @if (!collapseManager) {
          @for (user of displayedManagerUsers(); track $index) {
            @if (user.userAcsId && user.userAcsId !== communicationUserId) {
              <div class="template-radio">
                <input
                  type="checkbox"
                  [id]="'my-support-' + $index"
                  name="my-supports"
                  class="form-check-input"
                  [checked]="isSelected(user.userAcsId)"
                  (change)="updateParticipants(user)"
                />
                <div class="user-image">
                  <img [src]="user.userImageUrl" [alt]="'my-support-' + $index" />
                </div>
                <label class="radio-label" [for]="'my-support-' + $index">
                  <span class="body-text">{{ user.userFullName }}</span>
                  @if (user.groupName) {
                    <span>{{ 'Groups' | translate }}: {{ user.groupName }}</span>
                  }
                </label>
              </div>
            }
          }
        }
      </div>
    }
  </div>
</form>
