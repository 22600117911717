import { Injectable } from '@angular/core';
import { CallAgent, CallClient } from '@azure/communication-calling';
import {
  ChatClient,
  ChatMessageEditedEvent,
  ChatMessageReceivedEvent,
  ChatThreadCreatedEvent,
  ParticipantsRemovedEvent,
} from '@azure/communication-chat';
import { AzureCommunicationTokenCredential } from '@azure/communication-common';
import { Observable, firstValueFrom } from 'rxjs';
import { ChatToken } from 'src/app/modules/shared/interfaces/acs.entities';
import { environment } from 'src/environments/environment';
import { HttpService } from './http.service';
/**
 * Service providing integration with Azure Communication Services (ACS) for chat and video calling functionalities.
 * Manages ACS tokens, initializes ChatClient and CallClient instances, and sets up real-time notifications for chat events.
 */
@Injectable({
  providedIn: 'root',
})
/**
 * This class manages communication utilities.
 */
export class ACSService {
  /** The ID of the communication user. */
  #communicationUserId = '';
  /** The chat token associated with the user. */
  #chatToken: ChatToken | null = null;
  /** The chat client used for communication. */
  #chatClient: ChatClient | null = null;
  /** The call client used for communication. */
  #callClient: CallClient | null = null;
  /** The call agent used for communication. */
  #callAgent: CallAgent | null = null;
  /** The base URL for API requests. */
  #baseUrl: string = environment.url.apiHost;
  /** The API version used for API requests. */
  #apiVersion: string = environment.url.version;
  /** The URL endpoint for chat communication. */
  #chatEndpointUrl = environment.url.chat;
  /**
   * Observable for real-time chat message updates.
   * Emits events for chatMessageEdited and chatMessageReceived.
   */
  public refreshData$!: Observable<ChatMessageEditedEvent | ChatMessageReceivedEvent>;
  /**
   * Observable for participants removed from a chat thread.
   * Emits events for participantsRemoved.
   */
  public removedThread$!: Observable<ParticipantsRemovedEvent>;
  /**
   * Observable for new chat thread created.
   * Emits events for chatThreadCreated.
   */
  public newThread$!: Observable<ChatThreadCreatedEvent>;
  /**
   * Timeout used for refreshing the thread list.
   * @type {ReturnType<typeof setTimeout>}
   */
  public threadListRefreshTimeout: ReturnType<typeof setTimeout> = setTimeout(() => {
    // do nothing
  }, 0);
  /**
   * Get the current ChatClient instance.
   * @returns Current instance of ChatClient or null if not initialized.
   */
  public get chatClient(): ChatClient | null {
    return this.#chatClient;
  }
  /**
   * Get the current CallAgent instance for video calling.
   * @returns Current instance of CallAgent or null if not initialized.
   */
  public get callAgent(): CallAgent | null {
    return this.#callAgent;
  }
  /**
   * Get the current CallClient instance.
   * @returns Current instance of CallClient or null if not initialized.
   */
  public get callClient(): CallClient | null {
    return this.#callClient;
  }
  /**
   * Initialize the ChatClient for chat functionalities.
   * If already initialized, does nothing.
   * @returns Promise that resolves when initialization is complete.
   */
  public constructor(private http: HttpService) {}
  /**
   * Initialize the ChatClient for handling chat functionalities.
   * If the communication user ID and ChatClient are already initialized, this method does nothing.
   * If the ChatToken is not yet retrieved, it fetches it asynchronously.
   * Once initialized, sets up real-time notifications for chat events.
   * @returns Promise that resolves when chat initialization and real-time notifications setup are complete.
   */
  public async initChat(): Promise<void> {
    if (this.#communicationUserId && this.#chatClient) return;
    if (!this.#chatToken) this.#chatToken = await firstValueFrom(this.getACSUserAccessToken());
    this.#chatClient = new ChatClient(
      this.#chatEndpointUrl,
      new AzureCommunicationTokenCredential(this.#chatToken.token),
    );
    this.#communicationUserId = this.#chatToken.communicationUserId;
    await this.realTimeNotifications();
  }
  /**
   * Initialize the CallAgent for video calling functionalities.
   * If already initialized, does nothing.
   * @returns Promise that resolves when initialization is complete.
   */
  public async initVideoLobby(): Promise<void> {
    if (this.#callAgent) return;
    if (!this.#chatToken) this.#chatToken = await firstValueFrom(this.getACSUserAccessToken());
    this.#callClient = new CallClient();
    this.#callAgent = await this.#callClient.createCallAgent(
      new AzureCommunicationTokenCredential(this.#chatToken.token),
    );
  }
  /**
   * Get the Azure Communication Services token for authentication.
   * If communication user ID is not set, retrieves and sets it.
   * @returns Promise resolving to the communication user ID.
   */
  public async getACSToken(): Promise<string> {
    if (!this.#communicationUserId) {
      this.#chatToken = await firstValueFrom(this.getACSUserAccessToken());
      this.#communicationUserId = this.#chatToken.communicationUserId;
    }
    return this.#communicationUserId;
  }
  /**
   * Reset all Azure Communication Services related objects and tokens.
   * Clears chatClient, communicationUserId, callAgent, chatToken, and callClient.
   * Stops real-time chat notifications.
   * Clears thread list refresh timeout.
   * @returns Promise that resolves when reset is complete.
   */
  public async resetACS(): Promise<void> {
    if (this.#chatClient) await this.#chatClient.stopRealtimeNotifications();
    this.#chatClient = null;
    this.#communicationUserId = '';
    this.#callAgent = null;
    this.#chatToken = null;
    this.#callClient = null;
    clearTimeout(this.threadListRefreshTimeout);
  }
  /**
   * Get Azure Communication Services user access token for ChatClient authentication.
   * @returns Observable that resolves to the ChatToken object.
   */
  private getACSUserAccessToken(): Observable<ChatToken> {
    const url = `${this.#baseUrl}${this.#apiVersion}/chat/token`;
    return this.http.post(url, {}, {}, true);
  }
  /**
   * Setup real-time notifications for chat events.
   * Sets up Observables for chatMessageReceived, chatMessageEdited, participantsRemoved, and chatThreadCreated events.
   * @returns Promise that resolves when real-time notifications setup is complete.
   */
  private async realTimeNotifications(): Promise<void> {
    if (!this.#chatClient) return;
    await this.#chatClient.startRealtimeNotifications();

    this.refreshData$ = new Observable((subscriber) => {
      this.#chatClient?.on('chatMessageReceived', (res: ChatMessageReceivedEvent): void => {
        subscriber.next(res);
      });
      this.#chatClient?.on('chatMessageEdited', (res: ChatMessageEditedEvent): void => {
        subscriber.next(res);
      });
    });

    this.removedThread$ = new Observable((subscriber) => {
      this.#chatClient?.on('participantsRemoved', (res: ParticipantsRemovedEvent): void => {
        subscriber.next(res);
      });
    });

    this.newThread$ = new Observable((subscriber) => {
      this.#chatClient?.on('chatThreadCreated', (res: ChatThreadCreatedEvent): void => {
        subscriber.next(res);
      });
    });
  }
}
