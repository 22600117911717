import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AddUserComponent } from "./components/add-user/add-user.component";
import { ProfileEditComponent } from "./components/profile-edit/profile-edit.component";
import { UsersListingComponent } from "./components/users-listing/users-listing.component";
import { AccessComponent } from './components/access/access.component';

const routes: Routes = [
  {
    path: "",
    redirectTo: "list",
    pathMatch: "full",
  },
  {
    path: "list",
    component: UsersListingComponent,
  },
  {
    path: "add-access",
    component: UsersListingComponent,
  },
  {
    path: "add",
    component: AddUserComponent,
  },
  {
    path: "edit/:uid",
    component: AddUserComponent,
  },
  {
    path: "edit",
    component: AddUserComponent,
  },
   {
    path: "access",
    component: AccessComponent,
  },
  {
    path: "profile",
    component: ProfileEditComponent,
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UsersRoutingModule {}
