<div class="panel-outer">
  <div class="hs-details-card card-base">
    <div class="hs-tab-card-title flex-between">
      <h2 class="mb-0">{{ widget.widgetDisplayTitle }}</h2>
      <div ngbDropdown>
        <i-feather name="more-vertical" class="feather-20 pointer" ngbDropdownToggle></i-feather>
        <div ngbDropdownMenu aria-labelledby="ngb-more-dropdown" class="ngb-more-dropdown right">
          <button ngbDropdownItem (click)="openMessage()">
            <i-feather name="maximize" class="feather-16"></i-feather>
            <span class="ngb-dropdown-text">{{ 'expand' | translate }}</span>
          </button>
          <button ngbDropdownItem (click)="createNewThread()">
            <i-feather name="edit" class="feather-16"></i-feather>
            <span class="ngb-dropdown-text">{{ 'newMessage' | translate }}</span>
          </button>
        </div>
      </div>
    </div>
    <app-message-thread-list [patientId]="patientId"></app-message-thread-list>
  </div>
</div>