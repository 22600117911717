import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { PermissionsService } from 'src/app/modules/permissions/services/permissions.service';
import { CommunicationService } from 'src/app/modules/settings/communication/communication.service';
import {
  BusinessHours,
  DEFAULT_FROM_HOUR,
  DEFAULT_MINUTES,
  DEFAULT_TO_HOUR,
  NUMBER_ONE,
  NUMBER_ZERO,
  OOOBusinessHoursResponse,
  ToggleView,
  WeekOption,
  WeekOptions,
} from 'src/app/modules/settings/communication/components/interfaces/out-of-office';
import { TranslatePipe } from 'src/app/modules/shared/pipes/translate.pipe';

/**
 * Component to manage Out of Office Business Hours settings.
 */
@Component({
  selector: 'app-out-of-business-hours',
  templateUrl: './out-of-business-hours.component.html',
  styleUrls: ['./out-of-business-hours.component.scss'],
})
export class OutOfBusinessHoursComponent {
  /** Input to toggle view of Out of Office Business Hours. */
  @Input() toggleView!: ToggleView;
  /** Default time set for 'from' business hour. */
  defaultFromTime = new Date(new Date().setHours(DEFAULT_FROM_HOUR, DEFAULT_MINUTES, NUMBER_ZERO, NUMBER_ZERO));
  /** Default time set for 'to' business hour. */
  defaultToTime = new Date(new Date().setHours(DEFAULT_TO_HOUR, DEFAULT_MINUTES, NUMBER_ZERO, NUMBER_ZERO));
  /** Form group for managing business hours settings. */
  businessHoursForm!: FormGroup;
  /** Available options for selecting days of the week. */
  availableWeekOptions = WeekOptions;
  /** Object to hold Out of Office Business Hours settings. */
  OOOBusinessHours: BusinessHours = {
    status: false,
    from: '9:30',
    to: '18:30',
    fromTime: this.defaultFromTime,
    toTime: this.defaultToTime,
    dayOfWeek: [],
    subject: '',
    body: '',
  };

  /**
   * Constructor for OutOfBusinessHoursComponent.
   * @param fb FormBuilder instance for creating form controls.
   * @param communicationService CommunicationService instance for API interactions.
   * @param toastrService ToastrService instance for displaying notifications.
   * @param translatePipe TranslatePipe instance for translating text.
   */
  constructor(
    private fb: FormBuilder,
    private communicationService: CommunicationService,
    private permissionsService: PermissionsService,
    private toastrService: ToastrService,
    private translatePipe: TranslatePipe,
  ) {
    this.getOOOBusinessHours();
  }

  /**
   * Toggles the view of Out of Office Business Hours settings.
   */
  toggleOOOBusinessHoursView() {
    this.toggleView.OOOBusinessHours = !this.toggleView.OOOBusinessHours;
    this.createOOOBusinessHoursFormControls();
    this.validateFormChanges();
  }

  /**
   * Validates the selected business hours.
   */
  validateBusinessHours() {
    if (this.businessHoursForm?.get('fromTime')?.value && this.businessHoursForm?.get('toTime')?.value) {
      const start = new Date(this.businessHoursForm?.get('fromTime')?.value).getTime();
      const end = new Date(this.businessHoursForm?.get('toTime')?.value).getTime();
      if (start >= end) {
        this.businessHoursForm?.get('fromTime')?.setErrors({ time: true });
      } else {
        this.businessHoursForm?.get('fromTime')?.setErrors(null);
      }
    }
  }

  /**
   * Validates if there are changes in the form compared to the original settings.
   */
  validateFormChanges() {
    if (
      this.businessHoursForm.value.subject !== this.OOOBusinessHours.subject ||
      this.businessHoursForm.value.body !== this.OOOBusinessHours.body ||
      this.businessHoursForm.value.from !== this.OOOBusinessHours.from ||
      this.businessHoursForm.value.to !== this.OOOBusinessHours.to ||
      this.businessHoursForm.value.status !== this.OOOBusinessHours.status ||
      this.businessHoursForm.value.dayOfWeek.join() !== this.OOOBusinessHours.dayOfWeek.join()
    ) {
      this.communicationService.calendarChanges.businessHours.hasChanges = true;
    } else {
      this.communicationService.calendarChanges.businessHours.hasChanges = false;
    }
    setTimeout(() => {
      this.validateBusinessHours();
    }, 10);
  }

  /**
   * Creates form controls for Out of Office Business Hours settings.
   */
  createOOOBusinessHoursFormControls() {
    this.businessHoursForm = this.fb.group({
      status: [this.OOOBusinessHours.status || false],
      from: [this.OOOBusinessHours.from || '', Validators.required],
      to: [this.OOOBusinessHours.to || '', Validators.required],
      fromTime: [this.OOOBusinessHours.fromTime || '', Validators.required],
      toTime: [this.OOOBusinessHours.toTime || '', Validators.required],
      dayOfWeek: this.fb.array(
        this.OOOBusinessHours?.dayOfWeek && this.OOOBusinessHours?.dayOfWeek?.length
          ? this.OOOBusinessHours.dayOfWeek
          : [],
      ),
      subject: [this.OOOBusinessHours.subject || '', Validators.required],
      body: [this.OOOBusinessHours.body || '', Validators.required],
    });
  }

  /**
   * Handler for changing 'from' time.
   * @param from Selected 'from' time.
   */
  onChangeFromTimeHandler(from: Date) {
    if (from) {
      const fromTime = `${from.getHours() <= NUMBER_ZERO ? '00' : from.getHours()}:${
        from.getMinutes() <= NUMBER_ZERO ? '00' : from.getMinutes()
      }`;
      this.businessHoursForm.controls.from.patchValue(fromTime);
      this.businessHoursForm.controls.fromTime.patchValue(from);
      this.businessHoursForm.updateValueAndValidity();
      this.validateFormChanges();
    }
  }

  /**
   * Handler for changing 'to' time.
   * @param to Selected 'to' time.
   */
  onChangeToTimeHandler(to: Date) {
    if (to) {
      const toTime = `${to.getHours() <= NUMBER_ZERO ? '00' : to.getHours()}:${
        to.getMinutes() <= NUMBER_ZERO ? '00' : to.getMinutes()
      }`;
      this.businessHoursForm.controls.to.patchValue(toTime);
      this.businessHoursForm.controls.toTime.patchValue(to);
      this.businessHoursForm.updateValueAndValidity();
      this.validateFormChanges();
    }
  }

  /**
   * Sets or removes a day from the selected working days.
   * @param weekOption WeekOption object representing the day to set or remove.
   */
  setWorkingDays(weekOption: WeekOption) {
    if (weekOption) {
      if (this.businessHoursForm.controls?.dayOfWeek?.value.indexOf(weekOption.value) >= NUMBER_ZERO) {
        const removeIndex = this.businessHoursForm.controls?.dayOfWeek?.value.indexOf(weekOption.value);
        this.businessHoursForm.controls?.dayOfWeek?.value?.splice(removeIndex, 1);
      } else {
        this.businessHoursForm.controls?.dayOfWeek?.value?.push(weekOption.value);
      }
      this.businessHoursForm.updateValueAndValidity();
      this.validateFormChanges();
    }
  }

  /**
   * Sets default 'from' and 'to' times based on API response.
   * @param response API response containing default business hours.
   */
  setDefaultFromToTime(response: OOOBusinessHoursResponse) {
    response.data.from = response?.data?.from ? response?.data?.from : this.OOOBusinessHours.from;
    response.data.to = response?.data?.to ? response?.data?.to : this.OOOBusinessHours.to;
    if (response?.data?.from) {
      const fromTimeA = response?.data?.from.split(':');
      if (fromTimeA?.length) {
        response.data.fromTime = new Date(
          new Date().setHours(
            Number(fromTimeA[NUMBER_ZERO] ? fromTimeA[NUMBER_ZERO] : NUMBER_ZERO),
            Number(fromTimeA[NUMBER_ONE] ? fromTimeA[NUMBER_ONE] : NUMBER_ZERO),
            NUMBER_ZERO,
            NUMBER_ZERO,
          ),
        );
      }
    }
    if (response?.data?.to) {
      const toTimeA = response?.data?.to ? response?.data?.to.split(':') : this.OOOBusinessHours.to;
      if (toTimeA?.length) {
        response.data.toTime = new Date(
          new Date().setHours(
            Number(toTimeA[NUMBER_ZERO] ? toTimeA[NUMBER_ZERO] : NUMBER_ZERO),
            Number(toTimeA[NUMBER_ONE] ? toTimeA[NUMBER_ONE] : NUMBER_ZERO),
            NUMBER_ZERO,
            NUMBER_ZERO,
          ),
        );
      }
    }
  }

  /**
   * Checks if a given day is active in the selected working days.
   * @param value Day value to check.
   * @returns True if the day is active, false otherwise.
   */
  isActiveWorkingDays(value: string) {
    return this.businessHoursForm?.controls?.dayOfWeek.value.indexOf(value) >= NUMBER_ZERO ? true : false;
  }

  /**
   * Retrieves a string representation of selected working days.
   * @returns String representing selected working days.
   */
  getWorkingDaysString() {
    if (this.OOOBusinessHours.dayOfWeek && this.OOOBusinessHours.dayOfWeek.length) {
      return this.OOOBusinessHours.dayOfWeek
        .map((d: string) => {
          const index = this.availableWeekOptions.map((w: WeekOption) => w.value).indexOf(d);
          const key = this.availableWeekOptions[index].key;
          return this.translatePipe.transform(key);
        })
        .join(', ');
    }
    return '';
  }

  /**
   * Opens a confirmation dialog to reset Out of Office Business Hours settings.
   */
  openResetOOBusinessHoursSettingsConfirmDialog() {
    const data = {
      title: `${this.translatePipe.transform('resetToDefaultSettingsTitle')}`,
      message:
        `${this.translatePipe.transform('resetOOOBusinessHoursSettingsConfirmLabel')}` ||
        'Your Out of Business Hours settings will be reset to the default settings defined for the account. Are you sure you want to continue?',
    };
    const confirmDialog = this.communicationService.openOOOConfirmDialog(data);
    confirmDialog.afterClosed().subscribe({
      next: (response) => {
        if (response) {
          this.resetOOOBusinessHoursSettings();
        }
      },
      error: (error) => {
        if (error) {
          //  console.log(error);
        }
      },
    });
  }

  /**
   * Callback function invoked after successful update of business hours settings.
   * @param response Updated BusinessHours object.
   */
  updateSuccessHandlerCallBack(response: BusinessHours) {
    if (response) {
      this.getOOOBusinessHours();
      this.toggleOOOBusinessHoursView();
      this.toastrService.success(this.translatePipe.transform('outsideOfBusinessHoursSettingsUpdatedSuccessfully'));
    }
  }

  /**
   * Submits the business hours form if valid.
   */
  submit() {
    this.businessHoursForm.controls.subject.patchValue(this.businessHoursForm.value.subject.trim());
    this.businessHoursForm.controls.body.patchValue(this.businessHoursForm.value.body.trim());
    this.businessHoursForm.updateValueAndValidity();
    if (this.businessHoursForm.valid) {
      //if(this.businessHoursForm?.value?.dayOfWeek?.length) {
      this.updateOOOBusinessHours();
      //}
    }
  }

  /**
   * Retrieves Out of Office Business Hours settings from the server.
   */
  getOOOBusinessHours() {
    if (!this.permissionsService.hasPermission('self.OutOfBusiness.Read')) {
      return;
    }
    this.communicationService.getOOOBusinessHours().subscribe({
      next: (response: OOOBusinessHoursResponse) => {
        if (response?.success && response?.data) {
          this.setDefaultFromToTime(response);
          this.OOOBusinessHours = response?.data;
        }
      },
      error: (error) => {
        if (error) {
          //  console.log(error);
        }
      },
    });
  }

  /**
   * Updates Out of Office Business Hours settings on the server.
   */
  updateOOOBusinessHours() {
    const businessHoursPostO = { ...this.businessHoursForm.value };
    delete businessHoursPostO.fromTime;
    delete businessHoursPostO.toTime;
    this.communicationService.updateOOOBusinessHours(businessHoursPostO).subscribe({
      next: (response) => {
        this.updateSuccessHandlerCallBack(response);
      },
      error: (error) => {
        if (error) {
          //  console.log(error);
        }
      },
    });
  }

  /**
   * Resets Out of Office Business Hours settings to default on the server.
   */
  resetOOOBusinessHoursSettings() {
    this.communicationService.resetDefaultOOOBusinessHoursSettings().subscribe({
      next: (response) => {
        this.updateSuccessHandlerCallBack(response);
      },
      error: (error) => {
        if (error) {
          //  console.log(error);
        }
      },
    });
  }
}
