import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  InputSignal,
  OnDestroy,
  Output,
  WritableSignal,
  inject,
  input,
  signal,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FeatherModule } from 'angular-feather';
import { Subscription, debounceTime } from 'rxjs';
import { AIMetricFormattedData, MessageAIMetrics } from 'src/app/modules/ai-metrics/entities/ai-metrics.entities';
import { AiMetricsService } from 'src/app/modules/ai-metrics/services/ai-metrics.service';
import { DotLoaderComponent } from 'src/app/modules/shared/components/dot-loader/dot-loader.component';
import { TranslatePipe } from 'src/app/modules/shared/pipes/translate.pipe';
import { HttpService } from 'src/app/modules/shared/services/http.service';
import { SkeletonLoaderComponent } from 'src/app/modules/shared/skeleton-loader/skeleton-loader.component';
import { SummaryDetailedAnalysisComponent } from '../../../ai-metrics/components/summary-detailed-analysis/summary-detailed-analysis.component';
import { SuggestedMessage } from '../../entities/message.entities';
import { MessageAPIsService } from '../../services/message-apis.service';
/**
 * Component for displaying and handling suggested messages.
 */
@Component({
  selector: 'app-suggested-message',
  templateUrl: './suggested-message.component.html',
  styleUrls: ['./suggested-message.component.scss'],
  standalone: true,
  imports: [FeatherModule, NgClass, SkeletonLoaderComponent, DotLoaderComponent, TranslatePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuggestedMessageComponent implements OnDestroy {
  /** Input property to determine the mode ('Alert' or 'Thread'). Default is 'Thread'. */
  @Input() public mode: 'Alert' | 'Thread' = 'Thread';
  /** Event emitter for emitting selected suggestion message. */
  @Output() public selectedSuggestion = new EventEmitter<string>();
  /**
   * Input signal that holds the patient ID, which can be undefined if not provided.
   */
  public patientId: InputSignal<string | undefined> = input();
  /** Signal for displaying loader state. */
  public showLoader: WritableSignal<boolean> = signal(true);
  /** Signal for displaying the suggested message text. */
  public message: WritableSignal<string> = signal('');
  /** Signal for indicating if streaming of messages is active. */
  public streaming: WritableSignal<boolean> = signal(false);

  /**
   * Writable signal that stores the current state of AI metrics data.
   */
  public aIMetricsState: WritableSignal<'Fetching' | 'Fetched' | 'No Data'> = signal('No Data');
  /**
   * Writable signal that stores the formatted AI metrics data.
   * The data is updated when new metrics are processed.
   */
  public aiMetricsFormattedData: WritableSignal<AIMetricFormattedData[]> = signal([]);

  /** Subscription for suggested message ID changes. */
  private suggestedMessageIdSub$: Subscription;
  /** Subscription for fetching suggested message. */
  private suggestedMessageSub$!: Subscription;
  /** Private subscription for streaming suggested messages. */
  #streamMessageSub$!: Subscription;
  /** Injected HTTP service for making HTTP requests. */
  #http = inject(HttpService);
  /** Injected service for message APIs. */
  #messageApiService = inject(MessageAPIsService);
  /** Injected service for Mat Dialog */
  #dialog = inject(MatDialog);
  /** Injected service for AI Metrics */
  #aIMetricsService = inject(AiMetricsService);
  /**
   * Subscription object for handling AI metrics-related data streams.
   */
  #aIMetricsSub$!: Subscription;

  /**
   * Constructs the suggested message component and initializes subscriptions.
   */
  public constructor() {
    this.suggestedMessageIdSub$ = this.#messageApiService.suggestedMessageId$
      .pipe(debounceTime(1000))
      .subscribe((id: string): void => {
        this.showLoader.set(true);
        if (this.#streamMessageSub$ && !this.#streamMessageSub$.closed) this.#streamMessageSub$.unsubscribe();
        this.streaming.set(false);
        this.aIMetricsState.set('No Data');
        this.aiMetricsFormattedData.set([]);
        this.message.set('');
        if (this.mode === 'Thread') {
          this.suggestedMessageSub$ = this.#messageApiService.getSuggestedMessageForThread(id).subscribe({
            next: (res: SuggestedMessage): void => this.showSuggestedMessage(res),
            error: () => this.useSuggestion(''),
          });
        }
        if (this.mode === 'Alert') {
          this.suggestedMessageSub$ = this.#messageApiService.getSuggestedMessageForAlert(id).subscribe({
            next: (res: SuggestedMessage): void => this.showSuggestedMessage(res),
            error: () => this.useSuggestion(''),
          });
        }
      });
  }
  /**
   * Cleans up subscriptions when component is destroyed.
   */
  public ngOnDestroy() {
    this.suggestedMessageIdSub$.unsubscribe();
    if (this.suggestedMessageSub$) this.suggestedMessageSub$.unsubscribe();
    if (this.#streamMessageSub$ && !this.#streamMessageSub$.closed) this.#streamMessageSub$.unsubscribe();
  }
  /**
   * Uses the suggested message and emits it through selectedSuggestion event.
   * @param message The suggested message to use.
   */
  public useSuggestion(message: string): void {
    this.showLoader.set(false);
    this.selectedSuggestion.emit(message);
  }

  /**
   * Opens a dialog for displaying detailed analysis of message summaries.
   */
  public openSummaryDetailAnalysis(): void {
    this.#dialog.open(SummaryDetailedAnalysisComponent, {
      panelClass: ['full-screen-dialog'],
      autoFocus: false,
      disableClose: true,
      data: this.aiMetricsFormattedData(),
    });
  }

  /**
   * Displays the suggested message based on the response received.
   * @param message The suggested message object received from the API.
   */
  private showSuggestedMessage(message: SuggestedMessage): void {
    if (message && message.reply) {
      this.message.set(message.reply);
      this.showLoader.set(false);

      if (message.metrics && message.metrics.length) {
        this.#processAIMetrics(message.metrics[0]);
        this.aIMetricsState.set('Fetched');
      }
    } else if (message && message.streamUrl) {
      this.showLoader.set(false);
      this.streaming.set(true);
      this.#getSuggestedMessageFromStream(message.streamUrl);
    } else {
      this.useSuggestion('');
    }
    this.suggestedMessageSub$.unsubscribe();
  }

  /**
   * Initiates streaming of suggested messages from the provided URL.
   * @param url The URL for streaming suggested messages.
   */
  #getSuggestedMessageFromStream(url: string): void {
    this.#streamMessageSub$ = this.#http.subscribeToSSE(url, '![DONE]').subscribe({
      next: (res) => {
        if (res === '![DONE]') {
          this.streaming.set(false);
          this.#streamMessageSub$.unsubscribe();
          this.#getAIMetrics(url);
          return;
        }
        this.message.update((x) => x + res);
      },
      error: () => {
        this.streaming.set(false);
        this.#streamMessageSub$.unsubscribe();
        this.useSuggestion('');
      },
    });
  }

  /**
   * Fetches AI metrics for a given URL and updates the state accordingly.
   */
  #getAIMetrics(url: string): void {
    if (!this.patientId()) return;

    this.aIMetricsState.set('Fetching');

    const index = url.indexOf('token=');
    const streamId = url.substring(index + 6);

    setTimeout(() => {
      this.#aIMetricsSub$ = this.#aIMetricsService.getAIMetrics(this.patientId()!, [streamId]).subscribe({
        next: (res) => {
          if (res.length) {
            this.#processAIMetrics(res[0] as MessageAIMetrics);
          }
          this.aIMetricsState.set('Fetched');
          this.#aIMetricsSub$.unsubscribe();
        },
        error: () => {
          this.aIMetricsState.set('No Data');
          this.#aIMetricsSub$.unsubscribe();
        },
      });
    }, 5000);
  }

  /**
   * Processes the AI metrics received from the service and updates
   * the formatted AI metrics data. This method handles various scores
   * such as medical accuracy, hallucination, toxicity, and medical appropriateness.
   */
  #processAIMetrics(metrics: MessageAIMetrics): void {
    if (metrics.scores.med_accuracy) {
      this.aiMetricsFormattedData.update((data) => [
        ...data,
        this.#aIMetricsService.processMedicalAIMetrics(metrics.scores.med_accuracy.display_score, 'medicalAccuracy'),
      ]);
    }
    if (metrics.scores.hallucination) {
      this.aiMetricsFormattedData.update((data) => [
        ...data,
        this.#aIMetricsService.processHallucinations(parseFloat(metrics.scores.hallucination.display_score)),
      ]);
    }
    if (metrics.scores.toxicity) {
      this.aiMetricsFormattedData.update((data) => [
        ...data,
        this.#aIMetricsService.processToxicity(metrics.scores.toxicity),
      ]);
    }
    if (metrics.scores.medical_appropriateness) {
      this.aiMetricsFormattedData.update((data) => [
        ...data,
        this.#aIMetricsService.processMedicalAIMetrics(
          metrics.scores.medical_appropriateness.display_score,
          'appropriateness',
        ),
      ]);
    }
  }
}
