<div class="page-header">
  <h1>{{ 'userGroupManagement' | translate }}</h1>
</div>
<div class="page-content-wrapper">
  <div class="row">
    <div class="col-12">
      @if (data) {
        <div>
          <div class="card-base">
            <div class="card-titlebar">
              <button
                class="btn btn-primary btn-icon"
                [routerLink]="['/groupManagment/add']"
                *appPermissions="'self.Management_UserGroup.Write'"
              >
                <i-feather
                  name="plus-circle"
                  *appPermissions="'self.Management_UserGroup.Write'"
                  class="feather-20 mr-2"
                ></i-feather>
                {{ 'addGroup' | translate }}
              </button>
            </div>
            <app-table
              [columns]="columns"
              [displayedColumns]="displayedColumns"
              [paginationOptions]="paginationOptions"
              [data]="data"
              (sort)="sortByColumn($event)"
              (delete)="deleteGroup($event)"
              (refetch)="refreshGroup($event)"
              (search)="openSearchAndFiltersDialog($event)"
              class="customGroups"
            ></app-table>
          </div>
        </div>
      }
    </div>
  </div>
</div>
