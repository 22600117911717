import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { Disclaimer } from 'src/app/modules/authentication/terms-service-list/terms-service-list.component';
import { TabsService } from 'src/app/modules/dashboard/health-summary-tabs/services/tabs.service';
import { ProfileData } from 'src/app/modules/shared/interfaces/profileData.interface';
import { ACSService } from 'src/app/modules/shared/services/acs.service';
import { HttpService } from 'src/app/modules/shared/services/http.service';
import { environment } from 'src/environments/environment';
import { registerFormGrp } from '../../shared/interfaces/common.entities';
import { Token } from '../auth.interface';

/**
 * Service for handling authentication-related operations.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthServiceService {
  /**
   * URL for authentication API.
   */
  authUrl: string = environment.url.apiAuth;

  /**
   * Base URL for API requests.
   */
  baseUrl: string = environment.url.apiHost;

  /**
   * API version used for API requests.
   */
  apiVersion: string = environment.url.version;

  /**
   * Interval timer for refreshing tokens.
   */
  refreshTokenTimerInterval!: ReturnType<typeof setInterval>;

  /**
   * Array of disclaimers.
   */
  disclaimers: Disclaimer[] = [];

  /**
   * Subject for synchronizing verification.
   */
  syncVerification = new Subject();

  /**
   * Constructor for AuthServiceService.
   * @param acsService Service for ACS operations.
   * @param httpService HTTP service for API calls.
   * @param toastrService Service for displaying toastr messages.
   * @param tabs Service for managing tabs.
   * @param router Angular router for navigation.
   */
  constructor(
    private acsService: ACSService,
    private httpService: HttpService,
    private toastrService: ToastrService,
    private tabs: TabsService,
    private router: Router,
  ) {}

  /**
   * Performs application login using provided credentials.
   * @param body - Login credentials.
   * @param headers - Optional HTTP headers.
   * @returns An observable with the login response.
   */
  login(body: { username: string; password: string }, headers?: unknown) {
    const url = `${this.authUrl}/auth/application-login`;
    return this.httpService.post(url, body, { headers: headers }).pipe();
  }

  /**
   * Registers a new user.
   * @param body - User registration data.
   * @returns An observable with the registration response.
   */
  register(body: registerFormGrp): Observable<registerFormGrp> {
    const url = `${this.baseUrl}${this.apiVersion}/auth/register`;
    return this.httpService.post(url, body).pipe();
  }

  /**
   * Activates a user account using activation ID and body data.
   * @param body - Activation data.
   * @param id - User ID for activation.
   * @returns An observable with the activation response.
   */
  activateUser(body: any, id: string): Observable<any> {
    const url = this.baseUrl + this.apiVersion + '/user/activate/' + id;
    return this.httpService.patch(url, body);
  }

  /**
   * Sends a request to initiate the password reset process.
   * @param {any} body - The request body containing necessary data.
   * @returns {Observable<any>} Observable containing the API response.
   */
  forgotPassword(body: any): Observable<any> {
    const url = this.baseUrl + this.apiVersion + '/auth/forgotPassword';
    return this.httpService.post(url, body);
  }

  /**
   * Sends a request to reset the user's password.
   * @param {any} body - The request body containing necessary data.
   * @returns {Observable<any>} Observable containing the API response.
   */
  resetPassword(body: any): Observable<any> {
    const url = this.baseUrl + this.apiVersion + '/auth/resetPassword';
    return this.httpService.patch(url, body);
  }

  /**
   * Sends a request to update the user's password.
   * @param {any} body - The request body containing necessary data.
   * @returns {Observable<any>} Observable containing the API response.
   */
  updatePassword(body: any): Observable<any> {
    const url = this.baseUrl + this.apiVersion + '/auth/updatePassword';
    return this.httpService.patch(url, body);
  }

  /**
   * Fetches disclaimers based on whether they are signed or not.
   * @param {boolean} isSigned - Indicates whether to fetch signed disclaimers or not.
   * @returns {Observable<any>} Observable containing the API response.
   */
  fetchDisclaimers(isSigned: boolean) {
    const url = this.baseUrl + this.apiVersion + '/disclaimer?signed=' + isSigned;
    return this.httpService.get(url);
  }

  /**
   * Accepts a disclaimer by its ID.
   * @param {string} id - The ID of the disclaimer to accept.
   * @returns {Observable<any>} Observable containing the API response.
   */
  acceptDisclaimerById(id: string) {
    const url = this.baseUrl + this.apiVersion + `/disclaimer/${id}/Accepted`;
    return this.httpService.patch(url);
  }

  /**
   * Declines a disclaimer by its ID.
   * @param {string} id - The ID of the disclaimer to decline.
   * @returns {Observable<any>} Observable containing the API response.
   */
  declineDisclaimerById(id: string) {
    const url = this.baseUrl + this.apiVersion + `/disclaimer/${id}/Rejected`;
    return this.httpService.patch(url);
  }

  /**
   * Logs the user out.
   * @returns {Observable<any>} Observable containing the API response.
   */
  logOut() {
    const url = `${this.authUrl}/auth/logout`;
    return this.httpService.get(url);
  }

  /**
   * Requests a token refresh.
   * @param {any} body - The request body containing necessary data.
   * @param {any} headers - Optional headers for the request.
   * @returns {Observable<any>} Observable containing the API response.
   */
  refreshToken(body: any, headers: any) {
    const url = `${this.authUrl}/auth/token`;
    return this.httpService.post(url, body, headers, true);
  }

  /**
   * Retrieves affiliation code details.
   * @param {string} affiliationCode - The affiliation code to retrieve details for.
   * @returns {Observable<any>} Observable containing the API response.
   */
  getAffiliationCodeDetails(affiliationCode: string) {
    const url = `${this.baseUrl}${this.apiVersion}/affiliation/detail/${affiliationCode}`;
    return this.httpService.get(url);
  }

  /**
   * Fetches user configurations from the API.
   * @returns {Observable<any>} An observable of the HTTP response containing user configurations.
   */
  fetchUserConfigurations() {
    const url = `${this.baseUrl}${this.apiVersion}/user/configuration`;
    return this.httpService.get(url);
  }

  /**
   * Stores user roles in localStorage.
   * @param {Array<any>} roles - The roles array to store.
   */
  setRoles(roles: Array<any>) {
    if (roles?.length) {
      localStorage.setItem('roles', JSON.stringify(roles));
    }
  }

  /**
   * Stores the user's username in localStorage.
   * @param {string} userName - The username to store.
   */
  setUserName(userName: string) {
    if (userName) {
      localStorage.setItem('userName', userName);
    }
  }

  /**
   * Stores the user's ID in localStorage.
   * @param {string} userId - The user ID to store.
   */
  setUserId(userId: string) {
    if (userId) {
      localStorage.setItem('userId', userId);
    }
  }

  /**
   * Stores the client ID in localStorage.
   * @param {string} clientId - The client ID to store.
   */
  setClientId(clientId: string) {
    if (clientId) {
      localStorage.setItem('clientId', clientId);
    }
  }

  /**
   * Stores client details in localStorage.
   * @param {{ name: string; _id: string; id?: string }} client - The client details object to store.
   */
  setClientDetails(client: { name: string; _id: string; id?: string }) {
    if (client && client?._id) {
      localStorage.setItem('client', JSON.stringify(client));
    }
  }

  /**
   * Stores user demographic data in localStorage.
   * @param {ProfileData} userDemographicO - The user demographic data object to store.
   */
  setUserDemographic(userDemographicO: ProfileData) {
    if (userDemographicO) {
      localStorage.setItem('demographicData', JSON.stringify(userDemographicO));
    }
  }

  /**
   * Sets the array of disclaimers.
   * @param {Disclaimer[]} disclaimers - The array of disclaimers to store.
   */
  setDisclaimers(disclaimers: Disclaimer[]) {
    if (disclaimers?.length) {
      this.disclaimers = disclaimers;
    }
  }

  /**
   * Sets whether dashboard access is allowed.
   * @param {boolean} access - Indicates whether dashboard access is allowed.
   */
  setAllowDashboardAccess(access: boolean) {
    localStorage.setItem('allowDashboardAccess', access ? 'true' : 'false');
  }

  /**
   * Stores OAuth tokens in localStorage.
   * @param {any} tokens - The OAuth tokens object to store.
   */
  setOAuthTokens(tokens: Token) {
    if (tokens) {
      this.removeOAuthTokens();
      this.removeVerificationToken();
      localStorage.setItem('access_token', tokens.access_token ? tokens.access_token : '');
      localStorage.setItem('refresh_token', tokens.refresh_token ? tokens.refresh_token : '');
      localStorage.setItem(
        'access_token_expires_in_minutes',
        tokens.access_token_expires_in_minutes ? String(tokens.access_token_expires_in_minutes) : '',
      );
      this.setRoles(tokens?.principal?.authorities?.roles || '');
      this.setUserName(tokens?.principal?.userName || '');
      this.setUserId(tokens?.principal?.sub || '');
      this.setClientDetails(tokens.authenticatedFor.client);
    }
  }

  /**
   * Removes OAuth tokens from localStorage.
   */
  removeOAuthTokens() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
  }

  /**
   * Retrieves the OAuth access token from localStorage.
   * @returns {string} The OAuth access token.
   */
  getOAuthAccessToken(): string {
    return localStorage.getItem('access_token') || '';
  }

  /**
   * Retrieves the OAuth access token from localStorage.
   * @returns {string} The OAuth access token.
   */
  getToken(): string {
    return localStorage.getItem('access_token') || '';
  }

  /**
   * Retrieves user roles from localStorage.
   * @returns {Array<any>} The array of user roles.
   */
  getRoles() {
    const roles = localStorage.getItem('roles');
    return roles ? JSON.parse(roles) : [];
  }

  /**
   * Retrieves the user's username from localStorage.
   * @returns {string} The user's username.
   */
  getUserName(): string {
    return localStorage.getItem('userName') || '';
  }

  /**
   * Retrieves the full name of the logged-in user.
   * @returns {string} The full name of the logged-in user.
   */
  getLoggedInUserFullName(): string {
    const demographicData = this.getUserDemographic();
    return demographicData?.userID ? `${demographicData.firstName} ${demographicData.lastName}` : '';
  }

  /**
   * Retrieves the user ID from localStorage.
   * @returns {string} The user ID.
   */
  getUserId(): string {
    return localStorage.getItem('userId') || '';
  }

  /**
   * Retrieves the user ACS ID from localStorage.
   * @returns {string} The user ACS ID.
   */
  getUserAcsId(): string {
    const configurations = localStorage.getItem('userConfigurations');
    return configurations ? JSON.parse(configurations).userAcsId : '';
  }

  /**
   * Retrieves user configurations from localStorage.
   * @returns {any} The user configurations object.
   */
  getUserConfiguration() {
    const configurations = localStorage.getItem('userConfigurations');
    return configurations ? JSON.parse(configurations) : '';
  }

  /**
   * Retrieves the client ID from localStorage.
   * @returns {string} The client ID.
   */
  getClientId(): string {
    return this.getClientDetails()?._id || '';
  }

  /**
   * Retrieves the client name from localStorage.
   * @returns {string} The client name.
   */
  getClientName(): string {
    return this.getClientDetails()?.name || '';
  }

  /**
   * Retrieves client details from localStorage.
   * @returns {{ name: string; _id: string; id?: string }} The client details object.
   */
  getClientDetails() {
    const client = localStorage.getItem('client');
    return client ? JSON.parse(client) : null;
  }

  /**
   * Retrieves the user's demographic data from local storage.
   * @returns User demographic data if available, otherwise null.
   */
  getUserDemographic() {
    const demographicData = localStorage.getItem('demographicData');
    return demographicData ? JSON.parse(demographicData) : null;
  }

  /**
   * Retrieves the list of disclaimers stored locally.
   * @returns An array of disclaimers.
   */
  getDisclaimers() {
    return this.disclaimers;
  }

  /**
   * Retrieves the current status of dashboard access permission from localStorage.
   * @returns {boolean} Whether dashboard access is allowed (`true`) or not (`false`).
   */
  getAllowDashboardAccess() {
    return localStorage.getItem('allowDashboardAccess') &&
      localStorage.getItem('allowDashboardAccess')?.toLowerCase() == 'true'
      ? true
      : false;
  }

  /**
   * Checks if the user is authorized (i.e., has an OAuth access token).
   * @returns {boolean} Whether the user is authorized (`true`) or not (`false`).
   */
  isAuthorized() {
    return this.getOAuthAccessToken() ? true : false;
  }

  /**
   * Logs out the user by clearing local storage and redirecting to login page.
   * @param refresh - Optional parameter to force a page refresh.
   */
  logoutCallback(refresh?: boolean) {
    localStorage.clear();
    this.acsService.resetACS();
    if (!refresh) {
      window.location.href = '/login';
    } else {
      this.router.navigate(['/login']);
      this.toastrService.error(`Access Denied, You don't have permission to access disclaimers`);
    }
  }

  /**
   * Signs the user out by calling the logout API.
   * If the user is not authorized (i.e., does not have an OAuth access token),
   * it directly calls the logout callback.
   * @param {boolean} [refresh] - Optional parameter to indicate whether to refresh the page after logout.
   */
  signOut(refresh?: boolean) {
    if (!this.isAuthorized()) {
      this.logoutCallback(refresh);
      return;
    }
    this.logOut().subscribe({
      next: (res) => {
        if (res) {
          this.tabs.removeAllTabs();
          this.tabs.removeAllHiddenTabs();
          this.logoutCallback(refresh);
        }
      },
      error: (err) => {
        if (err) {
          this.logoutCallback(refresh);
        }
      },
    });
  }

  /**
   * Starts a timer to periodically refresh the access token if authenticated.
   */
  startRefreshTokenTimer() {
    this.refreshTokenTimerInterval = setInterval(() => {
      if (this.isAuthorized()) {
        const refresh_token_timer_in_seconds = Number(localStorage.getItem('refresh_token_timer_in_seconds'));
        const access_token_expires_in_minutes = Number(localStorage.getItem('access_token_expires_in_minutes')) * 60;
        let timer =
          refresh_token_timer_in_seconds > 0 ? refresh_token_timer_in_seconds : access_token_expires_in_minutes;
        timer = timer - 1;
        localStorage.setItem('refresh_token_timer_in_seconds', String(timer));

        if (timer <= 60) {
          this.fetchRefreshToken();
          this.clearRefreshTokenTimerInterval();
        }
      }
    }, 1000);
  }

  /**
   * Clears the refresh token timer interval if it's set.
   */
  clearRefreshTokenTimerInterval() {
    if (this.refreshTokenTimerInterval) {
      clearInterval(this.refreshTokenTimerInterval);
    }
  }

  /**
   * Fetches a new access token using the refresh token from local storage.
   * Initiates a POST request to the server to refresh tokens.
   * @returns An Observable of the HTTP response.
   */
  fetchRefreshToken() {
    const body = new URLSearchParams();
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    const refresh_token = localStorage.getItem('refresh_token') || '';
    body.set('grant_type', 'refresh_token');
    body.set('refresh_token', refresh_token);
    if (refresh_token) {
      this.refreshToken(body, { headers: headers }).subscribe(
        (response) => {
          this.clearRefreshTokenTimerInterval();
          localStorage.removeItem('refresh_token_timer_in_seconds');
          this.startRefreshTokenTimer();
          this.setOAuthTokens(response);
        },
        (error) => {
          if (error) {
            // this.toastr.error(error.error.message, error.error.error);
            //this.signOut();
          }
        },
      );
    }
  }

  /**
   * Sets the verification token and related information in local storage.
   * @param _token The verification token object.
   */
  setVerificationToken(_token: any) {
    if (_token) {
      this.removeOAuthTokens();
      localStorage.setItem('verification_token', _token.token);
      localStorage.setItem('token_type', _token.token_type);
      localStorage.setItem(
        'verificationInfo',
        JSON.stringify({ mobilePhone: _token.mobilePhone, emailAddress: _token.emailAddress }),
      );
    }
  }

  /**
   * Retrieves the verification token from local storage.
   * @returns The verification token string.
   */
  getVerificationToken(): string {
    return localStorage.getItem('verification_token') || '';
  }

  /**
   * Retrieves the token type from local storage.
   * @returns The token type string.
   */
  getTokenType(): string {
    return localStorage.getItem('token_type') || '';
  }

  /**
   * Retrieves the verification information (mobile phone and email address) from local storage.
   * @returns The verification information object.
   */
  getVerificationInfo() {
    return JSON.parse(localStorage.getItem('verificationInfo') || '');
  }

  /**
   * Removes the verification token and related information from local storage.
   */
  removeVerificationToken() {
    localStorage.removeItem('verification_token');
    localStorage.removeItem('token_type');
    localStorage.removeItem('verificationInfo');
  }

  /**
   * Checks if there is a verification token present in local storage.
   * @returns True if there is a verification token, false otherwise.
   */
  forVerification() {
    return this.getVerificationToken() ? true : false;
  }

  /**
   * Initiates a two-factor authentication (TFA) request.
   * @param body The request body containing TFA details.
   * @returns An Observable of the HTTP response.
   */
  requestVerification(body: any) {
    const url = `${this.authUrl}/auth/tfa`;
    return this.httpService.post(url, body);
  }
}
