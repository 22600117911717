<div class="table-wrapper nudges-table">
  <div class="table-responsive mb-0">
    <table class="mat-mdc-table">
      <tr class="mat-mdc-header-row">
        <th class="mat-mdc-header-cell">{{ 'notification' | translate }}</th>
        @if (checkedChannels('sms')) {
          <th class="mat-mdc-header-cell toggle-cell-width">{{ 'sms' | translate }}</th>
        }
        @if (checkedChannels('email')) {
          <th class="mat-mdc-header-cell toggle-cell-width">
            {{ 'email' | translate }}
          </th>
        }
        @if (checkedChannels('inApp')) {
          <th class="mat-mdc-header-cell toggle-cell-width">
            {{ 'inAppNotification' | translate }}
          </th>
        }
        @if (checkedChannels('push')) {
          <th class="mat-mdc-header-cell toggle-cell-width">
            {{ 'pushNotification' | translate }}
          </th>
        }
      </tr>

      @for (item of notificationSettingsData; track item; let i = $index) {
        @if (item.title !== 'Medication Engagement' && item.title !== 'Application Engagement') {
          <tr class="mat-mdc-row">
            <td class="mat-mdc-cell">
              <div
                [ngClass]="{
                  'cell-has-child justify-content-start':
                    item.resendAfter !== undefined && item.resendAfter !== undefined
                }"
              >
                <strong>{{ item.title }} </strong>
                <!-- <i-feather name="{{appEngagement ? 'chevron-up' : 'chevron-down'}}"
              class="feather-20 pointer" (click)="applicationEngagement()"></i-feather> -->
                <span>
                  @if (item.resendAfter !== undefined && item.resendAfter !== undefined) {
                    <div class="flex-between reminder-gap">
                      <div class="flex-between">
                        <span class="pe-2">{{ 'resendAfter' | translate }}</span>
                        <div class="form-group mb-0">
                          <input
                            type="text"
                            maxlength="4"
                            appOnlyNumber
                            [(ngModel)]="item.resendAfter"
                            class="reminder-input form-control"
                            (change)="getUpdatedRow(item)"
                            [ngClass]="{ 'is-invalid': invitationReminder }"
                          />
                        </div>
                        <span class="ps-1">{{ 'days(s)' | translate }} </span>
                      </div>
                      <div class="flex-between">
                        <span class="pe-2">#{{ 'attempts' | translate }}</span>
                        <div class="form-group mb-0">
                          <input
                            type="text"
                            maxlength="4"
                            appOnlyNumber
                            class="reminder-input form-control"
                            (change)="getUpdatedRow(item)"
                            [(ngModel)]="item.attempts"
                            [ngClass]="{ 'is-invalid': invitationReminderAttempts }"
                          />
                        </div>
                      </div>
                    </div>
                  }
                </span>
              </div>
            </td>
            @if (item?.communicationChannels) {
              @if (checkedChannels('sms')) {
                <td class="mat-mdc-cell toggle-cell">
                  @if (item?.communicationChannels.smsChannel) {
                    <span>
                      <mat-slide-toggle
                        [(ngModel)]="item.communicationChannels.sms"
                        (change)="getUpdatedRow(item)"
                      ></mat-slide-toggle>
                    </span>
                  }
                </td>
              }
              @if (checkedChannels('email')) {
                <td class="mat-mdc-cell toggle-cell">
                  @if (item.communicationChannels.emailChannel) {
                    <span>
                      <mat-slide-toggle
                        [(ngModel)]="item.communicationChannels.email"
                        (change)="getUpdatedRow(item)"
                      ></mat-slide-toggle>
                    </span>
                  }
                </td>
              }
              @if (checkedChannels('inApp')) {
                <td class="mat-mdc-cell toggle-cell">
                  @if (item.communicationChannels.inAppChannel) {
                    <span>
                      <mat-slide-toggle
                        [(ngModel)]="item.communicationChannels.inApp"
                        (change)="getUpdatedRow(item)"
                      ></mat-slide-toggle>
                    </span>
                  }
                </td>
              }
              @if (checkedChannels('push')) {
                <td class="mat-mdc-cell toggle-cell">
                  @if (item.communicationChannels.pushChannel) {
                    <span>
                      <mat-slide-toggle
                        [(ngModel)]="item.communicationChannels.push"
                        (change)="getUpdatedRow(item)"
                      ></mat-slide-toggle>
                    </span>
                  }
                </td>
              }
            }
          </tr>
        }
        <!-- <ng-container *ngIf="item[item.key]">
      <tr class="mat-row" *ngFor="let data of item[item.key]">
        <td class="mat-mdc-cell"> {{ data.title }}
        </td>
        <td class="mat-mdc-cell toggle-cell">
          <span *ngIf="data.communicationChannels.smsChannel">
            <mat-slide-toggle [(ngModel)]="data.communicationChannels.sms"
            (change)="getUpdatedRowNested(item,item.key)"></mat-slide-toggle>
          </span>
        </td>
        <td class="mat-mdc-cell toggle-cell">
          <span *ngIf="data.communicationChannels.emailChannel">
            <mat-slide-toggle [(ngModel)]="data.communicationChannels.email"
            (change)="getUpdatedRowNested(item,item.key)"></mat-slide-toggle>
          </span>
        </td>
        <td class="mat-mdc-cell toggle-cell">
          <span *ngIf="data.communicationChannels.inAppChannel">
            <mat-slide-toggle [(ngModel)]="data.communicationChannels.inApp"
            (change)="getUpdatedRowNested(item,item.key)"></mat-slide-toggle>
          </span>
        </td>
        <td class="mat-mdc-cell toggle-cell">
          <span *ngIf="data.communicationChannels.pushChannel">
            <mat-slide-toggle [(ngModel)]="data.communicationChannels.push"
            (change)="getUpdatedRowNested(item,item.key)"></mat-slide-toggle>
          </span>
        </td>
      </tr>
    </ng-container> -->
      }
    </table>
  </div>
</div>

<!-- table end -->
