<mat-dialog-content>
  <div class="notes-wrap pt-1">
    @if (!hideNoteInputBox) {
      <form [formGroup]="saveNoteForm">
        <div class="form-group">
          <textarea
            rows="5"
            class="form-control w-100"
            placeholder="{{ 'addNote' | translate }}"
            formControlName="note"
            [ngClass]="{ 'is-invalid': submitted && f.note.errors }"
            appSpaceHandle
          ></textarea>
          @if (submitted && f.note.errors) {
            <div class="invalid-feedback">
              @if (f.note.errors.required || f.note.errors) {
                <div>{{ 'noteIsRequired' | translate }}</div>
              }
            </div>
          }
        </div>
      </form>
    }
    <div class="form-group search-input">
      <label class="sm-text clr-secondary">{{ 'previousNotes' | translate }}</label>
      <input
        type="text"
        class="form-control me-0"
        placeholder="{{ 'search' | translate }}"
        [formControl]="notesSearch"
      />
      <i-feather name="search" class="feather-20"></i-feather>
    </div>
    <div class="clr-secondary my-2 note-detail">
      @for (item of notes | filter: searchTextNote; track item) {
        <div class="notes-item">
          <div class="notes-title-row">
            <span class="notes-title">{{ item.noteOwnerName }}</span>
            <div class="notes-date">
              <span class="ff-medium">{{ item.createdAt | date: 'short' }}</span>
              <div ngbDropdown class="position-relative">
                @if (item.relatedTo.type === 'Alerts') {
                  <i-feather
                    name="info"
                    class="pointer feather-16 error p-0"
                    ngbDropdownToggle
                    (click)="getAlert(item)"
                  ></i-feather>
                }
                @if (getNotes) {
                  <div ngbDropdownMenu aria-labelledby="alert_wrapper clr-secondary" class="alert_wrapper right">
                    <div class="ff-bold">
                      {{ 'alert' | translate }} ({{ 'received' | translate }}:
                      {{ alertData.createdAt | date: 'short' }})
                      <span class="ff-bold">{{ alertData.type | translate }}</span>
                    </div>
                    <div class="ws-pre-line">{{ formatNotesText(alertData.text) }}</div>
                  </div>
                }
              </div>
            </div>
          </div>
          @if (item.body.length > 50) {
            <div class="notes-body sm-text">
              @if (!item.showFull) {
                <span
                  >{{ item.body.slice(0, 50) }}...
                  <a href="javascript:void(0)" (click)="toggleReadMore(item)">{{ 'readMore' | translate }}</a></span
                >
              }
              @if (item.showFull) {
                <span
                  >{{ item.body }}
                  <a href="javascript:void(0)" (click)="toggleReadMore(item)">{{ 'readLess' | translate }}</a></span
                >
              }
            </div>
          } @else {
            <div class="notes-body sm-text">
              <span>{{ item.body || '' }} </span>
            </div>
          }
        </div>
      }
    </div>
  </div>
</mat-dialog-content>
