import { inject, Injectable } from '@angular/core';
import { mergeMap, Observable, of, retry, throwError } from 'rxjs';
import { HttpService } from 'src/app/modules/shared/services/http.service';
import { environment } from 'src/environments/environment';
import {
  AIMetricFormattedData,
  MessageAIMetrics,
  SEVERITY_CLASSES,
  SummaryAIMetrics,
  Toxicity,
} from '../entities/ai-metrics.entities';

/**
 * Service for managing AI metrics-related operations.
 * This service provides methods to fetch and process AI metrics data.
 */
@Injectable({
  providedIn: 'root',
})
export class AiMetricsService {
  /**
   * Base URL for API requests, retrieved from environment configuration.
   */
  #baseUrl: string = environment.url.apiHost;
  /**
   * API version used for API requests, retrieved from environment configuration.
   */
  #apiVersion: string = environment.url.version;
  /**
   * Injected instance of the HTTP service.
   */
  #http = inject(HttpService);

  /**
   * Fetches AI metrics for a specific patient and stream IDs.
   */
  public getAIMetrics(
    patientId: string,
    streamIds: string[],
    retryCount = 1,
  ): Observable<MessageAIMetrics[] | SummaryAIMetrics[]> {
    const url = `${this.#baseUrl}${this.#apiVersion}/ai/metrics/user/${patientId}?streamId=${streamIds.join('|')}`;
    return this.#http.get(url, {}, true).pipe(
      mergeMap((res) => (res.length ? of(res) : throwError(() => 'No Data'))),
      retry({ count: retryCount, delay: 5000 }),
    );
  }

  /**
   * Processes medical AI metrics to generate a formatted data object.
   */
  public processMedicalAIMetrics(
    displayScore: number,
    labelKey: 'quality' | 'consistency' | 'medicalAccuracy' | 'appropriateness',
  ): AIMetricFormattedData {
    let score = -1;
    let colorClass: SEVERITY_CLASSES = 'servity-0';

    if (displayScore >= 0.9 && displayScore <= 1) {
      colorClass = 'servity-1';
    } else if (displayScore >= 0.8 && displayScore < 0.9) {
      colorClass = 'servity-2';
    } else if (displayScore >= 0.7 && displayScore < 0.8) {
      colorClass = 'servity-3';
    } else if (displayScore >= 0 && displayScore < 0.7) {
      colorClass = 'servity-4';
    }

    if (colorClass !== 'servity-0') {
      score = Math.round(displayScore * 100);
    }

    return {
      labelKey,
      descriptionKey: `${labelKey}Description`,
      score,
      colorClass,
    };
  }

  /**
   * Processes hallucination scores to generate a formatted data object.
   */
  public processHallucinations(displayScore: number): AIMetricFormattedData {
    let score = -1;
    let colorClass: SEVERITY_CLASSES = 'servity-0';

    if (displayScore >= 0 && displayScore <= 0.1) {
      colorClass = 'servity-1';
    } else if (displayScore > 0.1 && displayScore <= 0.15) {
      colorClass = 'servity-2';
    } else if (displayScore > 0.15 && displayScore <= 0.2) {
      colorClass = 'servity-3';
    } else if (displayScore > 0.2 && displayScore <= 1) {
      colorClass = 'servity-4';
    }

    if (colorClass !== 'servity-0') {
      score = Math.round(displayScore * 100);
    }

    return {
      labelKey: 'hallucinations',
      descriptionKey: 'hallucinationsDescription',
      score,
      colorClass,
    };
  }

  /**
   * Processes toxicity scores to generate a formatted data object.
   */
  public processToxicity(toxicity: Toxicity): AIMetricFormattedData {
    let score = -1;
    let colorClass: SEVERITY_CLASSES = 'servity-0';
    const displayScore = toxicity.display_score;

    if (displayScore >= 0 && displayScore <= 0.01) {
      colorClass = 'servity-1';
    } else if (displayScore > 0.01 && displayScore <= 0.02) {
      colorClass = 'servity-2';
    } else if (displayScore > 0.02 && displayScore <= 0.03) {
      colorClass = 'servity-3';
    } else if (displayScore > 0.03 && displayScore <= 1) {
      colorClass = 'servity-4';
    }

    if (colorClass !== 'servity-0') {
      score = Math.round(displayScore * 100);
    }

    return {
      labelKey: 'toxicity',
      descriptionKey: 'toxicityDescription',
      score,
      colorClass,
      toxicityInfo: {
        obscene: toxicity.critieria.obscene && toxicity.critieria.obscene >= 0 ? toxicity.critieria.obscene * 100 : -1,
        identity_attack:
          toxicity.critieria.identity_attack && toxicity.critieria.identity_attack >= 0
            ? toxicity.critieria.identity_attack * 100
            : -1,
        insult: toxicity.critieria.insult && toxicity.critieria.insult >= 0 ? toxicity.critieria.insult * 100 : -1,
        threat: toxicity.critieria.threat && toxicity.critieria.threat >= 0 ? toxicity.critieria.threat * 100 : -1,
        sexual_explicit:
          toxicity.critieria.sexual_explicit && toxicity.critieria.sexual_explicit >= 0
            ? toxicity.critieria.sexual_explicit * 100
            : -1,
      },
    };
  }
}
