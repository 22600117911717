@if (healthwiseData$ | async; as data) {
  <div class="dialog-lg">
    <div class="hs-tab-card-title flex-between">
      <h2>{{ data.title }}</h2>
      <span class="dialog-header-icons">
        <i-feather mat-dialog-close name="x" class="pointer" aria-label="Close"></i-feather>
      </span>
    </div>
    <mat-dialog-content>
      @if (data.type !== 'hwVideo') {
        <div [innerHTML]="data.html"></div>
      }
      @if (data.type === 'hwVideo') {
        <video class="healthwise-video" autoplay controls>
          <source [src]="data.media?.sources?.mp4_720p_url" />
        </video>
      }
    </mat-dialog-content>
  </div>
}
