<div class="fc-calendar-dialog">
  <div class="hs-tab-card-title flex-between mb-0">
    <div class="heading-with-icon">
      <i-feather
        name="settings"
        class="feather-20 view-below-lg pointer text-secondary"
        (click)="mobileView = true"
      ></i-feather>
      <h2>{{ 'calendar' | translate }}</h2>
    </div>

    <div class="calendar-subheader view-above-lg">
      <div class="flex-center left-subheader">
        <div class="schedule-time flex-center">
          <i-feather name="calendar" class="feather-20 text-secondary"></i-feather>
          <i-feather name="chevron-left" class="feather-20 pointer text-secondary" (click)="prev()"></i-feather>
          <h2 class="date-text clr-primary" *ngIf="fcDateLabel">{{ fcDateLabel }}</h2>
          <i-feather name="chevron-right" class="feather-20 pointer text-secondary" (click)="next()"></i-feather>
          <a href="javascript:void(0);" (click)="today()" class="today">{{ 'today' | translate }}</a>
        </div>
      </div>
    </div>
    <div class="dialog-header-right">
      <div class="view-above-lg d-flex">
        <div class="form-check-flex align-items-center toggle-check my-0 me-2" *appPermissions="'self.Calendar.Update'">
          <label class="form-label m-0">{{ 'doNotDisturb' | translate }}</label>
          <mat-slide-toggle [(ngModel)]="dnd.doNotDisturb" (change)="toggleDoNotDisturb()"></mat-slide-toggle>
        </div>
        <div class="time-period-btn">
          <button
            class="btn btn-outline-primary"
            [class]="{ active: getCurrentView() === 'dayGridMonth' }"
            (click)="changeView('dayGridMonth')"
          >
            {{ 'month' | translate }}
          </button>
          <button
            class="btn btn-outline-primary"
            [class]="{ active: getCurrentView() === 'timeGridWeek' }"
            (click)="changeView('timeGridWeek')"
          >
            {{ 'week' | translate }}
          </button>
          <button
            class="btn btn-outline-primary"
            [class]="{ active: getCurrentView() === 'timeGridDay' }"
            (click)="changeView('timeGridDay')"
          >
            {{ 'day' | translate }}
          </button>
        </div>
      </div>
      <div class="dialog-header-icons">
        <span class="position-relative">
          <i-feather name="filter" class="pointer" (click)="eventFilter()"></i-feather>
          <span [class]="{ 'inactive-badge': cFilterOptions.appointment || cFilterOptions.virtualVisit }"></span>
        </span>
        <i-feather name="plus" class="pointer" *appPermissions="'self.Calendar.Write'" (click)="addEvent()"></i-feather>
        <i-feather name="x" class="pointer" mat-dialog-close></i-feather>
      </div>
    </div>
  </div>
  <div class="view-below-lg align-items-center justify-content-between py-8">
    <div ngbDropdown>
      <i-feather name="more-horizontal" class="feather-20 pointer" ngbDropdownToggle></i-feather>
      <div ngbDropdownMenu aria-labelledby="ngb-more-dropdown" class="ngb-more-dropdown">
        <button
          ngbDropdownItem
          class="px-2"
          [class]="{ active: getCurrentView() === 'dayGridMonth' }"
          (click)="changeView('dayGridMonth')"
        >
          <i-feather name="month" class="feather-16"></i-feather>
          <span class="ngb-dropdown-text"> {{ 'month' | translate }}</span>
        </button>
        <button
          ngbDropdownItem
          class="px-2"
          [class]="{ active: getCurrentView() === 'timeGridWeek' }"
          (click)="changeView('timeGridWeek')"
        >
          <i-feather name="week" class="feather-16"></i-feather>
          <span class="ngb-dropdown-text"> {{ 'week' | translate }}</span>
        </button>
        <button
          ngbDropdownItem
          class="px-2"
          [class]="{ active: getCurrentView() === 'timeGridDay' }"
          (click)="changeView('timeGridDay')"
        >
          <i-feather name="today" class="feather-16"></i-feather>
          <span class="ngb-dropdown-text"> {{ 'day' | translate }}</span>
        </button>
      </div>
    </div>
    <div class="calendar-subheader">
      <div class="flex-center left-subheader">
        <div class="schedule-time flex-center">
          <i-feather name="chevron-left" class="feather-20 pointer text-secondary" (click)="prev()"></i-feather>
          <h2 class="date-text clr-primary mb-0" *ngIf="fcDateLabel">{{ fcDateLabel }}</h2>
          <i-feather name="chevron-right" class="feather-20 pointer text-secondary" (click)="next()"></i-feather>

          <a href="javascript:void(0);" (click)="today()" class="today">{{ 'today' | translate }}</a>
        </div>
      </div>
    </div>

    <div class="form-check-flex align-items-center toggle-check my-0">
      <label class="form-label m-0">{{ 'dnd' | translate }}</label>
      <mat-slide-toggle [(ngModel)]="dnd.doNotDisturb" (change)="toggleDoNotDisturb()"></mat-slide-toggle>
    </div>
  </div>
  <mat-dialog-content class="mat-dialog-content calendar-body">
    <div class="fc-calendar">
      <div class="row">
        <div class="mini-column" *ngIf="windowSize === 'xl' || windowSize === 'xxl' || mobileView">
          <div class="hs-tab-card-title justify-content-end mb-0 view-below-lg">
            <i-feather name="x" class="feather-20 pointer text-secondary" (click)="mobileView = false"></i-feather>
          </div>
          <div class="calendar-scroll">
            <div class="leftSide-miniCalendar">
              <mat-calendar
                #fcDatePicker
                [selected]="selectedDate"
                (selectedChange)="onSelectedChange($event)"
                [headerComponent]="customHeaderForDatepickerComponent"
                [dateClass]="dateClass"
              ></mat-calendar>
            </div>

            <div class="calendar-list">
              <form>
                <div class="d-flex justify-content-between mt-1 mb-2">
                  <h6 class="ff-bold">{{ 'My Calendars' | translate }}</h6>
                </div>
                <div class="form-group search-input search-100 mb-3 d-none">
                  <input type="text" class="form-control" placeholder="{{ 'search' | translate }}" />
                  <i-feather name="x" class="feather-20 clr-disabled"></i-feather>
                </div>
                <div
                  class="form-check-flex justify-content-between iconOnHover"
                  *ngFor="let myCalendar of myCalendars | filter: searchOptions.myCalendars.keywords : 'userFullName'"
                >
                  <span>
                    <input
                      type="checkbox"
                      name="myCalendars"
                      class="form-check-input"
                      [(ngModel)]="myCalendar.checked"
                      [ngModelOptions]="{ standalone: true }"
                      (change)="onChangeMyCalendarsOptions(myCalendar)"
                      [ngStyle]="
                        myCalendar.checked
                          ? {
                              'background-color': myCalendar.colorCode,
                              'border-color': myCalendar.colorCode
                            }
                          : null
                      "
                      id="{{ myCalendar.id }}"
                    />
                    <label class="check-flex-label" for="{{ myCalendar.id }}">{{ myCalendar.userFullName }}</label>
                  </span>
                  <i-feather
                    *ngIf="myCalendar.id !== getLoggedInUserId()"
                    (click)="removeUserFromMyCalendars(myCalendar)"
                    name="x"
                    class="feather-20 pointer"
                    ngbTooltip="{{ 'removeFromMyCalendar' | translate }}"
                    placement="top"
                  ></i-feather>
                </div>
                <!-- other calendars start -->
                <div class="pb-4" *appPermissions="'Others.VideoLobby.Read'">
                  <div class="d-flex justify-content-between align-content-center mb-2 mt-4">
                    <h6 class="ff-bold">{{ 'Other Calendars' | translate }}</h6>
                    <i-feather
                      *ngIf="!searchOptions.otherCalendars.openSearchBox"
                      name="search"
                      class="feather-20 pointer"
                      (click)="searchOptions.otherCalendars.openSearchBox = true"
                    ></i-feather>
                    <i-feather
                      name="x"
                      class="feather-20 pointer"
                      *ngIf="searchOptions.otherCalendars.openSearchBox"
                      (click)="
                        searchOptions.otherCalendars.openSearchBox = false; searchOptions.otherCalendars.keywords = ''
                      "
                    ></i-feather>
                  </div>
                  <div
                    class="form-group search-input search-100 mb-3"
                    *ngIf="searchOptions.otherCalendars.openSearchBox"
                  >
                    <input
                      name="otherCalendarsSearchBox"
                      type="text"
                      class="form-control"
                      [(ngModel)]="searchOptions.otherCalendars.keywords"
                      placeholder="{{ 'search' | translate }}"
                    />
                    <i-feather
                      name="x"
                      *ngIf="searchOptions.otherCalendars.keywords"
                      (click)="searchOptions.otherCalendars.keywords = ''"
                      class="feather-20 text-disabled pointer"
                    ></i-feather>
                  </div>
                  <div *ngIf="searchOptions.otherCalendars.keywords || selectedContactList.length">
                    <div
                      *ngFor="let contact of contacts | filter: searchOptions.otherCalendars.keywords : 'userFullName'"
                    >
                      <span
                        class="form-check-flex justify-content-between pointer iconOnHover"
                        *ngIf="!contact.myCalendar && isShowOtherContacts(contact)"
                      >
                        <span>
                          <input
                            type="checkbox"
                            name="otherContacts"
                            class="form-check-input"
                            [(ngModel)]="contact.checked"
                            [ngModelOptions]="{ standalone: true }"
                            (change)="onChangeOtherCalendarsOptions(contact)"
                            [ngStyle]="
                              contact.checked
                                ? {
                                    'background-color': contact.colorCode,
                                    'border-color': contact.colorCode
                                  }
                                : null
                            "
                            id="{{ contact.id }}"
                          />
                          <label class="check-flex-label" for="{{ contact.id }}">{{ contact.userFullName }}</label>
                        </span>
                        <i-feather
                          name="plus"
                          class="feather-20 onHover pointer"
                          ngbTooltip="{{ 'addToMyCalendar' | translate }}"
                          placement="top"
                          *ngIf="myCalendars.length <= 4"
                          (click)="addToMyCalendars(contact)"
                        ></i-feather>
                      </span>
                    </div>
                  </div>
                </div>
                <!-- other calendars end -->
              </form>
            </div>
            <!-- latest design for calendar aside -->

            <!-- latest design for calendar aside end -->
          </div>
        </div>
        <div class="calendar-column">
          <div class="calendar-scroll">
            <full-calendar class="full-calendar" [options]="calendarOptions" #calendar>
              <ng-template #eventContent let-arg>
                <div class="pointer" [style.border-left]="'4px solid ' + arg.event.extendedProps.colorCode">
                  <span ngbTooltip="{{ arg.event.title }}" class="event-title">
                    {{ arg.event.start | date: 'shortTime' }} {{ arg.event.title }}</span
                  >
                </div>
              </ng-template>
            </full-calendar>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
</div>
