<div class="suggested-message-section">
  <h3>
    <span class="suggested-message-title-text">
      {{ 'suggestedMessage' | translate }}
    </span>
    <img class="beta-badge" src="assets/images/icons/beta-icon.svg" alt="beta" />
  </h3>

  @if (showLoader()) {
    <app-skeleton-loader [lines]="2"></app-skeleton-loader>
  }

  <div [ngClass]="{ 'slide-down-animation': !showLoader() && !streaming(), 'slide-down-base': !streaming() }">
    <div class="suggested-message-text ws-pre-line" [ngClass]="{ 'justify-content-end': !message() }">
      @if (streaming()) {
        <app-dot-loader></app-dot-loader>
      }
      @if (message()) {
        <p [ngClass]="{ 'loader-space': streaming() }">
          {{ message() }}
        </p>
      }
    </div>

    @if (aIMetricsState() === 'Fetching') {
      <div class="ai-metrics-fetching">
        <app-skeleton-loader [lines]="1"></app-skeleton-loader>
      </div>
    }

    @if (aIMetricsState() === 'Fetched') {
      <div class="message-matrix">
        <div class="matrix-wrap">
          @for (metric of aiMetricsFormattedData(); track $index) {
            <span
              >{{ metric.labelKey | translate }}:
              <strong>{{ metric.score === -1 ? '–' : metric.score + '%' }}</strong></span
            >
          }
        </div>
        <i-feather name="info" class="pointer feather-20" (click)="openSummaryDetailAnalysis()"></i-feather>
      </div>
    }

    @if (!streaming()) {
      <div class="suggested-message-button">
        <button class="btn btn-primary" (click)="useSuggestion(message())">
          {{ 'useSuggestedMessage' | translate }}
        </button>
      </div>
    }
  </div>
</div>
