import { Injectable } from '@angular/core';
import { Observable, Subject, firstValueFrom } from 'rxjs';
import { HttpService } from 'src/app/modules/shared/services/http.service';
import { environment } from 'src/environments/environment';
import {
  APISuccess,
  MessageTemplate,
  RecommendedResources,
  ResourceData,
  SuggestedMessage,
} from '../entities/message.entities';

/**
 * Service for interacting with various message-related APIs.
 */
@Injectable()
export class MessageAPIsService {
  /**
   * Subject for notifying observers about changes in suggested message ID.
   */
  public suggestedMessageId$: Subject<string> = new Subject();
  /**
   * Base URL for API requests, retrieved from environment configuration.
   */
  private baseUrl: string = environment.url.apiHost;
  /**
   * API version used for API requests, retrieved from environment configuration.
   */
  private apiVersion: string = environment.url.version;
  /**
   * Constructs the MessageAPIsService.
   * @param http - The HTTP service for making API requests.
   */
  constructor(private http: HttpService) {}

  /**
   * Retrieves the list of message templates.
   * @returns A promise resolving to an array of MessageTemplate objects.
   */
  public async getMessageTemplate(): Promise<MessageTemplate[]> {
    const url = `${this.baseUrl}${this.apiVersion}/messagetemplates`;
    return firstValueFrom(this.http.get(url, {}, false));
  }

  /**
   * Retrive Recommended Resource Ids
   * @param id - ID of Recommended Resources group
   * @returns - Summary of Recommended Resources, along with resource IDs
   */
  public retriveResourcesInformation(id: string): Promise<RecommendedResources> {
    const url = `${this.baseUrl}${this.apiVersion}/ai/informationRetrieval/${id}`;
    return firstValueFrom(this.http.get(url, {}, false));
  }

  /**
   * Fetch Recommended Resource Data
   * @param id - Resource ID
   * @returns - Information related to a particular Recommended Resource
   */
  public getResourceData(id: string): Observable<ResourceData> {
    const url = `${this.baseUrl}${this.apiVersion}/resources/${id}`;
    return this.http.get(url, {}, false);
  }

  /**
   * Fetch Suggested Message for a given Thread
   * @param threadId - Thread Id for fetching Suggested Message
   * @returns - Suggested Message to be displayed
   */
  public getSuggestedMessageForThread(threadId: string): Observable<SuggestedMessage> {
    const url = `${this.baseUrl}${this.apiVersion}/ai/suggestions/message/${threadId}`;
    return this.http.get(url, {}, true);
  }

  /**
   * Fetch Suggested Message for a given Alert
   * @param alertId - Alert Id for fetching Suggested Message
   * @returns - Suggested Message to be displayed
   */
  public getSuggestedMessageForAlert(alertId: string): Observable<SuggestedMessage> {
    const url = `${this.baseUrl}${this.apiVersion}/ai/suggestions/alert/${alertId}`;
    return this.http.get(url, {}, true);
  }

  /**
   * Tracking for when Suggested Message is sent without modifying
   * @param threadId - Thread Id
   * @param suggestedMessage - Suggested Message to be tracked
   */
  public trackSuggestedMessage(threadId: string, suggestedMessage: SuggestedMessage): void {
    const url = `${this.baseUrl}${this.apiVersion}/ai/suggestions/message/${threadId}`;
    this.http.post(url, suggestedMessage, {}, true).subscribe();
  }

  /**
   * Handle Thread based on given thread Id
   * @param patientId - user Id of Patient
   * @param threadId - Thread Id
   * @returns - Success or Failure
   */
  public handleMessageByThreadId(patientId: string, threadId: string): Promise<APISuccess> {
    const url = `${this.baseUrl}${this.apiVersion}/actionResolution/user/${patientId}/messages`;
    return firstValueFrom(
      this.http.patch(
        url,
        {
          threadId,
        },
        {},
        false,
      ),
    );
  }

  /**
   * Joins a conversation identified by the thread ID.
   * @param threadId - Thread ID.
   * @param acsUserId - ACS User ID.
   * @param userId - User ID.
   * @returns A promise resolving to an APISuccess object.
   */
  public joinConversation(threadId: string, acsUserId: string, userId: string): Promise<APISuccess> {
    const url = `${this.baseUrl}${this.apiVersion}/chat/${threadId}/add`;
    return firstValueFrom(
      this.http.patch(
        url,
        {
          participants: [
            {
              acsUserId,
              userId,
            },
          ],
        },
        {},
        false,
      ),
    );
  }

  /**
   * Marks all messages in a thread as read.
   * @param threadId - Thread ID.
   * @returns A promise resolving to void.
   */
  public async markAllMessagesAsRead(threadId: string): Promise<void> {
    const url = `${this.baseUrl}${this.apiVersion}/chat/${threadId}`;
    await firstValueFrom(
      this.http.patch(
        url,
        {
          messageIds: [],
          readBy: true,
        },
        {},
        true,
      ),
    );
  }

  /**
   * Refreshes the observation summary for a given user ID.
   * @param userId - User ID of the patient.
   * @returns A promise resolving to void.
   */
  public async refreshObservationSummary(userId: string): Promise<void> {
    const url = `${this.baseUrl}${this.apiVersion}/ai/observationSummarization/user/${userId}/refresh`;
    await firstValueFrom(this.http.get(url, {}, true));
  }
}
