<div class="page-header">
  <h1>{{ 'addGroup' | translate }}</h1>
  <nav>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a [routerLink]="['/groupManagment/list']">{{ 'userGroupManagement' | translate }}</a>
      </li>
      <li class="breadcrumb-item active">{{ 'addGroup' | translate }}</li>
    </ol>
  </nav>
</div>
<div class="page-content-wrapper">
  <div class="row">
    <div class="col-lg-8">
      <div class="card-base">
        <div class="card-titlebar">
          <div class="card-header-icons mb-0">
            <i-feather name="check" (click)="onSubmit()"></i-feather>
            <i-feather
              name="x"
              [routerLink]="['/groupManagment/list']"
              [queryParams]="{ clientId: clientID }"
            ></i-feather>
          </div>
        </div>
        <form [formGroup]="groupForm">
          <div class="form-group">
            <label for="" class="form-label">{{ 'groupName' | translate }}*</label>
            <input
              formControlName="gName"
              type="text"
              minLength="4"
              maxlength="50"
              class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.gName.errors }"
            />
            @if (submitted && f.gName.errors) {
              <div class="invalid-feedback">
                @if (f.gName.errors.required) {
                  <div class="danger">{{ 'groupNameRequired' | translate }}</div>
                }
                @if (f.gName.errors?.['minlength']) {
                  <div class="danger">
                    {{ 'groupNameMinCharacterValidation' | translate }}
                  </div>
                }
              </div>
            }
          </div>
          <div class="form-group">
            <label for="">{{ 'groupOwner' | translate }}*</label>
            <div class="custom-select-arrow">
              <select
                class="form-control"
                formControlName="manager"
                [ngClass]="{ 'is-invalid': submitted && f.manager.errors }"
              >
                <option value="#" selected disabled>–Select–</option>
                @for (manager of managersList; track manager) {
                  <option [value]="manager.id">
                    {{ manager.name }}
                  </option>
                }
              </select>
            </div>
            @if (submitted && f.manager.errors) {
              <div class="invalid-feedback">
                @if (f.manager.errors.required) {
                  <div class="danger">{{ 'groupOwnerRequired' | translate }}</div>
                }
              </div>
            }
          </div>
          <div class="form-group">
            <label for="area1">{{ 'description' | translate }}</label>
            <textarea
              formControlName="description"
              minlength="4"
              maxlength="500"
              class="form-control lg"
              id="area1"
              rows="5"
              [ngClass]="{ 'is-invalid': submitted && f.description.errors?.['minlength'] }"
            ></textarea>
            @if (submitted && f.description.errors?.['minlength']) {
              <div class="invalid-feedback">
                @if (f.description.errors?.['minlength']) {
                  <div class="danger">
                    {{ 'descriptionMinCharacterValidation' | translate }}
                  </div>
                }
              </div>
            }
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
