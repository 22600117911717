import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NotesCommonRequest, NotesRequestData } from '../notes.entities';
import { NoteService } from '../services/note.service';

/**
 * Component for managing notes related to a specific entity.
 * Allows adding, retrieving, and searching notes.
 */
@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrl: './notes.component.scss',
})
export class NotesComponent implements OnInit {
  /**
   * Form group for saving a new note.
   */
  saveNoteForm!: FormGroup;
  /**
   * Represents a single note.
   */
  note: any;
  /**
   * Array of notes for search functionality.
   */
  notesForSearch: any;
  /**
   * Array of all notes.
   */
  notes: any;
  /**
   * User ID associated with the notes.
   */
  userId!: string;
  /**
   * Text for searching notes.
   */
  searchTextNote!: string;
  /**
   * Data related to alerts.
   */
  alertData: any;
  /**
   * Flag to indicate whether notes are fetched.
   */
  getNotes = false;
  /**
   * Flag to indicate whether notes are submitted.
   */
  submitted = false;
  /**
   * Flag to indicate if the note input box should be hidden.
   */
  hideNoteInputBox = false;
  /**
   * FormControl for searching notes.
   */
  public notesSearch: FormControl = new FormControl('');
  /**
   * Input: Configuration object for managing notes.
   */
  @Input() public notesCommonRequest!: NotesCommonRequest;
  /**
   * Constructs the NotesComponent.
   * @param toasterService ToastrService for displaying toast notifications.
   * @param _noteService NoteService for interacting with note-related operations.
   */
  constructor(
    private toasterService: ToastrService,
    private _noteService: NoteService,
  ) {
    this.notesSearch.valueChanges.subscribe((search: string) => {
      this.notes = this.notesForSearch.filter((note: any) => {
        if (!note.body) return false;
        return note.body.toLocaleLowerCase().includes(search.toLocaleLowerCase());
      });
    });
  }

  /**
   * Sets up initial values and retrieves all notes.
   */
  ngOnInit(): void {
    this.hideNoteInputBox = this.notesCommonRequest.hideNoteInputBox || false;
    this.userId = this.notesCommonRequest.notesRequestData.userId;
    this.getAllNotes();
    this.saveNoteForm = new FormGroup({
      note: new FormControl(null, [Validators.required]),
    });
  }

  /**
   * Saves a new note.
   * Sends the note data to the backend via NoteService.
   * Resets the form and updates notes list upon successful save.
   */
  saveNotes() {
    this.submitted = true;
    if (this.saveNoteForm.status == 'VALID') {
      const notesData: NotesRequestData = this.notesCommonRequest.notesRequestData;
      notesData.body = this.saveNoteForm.value.note;

      this._noteService.saveNote(notesData, this.notesCommonRequest.endPoint).subscribe((response: any) => {
        this.toasterService.success(response['message'], 'Success');
        this.getAllNotes();
        this.saveNoteForm.reset();
        this.submitted = false;
      });
    }
  }

  /**
   * Retrieves all notes associated with the current user or entity.
   * Updates notes and notesForSearch arrays with retrieved data.
   */
  getAllNotes() {
    this._noteService.getNote(this.notesCommonRequest.endPoint).subscribe((response: any) => {
      this.notes = response;
      this.notesForSearch = response;
    });
  }

  /**
   * Retrieves alert data based on provided alert information.
   * Updates alertData and sets getNotes flag to true upon successful retrieval.
   * @param alert Alert object containing related user and ID information.
   */
  getAlert(alert: any) {
    this._noteService.getAlertNote(alert.relatedUser, alert.relatedTo.id).subscribe((response: any) => {
      this.alertData = response;
      this.getNotes = true;
    });
  }
  get f() {
    return this.saveNoteForm.controls;
  }

  /**
   * Formats notes text by replacing specific placeholders.
   * Replaces '[:BulletPoint]' with bullet points for improved readability.
   * @param msg Original notes text to be formatted.
   * @returns Formatted notes text with replaced placeholders.
   */
  public formatNotesText(msg: string) {
    msg = msg.replace('[:BulletPoint]', '• '); // first occurance
    const re = /\[:BulletPoint]/gi;
    return msg.replace(re, '\n• ');
  }

  /**
   * Toggles the visibility of full notes text.
   * @param item Object representing notes item with showFull flag.
   */
  toggleReadMore(item: { showFull: boolean }): void {
    item.showFull = !item.showFull;
  }
}
