@if (showSearch) {
  <div class="search_dialog dialog-xs">
    <div class="flex-between dialog-header">
      <h2 class="mb-0">{{ 'search' | translate }}</h2>
      <div class="dialog-header-icons">
        <i-feather
          name="rotate-ccw"
          [ngClass]="{ disabled: !searchKey }"
          mat-dialog-close
          (click)="remove()"
        ></i-feather>
        <i-feather name="x" mat-dialog-close></i-feather>
      </div>
    </div>
    <mat-dialog-content>
      <div class="search-input search-100">
        <label for="" class="form-label">{{
          title === 'Manager' || title === 'Managed' ? (title | clientLabel) : title
        }}</label>
        <input
          type="text"
          class="form-control"
          #searchEvent
          [(ngModel)]="searchKey"
          placeholder="{{ 'search' | translate }}"
        />
        @if (!searchKey) {
          <i-feather name="search" (click)="search()" class="feather-20 disabled"></i-feather>
        }
        @if (searchKey && getAPIProcessingFlag()) {
          <div class="input-search-spinner">
            <mat-spinner [diameter]="18"></mat-spinner>
          </div>
        }
      </div>
      <!-- {{searchList | json}} -->
      @if (!getAPIProcessingFlag() && searchList && searchList.length > 0) {
        <ul class="list-group">
          @for (item of searchList; track item) {
            <li class="searchList pointer" (click)="selectedOption(item)">
              {{ item?.name ? item?.name : item }}
            </li>
          }
        </ul>
      }
      @if (searchKey && !getAPIProcessingFlag() && searchList && searchList.length <= 0) {
        <ul class="list-group">
          <li class="searchList pointer">{{ 'noRecordFound' | translate }}</li>
        </ul>
      }
    </mat-dialog-content>
  </div>
}
<!-- filter options-->

@if (showFilter) {
  <div class="filter_dialog dialog-xs">
    <div class="flex-between dialog-header">
      <h2 class="mb-0">{{ 'Filter' | translate }}</h2>
      <div class="dialog-header-icons">
        <i-feather name="check" (click)="save()"></i-feather>
        <i-feather name="rotate-ccw" mat-dialog-close (click)="remove()"></i-feather>
        <i-feather name="x" (click)="close()"></i-feather>
      </div>
    </div>
    <mat-dialog-content>
      <form [formGroup]="checkBoxForm">
        <div class="form-group search-input search-100 mt-1">
          <input type="text" #searchEvent class="form-control" placeholder="{{ 'search' | translate }}" />
        </div>
        @if (getAPIProcessingFlag()) {
          <div class="filter-spinner">
            <mat-spinner [diameter]="24"></mat-spinner>
          </div>
        }
        @if (filterOptions && !getAPIProcessingFlag() && filterOptions.length <= 0) {
          <div>
            <ul class="list-group">
              <li class="searchList text-center">{{ 'noRecordFound' | translate }}</li>
            </ul>
            <!-- <mat-spinner [diameter]="24"></mat-spinner> -->
          </div>
        }
        @if (filterOptions && filterOptions.length > 0) {
          <div>
            <div class="form-group form-check-flex">
              <!-- indeterminated: boolean=false; -->
              <mat-checkbox
                (change)="selectAllOptions($event)"
                [(indeterminate)]="indeterminate"
                [checked]="allSelected"
                id="selectAll"
              >
              </mat-checkbox>
              <!-- <input type="checkbox" (change)="selectAllOptions($event)" [checked]="allSelected" id="selectAll" /> -->
              <label for="selectAll">{{ 'Select all' | translate }}</label>
            </div>
            @for (
              control of options['controls'] | filterPopUp: searchEvent.value : true;
              track control;
              let i = $index
            ) {
              <div class="filter-list-item" formArrayName="filterOptions">
                <div class="filter-list">
                  <div [formGroup]="control" class="form-group form-check-flex">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      [id]="i"
                      formControlName="checked"
                      (change)="onChangeCheckBox($event)"
                    />
                    <label for="i">
                      {{ control.value.name }}
                    </label>
                  </div>
                </div>
              </div>
            }
          </div>
        }
      </form>
    </mat-dialog-content>
  </div>
}
