import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnInit, signal, WritableSignal } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { FeatherModule } from 'angular-feather';
import { TranslatePipe } from 'src/app/modules/shared/pipes/translate.pipe';
import {
  FormattedIntentData,
  FormattedKeywordData,
  IntentKeywordDialogData,
} from '../../entities/intent-keyword.entities';

/**
 * Component responsible for displaying Intents and Keywords details
 */
@Component({
  selector: 'app-intent-keyword-dialog',
  standalone: true,
  imports: [FeatherModule, MatDialogModule, TranslatePipe, NgClass],
  templateUrl: './intent-keyword-dialog.component.html',
  styleUrl: './intent-keyword-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IntentKeywordDialogComponent implements OnInit {
  /**
   * Signal for Formatted Keyword Data
   */
  public keywords: WritableSignal<FormattedKeywordData[]> = signal([]);
  /**
   * Signal for Formatted Intent Data
   */
  public intents: WritableSignal<FormattedIntentData[]> = signal([]);
  /**
   * Data received by Dialog
   */
  #data: IntentKeywordDialogData = inject(MAT_DIALOG_DATA);

  /**
   * Lifecycle hook used to assign and sort Keyword and Intent Data
   */
  public ngOnInit(): void {
    this.keywords.set(this.#data.keywords);
    this.keywords.update((keywords) => {
      return keywords.sort((a, b): number => b.severity - a.severity);
    });

    this.intents.set(this.#data.intents);
    this.intents.update((intents) => {
      return intents.sort((a, b): number => b.severity - a.severity);
    });
  }
}
