import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AudioDeviceInfo, VideoDeviceInfo } from '@azure/communication-calling';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { FeatherModule } from 'angular-feather';
import { Subscription } from 'rxjs';
import { TranslatePipe } from 'src/app/modules/shared/pipes/translate.pipe';
import { VideoLobbyService } from '../../services/video-lobby.service';
/**
 * Component for managing video lobby device settings.
 */
@Component({
  selector: 'app-meeting-device-settings',
  templateUrl: './meeting-device-settings.component.html',
  styleUrls: ['./meeting-device-settings.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [FeatherModule, TranslatePipe, MatSlideToggleModule, MatSelectModule, NgbTooltipModule, ReactiveFormsModule],
})
export class MeetingDeviceSettingsComponent implements AfterViewInit, OnDestroy {
  /** Array of available cameras. */
  public availableCameras: VideoDeviceInfo[] = [];
  /** Array of available microphones. */
  public availableMicrophones: AudioDeviceInfo[] = [];
  /** Array of available speakers. */
  public availableSpeakers: AudioDeviceInfo[] = [];
  /** Instance of VideoLobbyService for managing video lobby operations. */
  public videoLobbyService = inject(VideoLobbyService);
  /** Form group for managing device settings. */
  public deviceSettingsForm = new FormGroup({
    speaker: new FormControl('', {
      nonNullable: true,
    }),
    mic: new FormControl('', {
      nonNullable: true,
    }),
    camera: new FormControl('', {
      nonNullable: true,
    }),
    invertVideo: new FormControl(false, {
      nonNullable: true,
    }),
  });
  /** Subscription for microphone value changes. */
  #micValueChangeSub$!: Subscription;
  /** Subscription for speaker value changes. */
  #speakerValueChangeSub$!: Subscription;
  /** Subscription for camera value changes. */
  #cameraValueChangeSub$!: Subscription;
  /** Subscription for invert video value changes. */
  #invertVideoValueChangeSub$!: Subscription;
  /**
   * Lifecycle hook called after component view initialization.
   */
  public async ngAfterViewInit(): Promise<void> {
    await this.#refreshAudioDeviceList();
    await this.#refreshVideoDeviceList();
    this.videoLobbyService.deviceManager.on('audioDevicesUpdated', this.#refreshAudioDeviceList);
    this.videoLobbyService.deviceManager.on('videoDevicesUpdated', this.#refreshVideoDeviceList);
    this.videoLobbyService.deviceManager.on('selectedMicrophoneChanged', this.#setMic);
    this.videoLobbyService.deviceManager.on('selectedSpeakerChanged', this.#setSpeaker);
    this.videoLobbyService.localVideoStream()?.on('videoSourceChanged', this.#setCamera);

    this.deviceSettingsForm.setValue({
      speaker: this.videoLobbyService.videoDeviceSettings.selectedSpeaker,
      mic: this.videoLobbyService.videoDeviceSettings.selectedMic,
      camera: this.videoLobbyService.videoDeviceSettings.selectedCamera,
      invertVideo: this.videoLobbyService.videoDeviceSettings.selfVideoInverted,
    });

    this.#micValueChangeSub$ = this.deviceSettingsForm.controls.mic.valueChanges.subscribe((res: string): void => {
      this.videoLobbyService.videoDeviceSettings.selectedMic = res;
      this.videoLobbyService.deviceManager.selectMicrophone(this.availableMicrophones.find((mic) => mic.id === res)!);
      this.videoLobbyService.saveVideoLobbyDeviceSettings('mic');
    });
    this.#speakerValueChangeSub$ = this.deviceSettingsForm.controls.speaker.valueChanges.subscribe(
      (res: string): void => {
        this.videoLobbyService.videoDeviceSettings.selectedSpeaker = res;
        this.videoLobbyService.deviceManager.selectSpeaker(
          this.availableSpeakers.find((speaker) => speaker.id === res)!,
        );
        this.videoLobbyService.saveVideoLobbyDeviceSettings('speaker');
      },
    );
    this.#cameraValueChangeSub$ = this.deviceSettingsForm.controls.camera.valueChanges.subscribe(
      (res: string): void => {
        this.videoLobbyService.videoDeviceSettings.selectedCamera = res;
        this.videoLobbyService
          .localVideoStream()
          ?.switchSource(this.availableCameras.find((camera) => camera.id === res)!);
        this.videoLobbyService.saveVideoLobbyDeviceSettings('camera');
      },
    );
    this.#invertVideoValueChangeSub$ = this.deviceSettingsForm.controls.invertVideo.valueChanges.subscribe(
      (res: boolean): void => {
        this.videoLobbyService.videoDeviceSettings.selfVideoInverted = res;
        this.videoLobbyService.invertVideo.set(res);
        this.videoLobbyService.saveVideoLobbyDeviceSettings('invertVideo');
      },
    );
  }
  /**
   * This method unsubscribes from various event listeners and subscriptions related to
   * video and audio device management:
   * - Unsubscribes from 'audioDevicesUpdated' event listener.
   * - Unsubscribes from 'videoDevicesUpdated' event listener.
   * - Unsubscribes from 'selectedMicrophoneChanged' event listener.
   * - Unsubscribes from 'selectedSpeakerChanged' event listener.
   * - Unsubscribes from 'videoSourceChanged' event listener on local video stream.
   * - Unsubscribes from custom observable subscriptions related to microphone, speaker,
   *   camera, and video inversion changes.
   */
  public ngOnDestroy(): void {
    this.videoLobbyService.deviceManager.off('audioDevicesUpdated', this.#refreshAudioDeviceList);
    this.videoLobbyService.deviceManager.off('videoDevicesUpdated', this.#refreshVideoDeviceList);
    this.videoLobbyService.deviceManager.off('selectedMicrophoneChanged', this.#setMic);
    this.videoLobbyService.deviceManager.off('selectedSpeakerChanged', this.#setSpeaker);
    this.videoLobbyService.localVideoStream()?.off('videoSourceChanged', this.#setCamera);
    this.#micValueChangeSub$.unsubscribe();
    this.#speakerValueChangeSub$.unsubscribe();
    this.#cameraValueChangeSub$.unsubscribe();
    this.#invertVideoValueChangeSub$.unsubscribe();
  }
  /**
   * Refreshes the list of available audio input and output devices.
   */
  #refreshAudioDeviceList = async (): Promise<void> => {
    this.availableMicrophones = await this.videoLobbyService.deviceManager.getMicrophones();
    this.availableSpeakers = await this.videoLobbyService.deviceManager.getSpeakers();
  };
  /**
   * Refreshes the list of available video input devices (cameras).
   */
  #refreshVideoDeviceList = async (): Promise<void> => {
    this.availableCameras = await this.videoLobbyService.deviceManager.getCameras();
  };
  /**
   * Sets the selected speaker in the device settings form.
   */
  #setSpeaker = () => {
    const speaker: string = this.videoLobbyService.deviceManager.selectedSpeaker?.id || '';
    this.deviceSettingsForm.controls.speaker.patchValue(speaker);
    this.videoLobbyService.videoDeviceSettings.selectedSpeaker = speaker;
  };
  /**
   * Sets the selected camera in the device settings form.
   */
  #setCamera = () => {
    const camera: string = this.videoLobbyService.localVideoStream()?.source.id || '';
    this.deviceSettingsForm.controls.camera.patchValue(camera);
    this.videoLobbyService.videoDeviceSettings.selectedCamera = camera;
  };
  /**
   * Sets the selected microphone in the device settings form.
   */
  #setMic = () => {
    const mic: string = this.videoLobbyService.deviceManager.selectedMicrophone?.id || '';
    this.deviceSettingsForm.controls.mic.patchValue(mic);
    this.videoLobbyService.videoDeviceSettings.selectedMic = mic;
  };
}
