import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  inject,
  signal,
  ViewChild,
  WritableSignal,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SafeUrl } from '@angular/platform-browser';
import { ChatParticipant, ChatThreadProperties, CreateChatThreadResult } from '@azure/communication-chat';
import { CommunicationUserIdentifier } from '@azure/communication-common';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ToastrService } from 'ngx-toastr';
import { ObservationSummarizationComponent } from 'src/app/modules/ai-metrics/components/observation-summarization/observation-summarization.component';
import { PermissionsService } from 'src/app/modules/permissions/services/permissions.service';
import { CameraViewerComponent } from 'src/app/modules/shared/components/camera-viewer/camera-viewer.component';
import { Contact } from 'src/app/modules/shared/interfaces/contact.entities';
import { ContactsService } from 'src/app/modules/shared/services/contacts.service';
import { CssService } from 'src/app/modules/shared/services/css.service';
import { HttpService } from 'src/app/modules/shared/services/http.service';
import {
  Attachment,
  ChatThreadMetaData,
  CreateThreadData,
  MessageTemplate,
  MessageTemplateReplacementKeys,
  MultipleFileUploadSuccess,
  UserMetaData,
} from '../../entities/message.entities';
import { MessageAPIsService } from '../../services/message-apis.service';
import { MessageService } from '../../services/message.service';
/**
 * Component for creating a new message thread.
 *
 * @remarks
 * This component handles the creation of chat threads, sending messages, managing participants,
 * uploading attachments, and integrating with external APIs for suggested messages and patient summaries.
 */
@Component({
  selector: 'app-create-message-thread',
  templateUrl: './create-message-thread.component.html',
  styleUrls: ['./create-message-thread.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateMessageThreadComponent implements AfterContentInit {
  /**
   * Title for the message thread header.
   */
  public headerTitle = 'newMessage';

  /**
   * Topic of the message thread.
   */
  public threadTopic = '';
  /**
   * Initial message content.
   */
  public message = '';
  /**
   * Flag indicating if contacts section is visible.
   */
  public contactsVisible = false;
  /**
   * Flag indicating if templates section is visible.
   */
  public templatesVisible = false;
  /**
   * Flag indicating if contact summary is visible.
   */
  public contactSummary = false;
  /**
   * Selected managed user for the message thread.
   */
  public selectedMangedUser: ChatParticipant | null = null;
  /**
   * List of participants in the message thread.
   */
  public participantList: ChatParticipant[] = [];
  /**
   * Flag indicating if send button was clicked.
   */
  public clickedSend = false;
  /**
   * Attachments added to the message thread.
   */
  public attachments: Attachment[] = [];
  /**
   * Number of file uploads in progress.
   */
  public fileUploadsInProgress = 0;
  /**
   * CKEditor instance for message editing.
   */
  public ckEditor = ClassicEditor;
  /**
   * Configuration for CKEditor.
   */
  public ckEditorConfig;
  /**
   * Flag indicating if the message has been edited.
   */
  public messageEdited = false;
  /**
   * Flag indicating if a template has been selected for the first time.
   */
  public firstTimeTemplateSelected = false;
  /**
   * Selected message template.
   */
  public selectedTemplate: MessageTemplate | null = null;
  /**
   * Flag indicating if patient summary is visible.
   */
  public showPatientSummary: WritableSignal<boolean> = signal(false);
  /**
   * Flag indicating if suggested message section is visible.
   */
  public showSuggestedMessage = false;
  /**
   * Flag indicating if reset button for message input is visible.
   */
  public showResetButton = false;
  /** User Id of patient */
  public patientUserId: WritableSignal<string> = signal('');

  public showMobileSuggestedMessage: WritableSignal<boolean> = signal(false);

  /**
   * Reference to Observation Summarization Component
   */
  @ViewChild(ObservationSummarizationComponent)
  private observationSummaryComponent!: ObservationSummarizationComponent;

  /**
   * Suggested message content.
   */
  private suggestedMessage = '';

  /** Injected instance of ContactsService.*/
  #contactsService = inject(ContactsService);
  #cssService = inject(CssService);

  /**
   * Constructor for CreateMessageThreadComponent.
   * @param change - ChangeDetectorRef for detecting changes in component state.
   * @param dialog - MatDialogRef for managing dialog interactions.
   * @param http - HttpService for making HTTP requests.
   * @param matDialog - MatDialog for opening dialogs like camera viewer.
   * @param messageService - MessageService for handling message-related functionalities.
   * @param messageApiService - MessageAPIsService for interacting with message-related APIs.
   * @param permissions - PermissionsService for managing user permissions.
   * @param toast - ToastrService for displaying toast notifications.
   * @param messageData - Data injected into the component via MAT_DIALOG_DATA.
   */
  public constructor(
    private change: ChangeDetectorRef,
    private dialog: MatDialogRef<CreateMessageThreadComponent>,
    private http: HttpService,
    private matDialog: MatDialog,
    private messageService: MessageService,
    private messageApiService: MessageAPIsService,
    private permissions: PermissionsService,
    private toast: ToastrService,
    @Inject(MAT_DIALOG_DATA) public messageData: CreateThreadData,
  ) {
    this.headerTitle = messageData?.headerTitle || 'newMessage';
    this.message = this.messageData?.message ? `<p>${this.messageData.message.replaceAll('\n', '<br>')}<\p>` : '';
    this.ckEditorConfig = this.messageService.ckEditorConfig;
    this.ckEditorConfig.placeholder = 'Type your message here...';
  }

  /**
   * This method sets up initial conditions for the messaging component:
   * - Shows a loader briefly using HTTP service (`this.http.loader.next(true)`).
   * - Updates contacts if `initACS` is true and initializes chat using `messageService`.
   * - Adds a participant to the participant list if `participantID` is provided.
   * - Marks the component for check to trigger change detection (`this.change.markForCheck()`).
   * - Hides the patient summary or suggested message based on `participantID` and `alertId`.
   */
  public async ngAfterContentInit(): Promise<void> {
    setTimeout(() => this.http.loader.next(true), 0);

    if (this.messageData.initACS) {
      await this.#contactsService.updateContacts();
      await this.messageService.initChat();
    }

    if (this.messageData.participantID) {
      const participant: Contact | null = this.#contactsService.getContactFromUserId(this.messageData.participantID);
      if (!participant) return;
      this.participantList.push({
        id: {
          communicationUserId: participant.userAcsId || '',
        },
        displayName: participant.userFullName,
      });
    }

    this.change.markForCheck();
    setTimeout(() => this.http.loader.next(false), 0);
    this.showOrHidePatientSummary(this.messageData?.participantID);
    this.showOrHideSuggestedMessage(this.messageData?.alertId);
  }

  /**
   * Method to create a new chat thread.
   */
  public async createThread(): Promise<void> {
    this.clickedSend = true;
    this.threadTopic = this.threadTopic.trimEnd();

    if ((this.participantList.length || this.selectedMangedUser) && this.threadTopic && this.message) {
      this.http.loader.next(true);
      const participants: ChatParticipant[] = [...this.participantList];
      if (this.selectedMangedUser) {
        participants.push(this.selectedMangedUser);
      }

      try {
        const chatThread: CreateChatThreadResult = await this.messageService.createChatThread(
          this.threadTopic,
          participants,
        );
        let uploadedFiles: MultipleFileUploadSuccess = { urls: [] };

        if (!chatThread.chatThread) throw 'Chat Thread not created';

        if (this.attachments.length) {
          try {
            const files: File[] = this.attachments.map((el) => el.file || ({} as File));
            uploadedFiles = await this.messageService.uploadMultipleFiles(files, chatThread.chatThread.id);
          } catch {
            this.toast.warning(
              'There was an error uploading the attachments. Sending the message without attachments. Please try uploading again.',
            );
          }
        }

        await this.messageService.sendMessage(
          chatThread.chatThread.id,
          this.messageService.ckEditorHtml2Text(this.message),
          uploadedFiles.urls,
        );
        this.trackSuggestedMessage(chatThread.chatThread.id);
        setTimeout(() => {
          this.messageService.closedMessageDialog$.next(true);
          this.dialog.close(
            this.createTemporaryMetaData(chatThread.chatThread || ({} as ChatThreadProperties), participants),
          );
        }, 1500);
      } catch {
        this.http.loader.next(false);
        this.toast.error('There was an error in starting a new chat. Please try again', 'Error');
      }
    }
  }
  /**
   * Method to show contacts panel.
   */
  public showContacts(): void {
    if (this.messageData.participantID) return;
    this.contactsVisible = true;
    this.templatesVisible = false;
  }

  /**
   * Sets the visibility to show the templates section and hide the contacts section.
   */
  public showTemplates(): void {
    this.contactsVisible = false;
    this.templatesVisible = true;
  }
  /**
   * Method to update managed user in the participant list.
   * @param user - The managed user to update.
   */
  public async updateManagedUser(user: ChatParticipant | null): Promise<void> {
    this.selectedMangedUser = user;
    this.messageEdited = false;
    this.message = this.addParticipantsToMessageTemplate(
      this.selectedTemplate?.message ? `<p>${this.selectedTemplate?.message}<\p>` : this.message,
    );
    if (!this.#cssService.isLG()) {
      this.showOrHidePatientSummary(
        this.#contactsService.getUserFromACSId((user?.id as CommunicationUserIdentifier).communicationUserId)?.user,
      );
    }
  }
  /**
   * Method to update participant list.
   */
  public updateParticipantList(): void {
    this.messageEdited = false;
    this.message = this.addParticipantsToMessageTemplate(
      this.selectedTemplate?.message ? `<p>${this.selectedTemplate?.message}<\p>` : this.message,
    );
  }

  public showObservationSummary(acsId: string): void {
    this.showOrHidePatientSummary(this.#contactsService.getUserFromACSId(acsId)?.user, true);
  }
  /**
   * Method to remove managed user from the participant list.
   * @param ev - The MouseEvent object.
   */
  public removeManagedUser(ev: MouseEvent): void {
    this.selectedMangedUser = null;
    this.messageEdited = false;
    this.message = this.addParticipantsToMessageTemplate(
      this.selectedTemplate?.message ? `<p>${this.selectedTemplate?.message}<\p>` : this.message,
    );
    this.showOrHidePatientSummary(undefined);
    ev.stopPropagation();
  }
  /**
   * Method to remove participant from the participant list.
   * @param ev - The MouseEvent object.
   * @param index - The index of the participant to remove.
   */
  public removeParticipant(ev: MouseEvent, index: number): void {
    if (this.messageData?.participantID) return;
    this.participantList.splice(index, 1);
    this.participantList = [...this.participantList];
    this.messageEdited = false;
    this.message = this.addParticipantsToMessageTemplate(
      this.selectedTemplate?.message ? `<p>${this.selectedTemplate?.message}<\p>` : this.message,
    );
    ev.stopPropagation();
  }
  /**
   * Method to handle file selection.
   * @param ev - The Event object.
   */
  public onFileSelected(ev: Event): void {
    const files: FileList | null = (ev.target as HTMLInputElement)?.files;
    if (files && files.length) {
      for (let i = 0; i < files.length; i++) {
        this.previewFile(files[i]);
      }
    }
  }
  /**
   * Method to open the camera viewer for capturing images.
   */
  public openCamera(): void {
    if (this.attachments.length >= this.messageService.maxNumberOfFilesAllowed) {
      this.toast.error('Only 10 files are allowed per message.', 'Error');
      return;
    }

    const dialogRef: MatDialogRef<CameraViewerComponent> = this.matDialog.open(CameraViewerComponent);
    dialogRef.afterClosed().subscribe((res: File): void => {
      if (res) {
        this.previewFile(res);
      }
    });
  }
  /**
   * Method to preview the selected file.
   * @param file - The File object to preview.
   */
  public previewFile(file: File): void {
    if (file) {
      if (this.attachments.length >= this.messageService.maxNumberOfFilesAllowed) {
        this.toast.error('Only 10 files are allowed per message.', `Error File - ${file.name}`);
        return;
      }

      if (file.size > this.messageService.maxFileSizeAllowed) {
        this.toast.error('Maximum 25MB allowed per file', `Error File - ${file.name}`);
        return;
      }

      const localFileUrl: string = URL.createObjectURL(file);
      const fileIcon: string | SafeUrl | null = this.messageService.getFileIcon(file.type, localFileUrl);

      if (!fileIcon) {
        this.toast.error('File format not supported', `Error File - ${file.name}`);
        return;
      }

      this.attachments.push({
        fileIcon: fileIcon,
        file,
        mimeType: '',
        documentId: '',
        url: '',
      });
    }
  }
  /**
   * Method to remove file attachment from the attachments list.
   * @param index - The index of the attachment to remove.
   */
  public removeFile(index: number): void {
    this.attachments.splice(index, 1);
  }
  /**
   * Method to select a message template.
   * @param template - The selected message template.
   */
  public selectTemplate(template: MessageTemplate): void {
    this.selectedTemplate = template;
    this.threadTopic = this.selectedTemplate.subject;
    this.messageEdited = false;
    this.firstTimeTemplateSelected = true;
    this.message = `<p>${this.addParticipantsToMessageTemplate(this.selectedTemplate.message)}<\p>`;
  }
  /**
   * Method called when the message content is edited.
   */
  public onMessageEdit(): void {
    if (this.messageEdited) {
      this.selectedTemplate = null;
    }
    this.messageEdited = true;
  }

  /**
   * Show or Hide Patient Observation Summary
   * @param userId - Selected User Id
   */
  public showOrHidePatientSummary(userId: string | undefined, forceShow = false): void {
    if (!userId) {
      this.showPatientSummary.set(false);
      return;
    }

    if (!this.permissions.hasPermission('ai.PatientSummarization.Read')) return;

    if (!this.#cssService.isLG() || forceShow) {
      this.showPatientSummary.set(true);
    }
    setTimeout(() => {
      this.patientUserId.set(userId);
    }, 0);
  }

  /**
   * Show or Hide Suggested Message Section
   * @param alertId - Alert Id of selected alert
   */
  public showOrHideSuggestedMessage(alertId: string | undefined): void {
    if (!alertId) {
      this.showSuggestedMessage = false;
      return;
    }

    if (!this.permissions.hasPermission('ai.SuggestedHcpReplies.Read')) return;

    this.showSuggestedMessage = true;
    setTimeout(() => {
      this.messageApiService.suggestedMessageId$.next(alertId);
    }, 0);
  }

  /**
   * Add Suggested Message to Message Input
   * @param message - suggested message
   */
  public useSuggestion(message: string): void {
    if (!message) {
      this.showSuggestedMessage = false;
      return;
    }

    this.message = `<p>${this.addParticipantsToMessageTemplate(message)}<\p>`;
    this.suggestedMessage = message;
    this.showSuggestedMessage = false;
    this.showResetButton = true;
  }

  /**
   * Reset Message Input
   */
  public resetMessage(): void {
    this.message = '';
    this.showMobileSuggestedMessage.set(false);
    this.showSuggestedMessage = true;
    this.showResetButton = false;
    setTimeout(() => {
      this.messageApiService.suggestedMessageId$.next(this.messageData?.alertId || '');
    }, 0);
  }

  /**
   * Method to close the dialog.
   */
  public closeDialog(): void {
    this.messageService.closedMessageDialog$.next(true);
    this.dialog.close();
  }

  /**
   * Reloads the Observation Summary
   */
  public reloadObservationSummary(): void {
    this.observationSummaryComponent.reload();
  }

  /**
   * Creates metadata for a chat thread based on the provided chat thread properties and participants.
   * @param chatThread The properties of the chat thread.
   * @param participants The list of participants in the chat thread.
   * @returns Metadata for the chat thread.
   */
  private createTemporaryMetaData(
    chatThread: ChatThreadProperties,
    participants: ChatParticipant[],
  ): ChatThreadMetaData {
    const recipients: UserMetaData[] = [];
    participants.forEach((participant: ChatParticipant): void => {
      const acsId = (participant.id as CommunicationUserIdentifier).communicationUserId;
      const contact: Contact | null = this.#contactsService.getUserFromACSId(acsId);
      recipients.push({
        communicationUserId: acsId,
        displayName: participant.displayName || '',
        user: contact?.user || '',
        userType:
          (this.selectedMangedUser?.id as CommunicationUserIdentifier)?.communicationUserId === acsId
            ? 'Managed'
            : 'Manager',
      });
    });
    recipients.push({
      communicationUserId: (chatThread.createdBy as CommunicationUserIdentifier).communicationUserId,
      displayName: this.messageService.displayName,
      user: '',
      userType: 'Manager',
    });

    return {
      lastMessage: {
        message: this.messageService.ckEditorHtml2Text(this.message),
        date: new Date().toISOString(),
        user: '',
        displayName: this.messageService.displayName,
        communicationUserId: (chatThread.createdBy as CommunicationUserIdentifier).communicationUserId,
      },
      topic: chatThread.topic,
      unreadCount: 0,
      createdAt: chatThread.createdOn.toISOString(),
      user: '',
      threadId: chatThread.id,
      recipients,
      unreadMessages: [],
      initiator: {
        communicationUserId: (chatThread.createdBy as CommunicationUserIdentifier).communicationUserId,
        displayName: this.messageService.displayName,
        user: '',
        userType: 'Manager',
      },
    };
  }

  /**
   * Replaces placeholder keys in the message template with actual participant names and sender information.
   * @param message The message template to be modified.
   * @returns The modified message template with participant names and sender information replaced.
   */
  private addParticipantsToMessageTemplate(message: string): string {
    const participant: Contact | null = this.selectedMangedUser
      ? this.#contactsService.getUserFromACSId(
          (this.selectedMangedUser.id as CommunicationUserIdentifier).communicationUserId,
        )
      : this.participantList.length
        ? this.#contactsService.getUserFromACSId(
            (this.participantList[0].id as CommunicationUserIdentifier).communicationUserId,
          )
        : null;

    message = message.replaceAll('\n', '<br>');
    const receiverName = participant?.userFullName.split(' ');
    message = message.replaceAll(
      MessageTemplateReplacementKeys.RECEIVERFIRSTNAME,
      receiverName?.slice(0, -1).join(' ') || MessageTemplateReplacementKeys.RECEIVERFIRSTNAME,
    );
    message = message.replaceAll(
      MessageTemplateReplacementKeys.RECEIVERLASTNAME,
      receiverName?.at(-1) || MessageTemplateReplacementKeys.RECEIVERLASTNAME,
    );
    message = message.replaceAll(
      MessageTemplateReplacementKeys.SENDERFIRSTNAME,
      this.messageService.userDemographicData?.firstName || MessageTemplateReplacementKeys.SENDERFIRSTNAME,
    );
    message = message.replaceAll(
      MessageTemplateReplacementKeys.SENDERLASTNAME,
      this.messageService.userDemographicData.lastName || MessageTemplateReplacementKeys.SENDERLASTNAME,
    );
    return message;
  }

  /**
   * Tracks the usage of a suggested message if it is used without modification.
   * @param threadId The ID of the thread where the message was sent.
   */
  private trackSuggestedMessage(threadId: string): void {
    if (this.suggestedMessage && this.messageService.ckEditorHtml2Text(this.message).trim() === this.suggestedMessage) {
      this.messageApiService.trackSuggestedMessage(threadId, {
        reply: this.suggestedMessage,
      });
    }
  }
}
