import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { map } from 'rxjs';
import { UsersService } from '../../users.service';

/**
 * Component for managing user interactions in a group dialog.
 */
@Component({
  selector: 'app-dialog',
  templateUrl: './managed-user.component.html',
  styleUrls: ['./managed-user.component.scss'],
})
export class ManagedUserComponent {
  /** confirmation message displayed in the dialog */
  message: string = 'Are you sure?';
  /** Text for the confirm button */
  confirmButtonText = 'Yes';
  /** Text for the cancel button */
  cancelButtonText = 'Cancel';
  /** Search query string for filtering users */
  search: string = '';
  /** List of user data */
  userData = [];
  /** List of users to be added */
  userAddList: string[] = [];
  /** Group ID */
  gid = '';
  /** User ID */
  uid = '';
  /** List of user IDs to add */
  addUserData: any = [];
  /** Flag to show/hide user addition section */
  showRight: boolean = false;
  /** Name of the group */
  groupName = '';
  /** Indicates if the current user is the owner of the group */
  isOwner = true;
  /** List of co-owner user IDs */
  coOwnerList: string[] = [];

  /**
   * Constructor of the group dialog.
   * @param _UsersService - Service for user-related operations.
   * @param toast - Toastr service for displaying notifications.
   * @param dialog - MatDialog service for opening dialogs.
   * @param data - Data passed to the dialog when opened.
   * @param dialogRef - Reference to the dialog instance.
   */
  constructor(
    private _UsersService: UsersService,
    private toast: ToastrService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<ManagedUserComponent>,
  ) {
    if (data) {
      // console.log(data):87
      this.userData = [];
      this.userAddList = [];
      this.uid = data.uid;
      this.gid = data.gid;
      this.groupName = data.group;
      this.userAddList = data.member;
      this.coOwnerList = data.coOwner;
      this.message = data.message || this.message;
      this.isOwner = data.isOwner;
      if (data.buttonText) {
        this.confirmButtonText = data.buttonText.ok || this.confirmButtonText;
        this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
      }
    }

    this.getUserGroups(this.uid);
    this.getGroupUsers(this.gid, 'member');
  }

  /**
   * Closes the dialog with a confirmation response.
   */
  onConfirmClick(): void {
    this.dialogRef.close(true);
  }

  /**
   * Opens the section for adding users.
   */
  openAddUser() {
    this.showRight = true;
  }

  /**
   * Closes the section for adding users.
   */
  closeAddUser() {
    this.showRight = false;
  }

  /**
   * Adds a member to the user group.
   * @param gid - Group ID to which the user is being added.
   * @param uid - User ID of the member being added.
   */
  addMemberInUserGroup(gid: any, uid: any) {
    const postData = {
      users: uid,
    };
    const userLevel = 'member';
    this._UsersService
      .addUserGroup(postData, gid, userLevel)
      .pipe(
        map((response: any) => {
          return response;
        }),
      )
      .subscribe(() => {
        this.getUserGroups(this.uid);
        this.getGroupUsers(this.gid, 'member');
        this.toast.success('User added in group Successfully');
      });
  }

  /**
   * Removes a member from the user group.
   * @param uid - User ID of the member to be removed.
   */
  removeMemberInUserGroup(uid: string) {
    const postData = 'member';

    this._UsersService
      .deleteUserGroup(postData, this.gid, uid)
      .pipe(
        map((response: any) => {
          return response;
        }),
      )
      .subscribe(() => {
        const deletedData = this.userData.filter((data: any) => data.user == uid);
        this.userAddList.push(deletedData[0]);
        this.userData = this.userData.filter((data: any) => data.user != uid);
        this.toast.success('User removed from group Successfully');
      });
  }

  /**
   * Fetches users belonging to a specific group.
   * @param gid - Group ID to fetch users from.
   * @param userType - Type of users to fetch (e.g., 'member').
   */
  getGroupUsers(gid: any, userType: any) {
    this.userData = [];
    this._UsersService.getGroupUsers(gid, userType).subscribe((result) => {
      this.userData = result.data;
      if (this.userData) {
        const idArray: Array<object> = [];
        this.userData.forEach((item: any) => {
          idArray.push(item.user);
        });

        const filterInPlace = (array: any, predicate: any) => {
          let end = 0;
          for (let i = 0; i < array.length; i++) {
            const obj = array[i];
            if (predicate(obj)) {
              array[end++] = obj;
            }
          }
          array.length = end;
        };
        const toDelete = new Set(idArray);

        filterInPlace(this.userAddList, (obj: any) => !toDelete.has(obj.id));
      }
      //console.log(this.userAddList)
    });
  }

  /**
   * Fetches groups associated with a specific user.
   * @param uid - User ID to fetch group data for.
   */
  getUserGroups(uid: any) {
    this._UsersService.getUserGroupData(uid).subscribe(() => {
      // console.log(result, 'getUserGroups');
      // this.userData = result;
      // result.forEach((item: any, index:any) => {
      //   if(item.id === this.gid){
      //     this.userData = item;
      //   }
      // });
    });
  }

  /**
   * Updates the list of selected user IDs based on checkbox state.
   * @param data - User data containing user ID.
   * @param event - Checkbox change event.
   */
  getUserData(data: any, event: any) {
    if (event.target.checked) {
      this.addUserData.push(data.id);
    } else {
      if (this.addUserData.length > 1) {
        this.addUserData = this.addUserData.filter((v: any) => v != data.id);
      } else {
        this.addUserData = [];
      }
    }
  }

  /**
   * Closes the dialog and adds selected users to the group.
   */
  onDoneClick(): void {
    this.dialogRef.close(true);
    if (this.addUserData.length > 0) {
      this.addMemberInUserGroup(this.gid, this.addUserData);
    }
  }

  /**
   * Clears the search input field.
   */
  clearSearch() {
    this.search = '';
  }
}
