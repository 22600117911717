<div class="page-header">
  @if (clientId && !uid) {
    <h1>{{ 'userManageMent' | translate }}</h1>
  }
  @if (clientId && uid) {
    <h1>{{ 'userManageMent' | translate }}</h1>
  }
  @if (!clientId) {
    <h1>{{ 'addSystemUser' | translate }}</h1>
  }
  <nav>
    <ol class="breadcrumb">
      <li class="breadcrumb-item pointer">
        <a (click)="onClickBreadcrumbMenu(clientId ? '/userManagment/list' : 'userManagment/list')">{{
          'userManageMent' | translate
        }}</a>
      </li>
      @if (clientId && uid) {
        <li class="breadcrumb-item pointer">
          <a (click)="onClickBreadcrumbMenu('/userManagment/profile')">{{ 'userProfile' | translate }}</a>
        </li>
      }
      @if (clientId && uid) {
        <li class="breadcrumb-item active">Edit User{{ 'userProfile' | translate }}</li>
      }
      @if (clientId && !uid) {
        <li class="breadcrumb-item active">{{ 'addUSer' | translate }}</li>
      }
      @if (!clientId) {
        <li class="breadcrumb-item active">{{ 'addSystemUser' | translate }}</li>
      }
    </ol>
  </nav>
</div>
<div class="page-content-wrapper">
  <form [formGroup]="addUserFormGroup">
    <div class="row">
      <div class="col-md-6">
        <div class="card-base">
          <div class="flex-between mcard-header">
            <h1>{{ 'titleProfile' | translate }}</h1>
            <div class="card-header-icons mb-0">
              <i-feather name="check" (click)="submit()"></i-feather>
              <i-feather name="x" (click)="redirectToUsers()"></i-feather>
            </div>
          </div>
          <fieldset [class.disabled]="uid">
            <div class="form-group">
              <label for="Affiliation Code" required>User Type</label>
              <div class="d-flex">
                <div class="form-check-flex me-2">
                  <input
                    class="form-check-input"
                    type="radio"
                    formControlName="userType"
                    value="Managed"
                    (click)="addRemovedAffiliationValidation('Managed')"
                  />
                  <label class="check-flex-label" for="Managed"> {{ 'Managed' | clientLabel }} </label>
                </div>
                <div class="form-check-flex">
                  <input
                    class="form-check-input"
                    type="radio"
                    formControlName="userType"
                    value="Manager"
                    (click)="addRemovedAffiliationValidation('Manager')"
                  />
                  <label class="check-flex-label" for="Manager"> {{ 'Manager' | clientLabel }} </label>
                </div>
              </div>
              @if (submitted && formControls.userType.errors) {
                <div class="invalid-feedback">
                  @if (formControls.userType.errors.required) {
                    <div class="danger">
                      {{ 'userTypeIsRequired' | translate }}
                    </div>
                  }
                </div>
              }
            </div>
            <div class="form-group">
              <div class="custom-select-arrow">
                <label for="Affiliation Code" required>{{ 'affiliationCode' | translate }}</label>
                <select
                  class="form-control"
                  formControlName="affiliationCode"
                  (change)="onChangeAffiliationCode()"
                  [ngClass]="{
                    'is-invalid': submitted && formControls.affiliationCode.errors,
                    disabled: formControls.userType.value.toLowerCase() === ''
                  }"
                >
                  <option disabled value="">{{ 'selectOption' | translate }}</option>
                  @for (affiliationCodeO of affiliationCodes; track affiliationCodeO) {
                    <option [ngValue]="affiliationCodeO.code">
                      {{ affiliationCodeO.code }}
                    </option>
                  }
                </select>
                @if (submitted && formControls.affiliationCode.errors) {
                  <div class="invalid-feedback">
                    @if (formControls.affiliationCode.errors.required) {
                      <div class="danger">
                        {{ 'affliationCodeValidationMessage' | translate }}
                      </div>
                    }
                  </div>
                }
              </div>
            </div>
          </fieldset>
          <fieldset>
            <h3 class="pb-1">{{ 'userNameAndPassword' | translate }}</h3>
            <div [class.disabled]="uid">
              <div class="form-group mb-3">
                <label for="" class="form-label">{{ 'username' | translate }}*</label>
                <input
                  type="text"
                  class="form-control"
                  (keydown.space)="$event.preventDefault()"
                  minlength="4"
                  formControlName="userName"
                  [ngClass]="{ 'is-invalid': submitted && formControls.userName.errors }"
                />
                @if (submitted && formControls.userName.errors) {
                  <div class="invalid-feedback">
                    @if (formControls.userName.errors.required) {
                      <div class="danger">
                        {{ 'usernameRequiredValidationMessage' | translate }}
                      </div>
                    }
                  </div>
                }
              </div>
              <div class="form-group-inline">
                <div class="form-group">
                  <label for="" class="form-label">{{ 'password' | translate }}*</label>
                  <input
                    type="password"
                    class="form-control"
                    formControlName="password"
                    [ngClass]="{
                      'is-invalid': (submitted || formControls.password.touched) && formControls.password.errors
                    }"
                  />
                  @if ((submitted || formControls.password.touched) && formControls.password.errors) {
                    <div class="invalid-feedback">
                      @if (formControls.password.errors.required) {
                        <div>
                          {{ 'passwordRequiredValidationMessage' | translate }}
                        </div>
                      }
                      @if (
                        (submitted || formControls.password.touched) &&
                        (formControls.password.errors.minlength?.actualValue !== 8 ||
                          formControls.password.errors?.['pattern'])
                      ) {
                        <div class="danger">
                          {{ 'enterMinimum8CharsWithAtleastANumberLowerUpperChar' | translate }}.
                        </div>
                      }
                    </div>
                  }
                </div>
                <div class="form-group mt-0">
                  <label for="" class="form-label">{{ 'confirmPassword' | translate }}*</label>
                  <input
                    type="password"
                    class="form-control"
                    formControlName="cPassword"
                    [ngClass]="{
                      'is-invalid': (submitted || formControls.cPassword.touched) && formControls.cPassword.errors
                    }"
                  />
                  @if ((submitted || formControls.cPassword.touched) && formControls.cPassword.errors) {
                    <div class="invalid-feedback">
                      @if (formControls.cPassword.errors.required) {
                        <div>
                          {{ 'confirmPasswordRequiredValidationMessage' | translate }}
                        </div>
                      }
                      @if (formControls.cPassword.errors.mustMatch) {
                        <div>{{ 'passwordsMismatch' | translate }}</div>
                      }
                      @if (
                        (submitted || formControls.cPassword.touched) &&
                        (formControls.cPassword.errors.minlength.actualValue !== 8 ||
                          formControls.cPassword.errors?.['pattern'])
                      ) {
                        <div class="danger">
                          {{ 'enterMinimum8CharsWithAtleastANumberLowerUpperChar' | translate }}.
                        </div>
                      }
                    </div>
                  }
                </div>
              </div>
              <div class="form-check-flex form-group">
                <input type="checkbox" class="form-check-input" id="psdcg" formControlName="requirePasswordChange" />
                <label for="psdcg">{{ 'requirePasswordChange' | translate }}</label>
              </div>
            </div>
            @if (this.uid) {
              <div class="form-group">
                <label for="">{{ 'status' | translate }}*</label>
                <div class="custom-select-arrow">
                  <select
                    class="form-control"
                    formControlName="status"
                    [ngClass]="{ 'is-invalid': submitted && formControls.status.errors }"
                  >
                    <option value="" disabled>{{ 'selectOption' | translate }}</option>
                    <option value="Active">{{ 'active' | translate }}</option>
                    <option value="InActive">{{ 'inactive' | translate }}</option>
                  </select>
                </div>
                @if (submitted && formControls.status.errors) {
                  <div class="invalid-feedback">
                    @if (formControls.status.errors.required) {
                      <div>
                        {{ 'statusIsRequired' | translate }}
                      </div>
                    }
                  </div>
                }
              </div>
            }
          </fieldset>
          <fieldset>
            <h3 class="pb-1">{{ 'personalInformation' | translate }}</h3>
            <div class="form-group-inline">
              @if (formControls.userType.value && formControls.userType.value.toLowerCase() === 'manager') {
                <div class="form-group suffix">
                  <label for="">{{ 'suffix' | translate }}</label>
                  <select class="form-control" formControlName="suffix">
                    <option value="" disabled>{{ 'selectOption' | translate }}</option>
                    @for (suffix of suffixes; track suffix) {
                      <option value="{{ suffix }}">
                        {{ suffix }}
                      </option>
                    }
                  </select>
                </div>
              }
              <div class="form-group">
                <label for="" class="form-label">{{ 'firstName' | translate }}*</label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="firstName"
                  trim
                  [ngClass]="{ 'is-invalid': submitted && formControls.firstName.errors }"
                />
                @if (submitted && formControls.firstName.errors) {
                  <div class="invalid-feedback">
                    @if (formControls.firstName.errors.required) {
                      <div class="danger">
                        {{ 'firstNameValidationMessage' | translate }}
                      </div>
                    }
                    @if (
                      submitted &&
                      (formControls.firstName.errors?.['firstName'] || formControls.firstName.errors?.['pattern'])
                    ) {
                      <div class="danger">
                        {{ 'invalidFirstName' | translate }}
                      </div>
                    }
                  </div>
                }
              </div>
              <div class="form-group">
                <label for="" class="form-label">{{ 'lastName' | translate }}*</label>
                <input
                  type="text"
                  class="form-control"
                  trim
                  formControlName="lastName"
                  [ngClass]="{ 'is-invalid': submitted && formControls.lastName.errors }"
                />
                @if (submitted && formControls.lastName.errors) {
                  <div class="invalid-feedback">
                    @if (formControls.lastName.errors.required) {
                      <div class="danger">
                        {{ 'lastNameValidationMessage' | translate }}
                      </div>
                    }
                    @if (
                      submitted &&
                      (formControls.lastName.errors?.['lastName'] || formControls.lastName.errors?.['minLength'])
                    ) {
                      <div class="danger">
                        {{ 'firstNameMinLength1Required' | translate }}
                      </div>
                    }
                  </div>
                }
              </div>
            </div>
            <div class="form-group">
              <div class="custom-select-arrow">
                <label for="">{{ 'sex' | translate }}*</label>
                <select
                  class="form-control sm-50"
                  formControlName="sex"
                  [ngClass]="{ 'is-invalid': submitted && formControls.sex.errors }"
                >
                  <option value="" disabled>{{ 'selectOption' | translate }}</option>
                  <option value="male">{{ 'male' | translate }}</option>
                  <option value="female">{{ 'female' | translate }}</option>
                  <option value="unknown">{{ 'unknown' | translate }}</option>
                </select>
                @if (submitted && formControls.sex.errors) {
                  <div class="invalid-feedback">
                    @if (formControls.sex.errors.required) {
                      <div class="danger">
                        {{ 'genderRequiredValidationMessage' | translate }}
                      </div>
                    }
                  </div>
                }
              </div>
            </div>

            @if (formControls.userType.value && formControls.userType.value.toLowerCase() === 'managed') {
              <div class="form-group">
                <app-dob
                  [dob]="user.demographics?.birthDate"
                  (updateDob)="updateDOB($event)"
                  (errorCode)="errorCallbackDOB($event)"
                ></app-dob>
              </div>
            }
            <!-- <div
            class="form-group"
            *ngIf="formControls?.userType?.value && formControls?.userType?.value.toLowerCase() === 'managed'"
            >
            <label for="">{{ 'Manager' | clientLabel }}*</label>

            <select
              class="form-control"
              formControlName="manager"
              [ngClass]="{ 'is-invalid': submitted && formControls.manager.errors }"
              >
              <option value="" disabled>{{ 'selectOption' | translate }}</option>
              <option *ngFor="let manager of managers" [ngValue]="manager.id">
                {{ manager.name }}
              </option>
            </select>
            <div *ngIf="submitted && formControls.manager.errors" class="invalid-feedback">
              <div *ngIf="formControls.manager.errors.required" class="danger">
                {{ 'managerIsRequired' | translate }}
              </div>
            </div>
          </div> -->
          </fieldset>
          <fieldset>
            <h3 class="pb-1">{{ 'contactInformation' | translate }}</h3>
            <label for="" class="form-label disabled mb-3">{{ 'oneFieldRequired' | translate }}</label>
            <div class="form-group-inline">
              <div class="form-group">
                <label for="" class="form-label">{{ 'mobilePhone' | translate }}*</label>
                <input
                  type="text"
                  digitOnly
                  trim
                  class="form-control phone sm-50"
                  formControlName="phone"
                  minlength="10"
                  maxlength="10"
                  [ngClass]="{ 'is-invalid': submitted && formControls.phone.errors }"
                />
                @if (submitted && formControls.phone.errors) {
                  <div class="invalid-feedback">
                    @if (formControls.phone.errors.required) {
                      <div class="danger">
                        {{ 'mobilePhoneValidationMessage' | translate }}
                      </div>
                    }
                    @if (formControls.phone.errors.optionalMatch) {
                      <div class="danger">
                        {{ 'validMobilePhoneIsRequired' | translate }}
                      </div>
                    }
                  </div>
                }
              </div>
              <div class="form-group">
                <label for="" class="form-label">{{ 'emailAddress' | translate }}*</label>
                <input
                  type="email"
                  class="form-control"
                  trim
                  formControlName="emailAddress"
                  [ngClass]="{ 'is-invalid': submitted && formControls.emailAddress.errors }"
                />
                @if (submitted && formControls.emailAddress.errors) {
                  <div class="invalid-feedback">
                    @if (formControls.emailAddress.errors.required) {
                      <div class="danger">
                        {{ 'emailAddressIsRequired' | translate }}
                      </div>
                    }
                    @if (formControls.emailAddress.errors.optionalMatch) {
                      <div class="danger">
                        {{ 'validEmailAddressIsRequired' | translate }}
                      </div>
                    }
                  </div>
                }
              </div>
            </div>
            @if (formControls.userType.value && formControls.userType.value.toLowerCase() === 'manager') {
              <div class="form-group">
                <label for="" class="form-label">{{ 'workPhone' | translate }}</label>
                <input
                  type="tel"
                  digitOnly
                  class="form-control phone sm-50"
                  minlength="10"
                  maxlength="10"
                  formControlName="workPhone"
                />
              </div>
            }
          </fieldset>

          <fieldset>
            <h3 class="pb-1">{{ 'systemSettings' | translate }}</h3>
            <div class="form-group">
              <div class="custom-select-arrow">
                <label for="" class="form-label">{{ 'timeZone' | translate }}</label>
                <select
                  class="form-control"
                  formControlName="timeZoneName"
                  (change)="onChangeTimeZone(formControls.timeZoneName.value)"
                >
                  <option value="" disabled>{{ 'selectOption' | translate }}</option>
                  @for (tz of tzNames; track tz) {
                    <option [value]="tz">
                      {{ tz }}
                    </option>
                  }
                </select>
              </div>
            </div>
            <!-- <ng-container formArrayName="externalIds">
          <div
            class="select-wrap form-group"
            *ngFor="let externalId of getIdFormArray().controls; let i = index"
            [formGroupName]="i"
            >
            <div class="custom-select-arrow">
              <label for="" class="form-label"
                ><span *ngIf="i === 0">{{ 'externalID' | translate }}</span></label
                >
                <select class="form-control" formControlName="k">
                  <option value="" disabled>{{ 'selectOption' | translate }}</option>
                  <option *ngFor="let type of types" [ngValue]="type?.name">
                    {{ type?.name }}
                  </option>
                </select>
              </div>
              <div class="system-setting-value">
                <div class="form-group">
                  <label for="" class="form-label"
                    ><span *ngIf="i === 0">{{ 'value' | translate }}</span></label
                    >
                    <input type="text" class="form-control" formControlName="v" />
                  </div>
                </div>
                <i-feather *ngIf="i === 0" name="plus-circle" (click)="addItem()" class="pointer"></i-feather>
                <i-feather *ngIf="i !== 0" name="x-circle" (click)="removeItem(i)" class="pointer"></i-feather>
              </div>
            </ng-container> -->

            @if (formControls.userType.value !== '') {
              <div formArrayName="externalIds">
                <h3 class="pt-4 pb-1">External ID</h3>
                @for (externalId of getIdFormArray().controls; track externalId; let i = $index) {
                  <div class="form-group" [formGroupName]="i">
                    <label for="" class="form-label"
                      ><span
                        >{{ externalIds(externalId.value) }}
                        @if (isRequiredShow && externalId.get('k')?.value.toLowerCase() === 'npi') {
                          <span> * </span>
                        }
                      </span></label
                    >
                    @if (externalId.get('k')?.value.toLowerCase() !== 'npi') {
                      <span>
                        <input type="text" class="form-control" formControlName="v" />
                      </span>
                    }
                    @if (externalId.get('k')?.value.toLowerCase() === 'npi') {
                      <span>
                        <input
                          type="text"
                          class="form-control"
                          formControlName="v"
                          appOnlyNumber
                          [ngClass]="{
                            'is-invalid':
                              (submitted && externalId.get('v')?.errors?.required) ||
                              externalId.get('v')?.errors?.pattern
                          }"
                        />
                      </span>
                    }
                    @if (submitted || (externalId.get('v')?.invalid && externalId.get('v')?.touched)) {
                      <div class="text-danger">
                        @if (submitted && externalId.get('v')?.errors?.required) {
                          {{ externalIds(externalId.value) }} is required.
                        }
                        @if (externalId.get('v')?.errors?.pattern) {
                          Please enter 10 numeric digits
                        }
                      </div>
                    }
                  </div>
                }
              </div>
            }
          </fieldset>
        </div>
      </div>
      @if (selectedAffiliationCode && selectedAffiliationCode.userType) {
        <div class="col-md-6">
          <div class="card-base add-client">
            <div class="view-detail-row">
              <div class="view-detail-item">
                <strong class="view-detail-label">{{ 'userType' | translate }}: </strong>
                @if (selectedAffiliationCode.userType) {
                  <div class="view-detail-info">
                    <span>{{ selectedAffiliationCode.userType || '-' }}</span>
                  </div>
                } @else {
                  <div>-</div>
                }
              </div>
              <div class="view-detail-item">
                <strong class="view-detail-label">{{ 'roles' | translate }}: </strong>
                @if (selectedAffiliationCode.roles?.length) {
                  <div class="view-detail-info">
                    @for (role of selectedAffiliationCode.roles; track role) {
                      <span>{{ role.label ? role.label : '-' }}</span>
                    }
                  </div>
                } @else {
                  <div>-</div>
                }
              </div>
              <div class="view-detail-item">
                <strong class="view-detail-label">{{ 'dashboards' | translate }}: </strong>
                @if (selectedAffiliationCode.dashboards?.length) {
                  <div class="view-detail-info">
                    @for (dashboard of selectedAffiliationCode.dashboards; track dashboard) {
                      <span>{{
                        dashboard.label ? dashboard.label + (dashboard.default ? ' (default)' : '') : '-'
                      }}</span>
                    }
                  </div>
                } @else {
                  <div>-</div>
                }
              </div>
              <div class="view-detail-item">
                <strong class="view-detail-label">{{ 'applications' | translate }}: </strong>
                @if (selectedAffiliationCode.application.id) {
                  <div class="view-detail-info">
                    <span>{{
                      selectedAffiliationCode.application.name ? selectedAffiliationCode.application.name : '-'
                    }}</span>
                  </div>
                } @else {
                  <div>-</div>
                }
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  </form>
</div>
