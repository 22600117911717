import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { map } from 'rxjs/operators';
import { UsersService } from 'src/app/modules/user-management/users.service';
import { User } from '../../entities/groups';
import { GroupsService } from '../../groups.service';

/**
 * Component for adding or editing a group.
 */
@Component({
  selector: 'app-group-add-edit',
  templateUrl: './group-add-edit.component.html',
  styleUrls: ['./group-add-edit.component.scss'],
})
export class GroupAddEditComponent implements OnInit {
  /** Form group for managing group data. */
  groupForm: FormGroup;

  /** Flag indicating if the form has been submitted. */
  submitted = false;

  /**
   * The route URL for navigation purposes.
   */
  routeURL: string = '';

  /**
   * The client ID associated with the component.
   */
  clientID: string | null = '';

  /**
   * List of managers (users) associated with groups.
   */
  managersList: User[] = [];

  /**
   * Constructor for initializing the component.
   * @param formBuilder - FormBuilder service instance
   * @param _GroupsService - GroupsService instance
   * @param router - Router service instance
   * @param toast - ToastrService instance
   * @param route - ActivatedRoute service instance
   * @param _UsersService - UsersService instance
   */
  constructor(
    private formBuilder: FormBuilder,
    private _GroupsService: GroupsService,
    private router: Router,
    private toast: ToastrService,
    private route: ActivatedRoute,
    private _UsersService: UsersService,
  ) {
    this.clientID = this.route.snapshot.queryParamMap.get('clientId');
    this.groupForm = this.formBuilder.group({
      gName: [null, [Validators.required, Validators.minLength(4)]],
      description: [null, [Validators.minLength(4)]],
      manager: [null, [Validators.required, Validators.minLength(4)]],
    });
  }

  /**
   * Retrieves the list of managers when the component initializes.
   */
  ngOnInit(): void {
    this.getManagers();
  }

  /**
   * Handles form submission to add a new group.
   * If the form is valid, sends a request to add the group and navigates to the group management list.
   */
  onSubmit() {
    this.submitted = true;
    const postData = {
      name: this.groupForm.value.gName,
      description: this.groupForm.value.description,
      owner: this.groupForm.value.manager,
    };
    if (this.groupForm.valid) {
      this._GroupsService
        .addGroup(postData)
        .pipe(
          map((response) => {
            return response;
          }),
        )
        .subscribe(() => {
          this.toast.success('Group created successfully');
          // this.sharedService.refreshSubject.next();
          this.router.navigate(['/groupManagment/list'], {
            queryParams: { clientId: this.clientID },
            queryParamsHandling: 'merge',
          });
        });
    }
  }

  /**
   * Retrieves the list of managers from the UsersService.
   * Populates the managersList array upon successful retrieval.
   */
  getManagers() {
    this._UsersService.getManagers().subscribe((result) => {
      if (result.data && result.data.length > 0) {
        // console.log(result.data);
        this.managersList = result.data;
      }
    });
  }

  /**
   * Getter to access form controls easily in the template.
   */
  get f() {
    return this.groupForm.controls;
  }
}
