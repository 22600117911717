import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import moment from 'moment-timezone';
/**
 * Component for handling date of birth input.
 */
@Component({
  selector: 'app-dob',
  templateUrl: './dob.component.html',
  styleUrls: ['./dob.component.scss'],
})
export class DobComponent implements OnChanges {
  /**
   * Input date of birth value.
   */
  @Input() dob: string | undefined = '';
  /**
   * Selected month value.
   */
  mm: string | null = '';
  /**
   * Selected day value.
   */
  dd: string | null = '';
  /**
   * Selected year value.
   */
  yyyy: string | null = '';
  /**
   * Updated date string.
   */
  updateDate = '';
  /**
   * Form group for date of birth input fields.
   */
  form: FormGroup;
  /**
   * Error flag for date of birth validation.
   */
  errMsg = false;
  /**
   * Output event emitter for updating full date of birth.
   */

  @Output() updateDob: EventEmitter<string> = new EventEmitter<string>();
  /**
   * Output event emitter for month changes.
   */
  @Output() month: EventEmitter<string> = new EventEmitter<string>();
  /**
   * Output event emitter for day changes.
   */
  @Output() date: EventEmitter<string> = new EventEmitter<string>();
  /**
   * Output event emitter for year changes.
   */
  @Output() year: EventEmitter<string> = new EventEmitter<string>();
  /**
   * Output event emitter for error code.
   */
  @Output() errorCode: EventEmitter<boolean> = new EventEmitter<boolean>();
  /**
   * Flag indicating final validation error state.
   */
  finalError = false;
  /**
   * Constructor to initialize form builder and date pipe.
   *
   * @param formBuilder - FormBuilder instance for creating form group.
   * @param datePipe - DatePipe instance for formatting dates.
   */
  constructor(
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
  ) {
    this.form = this.formBuilder.group({
      dobMonth: ['', [Validators.required]],
      dobDay: ['', [Validators.required]],
      dobYear: ['', [Validators.required, Validators.pattern('(19[0-9][0-9]|20[0-9][0-9]|2010)')]],
    });
  }
  /**
   * This method checks if `dob` (date of birth) property has changed. If `dob` is truthy,
   * it updates the month (`dobMonth`), day (`dobDay`), and year (`dobYear`) form fields
   * using `datePipe.transform` to format the date. It also sets `updateDate` with the
   * formatted date in MM-DD-YYYY format.
   */
  ngOnChanges() {
    // console.log(this.dob)

    if (this.dob) {
      this.mm = this.datePipe.transform(this.dob, 'MM');
      this.dd = this.datePipe.transform(this.dob, 'dd');
      this.yyyy = this.datePipe.transform(this.dob, 'yyyy');
      this.form.patchValue({
        dobMonth: this.mm,
        dobDay: this.dd,
        dobYear: this.yyyy,
      });
      this.updateDate = this.mm + '-' + this.dd + '-' + this.yyyy;
    }
  }
  /**
   * Handler for month change event.
   *
   * @param mm - New month value.
   */
  changeMonth(mm: string | null) {
    this.mm = ('0' + mm).slice(-2);
    this.updateDate = this.mm + '-' + this.dd + '-' + this.yyyy;
    this.updateDob.emit(this.updateDate);
  }
  /**
   * Handler for day change event.
   *
   * @param dd - New day value.
   */
  changeDate(dd: string | null) {
    this.dd = ('0' + dd).slice(-2);
    this.updateDate = this.mm + '-' + this.dd + '-' + this.yyyy;
    this.updateDob.emit(this.updateDate);
  }
  /**
   * Handler for year change event.
   *
   * @param yyyy - New year value.
   */
  changeYear(yyyy: string | null) {
    this.yyyy = yyyy;
    this.updateDate = this.mm + '-' + this.dd + '-' + this.yyyy;
    this.updateDob.emit(this.updateDate);
  }
  /**
   * Validates date of birth and emits error code if validation fails.
   *
   * @param birthYear - Year of birth.
   * @param month - Month of birth.
   * @param day - Day of birth.
   */
  delegateBox(birthYear: string, month: string, day: string) {
    // Care Provider Information 18 < age check

    month.toString().length == 1 ? (month = '0' + month) : month;
    const birth = birthYear + '-' + month + '-' + day;
    const ageMS = Date.parse(Date()) - Date.parse(birth);
    const age = new Date();
    age.setTime(ageMS);
    const ageYear = age.getFullYear() - 1970;
    if (moment(birth).isValid() == false || ageYear < 0) {
      this.errMsg = true;
    } else {
      this.errMsg = false;
    }
    //console.log(birth,'bbbbbbbbbbbbbbbbbbb', moment(birth).isValid())
    if (birth === '--') {
      this.finalError = false;
      this.updateDate = '';
      this.updateDob.emit(this.updateDate);
    } else {
      if (
        this.form.controls['dobDay'].hasError('required') ||
        this.form.controls['dobYear'].hasError('required') ||
        this.form.controls['dobMonth'].hasError('required')
      ) {
        this.finalError = true;
      } else {
        this.finalError =
          (this.form.controls['dobDay'].touched && this.form.controls['dobDay'].hasError('required')) ||
          (this.form.controls['dobMonth'].touched && this.form.controls['dobMonth'].hasError('required')) ||
          (this.form.controls['dobYear'].touched && this.form.controls['dobYear'].hasError('required')) ||
          this.form.controls['dobYear'].hasError('pattern') ||
          this.errMsg;
      }
      //console.log(this.finalError,'99999999999999999999')
    }
    //console.log(this.finalError,'rrrrrrrrrrrrrrrrrrrrr')
    this.errorCode.emit(this.finalError);
  }
}
