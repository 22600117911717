<!-- <div class="tooltip-content" (click)="$event.stopPropagation()">
  <div *ngIf="isScrollable(content)" class="close-icon" (click)="closeTooltip()">
    <i-feather name="x" class="feather-16"></i-feather>
  </div>
  <div [ngClass]="{ scrollable: isScrollable(content) }">
    <ng-container *ngIf="isTemplate(content); else textContent">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </ng-container>
    <ng-template #textContent>{{ content }}</ng-template>
  </div>
</div> -->

<div class="tooltip-content" #tooltipContent (click)="$event.stopPropagation()">
  <div *ngIf="isContentScrollable" class="close-icon" (click)="closeTooltip()">
    <i-feather name="x" class="feather-16"></i-feather>
  </div>
  <div [ngClass]="{ scrollable: isContentScrollable }">
    <ng-container *ngIf="isTemplate(content); else textContent">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </ng-container>
    <ng-template #textContent>{{ content }}</ng-template>
  </div>
</div>
