import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';
/**
 * Validator directive to ensure that the input does not begin or end with whitespace characters.
 *
 * @Directive
 * @selector `[appSpaceHandle]`
 * @providers Provides itself as a validator for NG_VALIDATORS.
 */
@Directive({
  selector: '[appSpaceHandle]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: SpaceHandleDirective,
      multi: true,
    },
  ],
})
export class SpaceHandleDirective implements Validator {
  /**
   * Validates the input control value.
   *
   * @param control The abstract control representing the input element.
   * @returns A validation error object if validation fails, otherwise null.
   */
  validate(control: AbstractControl): { [key: string]: boolean } | null {
    //const regex = /^(\s+\S+\s*)*(?!\s).*$/;
    const regex = /^(?:(?:\s*\S+\s*)*\n?)*$/;
    if (control.value && !regex.test(control.value)) {
      return { customValidator: true };
    }
    return null;
  }
}
