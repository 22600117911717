<div class="roles-modal dialog-xs">
  <div class="hs-tab-card-title flex-between" [ngClass]="{ 'align-items-start': subtitle }">
    <div class="managed_dialog-title">
      <h2>{{ title }}</h2>
      @if (subtitle) {
        <p class="managed_dialog-subtitle">{{ subtitle }}</p>
      }
    </div>
    <div class="dialog-header-icons">
      <i-feather name="check" (click)="onDoneClick()"></i-feather>
      <i-feather name="x" mat-dialog-close></i-feather>
    </div>
  </div>
  <div class="form-group search-input mb-4">
    <input type="text" class="form-control" [(ngModel)]="search" placeholder="Search" />
    <i-feather name="search" class="feather-20 clr-disabled"></i-feather>
  </div>
  <div class="scrolling_sec">
    <!-- <pre>{{supportUser | json}}</pre> -->
    @for (group of supportUser | filter: search; track group) {
      <div class="form-group form-check">
        <input
          #checkboxes
          type="checkbox"
          class="form-check-input"
          id="{{ group.id }}"
          (change)="getGroupData(group, $event)"
        />
        <label for="ds" class="form-check-label">{{ group.name }} </label>
      </div>
    }
    @if (supportUser.length === 0) {
      <div class="norecord">{{ 'noRecordFound' | translate }}</div>
    }
  </div>
</div>
