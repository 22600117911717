import { Directive, ElementRef, forwardRef, HostListener, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
/**
 * Directive to restrict input to numeric values only.
 * Implements ControlValueAccessor to integrate with Angular forms.
 *
 * @Directive
 * @selector `[appOnlyNumber]`
 * @providers Provides NG_VALUE_ACCESSOR to integrate with Angular forms.
 */
@Directive({
  selector: '[appOnlyNumber]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OnlyNumberDirective),
      multi: true,
    },
  ],
})
export class OnlyNumberDirective implements ControlValueAccessor {
  /**
   * Callback function to be called when the input value changes.
   * Used internally by Angular forms integration.
   */
  private onChange!: (val: string) => void;
  /**
   * Callback function to be called when the input control is touched.
   * Used internally by Angular forms integration.
   */
  private onTouched!: () => void;
  /**
   * Holds the current value of the input element.
   * Used internally for comparison during value changes.
   */
  private value!: string;

  /**
   * Constructor to inject dependencies.
   *
   * @param elementRef Reference to the host element where the directive is applied.
   * @param renderer Renderer to manipulate the host element.
   */
  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
  ) {}
  /**
   * Listen to 'input' event to filter and update the input value.
   *
   * @param value The current input value.
   */
  @HostListener('input', ['$event.target.value'])
  onInputChange(value: string) {
    const filteredValue: string = this.filterValue(value);
    this.updateTextInput(filteredValue, this.value !== filteredValue);
  }
  /**
   * Listen to 'blur' event to mark the control as touched.
   */
  @HostListener('blur')
  onBlur() {
    this.onTouched();
  }
  /**
   * Registers a callback function to be called when the value changes.
   *
   * @param fn Callback function provided by Angular forms.
   */
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  /**
   * Registers a callback function to be called when the control is touched.
   *
   * @param fn Callback function provided by Angular forms.
   */
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  /**
   * Sets the disabled state of the host element.
   *
   * @param isDisabled Whether the element should be disabled or not.
   */
  public setDisabledState(isDisabled: boolean): void {
    this.renderer.setProperty(this.elementRef.nativeElement, 'disabled', isDisabled);
  }
  /**
   * Writes a new value to the host element.
   *
   * @param value The new value to be written.
   */
  public writeValue(value: any): void {
    const valueChange = value ? String(value) : '';
    this.updateTextInput(valueChange, false);
  }
  /**
   * Filters the input value to allow only numeric characters.
   *
   * @param value The input value to be filtered.
   * @returns The filtered value containing only numeric characters.
   */
  public filterValue(value: any) {
    return value.replace(/[^0-9]*/g, '');
  }
  /**
   * Updates the host element's input value and triggers change propagation if necessary.
   *
   * @param value The new value to be set on the host element.
   * @param propagateChange Whether to propagate the change or not.
   */
  private updateTextInput(value: string, propagateChange: boolean) {
    this.renderer.setProperty(this.elementRef.nativeElement, 'value', value);
    if (propagateChange) {
      this.onChange(value);
    }
    this.value = value;
  }
}
