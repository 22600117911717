<div class="full-message-item" [ngClass]="{ right: communicationUserId === messageUserId }">
  <div class="full-message-item-inner">
    <div class="full-message-icon">
      <img [src]="sender?.userImageUrl || 'assets/images/icons/user.png'" alt="message-user" />
    </div>
    <div class="full-message-content">
      <div class="full-message-content-inner">
        <div class="message-container" [ngClass]="{ 'message-recalled': chatMessage.metadata?.recallDate }">
          @if (chatMessage.metadata?.recallDate) {
            <p class="message-recall-time">
              <span>{{ 'messageRecalled' | translate }}</span> |
              <span>{{ chatMessage.metadata?.recallDate! | dateTime: ', ' }}</span>
            </p>
            <div class="message-text feather-reset">
              <i-feather name="slash" class="feather-16"></i-feather> {{ 'recalledMessageText' | translate }}
            </div>
          }
          @if (!chatMessage.metadata?.recallDate) {
            <div class="message-body">
              <div class="message-text" [innerHTML]="chatMessage.content?.message | formatMessage | safeHtml"></div>
              @if (recommendedResources.length) {
                <div class="recommendation-resource-icon pointer" (click)="openRecommendedResources()">
                  <img src="assets/images/icons/recommendation-resource.svg" alt="" />
                </div>
              }
            </div>
            @if (attachments.length) {
              <div class="attachment-icons">
                @for (attachment of attachments; track attachment) {
                  <div class="attachment-icons-item" (click)="openAttachmentPreview(attachment)">
                    <img [src]="attachment.fileIcon" alt="user" />
                  </div>
                }
              </div>
            }
          }
        </div>

        @if (mode === 'MyMessages') {
          @if (communicationUserId === messageUserId) {
            <app-dropdown-menu [xPosition]="'before'">
              <button mat-menu-item (click)="openReadBy()">
                <i-feather name="eye" class="feather-16"></i-feather>
                <span>{{ 'readStatus' | translate }}</span>
              </button>
              @if (chatMessage.metadata?.recallDate) {
                <button mat-menu-item (click)="openRecalledMessage()">
                  <i-feather name="message-square" class="feather-16"></i-feather>
                  <span>{{ 'viewMessage' | translate }}</span>
                </button>
              }
              <ng-container *appPermissions="'self.Chat.Write'">
                @if (!chatMessage.metadata?.recallDate) {
                  <button mat-menu-item (click)="forwardMessage()">
                    <i-feather name="corner-up-right" class="feather-16"></i-feather>
                    <span>{{ 'forwardMessage' | translate }}</span>
                  </button>
                }
              </ng-container>
              <ng-container *appPermissions="'self.Chat.Update'">
                @if (!chatMessage.metadata?.recallDate) {
                  <button mat-menu-item (click)="recallMessage()">
                    <i-feather name="trash-2" class="feather-16"></i-feather>
                    <span>{{ 'recallMessage' | translate }}</span>
                  </button>
                }
              </ng-container>
            </app-dropdown-menu>
          }
          @if (communicationUserId !== messageUserId && (hasWritePermission || chatMessage.metadata?.recallDate)) {
            <app-dropdown-menu>
              <button mat-menu-item (click)="openReadBy()">
                <i-feather name="eye" class="feather-16"></i-feather>
                <span>{{ 'readStatus' | translate }}</span>
              </button>
              <button mat-menu-item (click)="forwardMessage()">
                <i-feather name="corner-up-right" class="feather-16"></i-feather>
                <span>{{ 'forwardMessage' | translate }}</span>
              </button>
              @if (chatMessage.metadata?.recallDate) {
                <button mat-menu-item (click)="openRecalledMessage()">
                  <i-feather name="message-square" class="feather-16"></i-feather>
                  <span>{{ 'viewMessage' | translate }}</span>
                </button>
              }
            </app-dropdown-menu>
          }
        }
      </div>
      <span class="message-date-text">
        <span>{{ sender?.userFullName || chatMessage.senderDisplayName }}</span> |
        <span>{{ chatMessage.createdOn | dateTime: ', ' }}</span>
      </span>
    </div>
  </div>
</div>
