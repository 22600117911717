import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

/**
 * Component for managing group dialog interactions.
 */
@Component({
  selector: 'app-dialog',
  templateUrl: './managed-group.component.html',
  styleUrls: ['./managed-group.component.scss'],
})
export class ManagedDialogComponent {
  /** message displayed in the dialog */
  message: string = 'Are you sure?';
  /** Text for the confirm button */
  confirmButtonText = 'Yes';
  /** Text for the cancel button */
  cancelButtonText = 'Cancel';
  /** Search query string */
  search!: string;
  /** Array of support user data */
  supportUser: any = [];
  /** Array of selected group IDs to add */
  addGroupData: any = [];
  /** Title for the dialog */
  title: string;
  /** Subtitle for the dialog */
  subtitle: string;

  /**
   * Represents a component/service constructor.
   * @param data - Data passed to the dialog when opened.
   * @param dialogRef - Reference to the dialog instance.
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<ManagedDialogComponent>,
  ) {
    if (data) {
      // aler(data)
      this.supportUser = data.managedUser;
      this.message = data.message || this.message;
      if (data.buttonText) {
        this.confirmButtonText = data.buttonText.ok || this.confirmButtonText;
        this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
      }
    }
    this.addGroupData = [];
    this.title = data.title;
    this.subtitle = data?.subtitle;
  }

  /**
   * Closes the dialog with a confirmation response.
   */
  onConfirmClick(): void {
    this.dialogRef.close(true);
  }

  /**
   * Closes the dialog with the list of added group IDs.
   */
  onDoneClick(): void {
    this.dialogRef.close(this.addGroupData);
  }

  /**
   * Updates the list of selected group IDs based on checkbox state.
   * @param data - Group data containing group ID.
   * @param event - Checkbox change event.
   */
  getGroupData(data: any, event: any) {
    // console.log(data, event)
    let id: string;
    if (data._id) {
      id = data._id;
    } else {
      id = data.id;
    }
    //  console.log(id)
    if (event.target.checked) {
      this.addGroupData.push(id);
    } else {
      if (this.addGroupData.length > 1) {
        this.addGroupData = this.addGroupData.filter((v: any) => v != id);
      } else {
        this.addGroupData = [];
      }
    }
  }

  /**
   * Clears the search input.
   */
  clearSearch() {
    this.search = '';
  }
}
