import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
/**
 * Service for managing CSS related functionalities based on window size.
 */
@Injectable({
  providedIn: 'root',
})
export class CssService {
  /**
   * Observable that emits the current window size category.
   */
  public windowSize$: BehaviorSubject<string>;

  /**
   * A BehaviorSubject that holds the current width of the window.
   * It allows subscribers to reactively get updates when the window width changes.
   */
  public windowWidth$: BehaviorSubject<number>;
  /**
   * Breakpoint size for Small devices (SM) - less than 576px.
   */
  #sm = 576;
  /**
   * Breakpoint size for Medium devices (MD) - less than 768px.
   */
  #md = 768;
  /**
   * Breakpoint size for Large devices (LG) - less than 992px.
   */
  #lg = 992;
  /**
   * Breakpoint size for Extra Large devices (XL) - less than 1200px.
   */
  #xl = 1200;
  /**
   * Breakpoint size for Extra Extra Large devices (XXL) - less than 1400px.
   */
  #xxl = 1400;
  /**
   * Private property to store the current inner width of the browser window.
   */
  #windowWidth = window.innerWidth;
  /**
   * Constructor of the CssService class.
   * Initializes the windowSize$ BehaviorSubject with the initial size category.
   */
  constructor() {
    /**
     * Observable for tracking window size category.
     */
    this.windowSize$ = new BehaviorSubject(this.sizeCategory());
    this.windowWidth$ = new BehaviorSubject(window.innerWidth);
  }
  /**
   * Notifies subscribers when window is resized.
   */
  public windowResized(): void {
    this.windowSize$.next(this.sizeCategory());
    this.windowWidth$.next(window.innerWidth);
  }
  /**
   * Checks if window size is SM (Small).
   * @returns True if window width is less than 576px, otherwise false.
   */
  public isSM(): boolean {
    if (this.#windowWidth < this.#sm) return true;
    return false;
  }
  /**
   * Checks if window size is MD (Medium).
   * @returns True if window width is less than 768px, otherwise false.
   */
  public isMD(): boolean {
    if (this.#windowWidth < this.#md) return true;
    return false;
  }
  /**
   * Checks if window size is LG (Large).
   * @returns True if window width is less than 992px, otherwise false.
   */
  public isLG(): boolean {
    if (this.#windowWidth < this.#lg) return true;
    return false;
  }
  /**
   * Checks if window size is XL (Extra Large).
   * @returns True if window width is less than 1200px, otherwise false.
   */
  public isXL(): boolean {
    if (this.#windowWidth < this.#xl) return true;
    return false;
  }
  /**
   * Checks if window size is XXL (Extra Extra Large).
   * @returns True if window width is less than 1400px, otherwise false.
   */
  public isXXL(): boolean {
    if (this.#windowWidth < this.#xxl) return true;
    return false;
  }
  /**
   * Determines the size category based on window width.
   * @returns Size category ('sm', 'md', 'lg', 'xl', 'xxl').
   */
  private sizeCategory(): string {
    const windowWidth: number = window.innerWidth;
    if (windowWidth < this.#sm) return 'sm';
    else if (windowWidth >= this.#sm && windowWidth < this.#md) return 'md';
    else if (windowWidth >= this.#md && windowWidth < this.#lg) return 'lg';
    else if (windowWidth >= this.#lg && windowWidth < this.#xl) return 'xl';
    return 'xxl';
  }
}
