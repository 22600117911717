import { Component } from '@angular/core';
import { TranslatePipe } from 'src/app/modules/shared/pipes/translate.pipe';
/**
 * Component representing content loader.
 */
@Component({
  selector: 'app-loader',
  standalone: true,
  imports: [TranslatePipe],
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent {}
