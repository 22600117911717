<div class="card-base">
  <div class="common-card-header flex-between">
    <h2>{{ 'outsideOfBusinessHours' | translate }}</h2>
    <div class="card-header-icons">
      <span *appPermissions="'self.OutOfBusiness.Update'">
        <i-feather
          name="edit-2"
          *ngIf="!toggleView.OOOBusinessHours"
          (click)="toggleOOOBusinessHoursView()"
        ></i-feather>
      </span>
      <ng-container *ngIf="toggleView.OOOBusinessHours">
        <i-feather name="check" (click)="submit()"></i-feather>
        <i-feather name="x" (click)="toggleOOOBusinessHoursView()"></i-feather>
      </ng-container>
    </div>
  </div>
  <div *ngIf="!toggleView.OOOBusinessHours">
    <p class="calender-card-info">
      {{ 'businessHoursInfoLabel' | translate }}
    </p>
    <div class="calendar-slide-toggle">
      <strong> {{ 'turnOnOutsideOfBusinessHoursResponse' | translate }}: </strong>
      <mat-slide-toggle [checked]="OOOBusinessHours.status" [disabled]="true"></mat-slide-toggle>
    </div>
  </div>
  <ng-container *ngIf="!toggleView.OOOBusinessHours">
    <div class="view-detail-row">
      <div class="view-detail-item">
        <strong class="view-detail-label">{{ 'subject' | translate }}:</strong>
        <div class="view-detail-info">{{ OOOBusinessHours.subject || '-' }}</div>
      </div>
      <div class="view-detail-item">
        <strong class="view-detail-label">{{ 'message' | translate }}:</strong>
        <div class="view-detail-info" [innerText]="OOOBusinessHours.body || '-'"></div>
      </div>
      <div class="view-detail-item">
        <strong class="view-detail-label">{{ 'businessHours' | translate }}:</strong>
        <div class="view-detail-info" *ngIf="OOOBusinessHours.fromTime && OOOBusinessHours.toTime">
          {{ OOOBusinessHours.fromTime | date: 'hh:mm a' }} {{ 'to' | translate }}
          {{ OOOBusinessHours.toTime | date: 'hh:mm a' }}
        </div>
        <div class="view-detail-info" *ngIf="!OOOBusinessHours.fromTime && !OOOBusinessHours.toTime">-</div>
      </div>
      <div class="view-detail-item">
        <strong class="view-detail-label">{{ 'workingDays' | translate }}:</strong>
        <div class="view-detail-info">{{ getWorkingDaysString() || '-' }}</div>
      </div>
    </div>
  </ng-container>
  <!-- edit mode start -->
  <div *ngIf="toggleView.OOOBusinessHours">
    <form [formGroup]="businessHoursForm">
      <div class="form-group">
        <p class="calender-card-info">
          {{ 'businessHoursInfoLabel' | translate }}
        </p>
        <div class="calendar-slide-toggle">
          <strong> {{ 'turnOnOutsideOfBusinessHoursResponse' | translate }}: </strong>
          <mat-slide-toggle formControlName="status" (change)="validateFormChanges()"></mat-slide-toggle>
        </div>
      </div>
      <div class="form-group">
        <label for="" class="form-label" required>{{ 'subject' | translate }}</label>
        <input
          type="text"
          class="form-control"
          placeholder="Office Hours Notice"
          name="subject"
          formControlName="subject"
          [class]="{
            'is-invalid':
              businessHoursForm.controls.subject.invalid &&
              (businessHoursForm.controls.subject.pristine || businessHoursForm.controls.subject.touched)
          }"
        />
        <div
          *ngIf="
            businessHoursForm.controls.subject.invalid &&
            (businessHoursForm.controls.subject.pristine || businessHoursForm.controls.subject.touched)
          "
          class="invalid-feedback"
        >
          <div *ngIf="businessHoursForm.controls.subject.errors?.['required']">
            {{ 'thisIsRequiredField' | translate }}
          </div>
        </div>
      </div>
      <div class="form-group">
        <label for="" class="form-label" required>{{ 'message' | translate }}</label>
        <textarea
          class="form-control"
          cols="30"
          rows="10"
          name="body"
          formControlName="body"
          [class]="{
            'is-invalid':
              businessHoursForm.controls.body.invalid &&
              (businessHoursForm.controls.body.pristine || businessHoursForm.controls.body.touched)
          }"
        ></textarea>
        <div
          *ngIf="
            businessHoursForm.controls.body.invalid &&
            (businessHoursForm.controls.body.pristine || businessHoursForm.controls.body.touched)
          "
          class="invalid-feedback"
        >
          <div *ngIf="businessHoursForm.controls.body.errors?.['required']">
            {{ 'thisIsRequiredField' | translate }}
          </div>
        </div>
      </div>
      <div class="form-group">
        <label for="" class="form-label" required>{{ 'businessHours' | translate }}</label>
        <div class="business-hrs">
          <div
            class="time-picker-input form-control"
            [class]="{
              'is-invalid':
                businessHoursForm.controls.fromTime.invalid &&
                (businessHoursForm.controls.fromTime.pristine || businessHoursForm.controls.fromTime.touched)
            }"
          >
            <div class="time-value">
              {{ businessHoursForm.controls.fromTime.value | date: 'hh:mm a' }}
            </div>
            <app-timepicker
              [timeControl]="businessHoursForm.controls['fromTime']"
              (changeTime)="onChangeFromTimeHandler($event)"
              [defaultTime]="defaultFromTime"
            ></app-timepicker>
          </div>
          <span
            [class]="{
              'mt--20': businessHoursForm.controls.fromTime.invalid && businessHoursForm.controls.toTime.invalid
            }"
            >{{ 'to' | translate }}</span
          >
          <div
            class="time-picker-input form-control"
            [class]="{
              'is-invalid':
                businessHoursForm.controls.toTime.invalid &&
                (businessHoursForm.controls.toTime.pristine || businessHoursForm.controls.toTime.touched)
            }"
          >
            <div class="time-value">
              {{ businessHoursForm.controls.toTime.value | date: 'hh:mm a' }}
            </div>
            <app-timepicker
              [timeControl]="businessHoursForm.controls['toTime']"
              (changeTime)="onChangeToTimeHandler($event)"
              [defaultTime]="defaultToTime"
            ></app-timepicker>
            <div *ngIf="businessHoursForm.controls.toTime.invalid" class="invalid-feedback">
              <div *ngIf="businessHoursForm.controls.toTime.errors?.['required']">
                {{ 'thisIsRequiredField' | translate }}
              </div>
            </div>
          </div>
        </div>
        <div
          *ngIf="businessHoursForm.controls.fromTime.invalid || businessHoursForm.controls.toTime.invalid"
          class="invalid-feedback"
        >
          <div *ngIf="businessHoursForm.controls.fromTime.errors?.['required']">
            {{ 'thisIsRequiredField' | translate }}
          </div>
          <div *ngIf="businessHoursForm.controls.toTime.errors?.['required']">
            {{ 'thisIsRequiredField' | translate }}
          </div>
          <div *ngIf="businessHoursForm.controls.fromTime.errors?.['time']">
            {{ 'startTimeShouldBeLessThanEnd' | translate }}
          </div>
        </div>
      </div>
      <div class="form-group">
        <label for="" class="form-label">{{ 'workingDays' | translate }}</label>
        <div class="select-days">
          <div class="days-item" *ngFor="let byWeekOption of availableWeekOptions">
            <button
              class="btn btn-days"
              [class]="{ selected: isActiveWorkingDays(byWeekOption.value) }"
              (click)="setWorkingDays(byWeekOption)"
            >
              {{ byWeekOption.xsLabelKey | translate }}
            </button>
          </div>
          <!-- <div class="has-error error mt-2" *ngIf="businessHoursForm?.controls?.dayOfWeek?.value?.length <= 0">Please select at least on working day.</div> -->
        </div>
      </div>
      <a
        href="javascript:void(0)"
        class="reset-setting pointer"
        (click)="openResetOOBusinessHoursSettingsConfirmDialog()"
      >
        <i-feather name="reset" class="feather-16"></i-feather> {{ 'resetToDefaultSettings' | translate }}</a
      >
    </form>
  </div>
  <!-- edit mode end -->
</div>
