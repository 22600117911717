import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { FilterColumnOption } from 'src/app/modules/shared/interfaces/common.entities';
import { GenericTableColumn, TableColumnIcon } from '../../interfaces/generic-table';
import { PaginationOptions } from '../../interfaces/pagination-options';
/**
 * Component to display data in a table format with various features such as pagination, sorting, and row actions.
 */
@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnChanges {
  /** Input data for the table. */
  @Input() data: any;
  /** Array of columns to be displayed in the table. */
  @Input() columns: GenericTableColumn[] = [];
  /** Array of column keys to be displayed in the table. */
  @Input() displayedColumns!: string[];
  /** Options for pagination control. */
  @Input() paginationOptions!: PaginationOptions;
  /** Flag to enable row click navigation. */
  /** Flag to show pagination controls. */
  @Input() showPagination = true;
  /** Flag to show checkboxes for row selection. */
  @Input() showCheckbox = false;
  /** Flag to identify if the table is rendered for a specific purpose. */
  @Input() tableRender = '';
  /** Event emitter for triggering data refetch based on pagination options. */
  @Output() refetch: EventEmitter<PaginationOptions> = new EventEmitter<PaginationOptions>();
  /** Event emitter for triggering delete action on a specific item. */
  @Output() delete: EventEmitter<string> = new EventEmitter<string>();
  /** Event emitter for triggering redirection to a specific route. */
  @Output() redirect: EventEmitter<string> = new EventEmitter<string>();
  /** Event emitter for triggering sorting based on column and order. */
  @Output() sort: EventEmitter<{ column: string; order: string }> = new EventEmitter<{
    column: string;
    order: string;
  }>();
  /** Event emitter for handling click events on action items in each row. */
  @Output() clickOnActionItems: EventEmitter<string> = new EventEmitter<string>();
  /** Event emitter for handling selection of multiple users. */
  @Output() selectedUserIds: EventEmitter<Array<string>> = new EventEmitter<Array<string>>();
  /** Event emitter for viewing details of a specific item. */
  @Output() view: EventEmitter<string> = new EventEmitter<string>();
  /** Event emitter for triggering search based on column options. */
  @Output() search: EventEmitter<FilterColumnOption> = new EventEmitter<FilterColumnOption>();
  /** Event emitter for triggering filter based on column options. */
  @Output() filter: EventEmitter<FilterColumnOption> = new EventEmitter<FilterColumnOption>();

  /** Reference to Material Paginator component for pagination control. */
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  /** Data source for MatTable to bind table data dynamically. */
  dataSource!: MatTableDataSource<any>;
  /** Options for selecting page size in pagination. */
  pageSizeOptions: number[] = [5, 10, 15, 20];
  /** Event object for pagination control. */
  pageEvent!: PageEvent;
  /** Array to store IDs of selected users. */
  uIdChecked: Array<string> = [];

  /**
   * Constructs an instance of the TableComponent.
   * @param router The Angular router service instance.
   */
  constructor(private router: Router) {}

  /**
   * Lifecycle hook that is called when any input property of the component changes.
   * Sets the dataSource with the updated data.
   */
  ngOnChanges(): void {
    this.dataSource = this.data;
  }
  /**
   * Sorts the table data based on the selected column and order.
   * @param column The column key to sort by.
   * @param order The sort order ('asc' or 'desc').
   */
  sortByColumn(column: string, order: string) {
    const sortO: { column: string; order: string } = { column, order };
    this.sort.emit(sortO);
    this.appliedSortOnColumn(sortO);
  }
  /**
   * Updates the UI to reflect the applied sorting on a column.
   * @param sortO The sorting options (column and order).
   */
  appliedSortOnColumn(sortO: { column: string; order: string }) {
    this.columns.filter((column) => {
      column.active = column.key === sortO.column ? true : false;
      column.order = column.key === sortO.column ? sortO.order : '';
      return column;
    });
  }
  /**
   * Handles change in checkbox state for a specific user.
   * @param id The ID of the user.
   * @param status The checkbox status (checked or unchecked).
   */
  changeChkState(id: string, status: boolean) {
    if (status) {
      this.uIdChecked.push(id);
    } else {
      this.uIdChecked = this.uIdChecked.filter((item) => item !== id);
    }
    this.selectedUserIds.emit(this.uIdChecked);
  }
  /**
   * Triggers delete action for a specific item by emitting the ID.
   * @param e The ID of the item to delete.
   */
  deleteById(e: string) {
    this.delete.emit(e);
  }

  /**
   * Triggers view action for a specific item by emitting the ID.
   * @param e The ID of the item to view.
   */
  viewById(e: string) {
    this.view.emit(e);
  }
  /**
   * Handles pagination changes and triggers data refetch with updated pagination options.
   */
  handlePagination() {
    this.refetch.emit(this.paginationOptions);
    this.paginationOptions = {
      limit: this.paginationOptions.limit,
      currentPage: this.paginationOptions.currentPage,
      totalCount: this.paginationOptions.totalCount,
    };
  }
  /**
   * Handles click event on a filter icon in a column header.
   * @param $event The click event object.
   * @param column The column associated with the filter icon.
   */
  clickOnFilter($event: { pageX: number; pageY: number }, column: GenericTableColumn) {
    const filterOptions = {
      column: column,
      pageX: $event.pageX,
      pageY: $event.pageY,
      type: 'filter',
      title: column.title || '',
    };
    this.filter.emit(filterOptions);
  }
  /**
   * Handles click event on a search icon in a column header.
   * @param $event The click event object.
   * @param column The column associated with the search icon.
   */
  clickOnSearch($event: { pageX: number; pageY: number }, column: GenericTableColumn) {
    const searchOptions = {
      column: column,
      pageX: $event.pageX,
      pageY: $event.pageY,
      type: 'search',
      title: column.title || '',
    };
    this.search.emit(searchOptions);
  }
  /**
   * Handles click event on action icons/buttons within each row.
   * @param $event The click event object.
   * @param icon The clicked icon/button details.
   * @param row The row data associated with the clicked icon/button.
   */
  clickOnActionIcons($event: MouseEvent, icon: TableColumnIcon, row: any) {
    $event.stopPropagation();
    if (icon && row && row?.id?.value) {
      if (icon.key === 'delete') {
        this.deleteById(row.id.value);
      } else if (icon.key === 'userStatus') {
        this.clickOnActionItems.emit(row);
      } else if (row.action === 'client') {
        this.redirect.emit(row.id.value);
      } else {
        if (icon.route) {
          this.router.navigate([icon.route], {
            queryParams: row.id.queryParams,
            queryParamsHandling: 'merge',
          });
        } else {
          this.clickOnActionItems.emit(row?.id?.value);
        }
      }
    }
  }
}
