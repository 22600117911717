<div class="dialog-lg">
  <div class="hs-tab-card-title flex-between">
    <h2>{{ 'attachments' | translate }}</h2>
    <div class="dialog-header-icons">
      <i-feather name="x" mat-dialog-close class="pointer"></i-feather>
    </div>
  </div>
  <div class="favorites-attachments" (click)="addToFavourites(selectedAttachment)">
    <i-feather name="star" class="favorites-icon feather-16" [ngClass]="{ active: isFavourite }"></i-feather>
    <span class="favorites-text">{{ 'addToFavorites' | translate }}</span>
  </div>
  <mat-dialog-content>
    <div class="attachments-caraousel">
      <div class="previous-attachment carousel-control" (click)="previousAttachment()">
        <i-feather name="chevron-left" class="pointer"></i-feather>
      </div>
      @if (selectedAttachmentIndex > -1 && showSelectedAttachment) {
        <div class="attachments-item">
          @if (isPreviewAvailable(this.selectedAttachment.mimeType)) {
            <object
              [type]="selectedAttachment.mimeType"
              [data]="selectedAttachmentUrl"
              width="800"
              height="400"
              class="attachments-object"
            ></object>
          } @else {
            <ng-container [ngTemplateOutlet]="noPreview"></ng-container>
          }
        </div>
      }
      <div class="next-attachment carousel-control" (click)="nextAttachment()">
        <i-feather name="chevron-right" class="pointer"></i-feather>
      </div>
    </div>
  </mat-dialog-content>
  @if (selectedAttachmentIndex > -1) {
    <div class="attachment-icons">
      @for (attachment of attachments; track $index) {
        <div
          class="attachment-icons-item"
          [ngClass]="{ active: attachment.url === selectedAttachment.url }"
          (click)="previewAttachment(attachment, $index)"
        >
          <img [src]="attachment.fileIcon" alt="img" />
        </div>
      }
    </div>
  }
</div>
<ng-template #noPreview>
  <div class="no-preview-attachments">
    <div class="no-preview-icon">
      <img [src]="selectedAttachment.fileIcon" alt="user" />
    </div>
    <div class="no-preview-text">
      <p>{{ 'couldntPreviewFile' | translate }}</p>
      <p>
        {{ 'click' | translate }}
        <a [href]="selectedAttachmentUrl">
          <i-feather name="download" class="feather-16"></i-feather> {{ 'download' | translate }}
        </a>
        {{ 'andViewOnYourDevice' | translate }}
      </p>
    </div>
  </div>
</ng-template>
