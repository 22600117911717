<div class="form-group button-file">
  @if (validation) {
    <label for="" class="form-label" required>{{ label ? label : 'Upload Image' }}</label>
  }
  @if (!validation) {
    <label class="form-label">{{ label ? label : 'Upload Image' }}</label>
  }
  <div class="button-file-inner">
    @if (allowPathOrUrl) {
      <input
        type="text"
        class="form-control allow-edit"
        #fileInputUrl
        (blur)="onBlurUrlInput(fileInputUrl.value)"
        [value]="file?.name || fileUrl || ''"
      />
    }
    <div class="form-control upload-file-label">{{ file?.name || fileUrl || '' }}</div>
    <div class="file-icons">
      <!-- <div class="clip-icon">
      <input type="file" #fileInput id="file" [accept]="allowFileType" (change)="uploadFile($event)" />
      <i-feather name="paperclip" class="feather-20"></i-feather>
    </div> -->
      <div class="btn btn-primary">
        <input type="file" #fileInput id="file" [accept]="allowFileType" (change)="uploadFile($event)" />
        <span class="file-text">{{ 'chooseFile' | translate }}</span>
      </div>
      @if (isAllowCamera) {
        <i-feather name="camera" class="feather-20 pointer ms-2" (click)="cameraViewer()"></i-feather>
      }
    </div>
  </div>
  <div class="selected-file" [hidden]="imageUrl === ''">
    <i-feather name="x-circle" class="feather-20 remove-logo" (click)="removeFile()"></i-feather>
    <span (click)="removeFile()" [hidden]="imageUrl !== ''">{{ 'chooseFile' | translate }} </span>
    <img [src]="imageUrl" alt="alt" />
  </div>
</div>
