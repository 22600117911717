import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GroupComponent } from './components/group/group.component';
import { GroupAddEditComponent } from './components/group-add-edit/group-add-edit.component';
import { GroupViewComponent } from './components/group-view/group-view.component';
import { GroupUseraddComponent } from './components/group-useradd/group-useradd.component';

const routes: Routes = [
  {
    path: "", redirectTo:"list", pathMatch: "full",
  },
  {
    path: "list",
    component: GroupComponent
  },
  {
    path: "add",
    component: GroupAddEditComponent
  },
  {
    path: "view",
    component: GroupViewComponent
  },
  {
    path: "useradd",
    component: GroupUseraddComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GroupsRoutingModule { }
