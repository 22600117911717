<div class="form-group mb-0 custom-select truncation">
  <div class="custom-select-arrow">
    <select class="form-control" (change)="onSelected($event, 'selection')">
      <!-- <option value="#" selected disabled>–Select–</option> -->
      @for (item of res; track item) {
        <option [value]="item.subscribedTo.name">{{ item.subscribedTo.name }}</option>
      }
    </select>
  </div>
</div>

<h2 class="my-4">{{ 'inactivityAlerts' | translate }}</h2>

<div class="table-wrapper nudges-table">
  <div class="table-responsive mb-0">
    <table class="mat-mdc-table">
      <tr class="mat-mdc-header-row">
        <th class="mat-mdc-header-cell">{{ 'notifications' | translate }}</th>
        @if (checkedChannels('sms')) {
          <th class="mat-mdc-header-cell toggle-cell-width">{{ 'sms' | translate }}</th>
        }
        @if (checkedChannels('email')) {
          <th class="mat-mdc-header-cell toggle-cell-width">
            {{ 'email' | translate }}
          </th>
        }
        @if (checkedChannels('inApp')) {
          <th class="mat-mdc-header-cell toggle-cell-width">
            {{ 'inAppNotification' | translate }}
          </th>
        }
        @if (checkedChannels('push')) {
          <th class="mat-mdc-header-cell toggle-cell-width">
            {{ 'pushNotification' | translate }}
          </th>
        }
      </tr>

      @for (item of inactivityAlertData; track item; let i = $index) {
        <tr class="mat-mdc-row" [ngClass]="!item.enabled ? 'row-disabled' : ''">
          <td class="mat-mdc-cell">
            <div class="cell-has-child">
              <div class="setting-notification-1">
                @if (item?.communicationChannels?.smsChannel) {
                  <span class="update-toggle">
                    <mat-slide-toggle
                      [(ngModel)]="item.enabled"
                      (change)="getUpdatedRow(item, item.key, 'inactivity')"
                    ></mat-slide-toggle>
                  </span>
                }
                <span>{{ item.title }} </span>
              </div>
              <!-- <i-feather name="{{appEngagement ? 'chevron-up' : 'chevron-down'}}"
            class="feather-20 pointer" (click)="applicationEngagement()"></i-feather> -->
              <div class="setting-notification-2">
                <span class="form-group mb-0">
                  <div class="custom-select-arrow">
                    <select
                      class="form-control"
                      [(ngModel)]="item.frequency"
                      (change)="getUpdatedRow(item, item.key, 'inactivity')"
                    >
                      @for (x of dropdownValue; track x) {
                        <option [value]="x.id">
                          {{ x.value }}
                        </option>
                      }
                    </select>
                  </div>
                </span>
                &nbsp; Thru &nbsp;
                <span class="form-group time-input time-dropdown d-flex align-items-center my-0">
                  <div class="form-control time-picker-input">
                    <div>
                      {{ toDate(item.time) | date: 'h:mm a' }}
                    </div>
                    @if (item.time) {
                      <app-timepicker
                        [timeControl]="dateFormate"
                        (changeTime)="onWeightTimeChanged($event, item)"
                        [defaultTime]="defaultTime"
                        (focusout)="getUpdatedRow(item, item.key, 'inactivity')"
                      ></app-timepicker>
                    }
                  </div>
                  <div class="alert-message ms-2" [ngbTooltip]="weightTooltip" placement="top" container="body">
                    <i-feather name="info" class="feather-20 site-edit pointer"></i-feather>
                  </div>
                  <ng-template #weightTooltip>
                    <!-- <div *ngIf="item.frequency ===  'Everyday' ">
                  Individual will be reminded at
                  every day to complete their {{item.title}}.
                </div> -->
                    <!-- <div *ngIf="item.frequency !==  'Everyday'"> -->
                    <div>
                      <!-- Individual will be reminded if at
                  after not completing their {{item.title}} for the past
                  {{item.frequency}} days. -->
                      {{ 'if' | translate }} {{ item.title }} {{ 'byIndividual' | translate }} {{ item.frequency }}
                      {{ 'priorToTodayThroughTodayAt' | translate }} {{ toDate(item.time) | date: 'h:mm a' }},
                      {{ 'youWillBeNotifiedAt' | translate }}
                      {{ toDate(item.time) | date: 'h:mm a' }} {{ 'todayInBetweenParagraph' | translate }}
                    </div>
                  </ng-template>
                </span>
              </div>
              <span>
                @if (item.resendAfter !== undefined && item?.resendAfter !== undefined) {
                  <div class="flex-between reminder-gap">
                    <div class="flex-between">
                      <span class="pe-2">{{ 'resendAfter' | translate }}</span>
                      <div class="form-group mb-0">
                        <input
                          type="text"
                          maxlength="4"
                          appOnlyNumber
                          [(ngModel)]="item.resendAfter"
                          class="reminder-input form-control"
                          (change)="getUpdatedRow(item, item.key, 'inactivity')"
                        />
                      </div>
                      <span class="pl-1"> {{ 'day(s)' | translate }} </span>
                    </div>
                    <div class="flex-between">
                      <span class="pe-2">#{{ 'attempts' | translate }}</span>
                      <div class="form-group mb-0">
                        <input
                          type="text"
                          maxlength="4"
                          appOnlyNumber
                          class="reminder-input form-control"
                          (change)="getUpdatedRow(item, item.key, 'inactivity')"
                          [(ngModel)]="item.attempts"
                        />
                      </div>
                    </div>
                  </div>
                }
              </span>
            </div>
          </td>
          @if (item?.communicationChannels) {
            @if (checkedChannels('sms')) {
              <td class="mat-mdc-cell toggle-cell">
                @if (item?.communicationChannels?.smsChannel) {
                  <span>
                    <mat-slide-toggle
                      [(ngModel)]="item.communicationChannels.sms"
                      (change)="getUpdatedRow(item, item.key, 'inactivity')"
                    ></mat-slide-toggle>
                  </span>
                }
              </td>
            }
            @if (checkedChannels('email')) {
              <td class="mat-mdc-cell toggle-cell">
                @if (item.communicationChannels?.emailChannel) {
                  <span>
                    <mat-slide-toggle
                      [(ngModel)]="item.communicationChannels.email"
                      (change)="getUpdatedRow(item, item.key, 'inactivity')"
                    ></mat-slide-toggle>
                  </span>
                }
              </td>
            }
            @if (checkedChannels('inApp')) {
              <td class="mat-mdc-cell toggle-cell">
                @if (item.communicationChannels?.inAppChannel) {
                  <span>
                    <mat-slide-toggle
                      [(ngModel)]="item.communicationChannels.inApp"
                      (change)="getUpdatedRow(item, item.key, 'inactivity')"
                    ></mat-slide-toggle>
                  </span>
                }
              </td>
            }
            @if (checkedChannels('push')) {
              <td class="mat-mdc-cell toggle-cell">
                @if (item.communicationChannels?.pushChannel) {
                  <span>
                    <mat-slide-toggle
                      [(ngModel)]="item.communicationChannels.push"
                      (change)="getUpdatedRow(item, item.key, 'inactivity')"
                    ></mat-slide-toggle>
                  </span>
                }
              </td>
            }
          }
        </tr>
      }
    </table>
  </div>
</div>
<h2 class="my-4 pt-4">{{ 'monitoringAlerts' | translate }}</h2>
<div class="table-wrapper nudges-table">
  <div class="table-responsive mb-0">
    <table class="mat-mdc-table">
      <tr class="mat-mdc-header-row">
        <th class="mat-mdc-header-cell">{{ 'notifications' | translate }}</th>
        @if (checkedChannels('sms')) {
          <th class="mat-mdc-header-cell toggle-cell-width">{{ 'sms' | translate }}</th>
        }
        @if (checkedChannels('email')) {
          <th class="mat-mdc-header-cell toggle-cell-width">
            {{ 'email' | translate }}
          </th>
        }
        @if (checkedChannels('inApp')) {
          <th class="mat-mdc-header-cell toggle-cell-width">
            {{ 'inAppNotification' | translate }}
          </th>
        }
        @if (checkedChannels('push')) {
          <th class="mat-mdc-header-cell toggle-cell-width">
            {{ 'pushNotification' | translate }}
          </th>
        }
      </tr>

      @for (item of monitoringAlertData; track item; let i = $index) {
        <tr class="mat-mdc-row">
          <td class="mat-mdc-cell">
            <div class="cell-has-child">
              <strong>{{ item.title }} </strong>
              <!-- <i-feather name="{{appEngagement ? 'chevron-up' : 'chevron-down'}}"
            class="feather-20 pointer" (click)="applicationEngagement()"></i-feather> -->
            </div>
          </td>
          @if (item?.communicationChannels) {
            @if (checkedChannels('sms')) {
              <td class="mat-mdc-cell toggle-cell">
                @if (item?.communicationChannels?.smsChannel) {
                  <span>
                    <mat-slide-toggle
                      [(ngModel)]="item.communicationChannels.sms"
                      (change)="getUpdatedRow(item, item.key, 'monitoring')"
                    ></mat-slide-toggle>
                  </span>
                }
              </td>
            }
            @if (checkedChannels('email')) {
              <td class="mat-mdc-cell toggle-cell">
                @if (item.communicationChannels.emailChannel) {
                  <span>
                    <mat-slide-toggle
                      [(ngModel)]="item.communicationChannels.email"
                      (change)="getUpdatedRow(item, item.key, 'monitoring')"
                    ></mat-slide-toggle>
                  </span>
                }
              </td>
            }
            @if (checkedChannels('inApp')) {
              <td class="mat-mdc-cell toggle-cell">
                @if (item.communicationChannels.inAppChannel) {
                  <span>
                    <mat-slide-toggle
                      [(ngModel)]="item.communicationChannels.inApp"
                      (change)="getUpdatedRow(item, item.key, 'monitoring')"
                    ></mat-slide-toggle>
                  </span>
                }
              </td>
            }
            @if (checkedChannels('push')) {
              <td class="mat-mdc-cell toggle-cell">
                @if (item.communicationChannels.pushChannel) {
                  <span>
                    <mat-slide-toggle
                      [(ngModel)]="item.communicationChannels.push"
                      (change)="getUpdatedRow(item, item.key, 'monitoring')"
                    ></mat-slide-toggle>
                  </span>
                }
              </td>
            }
          }
        </tr>
      }
    </table>
  </div>
</div>
