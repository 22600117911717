import { RRule, Weekday } from 'rrule';
import { Contact } from 'src/app/modules/shared/interfaces/contact.entities';

/**
 * Represents options for calendar search.
 */
export interface CalendarSearchOptions {
  /**
   * Options for "my calendars" search.
   */
  myCalendars: SearchOptions;

  /**
   * Options for "other calendars" search.
   */
  otherCalendars: SearchOptions;
}

/**
 * Represents search options.
 */
export interface SearchOptions {
  /**
   * Keywords to search for.
   */
  keywords: string;

  /**
   * Whether to open the search box.
   */
  openSearchBox: boolean;
}

/**
 * Represents a calendar contact, extending the Contact interface.
 */
export interface CalendarContact extends Contact {
  /**
   * Color code associated with the calendar contact.
   */
  colorCode: string;

  /**
   * Indicates whether the calendar contact is checked.
   */
  checked?: boolean;

  /**
   * ID of the calendar contact.
   */
  id: string;

  /**
   * Indicates whether the contact belongs to "my calendar".
   */
  myCalendar: boolean;
}

/**
 * Represents an attendant in an event or group.
 */
export interface Attendant {
  /**
   * URL of the user's image.
   */
  userImageUrl: string;

  /**
   * Full name of the user.
   */
  userFullName: string;

  /**
   * Unique identifier of the attendant.
   */
  id: string;

  /**
   * User name or identifier.
   */
  user: string;

  /**
   * Optional user ID.
   */
  userId?: string;

  /**
   * ACS ID (if applicable).
   */
  userAcsId: string;

  /**
   * Type of group owner.
   */
  groupOwnerType: string;

  /**
   * Relation of the user to the group.
   */
  userGroupRelation: string;
}

/**
 * Represents an entity with an ID and full name.
 */
export interface IdFullName {
  /**
   * Unique identifier.
   */
  id: string;

  /**
   * ACS ID (if applicable).
   */
  acsId?: string;

  /**
   * Type of user (if applicable).
   */
  userType?: string;

  /**
   * User name or identifier.
   */
  user: string;

  /**
   * Full name of the entity.
   */
  fullName: string;

  /**
   * First name of the entity (if available).
   */
  firstName?: string;

  /**
   * Last name of the entity (if available).
   */
  lastName?: string;
}

/**
 * Represents a calendar associated with a user.
 */
export interface MyCalendar {
  /**
   * Indicates whether this is the user's calendar.
   */
  myCalendar: boolean;

  /**
   * Color code associated with the calendar.
   */
  colorCode: string;

  /**
   * Indicates whether the calendar is checked.
   */
  checked?: boolean;

  /**
   * Unique identifier of the calendar.
   */
  id: string;

  /**
   * User name or identifier associated with the calendar.
   */
  user: string;

  /**
   * Full name of the user associated with the calendar.
   */
  userFullName: string;
}

/**
 * Represents an event.
 */
export interface Event {
  /**
   * Title of the event.
   */
  title: string;

  /**
   * Start date/time of the event (in ISO string format).
   */
  eventStart: string;

  /**
   * End date/time of the event (in ISO string format).
   */
  eventEnd: string;

  /**
   * Start date of the event (parsed Date object or null).
   */
  start?: Date | null;

  /**
   * End date of the event (parsed Date object or null).
   */
  end?: Date | null;

  /**
   * Type of calendar the event belongs to.
   */
  calendarType: string;

  /**
   * Indicates if the event is recurring.
   */
  recurring: boolean;

  /**
   * Indicates if the event is an all-day event.
   */
  allDay: boolean;

  /**
   * Indicates if there is a reminder set for the event.
   */
  reminder: boolean;

  /**
   * Location of the event.
   */
  location: string;

  /**
   * List of attendees for the event.
   */
  attendees: Array<{ id: string; fullName: string }>;

  /**
   * Additional notes for the event.
   */
  notes: string;

  /**
   * Recurrence option for the event.
   */
  rOption: string;

  /**
   * Until option for recurrence end.
   */
  untilOption: string;

  /**
   * RRule settings for recurring events.
   */
  rruleSetting: RRuleSetting | object;
}

/**
 * Represents settings for generating recurrence rules (RRule).
 */
export interface RRuleSetting {
  /**
   * Frequency of recurrence (e.g., "DAILY", "WEEKLY").
   */
  freq: string;

  /**
   * Interval between recurrences.
   */
  interval: number;

  /**
   * Number of occurrences (optional).
   */
  count?: number;

  /**
   * Start date/time of recurrence (in ISO string format).
   */
  dtstart: string;

  /**
   * End date/time of recurrence (in ISO string format, or null for no end date).
   */
  until: string | null;

  /**
   * Positions within a set (e.g., [1, -1] for first and last occurrence of the month).
   */
  bysetpos: Array<number>;

  /**
   * Days of the week for recurrence (e.g., ["MO", "WE"] for Monday and Wednesday).
   */
  byweekday: Array<string>;

  /**
   * Months of the year for recurrence (e.g., ["1", "3"] for January and March).
   */
  bymonth: Array<string>;

  /**
   * Days of the month for recurrence (e.g., ["1", "15"] for 1st and 15th of the month).
   */
  bymonthday: Array<string>;

  /**
   * Additional option for each occurrence (e.g., "EVERY_DAY", "EVERY_WEEK").
   */
  eachOption: string;

  /**
   * Option for yearly recurrence (e.g., "EVERY_YEAR").
   */
  onTheYearOption: string;
}

/**
 * Represents an option for a specific week day.
 */
export interface WeekOption {
  /**
   * Key identifier for the week day (e.g., "monday", "tuesday").
   */
  key: string;

  /**
   * Short label key for the week day (e.g., "Mon", "Tue").
   */
  shortLabelKey: string;

  /**
   * Extra small label key for the week day (e.g., "M", "T").
   */
  xsLabelKey: string;

  /**
   * Actual value of the week day.
   */
  value: Weekday;

  /**
   * Placeholder value for the week day (e.g., "MO", "TU").
   */
  pValue: string;
}

/**
 * Represents an option for a month.
 */
export interface MonthOption {
  /**
   * Key identifier for the month (e.g., "january", "february").
   */
  key: string;

  /**
   * Short label key for the month (e.g., "Jan", "Feb").
   */
  shortLabelKey: string;

  /**
   * Numeric value representing the month (1 for January, 2 for February, etc.).
   */
  value: number;
}

/**
 * Represents an interface with a label and a numeric value.
 */
export interface IValueLabel {
  /**
   * Label associated with the value.
   */
  label: string;

  /**
   * Numeric value.
   */
  value: number;
}

/**
 * List of recurrence options for events.
 */
export const rOptionsList = [
  {
    key: 'doesNotRepeat',
    value: 'doesNotRepeat',
  },
  {
    key: 'everyDay',
    value: 'DAILY',
    index: RRule.DAILY,
  },
  {
    key: 'everyWeek',
    value: 'WEEKLY',
    index: RRule.WEEKLY,
  },
  {
    key: 'everyMonth',
    value: 'MONTHLY',
    index: RRule.MONTHLY,
  },
  {
    key: 'everyYear',
    value: 'YEARLY',
    index: RRule.YEARLY,
  },
  {
    key: 'custom',
    value: 'custom',
  },
];

/**
 * List of options for specifying the end of recurring events.
 */
export const untilOptions = [
  {
    key: 'never',
    value: 'never',
  },
  {
    key: 'afterNumberOfTimes',
    value: 'afterNumberOfTimes',
  },
  {
    key: 'onDate',
    value: 'onDate',
  },
];

/**
 * List of frequency options for recurring events.
 */
export const FrequencyOptions = [
  {
    key: 'daily',
    value: 'DAILY',
    index: RRule.DAILY,
  },
  {
    key: 'weekly',
    value: 'WEEKLY',
    index: RRule.WEEKLY,
  },
  {
    key: 'monthly',
    value: 'MONTHLY',
    index: RRule.MONTHLY,
  },
  {
    key: 'yearly',
    value: 'YEARLY',
    index: RRule.YEARLY,
  },
];

/**
 * List of options for weekdays.
 */
export const WeekOptions: WeekOption[] = [
  {
    key: 'monday',
    shortLabelKey: 'mon',
    xsLabelKey: 'm',
    value: RRule.MO,
    pValue: 'MO',
  },
  {
    key: 'tuesday',
    shortLabelKey: 'tue',
    xsLabelKey: 't',
    value: RRule.TU,
    pValue: 'TU',
  },
  {
    key: 'wednesday',
    shortLabelKey: 'wed',
    xsLabelKey: 'w',
    value: RRule.WE,
    pValue: 'WE',
  },
  {
    key: 'thursday',
    shortLabelKey: 'thu',
    xsLabelKey: 't',
    value: RRule.TH,
    pValue: 'TH',
  },
  {
    key: 'friday',
    shortLabelKey: 'fri',
    xsLabelKey: 'f',
    value: RRule.FR,
    pValue: 'FR',
  },
  {
    key: 'saturday',
    shortLabelKey: 'sat',
    xsLabelKey: 's',
    value: RRule.SA,
    pValue: 'SA',
  },
  {
    key: 'sunday',
    shortLabelKey: 'sun',
    xsLabelKey: 's',
    value: RRule.SU,
    pValue: 'SU',
  },
];

/**
 * List of options for months.
 */
export const MonthOptions: MonthOption[] = [
  {
    key: 'january',
    shortLabelKey: 'jan',
    value: 1,
  },
  {
    key: 'february',
    shortLabelKey: 'feb',
    value: 2,
  },
  {
    key: 'march',
    shortLabelKey: 'mar',
    value: 3,
  },
  {
    key: 'april',
    shortLabelKey: 'apr',
    value: 4,
  },
  {
    key: 'may',
    shortLabelKey: 'may',
    value: 5,
  },
  {
    key: 'june',
    shortLabelKey: 'jun',
    value: 6,
  },
  {
    key: 'july',
    shortLabelKey: 'jul',
    value: 7,
  },
  {
    key: 'august',
    shortLabelKey: 'aug',
    value: 8,
  },
  {
    key: 'september',
    shortLabelKey: 'sep',
    value: 9,
  },
  {
    key: 'october',
    shortLabelKey: 'oct',
    value: 10,
  },
  {
    key: 'november',
    shortLabelKey: 'nov',
    value: 11,
  },
  {
    key: 'december',
    shortLabelKey: 'dec',
    value: 12,
  },
];

/**
 * List of options for specifying position within a set.
 */
export const SetPosOptions = [
  {
    key: 'first',
    value: 1,
  },
  {
    key: 'second',
    value: 2,
  },
  {
    key: 'third',
    value: 3,
  },
  {
    key: 'fourth',
    value: 4,
  },
  {
    key: 'fifth',
    value: 5,
  },
  {
    key: 'last',
    value: -1,
  },
];
