import { Component, EventEmitter, Output } from '@angular/core';
import { FeatherModule } from 'angular-feather';
import { TranslatePipe } from 'src/app/modules/shared/pipes/translate.pipe';
/**
 * Component representing meeting information.
 */
@Component({
  selector: 'app-meeting-info',
  templateUrl: './meeting-info.component.html',
  styleUrls: ['./meeting-info.component.scss'],
  standalone: true,
  imports: [TranslatePipe, FeatherModule],
})
export class MeetingInfoComponent {
  /**
   * Event emitter for closing the side panel.
   * Emits a boolean value to indicate whether to close the panel.
   */
  @Output() public closeSidePanel: EventEmitter<boolean> = new EventEmitter<boolean>();
}
