<div class="hs-details-card card-base">
  <div class="hs-card-wrappper">
    @if (cardTitle) {
      <div class="hs-tab-card-title flex-between">
        <h2>{{ cardTitle }}</h2>
      </div>
    }
    <div>
      <i>{{ 'dontHaveViewPermission' | translate }}</i>
    </div>
  </div>
</div>
