import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HealthSummaryTabs, PatientTabsItem, ViewMode } from '../entities/tabs.entities';

/**
 * Service for managing Health Summary Tabs
 */
@Injectable({
  providedIn: 'root',
})
export class TabsService {
  /**
   * Tracks changes to Health Summary tabs
   */
  public patientTabs$!: BehaviorSubject<HealthSummaryTabs>;

  /**
   * A BehaviorSubject that holds the currently hidden patient tabs.
   */
  public hiddenTabs$: BehaviorSubject<PatientTabsItem[]> = new BehaviorSubject<PatientTabsItem[]>([]);
  /**
   * List of Health Summary Tabs
   */
  private patientTabs: PatientTabsItem[] = [];

  /**
   * The threshold for the number of open tabs that can be displayed based on the window's width.
   */
  #openTabsThreshold: number = Math.floor(window.innerWidth / 180) - 1;

  /**
   * Initialize or load tabs
   */
  constructor() {
    this.loadTabs();
  }

  /**
   * Add and open new Patient Tab
   * @param patientTabItem - Patient Tab Item corresponding to the tab being opened
   * @param unshift - Whether to open a minimized tab in first position
   */
  public addNewTab(patientTabItem: PatientTabsItem, unshift = false): void {
    const index: number = this.patientTabs.findIndex(
      (tab: PatientTabsItem): boolean => tab.patientId === patientTabItem.patientId,
    );
    if (index === -1) {
      this.patientTabs.unshift(patientTabItem);
    } else if (unshift) {
      this.patientTabs.splice(index, 1);
      this.patientTabs.unshift(patientTabItem);
    }

    if (this.patientTabs.length > this.#openTabsThreshold) {
      this.hiddenTabs$.next([this.patientTabs.pop()!, ...this.hiddenTabs$.value]);
    }

    this.removeHiddenTab(patientTabItem.patientId);

    this.patientTabs$.next({
      activeTabId: patientTabItem.patientId,
      tabItems: this.patientTabs,
      activeTabMode: ViewMode.Maximized,
    });
  }

  /**
   * Close a patient tab
   * @param patientId - Patient ID corresponding to the closing tab
   * @param changeTabMode - Flag to tell whether tab mode needs to be changed or not
   */
  public removeTab(patientId: string, changeTabMode = false): void {
    this.patientTabs = this.patientTabs.filter((tab) => patientId !== tab.patientId);

    const hiddenTabs: PatientTabsItem[] = this.hiddenTabs$.value;
    if (this.patientTabs.length < this.#openTabsThreshold && hiddenTabs.length) {
      this.patientTabs.push(hiddenTabs.shift()!);
      this.hiddenTabs$.next(hiddenTabs);
    }

    this.patientTabs$.next({
      activeTabId: this.patientTabs.length ? this.patientTabs[0].patientId : null,
      activeTabMode: changeTabMode ? ViewMode.Minimized : null,
      tabItems: this.patientTabs,
    });
  }

  /**
   * Removes a hidden tab from the list of hidden tabs by filtering out the tab with the specified patient ID.
   */
  public removeHiddenTab(patientId: string): void {
    this.hiddenTabs$.next(this.hiddenTabs$.value.filter((tab) => tab.patientId !== patientId));
  }

  /**
   * Remove all tabs
   */
  public removeAllTabs(): void {
    this.patientTabs = [];
    this.patientTabs$.next({
      activeTabId: null,
      activeTabMode: null,
      tabItems: this.patientTabs,
    });
  }

  /**
   * Remove all hidden tabs
   */
  public removeAllHiddenTabs(): void {
    this.hiddenTabs$.next([]);
  }

  /**
   * Adjusts the threshold for the number of visible tabs based on the width of the window.
   * If the threshold changes, it hides or shows tabs accordingly.
   */
  public setTabThreshold(width: number): void {
    if (!this.patientTabs.length) return;

    const newThreshold = Math.floor(width / 180) - 1; // width of tab plus gap = 180px

    if (this.#openTabsThreshold === newThreshold) return;
    if (this.patientTabs.length === newThreshold) return;

    const hiddenTabs: PatientTabsItem[] = this.hiddenTabs$.value;
    if (newThreshold === 0) {
      this.hiddenTabs$.next([...this.patientTabs.splice(1), ...hiddenTabs]);
    } else {
      const leftoverTabs = this.patientTabs.splice(newThreshold);
      if (leftoverTabs.length) {
        this.hiddenTabs$.next([...leftoverTabs, ...hiddenTabs]);
      } else if (hiddenTabs.length) {
        const diff: number = newThreshold - this.patientTabs.length;
        this.patientTabs.push(...hiddenTabs.splice(0, Math.abs(diff)));
        this.hiddenTabs$.next(hiddenTabs);
      }
    }

    this.#openTabsThreshold = newThreshold;

    this.patientTabs$.next({
      activeTabId: null,
      activeTabMode: null,
      tabItems: this.patientTabs,
    });
  }

  /**
   * Activate or Deactivate patient
   * @param patientId - Patient ID
   * @param state - Active or Inactive
   */
  public changePatientActivity(patientId: string, state: 'Active' | 'Inactive'): void {
    for (let i = 0; i < this.patientTabs.length; i++) {
      if (this.patientTabs[i].patientId === patientId) {
        this.patientTabs[i].patientActive = state === 'Active' ? true : false;
        break;
      }
    }
    this.patientTabs$.next({
      activeTabId: patientId,
      activeTabMode: null,
      tabItems: this.patientTabs,
    });
  }

  /**
   * Save tabs to localStorage, so that it can be maintained through multiple sessions or browser tabs
   * @param tabs - Health Summary tabs data
   */
  public saveTabs(tabs: HealthSummaryTabs): void {
    try {
      localStorage.setItem('healthSummaryTabs', JSON.stringify(tabs));
      localStorage.setItem('hiddenHealthSummaryTabs', JSON.stringify(this.hiddenTabs$.value));
    } catch {}
  }

  public changeTabName(patientId: string, newName: string): void {
    const index: number = this.patientTabs.findIndex((tab) => tab.patientId === patientId);
    if (index === -1) return;

    if (this.patientTabs[index].name === newName) return;

    this.patientTabs[index].name = newName;

    this.patientTabs$.next({
      activeTabId: this.patientTabs$.value.activeTabId,
      activeTabMode: this.patientTabs$.value.activeTabMode,
      tabItems: this.patientTabs,
    });
  }

  /**
   * Load tabs from localStorage, so that it can be continued from previous sessions
   */
  private loadTabs(): void {
    let tabs: HealthSummaryTabs = {
      activeTabId: null,
      activeTabMode: null,
      tabItems: this.patientTabs,
    };
    const storedTabsString: string | null = localStorage.getItem('healthSummaryTabs');
    if (storedTabsString) {
      tabs = JSON.parse(storedTabsString);
      this.patientTabs = tabs.tabItems;
    }
    if (tabs.activeTabMode === ViewMode.Minimized) tabs.activeTabId = null;
    this.patientTabs$ = new BehaviorSubject(tabs);

    const storedHiddenTabs: string | null = localStorage.getItem('hiddenHealthSummaryTabs');
    if (!storedHiddenTabs) return;
    this.hiddenTabs$.next(JSON.parse(storedHiddenTabs));
  }
}
