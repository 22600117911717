import { Component, OnDestroy, inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { InactiveSessionWarningComponent } from 'src/app/modules/authentication/components/inactive-session-warning/inactive-session-warning.component';
import { AuthServiceService } from 'src/app/modules/authentication/services/auth-service.service';
import { CommonService } from 'src/app/modules/shared/services/common.service';

/**
 * Component that manages user idle timeout and session expiration handling.
 * It uses NgIdle to monitor user inactivity and triggers session warning and logout functionalities.
 */
@Component({
  selector: 'app-full-component',
  templateUrl: './full-component.component.html',
  styleUrls: ['./full-component.component.scss'],
})
export class FullComponentComponent implements OnDestroy {
  /** Injected instance of AuthServiceService for authentication-related operations */
  public authService = inject(AuthServiceService);
  /** Injected instance of NgIdle's Idle service for managing idle timeout */
  #idle = inject(Idle);
  /** Injected instance of MatDialog for displaying dialogs */
  #dialog = inject(MatDialog);
  /** Injected instance of CommonService for handling common functionalities */
  #commonService = inject(CommonService);
  /** Reference to the dialog displaying inactive session warning */
  #informationDialog!: MatDialogRef<InactiveSessionWarningComponent>;

  /**
   * Component responsible for managing user idle timeout and session expiration.
   * Uses NgIdle to monitor user activity and trigger session warning and logout functionalities.
   */
  constructor() {
    const accessTokenExpiryInSeconds = parseInt(localStorage.getItem('access_token_expires_in_minutes') || '20') * 60; //Considering 20 minutes as default
    this.#idle.setIdle(accessTokenExpiryInSeconds - 60);
    this.#idle.setTimeout(60);
    this.#idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.#idle.onIdleEnd.subscribe(() => {
      this.#informationDialog.close();
      this.#idle.watch();
    });

    this.#idle.onTimeout.subscribe(() => {
      this.#informationDialog.close();
      this.authService.signOut();
    });

    this.#idle.onIdleStart.subscribe(() => {
      this.#informationDialog = this.#dialog.open(InactiveSessionWarningComponent, {
        autoFocus: false,
        disableClose: true,
      });
    });

    this.#idle.onTimeoutWarning.subscribe((countdown) => {
      this.#commonService.idleCountdown.set(countdown);
    });

    if (this.authService.isAuthorized()) {
      this.#idle.watch();
    } else {
      this.#idle.stop();
    }

    this.#idle.watch();
  }

  /**
   * Stops the idle monitoring to prevent memory leaks.
   */
  public ngOnDestroy(): void {
    this.#idle.stop();
  }
}
