<div class="roles-modal">
  <div class="managed_user">
    <div class="flex-between hs-tab-card-title">
      <h2>{{ groupName }}</h2>
      <!-- : {{userData.owner?.name}} -->
      <div class="dialog-header-icons">
        <i-feather name="x" mat-dialog-close></i-feather>
      </div>
    </div>
    <!-- <div class="preferences-title-bar pb-3 d-flex justify-content-between pr-2">
    <h2>{{groupName}}</h2>
    <span>
      <i-feather name="x" class=" ml-2" mat-dialog-close></i-feather>
    </span>
  </div> -->

    <!-- <h3 class="third-title">Managing</h3> -->

    @if (isOwner) {
      <div class="form-group search-input">
        <input type="text" class="form-control me-0" [(ngModel)]="search" placeholder="Search" />
        <i-feather name="search" class="feather-20 clr-disabled"></i-feather>
      </div>
    }

    <!-- <pre>{{userData | json}}</pre> -->
    <div class="scrolling_sec">
      <div class="d-flex justify-content-between">
        <div class="user_side" [ngClass]="{ right_sec: !showRight }">
          <div class="title_user border-bottom flex-between">
            <label>{{ 'managedMembers' | translate }}</label>
            @if (isOwner && !showRight) {
              <i-feather name="plus" (click)="openAddUser()" class="feather-20 pointer"></i-feather>
            }
          </div>
          <div class="managed-member-list" [ngClass]="{ 'managed-member-space': showRight }">
            @for (user of userData | filter: search; track user) {
              <div class="name_label flex-between">
                <label>{{ user.name }}</label>
                <!-- {{isOwner}} -->
                @if (isOwner) {
                  <i-feather
                    name="x"
                    class="feather-20 pointer"
                    (click)="removeMemberInUserGroup(user.user)"
                  ></i-feather>
                }
              </div>
            }
            @if (userData.length === 0) {
              <div class="norecord">{{ 'noRecordFound' | translate }}</div>
            }
          </div>
        </div>
        @if (showRight) {
          <div class="user_side">
            <div class="title_user border-bottom flex-between">
              <label>{{ 'addManagedMembers' | translate }}</label>
              <div class="dialog-header-icons">
                <i-feather name="check" class="feather-20 pointer" (click)="onDoneClick()"></i-feather>
                <i-feather name="x" (click)="closeAddUser()" class="feather-20 pointer"></i-feather>
              </div>
            </div>
            <!-- <pre>{{userAddList | json}}</pre> -->
            <div class="user_side_checkbox-list">
              @for (user of userAddList | filter: search; track user) {
                <div class="form-group form-check">
                  <input
                    #checkboxes
                    type="checkbox"
                    class="form-check-input"
                    id="{{ user.user }}"
                    (change)="getUserData(user, $event)"
                  />
                  <label>{{ user.name }}</label>
                </div>
              }
              @if (userAddList.length === 0) {
                <div class="norecord">{{ 'noRecordFound' | translate }}</div>
              }
              @for (user of coOwnerList | filter: search; track user) {
                <div class="form-group form-check">
                  <input
                    #checkboxes
                    type="checkbox"
                    class="form-check-input"
                    id="{{ user.user }}"
                    (change)="getUserData(user, $event)"
                  />
                  <label>{{ user.name }}</label>
                </div>
              }
              @if (coOwnerList.length === 0) {
                <div class="norecord">{{ 'noRecordFound' | translate }}</div>
              }
            </div>
          </div>
        }
      </div>
    </div>
  </div>
</div>
