import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/modules/shared/services/http.service';
import { environment } from 'src/environments/environment';
import { ParamsOptions } from '../shared/interfaces/pagination-options';
/**
 * Injectable decorator marks the `GroupsService` class as a service
 */
@Injectable({
  providedIn: 'root',
})
export class GroupsService {
  /**
   * Base API URL for making HTTP requests related to groups.
   * Retrieved from the environment configuration.
   */
  apiUrl: string = environment.url.apiHost;

  /**
   * API version used for making HTTP requests to the groups API.
   * Retrieved from the environment configuration.
   */
  apiVersion: string = environment.url.version;

  /**
   * Constructor of GroupsService.
   * @param _httpService The HTTP service used to make API requests.
   * @param router The Angular router service.
   */
  constructor(
    private _httpService: HttpService,
    private router: Router,
  ) {}

  /**
   * Retrieves a list of groups based on provided parameters.
   * @param params The pagination and filtering options for the query.
   * @returns An observable of the HTTP response containing group data.
   */
  getGroups(params: ParamsOptions) {
    let url = `${this.apiUrl}${this.apiVersion}/group/client?limit=${params.limit}&page=${params.page}`;
    if (!params.filter) {
      delete params.filter;
    } else {
      url = url + `&filter=${params.filter}`;
    }
    if (!params.sort) {
      delete params.sort;
    } else {
      url = url + `&sort=${params.sort}`;
    }
    if (!params.search) {
      delete params.search;
    } else {
      url = url + `&search=${params.search}`;
    }
    return this._httpService.get(url, false);
  }

  /**
   * Retrieves details of a specific group.
   * @param gid The ID of the group to fetch details for.
   * @returns An observable of the HTTP response containing group details.
   */
  getGroupsDetail(gid: string) {
    const url = `${this.apiUrl}${this.apiVersion}/group/` + gid;
    return this._httpService.get(url);
  }

  /**
   * Adds a new group.
   * @param body The data representing the group to be added.
   * @returns An observable of the HTTP response indicating success or failure.
   */
  addGroup(body: object) {
    const url = `${this.apiUrl}${this.apiVersion}/group`;
    return this._httpService.post(url, body);
  }

  /**
   * Updates an existing group.
   * @param body The updated data for the group.
   * @param gid The ID of the group to be updated.
   * @returns An observable of the HTTP response indicating success or failure.
   */
  updateGroup(body: object, gid: string) {
    const url = `${this.apiUrl}${this.apiVersion}/group/` + gid;
    return this._httpService.patch(url, body);
  }

  /**
   * Deletes a group.
   * @param id The ID of the group to be deleted.
   * @returns An observable of the HTTP response indicating success or failure.
   */
  deleteGroup(id: string) {
    const url = `${this.apiUrl}${this.apiVersion}/group/` + id;
    return this._httpService.delete(url);
  }

  /**
   * Adds a user to a group.
   * @param body The user data to be added to the group.
   * @param gid The ID of the group to which the user will be added.
   * @returns An observable of the HTTP response indicating success or failure.
   */
  addUserGroup(body: object, gid: string) {
    const url = `${this.apiUrl}${this.apiVersion}/group/` + gid + '/user';
    return this._httpService.patch(url, body);
  }

  /**
   * Adds a user to a group with a specific user level.
   * @param body The user data to be added to the group.
   * @param gid The ID of the group to which the user will be added.
   * @param userLevel The level of the user within the group.
   * @returns An observable of the HTTP response indicating success or failure.
   */
  addGroupUser(body: object, gid: string | null | undefined, userLevel?: string) {
    const url = `${this.apiUrl}${this.apiVersion}/group/` + gid + `/${userLevel}`;
    return this._httpService.post(url, body);
  }

  /**
   * Deletes support for a user within a group.
   * @param gid The ID of the group from which support is being removed.
   * @param userLevel The level of the user within the group ('member', 'admin', etc.).
   * @param id The ID of the support item to be deleted.
   * @returns An observable of the HTTP response indicating success or failure.
   */
  deleteSupport(gid: string, userLevel?: string, id?: string) {
    const url =
      `${this.apiUrl}${this.apiVersion}/group/` + gid + `/${userLevel == 'member' ? '' : userLevel}` + `/${id}`;
    return this._httpService.delete(url);
  }
}
