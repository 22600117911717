<div class="wrapper">
  <div class="main-content">
    <app-header></app-header>
    <div class="router-content">
      <router-outlet></router-outlet>
    </div>
    <!-- show to dashboard footer -->
    <div class="footer-wrapper" *ngIf="authService.getToken() !== null">
      <app-footer></app-footer>
    </div>
  </div>
</div>
