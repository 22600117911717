<div class="card-base">
  <div class="common-card-header flex-between">
    <h2>{{ 'outOfOffice' | translate }}</h2>
    <div class="card-header-icons">
      <span *appPermissions="'self.OutOfOffice.Update'">
        <i-feather name="edit-2" *ngIf="!toggleView.outOfOffice" (click)="toggleOOOView()"></i-feather
      ></span>
      <ng-container *ngIf="toggleView.outOfOffice">
        <i-feather name="check" (click)="submit()"></i-feather>
        <i-feather name="x" (click)="toggleOOOView()"></i-feather>
      </ng-container>
    </div>
  </div>
  <p class="calender-card-info">{{ 'outOfOfficeInfoLabel' | translate }}</p>
  <ng-container *ngIf="!toggleView.outOfOffice">
    <div>
      <div class="calendar-slide-toggle">
        <strong>
          {{ 'turnOnOutOfOfficeResponse' | translate }}
        </strong>
        <mat-slide-toggle [checked]="outOfOffice.status" [disabled]="true"></mat-slide-toggle>
      </div>
    </div>
    <div class="view-detail-row">
      <div class="view-detail-item">
        <strong class="view-detail-label">{{ 'date' | translate }}:</strong>
        <div class="view-detail-info" *ngIf="outOfOffice.from && outOfOffice.to">
          {{ outOfOffice.from | date: 'mediumDate' }} {{ 'to' | translate }}
          {{ outOfOffice.to | date: 'mediumDate' }}
        </div>
        <div class="view-detail-info" *ngIf="!outOfOffice.from && !outOfOffice.to">-</div>
      </div>
      <div class="view-detail-item">
        <strong class="view-detail-label">{{ 'subject' | translate }}:</strong>
        <div class="view-detail-info">{{ outOfOffice.subject || '-' }}</div>
      </div>
      <div class="view-detail-item">
        <strong class="view-detail-label">{{ 'message' | translate }}:</strong>
        <div class="view-detail-info" [innerText]="outOfOffice.body || '-'"></div>
      </div>
    </div>
  </ng-container>
  <!-- edit mode start -->
  <div *ngIf="toggleView.outOfOffice">
    <form name="outOfOfficeForm" [formGroup]="outOfOfficeForm">
      <div class="form-group">
        <div class="calendar-slide-toggle">
          <strong>
            {{ 'turnOnOutOfOfficeResponse' | translate }}
          </strong>
          <mat-slide-toggle formControlName="status" (change)="onChangedStatus()"></mat-slide-toggle>
        </div>
      </div>
      <div class="form-group">
        <label for="" class="form-label" required>{{ 'date' | translate }}</label>
        <div class="date-block">
          <div class="date-picker">
            <input
              class="form-control"
              matInput
              [matDatepicker]="fromDatePicker"
              formControlName="from"
              placeholder="{{ 'from' | translate }}"
              [min]="outOfOfficeForm.controls.status.value ? today : null"
              [ngClass]="{
                'is-invalid': outOfOfficeForm.controls.from.invalid
              }"
              readonly
            />
            <mat-datepicker-toggle matIconSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #fromDatePicker></mat-datepicker>
            <div class="invalid-feedback" *ngIf="outOfOfficeForm.controls.from.invalid">
              <div *ngIf="outOfOfficeForm.controls.from.errors?.['required']">
                {{ 'thisIsRequiredField' | translate }}
              </div>
            </div>
          </div>
          <span class="to-label">{{ 'to' | translate }}</span>
          <div class="date-picker">
            <input
              class="form-control"
              matInput
              [matDatepicker]="toDatePicker"
              formControlName="to"
              placeholder="{{ 'to' | translate }}"
              [min]="outOfOfficeForm.controls.from.value"
              [ngClass]="{
                'is-invalid': outOfOfficeForm.controls.to.invalid
              }"
              readonly
            />
            <mat-datepicker-toggle matIconSuffix [for]="toDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #toDatePicker></mat-datepicker>
            <div class="invalid-feedback" *ngIf="outOfOfficeForm.controls.to.invalid">
              <div *ngIf="outOfOfficeForm.controls.to.errors?.['required']">
                {{ 'thisIsRequiredField' | translate }}
              </div>
            </div>
          </div>
          <div
            class="invalid-feedback"
            *ngIf="
              outOfOfficeForm.controls?.status?.value &&
              outOfOfficeForm.controls?.from?.invalid &&
              outOfOfficeForm.controls.from.errors?.['matDatepickerMin']
            "
          >
            {{ 'outOfOfficePrevDateValidationMsg' | translate }}
          </div>
        </div>
      </div>
      <div class="form-group">
        <label for="" class="form-label" required>{{ 'subject' | translate }}</label>
        <input
          type="text"
          class="form-control"
          name="subject"
          formControlName="subject"
          placeholder="{{ 'outOfOfficeNotice' | translate }}"
          [ngClass]="{
            'is-invalid':
              outOfOfficeForm.controls.subject.invalid &&
              (outOfOfficeForm.controls.subject.pristine || outOfOfficeForm.controls.subject.touched)
          }"
        />
        <div
          *ngIf="
            outOfOfficeForm.controls.subject.invalid &&
            (outOfOfficeForm.controls.subject.pristine || outOfOfficeForm.controls.subject.touched)
          "
          class="invalid-feedback"
        >
          <div *ngIf="outOfOfficeForm.controls.subject.errors?.['required']">
            {{ 'thisIsRequiredField' | translate }}
          </div>
        </div>
      </div>
      <div class="form-group">
        <label for="" class="form-label" required>{{ 'message' | translate }}</label>
        <textarea
          class="form-control w-100"
          cols="30"
          rows="10"
          name="body"
          formControlName="body"
          [ngClass]="{
            'is-invalid':
              outOfOfficeForm.controls.body.invalid &&
              (outOfOfficeForm.controls.body.pristine || outOfOfficeForm.controls.body.touched)
          }"
        ></textarea>
        <div
          *ngIf="
            outOfOfficeForm.controls.body.invalid &&
            (outOfOfficeForm.controls.body.pristine || outOfOfficeForm.controls.body.touched)
          "
          class="invalid-feedback"
        >
          <div *ngIf="outOfOfficeForm.controls.body.errors?.['required']">
            {{ 'thisIsRequiredField' | translate }}
          </div>
        </div>
      </div>
      <a href="javascript:void(0)" class="reset-setting pointer" (click)="openResetSettingsConfirmDialog()"
        ><i-feather name="rotate-ccw" class="feather-16"></i-feather>{{ 'resetToDefaultSettings' | translate }}</a
      >
    </form>
  </div>
  <!-- edit mode end -->
</div>
