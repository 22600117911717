import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/modules/shared/services/common.service';

/**
 * Interface representing participant groups.
 */
interface IParticipantGroups {
  /**
   * The name of the participant group.
   */
  name: string;
  /**
   * The ID of the participant group.
   */
  id: string;
}

/**
 * Interface representing clinical trial filter.
 */
interface ICTFilter {
  /**
   * The name of the clinical trial filter.
   */
  name: string;
  /**
   * The ID of the clinical trial filter.
   */
  id: string;
  /**
   * An array of participant groups associated with this clinical trial filter.
   */
  participantGroups: IParticipantGroups[];
}

/**
 * Interface representing group filter.
 */
interface IGroupFilter {
  /**
   * The group name.
   */
  group: string;
  /**
   * The display name of the group.
   */
  groupName: string;
  /**
   * An array of managed roles within the group.
   */
  roles: IManagedRole[];
}

/**
 * Interface representing managed role.
 */
interface IManagedRole {
  /**
   * The ID of the managed role.
   */
  id: string;
  /**
   * The label or name of the managed role.
   */
  label: string;
}

/**
 * Component representing a header filter for managing filtering options.
 */
@Component({
  selector: 'app-header-filter',
  templateUrl: './header-filter.component.html',
  styleUrls: ['./header-filter.component.scss'],
})
export class HeaderFilterComponent implements OnInit {
  /**
   * Array of clinical trial filters.
   */
  public clincalTrailFilter: ICTFilter[] = [];
  /**
   * Array of participant groups.
   */
  public participantGroups: IParticipantGroups[] = [];
  /**
   * Array of user groups with roles.
   */
  public userGroups: IGroupFilter[] = [];
  /**
   * Array of managed roles.
   */
  public managedRoles: IManagedRole[] = [];

  /**
   * FormControl for clinical trial selection.
   */
  public clinicalTrailForm = new FormControl('');
  /**
   * FormControl for participant group selection.
   */
  public participantGroupForm = new FormControl('');
  /**
   * FormControl for user group selection.
   */
  public userGroupsForm = new FormControl('');
  /**
   * FormControl for managed role selection.
   */
  public managedRolesForm = new FormControl('');
  /**
   * Flag to toggle display of group filter.
   */
  public showGroupFilter = false;
  /**
   * Stored filter data.
   */
  public storedFilter = {};

  /**
   * Constructor of the HeaderComponent.
   * @param commonService Instance of the CommonService used for common functionalities.
   * @param router Instance of the Router service for navigation.
   * @param route Instance of the ActivatedRoute service for accessing route parameters.
   */
  constructor(
    private commonService: CommonService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  /**
   * Lifecycle hook that is called after Angular has initialized all data-bound properties
   * of a directive.
   */
  ngOnInit(): void {
    this.getClinicalTrilsFilterData();
  }

  /**
   * Event handler when user group is selected.
   */
  onUserGroupSelect() {
    this.managedRolesForm.reset();
    const selectedGroup = this.userGroupsForm.value ? this.userGroupsForm.value : '';
    this.managedRoles = this.userGroups.find((item) => item.group === selectedGroup)?.roles || [];
  }

  /**
   * Event handler when clinical trial is selected.
   */
  onClinicalTrialSelect() {
    this.participantGroupForm.reset();
    const selectedCT = this.clinicalTrailForm.value ? this.clinicalTrailForm.value : '';
    this.participantGroups = this.clincalTrailFilter.find((item) => item.id === selectedCT)?.participantGroups || [];
  }

  /**
   * Apply filter and store data.
   */
  public onApplyFilter() {
    const selectedFilter = {
      clinicalTrials: this.clinicalTrailForm.value ? this.clinicalTrailForm.value : '',
      participantGroups: this.participantGroupForm.value ? this.participantGroupForm.value : '',
      userGroups: this.userGroupsForm.value ? this.userGroupsForm.value : '',
      managedRoles: this.managedRolesForm.value ? this.managedRolesForm.value : '',
    };
    this.commonService.storeMasterFilterData(selectedFilter);
    this.reloadCurrentRoute();
  }

  /**
   * Reset filter to initial state.
   */
  public onResetFilter() {
    this.clinicalTrailForm.reset();
    this.participantGroupForm.reset();
    this.userGroupsForm.reset();
    this.managedRolesForm.reset();
    this.commonService.removeMasterFilterData();
    this.reloadCurrentRoute();
  }

  /**
   * Retrieves clinical trial filter data from the backend API.
   * If no clinical trial filters are returned, it also fetches user groups filter data.
   */
  private getClinicalTrilsFilterData() {
    this.commonService.get('/dashboard/filter/clinicaltrial').subscribe((res) => {
      this.clincalTrailFilter = res;
      if (this.clincalTrailFilter && this.clincalTrailFilter.length === 0) {
        this.getUserGroupsFilterData();
      }
      this.setStoredData();
    });
  }

  /**
   * Fetch user groups filter data from service.
   */
  private getUserGroupsFilterData() {
    this.commonService.get('/dashboard/filter/usergroups').subscribe((res) => {
      this.userGroups = res;
      this.setStoredData();
    });
  }

  /**
   * Set stored filter data on component initialization.
   */
  private setStoredData() {
    const storedFilter = this.commonService.getStoredMasterFilterData();
    this.clinicalTrailForm?.setValue(storedFilter.clinicalTrials || '');
    this.onClinicalTrialSelect();
    this.participantGroupForm?.setValue(storedFilter.participantGroups);
    this.userGroupsForm?.setValue(storedFilter.userGroups);
    this.onUserGroupSelect();
    this.managedRolesForm?.setValue(storedFilter.managedRoles);
  }

  /**
   * Reload the current route after filter change.
   */
  private reloadCurrentRoute() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/temp-filter', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }
}
