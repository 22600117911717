import { CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';
import { AsyncPipe, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, WritableSignal, inject, signal } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { FeatherModule } from 'angular-feather';
import { ToastrService } from 'ngx-toastr';
import { TranslatePipe } from 'src/app/modules/shared/pipes/translate.pipe';
import { ACSService } from 'src/app/modules/shared/services/acs.service';
import { BackdropAndLoaderService } from 'src/app/modules/shared/services/backdrop-and-loader.service';
import { HttpService } from 'src/app/modules/shared/services/http.service';
import { VideoLobbyService } from '../../services/video-lobby.service';
import { VideoCallComponent } from '../video-call/video-call.component';
import { VideoListComponent } from '../video-list/video-list.component';
/**
 * Component representing the video lobby view.
 */
@Component({
  selector: 'app-video-lobby',
  templateUrl: './video-lobby.component.html',
  styleUrls: ['./video-lobby.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    FeatherModule,
    TranslatePipe,
    VideoListComponent,
    VideoCallComponent,
    AsyncPipe,
    CdkDrag,
    CdkDragHandle,
    NgClass,
    NgbTooltipModule
],
})
export class VideoLobbyComponent implements OnInit, OnDestroy {
  /** Signal to manage whether to show the video call component. */
  public showVideoCallComponent: WritableSignal<boolean> = signal(false);
  /** Injected instance of VideoLobbyService for managing video lobby operations. */
  public videoLobbyService = inject(VideoLobbyService);
  /** Injected instance of ACSService for ACS related functionalities. */
  #acsService = inject(ACSService);
  /** Self reference to the MatDialogRef of this component. */
  #selfRef = inject(MatDialogRef<VideoLobbyComponent>);
  /** Injected instance of ToastrService for displaying toast notifications. */
  #toast = inject(ToastrService);
  /** Injected instance of HttpService for HTTP related operations. */
  #http = inject(HttpService);
  /** Injected instance of BackdropAndLoaderService for managing backdrop and loader display. */
  #backdropAndLoaderService = inject(BackdropAndLoaderService);
  /**
   * Initializes the component:
   * - Shows backdrop and loader.
   * - Initializes video lobby.
   * - Handles ACS service and connection errors.
   */
  public async ngOnInit(): Promise<void> {
    setTimeout(() => {
      this.#backdropAndLoaderService.showBackdrop.set(true);
      this.#http.loader.next(true);
    }, 0);
    await this.videoLobbyService.initVideoLobby();
    if (!this.#acsService.callClient || !this.#acsService.callAgent) {
      this.#toast.error(
        'There was a error while connecting to Video Lobby. Please try again later',
        'Connection Error',
      );
      this.#selfRef.close();
      return;
    }
    this.showVideoCallComponent.set(true);
    setTimeout(() => this.#http.loader.next(false), 0);
  }
  /**
   * Cleans up resources:
   * - Hides backdrop and loader.
   * - Signals dialog loading completion.
   */
  public ngOnDestroy(): void {
    this.#backdropAndLoaderService.showBackdrop.set(false);
    this.videoLobbyService.dialogLoadingComplete.next(false);
  }
  /**
   * Stops the local video stream and closes the video lobby dialog.
   * Also stops call recording, ends the call, and resets camera and microphone statuses.
   */
  public stopLocalVideoStream(): void {
    this.showVideoCallComponent.set(false);
    this.videoLobbyService.stopCallRecording();
    this.videoLobbyService.endCall(false);
    this.videoLobbyService.cameraStatus.set('Off');
    this.videoLobbyService.microphoneStatus.set('Off');
    this.#selfRef.close();
    this.videoLobbyService.callInProgress$.next(false);
    this.videoLobbyService.pipMode.set(false);
  }
}
