<div class="device-settings">
  <div class="d-flex justify-content-between mb-4">
    <h3 class="mb-0">{{ 'deviceSettings' | translate }}</h3>
    <i-feather
      name="x"
      class="feather-20 text-secondary pointer"
      (click)="videoLobbyService.sideMenu.set(null)"
    ></i-feather>
  </div>
  <p class="label-heading mb-4">{{ 'audiosettings' | translate }}</p>

  <form [formGroup]="deviceSettingsForm">
    <div class="form-group form-group-mat-select device-setting-select">
      <label class="form-label">{{ 'speaker' | translate }}</label>
      <div class="custom-select-arrow">
        <mat-form-field class="md-mat-select">
          <mat-select formControlName="speaker">
            @for (speaker of availableSpeakers; track speaker.id) {
              <mat-option [value]="speaker.id">{{ speaker.name }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="form-group form-group-mat-select device-setting-select">
      <label class="form-label">{{ 'microphone' | translate }}</label>
      <div class="custom-select-arrow">
        <mat-form-field class="md-mat-select">
          <mat-select formControlName="mic">
            @for (mic of availableMicrophones; track mic.id) {
              <mat-option [value]="mic.id">{{ mic.name }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <p class="label-heading my-4">{{ 'videosettings' | translate }}</p>

    <div class="form-group form-group-mat-select device-setting-select">
      <label class="form-label">{{ 'camera' | translate }}</label>
      <div class="custom-select-arrow">
        <mat-form-field class="md-mat-select">
          <mat-select formControlName="camera">
            @for (camera of availableCameras; track camera.id) {
              <mat-option [value]="camera.id">{{ camera.name }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="form-group form-check-flex align-items-center justify-content-between">
      <label>
        {{ 'mirrormyvideo' | translate }}&nbsp;
        <i-feather
          name="info"
          class="feather-20 pointer ms-1"
          ngbTooltip="{{ 'mirrorMyVideoTooltip' | translate }}"
          aria-label="info"
        ></i-feather>
      </label>
      <mat-slide-toggle formControlName="invertVideo"></mat-slide-toggle>
    </div>
  </form>
</div>
