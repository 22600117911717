import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Directive, ElementRef, EventEmitter, Inject, OnDestroy, Output } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
/**
 * Directive to detect clicks outside of a specified element and emit an event when detected.
 */
@Directive({
  selector: '[clickOutSide]',
})
export class ClickOutSideDirective implements AfterViewInit, OnDestroy {
  /** Event emitter to emit when a click occurs outside of the specified element. */
  @Output() clickedOutSide = new EventEmitter<void>();
  /** Subscription to handle outside click events. */
  outsideClickSubscription!: Subscription;
  /**
   * Constructor to inject dependencies.
   * @param element Reference to the host element of the directive.
   * @param document Reference to the global document object.
   */
  constructor(
    private element: ElementRef,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  /**
   * Lifecycle hook called after the view and child views are initialized.
   * Sets up a subscription to detect clicks outside of the host element.
   */
  ngAfterViewInit(): void {
    this.outsideClickSubscription = fromEvent(this.document, 'click')
      .pipe(
        filter((elem: any) => {
          return !this.isInside(elem?.target as HTMLElement);
        }),
      )
      .subscribe(() => {
        this.clickedOutSide.emit();
      });
  }
  /**
   * Checks if a given element is inside the host element.
   * @param elementToCheck The element to check if it is inside the host element.
   * @returns True if the elementToCheck is inside the host element, false otherwise.
   */
  isInside(elementToCheck: HTMLElement): boolean {
    return elementToCheck === this.element.nativeElement || this.element.nativeElement.contains(elementToCheck);
  }
  /**
   * Lifecycle hook called when the directive is being destroyed.
   * Unsubscribes from all subscriptions to prevent memory leaks.
   */
  ngOnDestroy() {
    this.outsideClickSubscription.unsubscribe();
  }
}
