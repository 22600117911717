<div class="confirmation-dialog dialog-sm">
  <div class="hs-tab-card-title flex-between">
    @if (data.title) {
      <h2>{{ data.title }}</h2>
    }
    <div class="dialog-header-icons">
      <i-feather name="x" class="pointer" [mat-dialog-close]="false"></i-feather>
    </div>
  </div>
  <mat-dialog-content class="mat-dialog-content mb-1">
    <div [innerHTML]="data.message"></div>
  </mat-dialog-content>
</div>
