<div class="table-wrapper nudges-table">
  <div class="table-responsive mb-0">
    <table class="mat-mdc-table">
      <tr class="mat-mdc-header-row">
        <th class="mat-mdc-header-cell">{{ 'notification' | translate }}</th>
        <th class="mat-mdc-header-cell toggle-cell-width" *ngIf="checkedChannels('sms')">{{ 'sms' | translate }}</th>
        <th class="mat-mdc-header-cell toggle-cell-width" *ngIf="checkedChannels('email')">
          {{ 'email' | translate }}
        </th>
        <th class="mat-mdc-header-cell toggle-cell-width" *ngIf="checkedChannels('inApp')">
          {{ 'inAppNotification' | translate }}
        </th>
        <th class="mat-mdc-header-cell toggle-cell-width" *ngIf="checkedChannels('push')">
          {{ 'pushNotification' | translate }}
        </th>
      </tr>

      <ng-container *ngFor="let item of notificationSettingsData; index as i">
        <tr
          class="mat-mdc-row"
          *ngIf="item.title !== 'Medication Engagement' && item.title !== 'Application Engagement'"
        >
          <td class="mat-mdc-cell">
            <div
              [ngClass]="{
                'cell-has-child justify-content-start': item.resendAfter !== undefined && item.resendAfter !== undefined
              }"
            >
              <strong>{{ item.title }} </strong>
              <!-- <i-feather name="{{appEngagement ? 'chevron-up' : 'chevron-down'}}"
                            class="feather-20 pointer" (click)="applicationEngagement()"></i-feather> -->

              <span>
                <div
                  class="flex-between reminder-gap"
                  *ngIf="item.resendAfter !== undefined && item.resendAfter !== undefined"
                >
                  <div class="flex-between">
                    <span class="pe-2">{{ 'resendAfter' | translate }}</span>
                    <div class="form-group mb-0">
                      <input
                        type="text"
                        maxlength="4"
                        appOnlyNumber
                        [(ngModel)]="item.resendAfter"
                        class="reminder-input form-control"
                        (change)="getUpdatedRow(item)"
                        [ngClass]="{ 'is-invalid': invitationReminder }"
                      />
                    </div>
                    <span class="ps-1">{{ 'days(s)' | translate }} </span>
                  </div>
                  <div class="flex-between">
                    <span class="pe-2">#{{ 'attempts' | translate }}</span>
                    <div class="form-group mb-0">
                      <input
                        type="text"
                        maxlength="4"
                        appOnlyNumber
                        class="reminder-input form-control"
                        (change)="getUpdatedRow(item)"
                        [(ngModel)]="item.attempts"
                        [ngClass]="{ 'is-invalid': invitationReminderAttempts }"
                      />
                    </div>
                  </div>
                </div>
              </span>
            </div>
          </td>
          <ng-container *ngIf="item?.communicationChannels">
            <td class="mat-mdc-cell toggle-cell" *ngIf="checkedChannels('sms')">
              <span *ngIf="item?.communicationChannels.smsChannel">
                <mat-slide-toggle
                  [(ngModel)]="item.communicationChannels.sms"
                  (change)="getUpdatedRow(item)"
                ></mat-slide-toggle>
              </span>
            </td>
            <td class="mat-mdc-cell toggle-cell" *ngIf="checkedChannels('email')">
              <span *ngIf="item.communicationChannels.emailChannel">
                <mat-slide-toggle
                  [(ngModel)]="item.communicationChannels.email"
                  (change)="getUpdatedRow(item)"
                ></mat-slide-toggle>
              </span>
            </td>
            <td class="mat-mdc-cell toggle-cell" *ngIf="checkedChannels('inApp')">
              <span *ngIf="item.communicationChannels.inAppChannel">
                <mat-slide-toggle
                  [(ngModel)]="item.communicationChannels.inApp"
                  (change)="getUpdatedRow(item)"
                ></mat-slide-toggle>
              </span>
            </td>
            <td class="mat-mdc-cell toggle-cell" *ngIf="checkedChannels('push')">
              <span *ngIf="item.communicationChannels.pushChannel">
                <mat-slide-toggle
                  [(ngModel)]="item.communicationChannels.push"
                  (change)="getUpdatedRow(item)"
                ></mat-slide-toggle>
              </span>
            </td>
          </ng-container>
        </tr>

        <!-- <ng-container *ngIf="item[item.key]">
                    <tr class="mat-row" *ngFor="let data of item[item.key]">
                        <td class="mat-mdc-cell"> {{ data.title }}                        
                        </td>
                        <td class="mat-mdc-cell toggle-cell">
                            <span *ngIf="data.communicationChannels.smsChannel">
                                <mat-slide-toggle [(ngModel)]="data.communicationChannels.sms"
                                    (change)="getUpdatedRowNested(item,item.key)"></mat-slide-toggle>
                            </span>
                        </td>
                        <td class="mat-mdc-cell toggle-cell">
                            <span *ngIf="data.communicationChannels.emailChannel">
                                <mat-slide-toggle [(ngModel)]="data.communicationChannels.email"
                                    (change)="getUpdatedRowNested(item,item.key)"></mat-slide-toggle>
                            </span>
                        </td>
                        <td class="mat-mdc-cell toggle-cell">
                            <span *ngIf="data.communicationChannels.inAppChannel">
                                <mat-slide-toggle [(ngModel)]="data.communicationChannels.inApp"
                                    (change)="getUpdatedRowNested(item,item.key)"></mat-slide-toggle>
                            </span>
                        </td>
                        <td class="mat-mdc-cell toggle-cell">
                            <span *ngIf="data.communicationChannels.pushChannel">
                                <mat-slide-toggle [(ngModel)]="data.communicationChannels.push"
                                    (change)="getUpdatedRowNested(item,item.key)"></mat-slide-toggle>
                            </span>
                        </td>
                    </tr>
                </ng-container> -->
      </ng-container>
    </table>
  </div>
</div>

<!-- table end -->
