<div class="roles-modal">
  <div class="managed_user">
    <div class="flex-between hs-tab-card-title">
      <h2>{{ groupName }}</h2>
      <!-- : {{userData.owner?.name}} -->
      <div class="dialog-header-icons">
        <i-feather name="x" mat-dialog-close></i-feather>
      </div>
    </div>
    <!-- <div class="preferences-title-bar pb-3 d-flex justify-content-between pr-2">
          <h2>{{groupName}}</h2>
          <span>
            <i-feather name="x" class=" ml-2" mat-dialog-close></i-feather> 
          </span>
        </div> -->

    <!-- <h3 class="third-title">Managing</h3> -->

    <div *ngIf="isOwner" class="form-group search-input">
      <input type="text" class="form-control me-0" [(ngModel)]="search" placeholder="Search" />
      <i-feather name="search" class="feather-20 clr-disabled"></i-feather>
    </div>

    <!-- <pre>{{userData | json}}</pre> -->
    <div class="scrolling_sec">
      <div class="d-flex justify-content-between">
        <div class="user_side" [ngClass]="{ right_sec: !showRight }">
          <div class="title_user border-bottom flex-between">
            <label>{{ 'managedMembers' | translate }}</label>
            <i-feather
              name="plus"
              *ngIf="isOwner && !showRight"
              (click)="openAddUser()"
              class="feather-20 pointer"
            ></i-feather>
          </div>
          <div class="managed-member-list" [ngClass]="{ 'managed-member-space': showRight }">
            <div class="name_label flex-between" *ngFor="let user of userData | filter: search">
              <label>{{ user.name }}</label>
              <!-- {{isOwner}} -->
              <i-feather
                name="x"
                *ngIf="isOwner"
                class="feather-20 pointer"
                (click)="removeMemberInUserGroup(user.user)"
              ></i-feather>
            </div>
            <div class="norecord" *ngIf="userData?.length === 0">{{ 'noRecordFound' | translate }}</div>
          </div>
        </div>
        <div class="user_side" *ngIf="showRight">
          <div class="title_user border-bottom flex-between">
            <label>{{ 'addManagedMembers' | translate }}</label>
            <div class="dialog-header-icons">
              <i-feather name="check" class="feather-20 pointer" (click)="onDoneClick()"></i-feather>
              <i-feather name="x" (click)="closeAddUser()" class="feather-20 pointer"></i-feather>
            </div>
          </div>
          <!-- <pre>{{userAddList | json}}</pre> -->
          <div class="user_side_checkbox-list">
            <div class="form-group form-check" *ngFor="let user of userAddList | filter: search">
              <input
                #checkboxes
                type="checkbox"
                class="form-check-input"
                id="{{ user.user }}"
                (change)="getUserData(user, $event)"
              />
              <label>{{ user.name }}</label>
            </div>
            <div class="norecord" *ngIf="userAddList.length === 0">{{ 'noRecordFound' | translate }}</div>
            <div class="form-group form-check" *ngFor="let user of coOwnerList | filter: search">
              <input
                #checkboxes
                type="checkbox"
                class="form-check-input"
                id="{{ user.user }}"
                (change)="getUserData(user, $event)"
              />
              <label>{{ user.name }}</label>
            </div>
            <div class="norecord" *ngIf="coOwnerList.length === 0">{{ 'noRecordFound' | translate }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
