import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

/**
 * Component that handles access-related functionalities.
 * This component retrieves client ID and user ID from query parameters.
 */
@Component({
  selector: 'app-access',
  templateUrl: './access.component.html',
  styleUrls: ['./access.component.scss'],
})
export class AccessComponent {
  /**
   * The client ID retrieved from the query parameters.
   */
  clientID: string | null;
  /**
   * The user ID retrieved from the query parameters.
   */
  uid: string | null;

  /**
   * Constructs the AccessComponent with necessary dependencies.
   * @param router - The Router service for navigation.
   * @param route - The ActivatedRoute service for accessing route information.
   */
  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.clientID = this.route.snapshot.queryParamMap.get('clientId');
    this.uid = this.route.snapshot.queryParamMap.get('uid');
  }
}
