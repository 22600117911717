import { Directive, ElementRef, HostListener, Input } from '@angular/core';
/**
 * Directive to restrict input to alphanumeric characters and underscores,
 * applied using [specialIsAlphaNumeric] attribute selector.
 */
@Directive({
  selector: '[specialIsAlphaNumeric]',
})
export class SpecialCharacterDirective {
  /**
   * Regular expression string to match alphanumeric characters and underscores.
   */
  regexStr = '^[a-zA-Z0-9_]+$';
  /**
   * Input property to determine if the directive allows alphanumeric input only.
   */
  @Input() isAlphaNumeric!: boolean;

  /**
   * Constructor to inject ElementRef for accessing the host element.
   * @param el The ElementRef injected to access the host element.
   */
  constructor(private el: ElementRef) {}

  /**
   * Host listener for 'keypress' event to validate input against regex.
   * @param event The keyboard event object.
   * @returns Returns true if the keypress matches the regex, false otherwise.
   */
  @HostListener('keypress', ['$event']) onKeyPress(event: KeyboardEvent) {
    return new RegExp(this.regexStr).test(event.key);
  }

  /**
   * Host listener for 'paste' event to block pasting invalid characters.
   * @param event The paste event object.
   */
  @HostListener('paste', ['$event']) blockPaste(event: KeyboardEvent) {
    this.validateFields(event);
  }
  /**
   * Validates the input field value, removing non-alphanumeric characters and spaces.
   * @param event The keyboard or paste event object.
   */
  validateFields(event: KeyboardEvent) {
    setTimeout(() => {
      this.el.nativeElement.value = this.el.nativeElement.value.replace(/[^A-Za-z ]/g, '').replace(/\s/g, '');
      event.preventDefault();
    }, 1);
  }
}
