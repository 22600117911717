import { Directive, HostListener } from '@angular/core';
/**
 * Directive to handle clicks on anchor (`<a>`) elements by opening their `href` in a new tab.
 * Prevents the default action of navigation and stops event propagation.
 */
@Directive({
  selector: '[appCmsLinksHandler]',
})
export class CmsLinksHandlerDirective {
  /**
   * Listens for 'click' events on the host element (anchor element).
   * Opens the anchor's `href` attribute in a new tab using `window.open`.
   * Prevents the default action of navigation and stops event propagation.
   * @param event The mouse click event.
   */
  @HostListener('click', ['$event'])
  handle(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (target.nodeName === 'A') {
      event.preventDefault();
      event.stopPropagation();
      const href = target.getAttribute('href') || '';
      window.open(href, '_blank');
    }
  }
}
