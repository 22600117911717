import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

/**
 * Pipe to sanitize and bypass security for HTML content.
 */
@Pipe({
  name: 'safeHtml',
  standalone: true,
})
export class SafeHtmlPipe implements PipeTransform {
  /**
   * Constructor to inject DomSanitizer for safe HTML handling.
   * @param sanitizer The DomSanitizer service instance.
   */
  constructor(private sanitizer: DomSanitizer) {}

  /**
   * Sanitizes and bypasses security for HTML content.
   * @param message The HTML string to sanitize.
   * @returns Safe HTML content.
   */
  transform(message: string | undefined): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(message || '');
  }
}
