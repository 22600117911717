import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from 'src/app/modules/shared/services/translate.service';
/**
 * A pipe that translates keys using a TranslateService instance.
 */
@Pipe({
  name: 'translate',
  pure: false,
  standalone: true,
})
export class TranslatePipe implements PipeTransform {
  /**
   * Constructor of the TranslatePipe class.
   *
   * @param translate An instance of TranslateService used for translation operations.
   */
  constructor(private translate: TranslateService) {}
  /**
   * Transforms a key into its translated value using TranslateService.
   *
   * @param key The key to be translated.
   * @returns The translated value corresponding to the key, or the key itself if translation is not available.
   */
  transform(key: string | null | undefined): string {
    if (key) {
      return this?.translate?.data?.[key] ? this?.translate?.data?.[key] : key;
    } else {
      return key ? key : '';
    }
  }
}
