<div class="hs-tab-card-title flex-between">
  <h2>{{ 'search' | translate }}</h2>
  <i-feather name="x" class="pointer" mat-dialog-close></i-feather>
</div>
<div class="form-group search-input search-100">
  <input type="text" class="form-control" [formControl]="patient" placeholder="Search" />
  <i-feather name="search" class="feather-20 text-disabled"></i-feather>
</div>

@for (patient of patientList(); track $index) {
  <div class="user-search-list" (click)="clickedPatient(patient)">
    <div class="user-search-list-item pointer">
      <img class="patient-img" src="{{ patient.userImageUrl }}" />
      <span>{{ patient.userFullName }}</span>
    </div>
  </div>
}
