<div class="meeting">
  <div class="d-flex justify-content-between align-items-center mb-3">
    <h3 class="mb-0">
      {{ 'participants' | translate }} <span>({{ attendees().length }})</span>
    </h3>
    <i-feather
      name="x"
      class="feather-20 text-secondary pointer"
      (click)="videoLobbyService.sideMenu.set(null)"
    ></i-feather>
  </div>
  <form>
    <div class="form-group search-input search-100 mb-3">
      <input type="text" class="form-control" placeholder="{{ 'search' | translate }}" />
      <i-feather name="search" class="feather-20 icon-in-input"></i-feather>
    </div>
  </form>
  <!-- <div class="d-flex justify-content-between align-items-center mb-3">
    <h3 class="mb-0">Share Invite</h3>
    <i-feather name="share" class="feather-20 text-secondary pointer"></i-feather>
    <i-feather name="x" class="feather-20 text-secondary pointer"></i-feather>
    <span class="text-success">
      <i-feather name="check" class="feather-20"></i-feather>
      Event Shared
    </span>
  </div> -->
  <!-- <form>
    <div class="form-group">
      <label>{{ 'name' | translate }}*</label>
      <input type="text" formControlName="name" id="name" class="form-control" />
    </div>
    <div class="form-group">
      <label>{{ 'email' | translate }}</label>
      <input type="text" formControlName="email" id="email" class="form-control" />
    </div>
    <div class="form-group">
      <label>{{ 'phone' | translate }}</label>
      <input type="text" formControlName="phone" id="phone" class="form-control" />
    </div>
    <div class="my-3">
      <button type="submit" class="btn btn-primary">{{ 'shareEvent' | translate }}</button>
    </div>
  </form> -->

  <div class="meeting-dropdown">
    @if (totalJoinedParticipants()) {
      <h3 class="flex-between mb-3 pointer" (click)="showInThisMeeting = !showInThisMeeting">
        <span class="ngb-dropdown-text">{{ 'inThisMeeting' | translate }} ({{ totalJoinedParticipants() }})</span>
        <i-feather name="{{ showInThisMeeting ? 'chevron-up' : 'chevron-down' }}" class="feather-16 video-lobby-arrow">
        </i-feather>
      </h3>
      @if (showInThisMeeting) {
        @for (participant of attendees(); track $index) {
          @if (participant.remoteParticipantData) {
            <div class="meeting-participant">
              <img [src]="participant.imageUrl" />
              <p>
                {{ participant.firstName }} {{ participant.lastName }}&nbsp;
                @if (hostAcsId === participant.acsId) {
                  ({{ 'organizer' | translate }})
                }
              </p>

              @if (participant.acsId !== hostAcsId) {
                <span class="group-icons">
                  @if (participant.microphoneStatus()) {
                    <i-feather
                      name="mic"
                      class="feather-20 text-success"
                      [ngClass]="{ pointer: hostAcsId === selfAcsId }"
                      (click)="muteParticipant(participant)"
                    ></i-feather>
                  } @else {
                    <i-feather name="mic-off" class="feather-20 clr-secondary"></i-feather>
                  }
                  <i-feather
                    [name]="participant.cameraStatus() ? 'video' : 'video-off'"
                    class="feather-20"
                    [ngClass]="participant.cameraStatus() ? 'text-success' : 'clr-secondary'"
                  ></i-feather>
                </span>
              } @else {
                <span class="group-icons">
                  <i-feather
                    [name]="videoLobbyService.microphoneStatus() === 'On' ? 'mic' : 'mic-off'"
                    class="feather-20 pointer"
                    [ngClass]="videoLobbyService.microphoneStatus() === 'On' ? 'text-success' : 'clr-secondary'"
                    (click)="
                      videoLobbyService.microphoneStatus() === 'On'
                        ? videoLobbyService.muteAudio()
                        : videoLobbyService.unmuteAudio()
                    "
                  ></i-feather>
                  <!-- fix below -->
                  <i-feather
                    [name]="videoLobbyService.cameraStatus() === 'On' ? 'video' : 'video-off'"
                    class="feather-20 pointer"
                    [ngClass]="videoLobbyService.cameraStatus() === 'On' ? 'text-success' : 'clr-secondary'"
                    (click)="
                      videoLobbyService.cameraStatus() === 'On'
                        ? videoLobbyService.muteVideo()
                        : videoLobbyService.unmuteVideo()
                    "
                  ></i-feather>
                </span>
              }
            </div>
          }
        }
      }
    }

    @if (attendees().length - totalJoinedParticipants() > 0) {
      <h3 class="flex-between mb-3 pointer" (click)="showNotJoined = !showNotJoined">
        <span class="ngb-dropdown-text"
          >{{ 'notJoined' | translate }} ({{ attendees().length - totalJoinedParticipants() }})</span
        >
        <i-feather name="{{ showNotJoined ? 'chevron-up' : 'chevron-down' }}" class="feather-16 video-lobby-arrow">
        </i-feather>
      </h3>
      @if (showNotJoined) {
        @for (participant of attendees(); track $index) {
          @if (!participant.remoteParticipantData) {
            <div class="meeting-participant">
              <img [src]="participant.imageUrl" />
              <p>
                {{ participant.firstName }} {{ participant.lastName }}&nbsp;
                @if (hostAcsId === participant.acsId) {
                  ({{ 'organizer' | translate }})
                }
              </p>
            </div>
          }
        }
      }
    }
  </div>
</div>
