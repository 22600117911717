import { DatePipe } from '@angular/common';
import { Component, Inject, WritableSignal, inject, signal } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { EventInput } from '@fullcalendar/core';
import { FeatherModule } from 'angular-feather';
import { ToastrService } from 'ngx-toastr';
import { AddEditEventComponent } from 'src/app/modules/calendar/components/add-edit-event/add-edit-event.component';
import { CalendarService } from 'src/app/modules/calendar/services/calendar.service';
import { PermissionsModule } from 'src/app/modules/permissions/permissions.module';
import { PermissionsService } from 'src/app/modules/permissions/services/permissions.service';
import { TranslatePipe } from 'src/app/modules/shared/pipes/translate.pipe';
import { Attendant } from '../../entities/calendar-entities';
/**
 * Component to display and manage event details.
 */
@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.scss'],
  standalone: true,
  imports: [TranslatePipe, FeatherModule, MatDialogModule, DatePipe, PermissionsModule],
})
export class EventDetailsComponent {
  /**
   * Date format used for displaying dates.
   */
  dtFormat = 'MMM d, y, h:mm a';
  /**
   * Holds the details of the event being displayed.
   */
  eventDetails!: EventInput;
  /**
   * Signal to control whether editing of the event is disabled.
   */
  public disableEditing: WritableSignal<boolean> = signal(false);
  /** Private field for ToastrService instance */
  #toast = inject(ToastrService);
  /** Private field to store the current date */
  #currentDate!: Date;

  /**
   * Constructor for EventDetailsComponent.
   * @param dialog Injected instance of MatDialog for dialog operations.
   * @param dialogRef Reference to the MatDialogRef of the current dialog instance.
   * @param data Data object containing attendees and event details.
   * @param calendarService Service for calendar-related operations.
   * @param permissionsService Service for handling permissions.
   */
  constructor(
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<EventDetailsComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { attendees: Array<{ label: string; users: Attendant[] }>; eventDetails: EventInput },
    private calendarService: CalendarService,
    private permissionsService: PermissionsService,
  ) {
    this.#setExtendedEventProp();
  }

  /**
   * Opens the dialog for editing the event.
   */
  public edit(): void {
    this.#currentDate = new Date();
    const validTill = new Date(this.eventDetails.eventEnd);
    if (this.#currentDate.getTime() >= validTill.getTime()) {
      this.#toast.error('This event is no longer valid. Please create a new event.');
      this.disableEditing.set(true);
      return;
    }

    if (this.permissionsService.hasPermission('self.Calendar.Update')) {
      this.#openAddEditEventDialog();
    }
  }

  /**
   * Sets extended properties for the event details.
   */
  #setExtendedEventProp(): void {
    if (!this.data.eventDetails?.extendedProps?.virtualVisit) {
      this.data.eventDetails.setExtendedProp('id', this.data.eventDetails.id || '');
      this.data.eventDetails.setExtendedProp('title', this.data.eventDetails.title);
      this.data.eventDetails.setExtendedProp('allDay', this.data.eventDetails.allDay ? true : false);
    }

    this.data.eventDetails = this.calendarService.getEventDetails(this.data.eventDetails.extendedProps || {});
    this.data.eventDetails.id = this.data.eventDetails.isVirtualVisit
      ? this.data.eventDetails.roomId
      : this.data.eventDetails.id;
    this.eventDetails = { ...this.data.eventDetails };
    this.eventDetails.attendees = this.calendarService.getAttendeesGroupByUserType(this.eventDetails.attendees);

    this.#currentDate = new Date();
    if (this.#currentDate.getTime() >= new Date(this.eventDetails.eventEnd).getTime()) {
      this.disableEditing.set(true);
    }
  }

  /**
   * Opens the add/edit event dialog.
   */
  #openAddEditEventDialog(): void {
    this.dialog.open(AddEditEventComponent, {
      data: this.data,
      panelClass: 'full-screen-dialog',
    });
    this.dialogRef.close();
  }
}
