<div class="video-lobby-left">
  @for (lobbyList of videoLobbyList(); track lobbyList) {
    <div class="video-list" [ngClass]="{ 'video-event-disabled': lobbyList.enableCall() === 'Past' }">
      <div class="video-card">
        <div class="flex-between mb-1 align-items-start">
          <div class="video-widget-row">
            <h1 class="clr-secondary mb-1">
              {{ lobbyList.validFrom | date: 'hh:mm a' }}
            </h1>
            <div class="video-event-title mb-2">
              <h3 class="ff-medium mb-0">{{ 'title' | translate }}: {{ lobbyList.eventTitle }}</h3>
              <i-feather name="info" class="feather-16 pointer" (click)="openEventDetails(lobbyList)"></i-feather>
            </div>
          </div>
          @if (lobbyList.callStatusSignal() === 'Started') {
            <div
              class="list-item"
              [ngClass]="{
                'inprogress-list': videoLobbyService.activeCall() && !videoLobbyService.pipMode(),
                '': videoLobbyService.activeCall() && videoLobbyService.pipMode(),
                'end-0': widgetMode
              }"
            >
              <span class="progress-btn">{{ 'inProgress' | translate }}</span>
            </div>
          } @else if (lobbyList.checkedInTimer()) {
            <div class="list-item inprogress-list" [ngClass]="{ 'end-0': widgetMode }">
              <span class="progress-btn">{{ 'checkedIn' | translate }}</span>
              @if (!widgetMode) {
                <div class="time ff-bold clr-secondary">
                  {{ lobbyList.checkedInTimer() }}
                </div>
              }
            </div>
          }
        </div>
        <div class="video-widget-row space-between">
          <div class="list-item mb-2">
            <img src="{{ lobbyList.displayedParticipantInList.imageUrl }}" />
            <h3 class="ff-medium mb-0">
              {{ lobbyList.displayedParticipantInList.firstName }} {{ lobbyList.displayedParticipantInList.lastName }}
            </h3>
          </div>
          <div class="call-btn-groups">
            @if (lobbyList.enableCall() === 'Current') {
              @if (lobbyList.checkInRequestStatus() !== null && !lobbyList.checkedInTimer()) {
                <span
                  [ngClass]="lobbyList.checkInRequestStatus() ? 'color-disabled' : 'text-primary pointer'"
                  (click)="requestCheckIn(lobbyList)"
                >
                  {{ 'requestCheckIn' | translate }}
                </span>
              }
              <button
                class="btn"
                [ngClass]="{
                  'btn-primary': !videoLobbyService.activeCall(),
                  'btn-disable': videoLobbyService.activeCall()
                }"
                (click)="startCall(lobbyList)"
              >
                {{ 'joinCall' | translate }}
              </button>
            }
          </div>
        </div>
      </div>
    </div>
  }

  @if (!videoLobbyList().length) {
    <div class="no-video-list">
      {{ 'noVideoList' | translate }}
    </div>
  }
</div>
