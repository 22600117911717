import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthServiceService } from 'src/app/modules/authentication/services/auth-service.service';
import { GroupsService } from 'src/app/modules/groups-management/groups.service';
import { PermissionsService } from 'src/app/modules/permissions/services/permissions.service';
import { ConfirmationDialogComponent } from 'src/app/modules/shared/components/confirmation-dialog/confirmation-dialog.component';
import { FilterColumnOption } from 'src/app/modules/shared/interfaces/common.entities';
import { GenericTableColumn } from 'src/app/modules/shared/interfaces/generic-table';
import { PaginationOptions } from 'src/app/modules/shared/interfaces/pagination-options';
import { FilterService } from 'src/app/modules/shared/services/filter.service';
import { Utils } from 'src/app/modules/shared/utils/utils';
// import { column } from 'src/app/modules/shared/interfaces/common.entities';

/**
 * Component for managing groups, displaying a list of groups with various operations.
 */
@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss'],
})
export class GroupComponent implements OnInit, OnDestroy {
  /** Client ID used for identifying client-specific data. */
  clientId = '';

  /** Show checkbox active or not */
  showCheckbox = false;
  /** Parameters for controlling pagination, filtering, and sorting of groups. */
  params = { limit: 20, page: 1, filter: '', sort: 'name|DESC', search: '' };

  /** Pagination options object for storing pagination settings. */
  paginationOptions!: PaginationOptions;

  /** Array of groups fetched from the API. */
  groups: Array<Group> = [];

  /** Transformed data array for displaying groups in a table view. */
  data: Array<Group> = [];

  /** Array of column titles to display in the UI. */
  displayedColumns: Array<string> = [];

  /** Configuration for each column in the groups table. */
  columns: Array<GenericTableColumn> = [
    {
      key: 'name',
      title: 'Name',
      displayKey: 'name',
      filterKey: 'groups',
      searchKey: 'name',
      sort: true,
      search: {
        icon: true,
        active: false,
      },
      filter: {
        icon: false,
        active: false,
      },
    },
    {
      key: 'owners',
      title: 'Owner',
      displayKey: 'owners',
      filterKey: 'owners',
      searchKey: 'owner',
      sort: true,
      search: {
        icon: true,
        active: false,
      },
      filter: {
        icon: false,
        active: false,
      },
    },
    {
      key: 'coOwner',
      title: 'Co-owner',
      displayKey: 'coOwner',
      sort: true,
      search: {
        icon: false,
        active: false,
      },
      filter: {
        icon: false,
        active: false,
      },
    },
    {
      key: 'observers',
      title: 'Observers',
      displayKey: 'observers',
      sort: true,
      search: {
        icon: false,
        active: false,
      },
      filter: {
        icon: false,
        active: false,
      },
    },
    {
      key: 'members',
      title: 'Members',
      displayKey: 'members',
      sort: true,
      search: {
        icon: false,
        active: false,
      },
      filter: {
        icon: false,
        active: false,
      },
    },
    {
      key: 'actions',
      title: 'Actions',
      displayKey: 'actions',
      hasLink: false,
      sort: false,
      search: {
        icon: false,
        active: false,
      },
      filter: {
        icon: false,
        active: false,
      },
      icons: [
        {
          iName: 'edit',
          key: 'edit',
          class: '',
          show: false,
          route: '/groupManagment/edit',
        },
        {
          iName: 'eye',
          key: 'view',
          class: '',
          show: true,
          route: '/groupManagment/view',
        },
        {
          iName: 'trash-2',
          key: 'delete',
          class: '',
          show: this.permissionService.hasPermission('self.Management_UserGroup.Delete'),
          route: '',
        },
        {
          iName: 'settings',
          key: 'settings',
          class: '',
          show: false,
          route: '',
        },
      ],
    },
  ];

  /**
   * Constructor to initialize the component.
   * @param router Injected instance of Router for navigation.
   * @param groupsService Injected instance of GroupsService for managing groups.
   * @param filterService Injected instance of FilterService for filtering data.
   * @param toast Injected instance of ToastrService for displaying toast notifications.
   * @param dialog Injected instance of MatDialog for displaying dialogs.
   * @param permissionService Injected instance of PermissionsService for managing permissions.
   * @param authService Injected instance of AuthServiceService for authentication and user details.
   */
  constructor(
    private router: Router,
    private groupsService: GroupsService,
    private filterService: FilterService,
    private toast: ToastrService,
    private dialog: MatDialog,
    private permissionService: PermissionsService,
    private authService: AuthServiceService,
  ) {
    this.clientId = this.authService.getClientDetails()._id;
    this.displayedColumns = this.columns.map((column: GenericTableColumn) => {
      return column.title;
    });
  }

  /**  Calls the method to fetch groups data. */
  ngOnInit(): void {
    this.getGroups();
  }
  /** Retrieves groups from the backend API based on current parameters. */
  getGroups() {
    this.groupsService.getGroups(this.params).subscribe({
      next: (response) => {
        if (response?.data) {
          if (response.pagination) {
            this.paginationOptions = response.pagination;
            this.setPaginationOptions(response.pagination);
          }
          this.groups = response.data;
          this.data = response.data.map((group: Group) => {
            return this.createTableViewClients(group);
          });
        }
      },
      error: (error) => {
        if (error) {
          //  console.log(error);
        }
      },
    });
  }

  /**
   * Deletes a group based on its ID.
   * @param id The ID of the group to delete.
   */
  deleteGroup(id: string) {
    const group = this.groups.find((groupO) => groupO.id === id);
    if (!group?.isClientDefaultUserGroup) {
      const confirmDialog = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          title: 'Confirm remove user group',
          message: 'Are you sure, you want to remove an user group?',
        },
        panelClass: 'confirmation-dialog',
        hasBackdrop: false,
        disableClose: true,
      });
      confirmDialog.afterClosed().subscribe((result) => {
        if (result) {
          this.groupsService.deleteGroup(id).subscribe({
            next: (response) => {
              if (response) {
                this.toast.success('Group deleted Successfully');
                this.getGroups();
                this.router.navigateByUrl('/refresh', { skipLocationChange: true }).then(() => {
                  this.router.navigate(['/groupManagment/list'], {
                    queryParams: { clientId: this.clientId },
                    queryParamsHandling: 'merge',
                  });
                });
              }
            },
            error: (error) => {
              if (error) {
                //  console.log(error);
              }
            },
          });
        }
      });
    }
  }

  /**
   * Transforms group data into table view format.
   * @param data The group data to transform.
   * @returns Transformed table view data for the group.
   */
  createTableViewClients(data: Group) {
    const tableData = {
      id: Utils.createGenericTableKeysData(data._id, '/groupManagment/view', {
        clientId: this.clientId,
        gid: data._id,
      }),
      name: Utils.createGenericTableKeysData(data.name, '', { description: data.description || '' }),
      owners: Utils.createGenericTableKeysData(
        data.ownerDemographics?.firstName + ' ' + data.ownerDemographics?.lastName,
        '',
        {},
      ),
      coOwner: Utils.createGenericTableKeysData(data.coOwnerCount, '', {}),
      observers: Utils.createGenericTableKeysData(data.observerCount, '', {}),
      members: Utils.createGenericTableKeysData(data.memberCount, '', {}),
      isClientDefaultUserGroup: Utils.createGenericTableKeysData(data?.isClientDefaultUserGroup, '', {}),
      isUserDefaultGroup: Utils.createGenericTableKeysData(data?.isUserDefaultGroup, '', {}),
    };
    return tableData;
  }

  /** Retrieves default pagination options. */
  getDefaultPaginationOptions() {
    const paginationO = {
      limit: 20,
      currentPage: 1,
      totalCount: 0,
    };
    return paginationO;
  }

  /**
   * Sorts groups by a specific column.
   * @param $event Event containing column and order information.
   */
  sortByColumn($event: { column: string; order: string }) {
    this.params.sort = `${$event.column}|${$event.order}`;
    this.getGroups();
  }

  /**
   * Updates pagination options based on provided options.
   * @param $event Updated pagination options.
   */
  setPaginationOptions($event: PaginationOptions) {
    this.paginationOptions.limit = $event.limit;
    this.paginationOptions.currentPage = $event.currentPage;
    this.params.limit = $event.limit;
    this.params.page = $event.currentPage;
  }

  /**
   * Refreshes groups based on provided pagination options.
   * @param event Pagination options triggering the refresh.
   */
  refreshGroup(event: PaginationOptions) {
    this.setPaginationOptions(event);
    this.getGroups();
  }

  /**
   * Retrieves filtering options based on keyword and column.
   * @param $event Event containing column and search keyword.
   * @param dialog Optional dialog reference.
   */
  getOptionsByKeyword($event: FilterColumnOption, dialog: any, keyword: string) {
    this.params.search = `${$event.column?.searchKey}|${keyword}`;
    this.getOptionsByColumn($event, dialog);
  }

  /**
   * Retrieves filtering options based on column.
   * @param $event Event containing column and optional dialog reference.
   * @param dialog Optional dialog reference.
   */
  getOptionsByColumn($event: FilterColumnOption, dialog?: any) {
    const tableName = 'group/client';
    const columnName =
      $event.type && $event.type.toLowerCase() === 'search' ? $event.column?.searchKey : $event.column?.filterKey;
    this.filterService.getOptionsByColumn(tableName, columnName || '', this.params, ',', dialog);
  }

  /**
   * Clears applied filters and retrieves all groups.
   * @param $event Event containing column and filter information.
   */
  clear($event: FilterColumnOption) {
    this.params.search = '';
    this.filterService.removeFiltersByKey($event.column?.filterKey);
    this.filterService.toggleColumnActiveClass(this.columns, $event.column?.key || '');
    this.params.filter = this.filterService.getAppliedFiltersParamString();
    // this.filterService.clear(this.params);
    this.getGroups();
  }

  /**
   * Opens search dialog to filter groups by a specific column.
   * @param $event Event containing column and search information.
   * @returns Search filter dialog reference.
   */
  openSearchDialog($event: FilterColumnOption) {
    $event.search = this.filterService.getAppliedFiltersByKey($event.column?.filterKey)?.name || '';
    return this.filterService.openSearchFilterDialog($event);
  }

  /**
   * Callback function after closing a search and filters dialog.
   * @param $event Event containing column and response data.
   * @param response Response data from the dialog.
   */
  dialogAfterClosedCallBack($event: FilterColumnOption, response: any) {
    this.filterService.setAppliedFilters($event.column?.filterKey, response);
    this.filterService.toggleColumnActiveClass(this.columns, $event.column?.key || '');
    this.params.filter = this.filterService.getAppliedFiltersParamString();
    this.params.search = '';
    this.setPaginationOptions({ totalPage: 1, limit: 20, currentPage: 1 });
    this.getGroups();
  }

  /**
   * Opens search or filters dialog based on provided column option.
   * @param $event Event containing column option for search or filter.
   */
  openSearchAndFiltersDialog($event: FilterColumnOption) {
    let dialog: any;
    if ($event && $event.type) {
      if ($event.type.toLowerCase() === 'search') {
        dialog = this.openSearchDialog($event);
      }
      dialog.componentInstance.searchKeyUp.subscribe((keyword: string) => {
        this.getOptionsByKeyword($event, dialog, keyword);
      });
      dialog.componentInstance.clear.subscribe({
        next: (flag: boolean) => {
          if (flag) {
            this.clear($event);
          }
        },
        error: (error: Error) => {
          if (error) {
            //  console.log(error);
          }
        },
      });
      dialog.afterClosed().subscribe({
        next: (response: any) => {
          if (response) {
            this.dialogAfterClosedCallBack($event, response);
          }
        },
        error: (error: Error) => {
          if (error) {
            //  console.log(error);
          }
        },
      });
    }
  }

  /**
   * Cleans up component resources and resets filters when the component is destroyed.
   */
  ngOnDestroy() {
    this.filterService.resetFilters();
  }
}
/**
 * Represents a group within the application.
 */
export interface Group {
  /** Unique identifier of the group. */
  _id?: string;

  /** Deprecated: Use _id instead. */
  id?: string;

  /** Current status of the group. */
  status: string;

  /** Username of the group's owner. */
  owner: string;

  /** Name of the group. */
  name: string;

  /** Description or purpose of the group. */
  description: string;

  /** Demographic information about the group's owner. */
  ownerDemographics: {
    /** URL of the owner's profile image. */
    imageUrl: string;

    /** Last name of the owner. */
    lastName: string;

    /** First name of the owner. */
    firstName: string;
  };

  /** Number of members in the group. */
  memberCount: number;

  /** Number of observers or non-active members in the group. */
  observerCount: number;

  /** Number of co-owners or secondary owners in the group. */
  coOwnerCount: number;

  /** Indicates if this group is the default group for clients. */
  isClientDefaultUserGroup: boolean;

  /** Indicates if this group is the default group for users. */
  isUserDefaultGroup: boolean;
}
